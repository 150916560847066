import React from 'react';
import {
  TextField,
  Datagrid,
  BooleanField,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import LinkField from 'components/LinkField';

const BillingGroupsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="AccountBillingGroup"
      target="account_id"
    >
      <Datagrid bulkActionButtons={false} data-testid="accounts-billing-groups-table">
        <LinkField label="ID" source="billing_group.id" />
        <TextField
          label="Name"
          source="billing_group.name"
          data-testid="account-billing_group_name_field"
        />
        <TextField
          label="Status"
          source="billing_group.status"
          data-testid="account-billing_group_status_field"
        />
        <TextField
          label="Plan"
          source="billing_group.plan"
          data-testid="account-billing_group_plan_field"
        />
        <TextField
          label="Billing"
          source="billing_group.billing"
          data-testid="account-billing_group_billing_field"
        />
        <BooleanField
          label="Auto Pay"
          source="billing_group.auto_pay"
          data-testid="account-billing_group_auto_pay_field"
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(BillingGroupsTab);
