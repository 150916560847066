import { gql } from '@apollo/client';
import list from './List';
import create from './Create';
import show from './Show';

export default {
  name: 'professionalTravelAssignmentStates',
  inputShow: gql`
    query professionalTravelAssignmentStates($input: ProfessionalTravelAssignmentStateQueryInput) {
      professionalTravelAssignmentStates(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  create,
  options: {
    group: 'Misc',
    label: 'Professional Travel States',
  },
};
