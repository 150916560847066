import React from 'react';
import {
  SimpleShowLayout,
  NumberField,
  FunctionField,
  TextField,
  useRecordContext,
} from 'react-admin';
import Heading from 'components/Heading';
import MoneyField from 'components/MoneyField';
import { DisplayHelpers } from 'utils/helpers';
import useAssignmentCalculator from '../useAssignmentCalculator';

const ChargeRateDetailsTab = () => {
  const record = useRecordContext();
  const { chargeValues } = useAssignmentCalculator(record);

  return (
    <SimpleShowLayout>
      <Heading>Schedule</Heading>
      <NumberField label="Hours per shift" source="hours_per_shift" />
      <NumberField label="Shifts per week" source="number_of_shifts_per_week" />
      <FunctionField label="Hours per week" render={() => chargeValues.hours.total_time} />
      <NumberField label="Total hours (estimated)" source="estimated_total_hours" />

      <Heading>Billing</Heading>
      <TextField label="VMS" source="user.vms_type.label" />
      <NumberField label="VMS fee percent" source="vms_fee_percent" />
      <MoneyField label="Facility hourly bill rate" source="charge_base_hourly_rate_cents" />
      <FunctionField
        label="Facility hourly overtime bill rate"
        render={() =>
          DisplayHelpers.money(
            record?.charge_base_hourly_rate_cents * record?.charge_overtime_multiplier,
          )
        }
      />
      <NumberField source="charge_overtime_multiplier" />
      <NumberField source="charge_double_overtime_multiplier" />
      <NumberField source="charge_callback_multiplier" />
      <NumberField source="charge_max_daily_regular_hours" />
      <NumberField source="charge_max_daily_ot_hours" />
      <NumberField source="charge_max_weekly_regular_hours" />
      <MoneyField label="Hours on call rate" source="charge_on_call_hourly_rate_cents" />
      <FunctionField
        label="Weekly charge"
        render={() => DisplayHelpers.money(record?.charge_per_week_cents)}
      />
      <FunctionField
        label="Total contract value"
        render={() =>
          DisplayHelpers.money(record?.estimated_total_hours * chargeValues.rates.blended_rate)
        }
      />
    </SimpleShowLayout>
  );
};

export default ChargeRateDetailsTab;
