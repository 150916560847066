import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const QualificationsGroupShow = () => (
  <Show data-testid="qualifications-group-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

QualificationsGroupShow.query = gql`
  query qualificationsGroup($id: Int!) {
    qualificationsGroup(id: $id) {
      id
      name
      created_at
      updated_at
    }
  }
`;

export default QualificationsGroupShow;
