import React from 'react';
import { remove } from 'ramda';
import { TextInput } from 'react-admin';
import { Box, Typography, Link } from '@mui/material';
import MoneyInput from 'components/MoneyInput';
import ItemizedTransactionBalance from './ItemizedTransactionBalance';
import { useFormContext } from 'react-hook-form';

const ItemizedTransactionRemaining = ({ record, amountLeft }) => {
  const { setValue, watch } = useFormContext();
  const invoices = watch('invoices');
  const billing_group_balances = watch('billing_group_balances');
  const amount = amountLeft(record, invoices, billing_group_balances);

  return (
    <Box>
      <ItemizedTransactionBalance amount={amount} />
      {billing_group_balances.map((_, index) => (
        <Box key={index}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>Transaction #{index + 1}</Typography>
            <Link
              sx={{
                cursor: 'pointer',
                color: 'red',
              }}
              onClick={() =>
                setValue('billing_group_balances', remove(index, 1, billing_group_balances))
              }
            >
              <Typography sx={{ fontSize: '0.8rem' }}>Remove</Typography>
            </Link>
          </Box>
          <TextInput
            label="Select a billing group to apply remaining balance to"
            source={`billing_group_balances[${index}].billing_group_id`}
            fullWidth
          />

          <MoneyInput
            label="Balance"
            source={`billing_group_balances[${index}].balance_cents`}
            onChange={(e) => {
              const updatedBalances = [...billing_group_balances];
              updatedBalances[index].balance_cents = e.target.value;
              setValue('billing_group_balances', updatedBalances);
            }}
            fullWidth
          />
          <TextInput
            label="Notes"
            source={`billing_group_balances[${index}].notes`}
            multiline
            fullWidth
          />
        </Box>
      ))}
      <Link
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          setValue('billing_group_balances', [...billing_group_balances, { balance_cents: 0 }]);
        }}
      >
        <Typography>Add Transaction</Typography>
      </Link>
    </Box>
  );
};

export default ItemizedTransactionRemaining;
