import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField } from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import { IdFilter } from 'components/Filter';
import List from 'components/List';
import ProfessionalInput from 'components/ProfessionalInput';
import DisputeInput from 'components/DisputeInput';

const ExpenseAdjustmentsListFilters = [
  <IdFilter />,
  <ProfessionalInput source="search.professional_id" alwaysOn />,
  <DisputeInput alwaysOn />,
];

const ExpenseAdjustmentsList = () => (
  <List exporter={false} filters={ExpenseAdjustmentsListFilters}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <MoneyField label="Amount of Cents" source="amount_cents" />
      <LinkField source="job.id" label="Job ID" />
      <LinkField source="payout.id" label="Payout ID" />
      <LinkField source="dispute_id" />
      <LinkField source="job.professional.id" label="Professional ID" />
      <LinkField source="balance_sheet.id" label="Balance Sheet" />
      <TextField label="Taxable Type" source="taxable_type" />
      <BooleanField label="Primary Adjustment" source="primary_adjustment" />
      <BooleanField label="Past Month Adjustment" source="past_month_adjustment" />
      <TextField source="notes" />
    </Datagrid>
  </List>
);

ExpenseAdjustmentsList.query = gql`
  query GET_LIST($input: ExpenseAdjustmentQueryInput) {
    _expenseAdjustmentsMeta(input: $input) {
      count
    }
    expenseAdjustments(input: $input) {
      id
      timecard {
        id
      }
      amount_cents
      job {
        id
        professional {
          id
        }
      }
      balance_sheet {
        id
      }
      payout {
        id
        professional_id
      }
      dispute_id
      notes
      primary_adjustment
      taxable_type
      past_month_adjustment
    }
  }
`;

export default ExpenseAdjustmentsList;
