import React from 'react';
import { TextInput, BooleanInput, Create, SimpleForm } from 'react-admin';
import PositionInput from 'components/PositionInput';
import VmsTypeInput from 'components/VmsTypeInput';

const VmsPositionCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <VmsTypeInput />
      <PositionInput label="Positions" />
      <TextInput label="VMS Position Label" source="label" />
      <TextInput label="VMS Position Name" source="vms_source_id" />
      <TextInput label="VMS Category Name" source="vms_category_id" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export default VmsPositionCreate;
