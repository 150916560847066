import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Datagrid, BooleanField } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';

const ReferralSourceList = () => (
  <List filters={[<IdInput />]} exporter={false} data-testid="referral-source-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField label="Admin Only" source="admin_only" />
      <BooleanField label="Applies to Professional" source="applies_to_professionals" />
      <BooleanField label="Applies to Facilities" source="applies_to_facilities" />
    </Datagrid>
  </List>
);

ReferralSourceList.query = gql`
  query referralSources($input: ReferralSourceQueryInput) {
    _referralSourcesMeta(input: $input) {
      count
    }
    referralSources(input: $input) {
      id
      name
      label
      admin_only
      created_at
      updated_at
      applies_to_professionals
      applies_to_facilities
    }
  }
`;

export default ReferralSourceList;
