import React, { useState, useMemo } from 'react';
import { Typography, Dialog, DialogTitle, Button } from '@mui/material';
import {
  useRefresh,
  useNotify,
  SelectInput,
  required,
  FormDataConsumer,
  TextInput,
  DateInput,
  useRecordContext,
  SimpleForm,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { isAvailableForCancel, isCanceled } from './utils';
import create from 'utils/api/create';

const STAFF_REASONS: string[] = [
  'Family emergency',
  'Unable to obtain housing',
  'Unable to perform job duties',
  'Childcare issues',
  'Personal reasons',
  'Hostile environment/uncomfortable',
  'Pro Unresponsive',
  'Accepted another job',
  'Non-compliant during onboarding',
  'Scheduling conflicts',
  'Dissatisfied with decrease in hours',
  'Dissatisfied with Pay',
];

const FACILITY_REASONS: Record<string, string[]> = {
  BOOKED_OR_IN_PROGRESS: [
    'Booked professional does not have proof of Covid vaccine/negative test',
    'Booked professional lacking competency/skills',
    'Booked professional has time/attendance issues',
    'Booked professional does not meet compliance requirements',
    'Booked professional not meeting facility expectations',
    'Staff returned',
    'No longer needed due to low census',
    'No longer wish to work with Medely',
    'Offer Rescinded/retracted',
  ],
  DRAFT_OR_MATCHED_OR_AVAILABLE: [
    'No applicants',
    'No longer needed-utilizing own staff (FT or PRN)',
    'No longer needed-filled with another agency',
    'No longer needed-low patient census',
    'No longer wish to work with Medely',
    'Bill rate too expensive',
    'Posted by mistake',
  ],
};

const ADMIN_REASONS: string[] = [
  'Child Assignment cancelled',
  'Error in posting or contract',
  'Software bug',
  'No longer working with facility/MSP/VMS',
  'No longer working with professional',
  'Location settings updated (rebooked)',
  'Facility became unresponsive',
  'Bill rate update-split assignment',
];

const NON_BOOKED_REASONS: string[] = [
  'Filled by another agency',
  'Order no longer listed on VMS',
  'Cancelled by facility',
  'Duplicate (wrong facility, wrong rates, wrong position)',
  'Error in posting',
  'Change in rate or other details',
  'Stale (older than 6 months on VMS)',
  'Facility or VMS issues',
  'Child Assignment offer declined',
  'Software bug',
  'Parent assignment no longer needed',
  'Unfilled',
  'Other',
];

const unfilled_reasons: string[] = [
  'No applicants',
  'Unable to secure qualified candidates',
  'Unable to secure qualified candidates(Off Platform)',
  'Unreasonable Start Date',
  'Unreasonable Start Date > 60 days',
  'Inflexible assignment Requirements',
  'Professional No Longer Available',
];

enum Personable {
  ADMIN = 'Administrator',
  PROFESSIONAL = 'Professional',
  LOCATION = 'Location',
}

const CancelButton = () => {
  const record = useRecordContext();
  const withDate = useMemo(() => isAvailableForCancel(record), [record]);
  const refresh = useRefresh();
  const notify = useNotify();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleCancel = async ({
    cancellation_reason,
    cancellation_date,
    canceled_by,
    other_reason,
    unfilled_reason,
  }: any) => {
    const payload: any = {
      cancellation_date: withDate && cancellation_date ? cancellation_date : undefined,
      cancellation_reason,
      canceled_by,
    };

    if (cancellation_reason === 'Other') {
      payload.cancellation_reason = `Other: ${other_reason}`;
    } else if (cancellation_reason === 'Unfilled') {
      payload.cancellation_reason = `Unfilled: ${unfilled_reason}`;
    }

    try {
      await create(`/v3/admin/assignments/${record.id}/cancel`, payload, 'Cancel');
      refresh();
      notify('Assignment canceled');
      closeDialog();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message ?? 'Something went wrong';
      notify(errorMsg, { type: 'error' });
    } finally {
    }
  };

  if (isCanceled(record)) {
    return null;
  }

  const not_booked =
    record?.status === 'available' ||
    record?.status === 'matched' ||
    record?.status === 'draft' ||
    record?.status === 'unfilled';

  return (
    <>
      <Button
        size="small"
        sx={(theme) => ({
          backgroundColor: theme.palette.error.main,
          color: '#fff',
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
        })}
        onClick={openDialog}
        data-testid="assignments-cancel-button"
      >
        Cancel
      </Button>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle data-testid="assignments-cancel-dialog-title">Cancel Assignment</DialogTitle>
        <SimpleForm
          sx={{ paddingLeft: 3, paddingRight: 3 }}
          onSubmit={handleCancel}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Yes" data-testid="assignments-cancel-dialog-confirm-button" />
              <Button onClick={closeDialog} data-testid="assignments-cancel-dialog-go-back-button">
                Go Back
              </Button>
            </Toolbar>
          }
        >
          <FormDataConsumer>
            {({ formData }) => {
              const cancellationDate = formData?.cancellation_date
                ? ` on ${formData.cancellation_date}`
                : '';
              return (
                <Typography gutterBottom data-testid="assignments-cancel-dialog-confirmation">
                  Are you sure you would like to cancel assignment ID {record?.id}
                  {cancellationDate}?
                </Typography>
              );
            }}
          </FormDataConsumer>
          <SelectInput
            source="canceled_by"
            label="Canceled By"
            fullWidth
            validate={[required()]}
            choices={[Personable.ADMIN, Personable.PROFESSIONAL, Personable.LOCATION].map((id) => ({
              id,
              name: id,
            }))}
            data-testid="assignments-cancel-dialog-canceled-by"
          />
          <FormDataConsumer>
            {({ formData }) => {
              let options: string[] = [];
              if (not_booked && formData.canceled_by !== Personable.LOCATION) {
                options = NON_BOOKED_REASONS;
              } else {
                if (formData.canceled_by === Personable.ADMIN) {
                  options = ADMIN_REASONS;
                } else if (formData.canceled_by === Personable.PROFESSIONAL) {
                  options = STAFF_REASONS;
                } else if (formData.canceled_by === Personable.LOCATION) {
                  if (not_booked) {
                    options = FACILITY_REASONS.DRAFT_OR_MATCHED_OR_AVAILABLE;
                  } else {
                    options = FACILITY_REASONS.BOOKED_OR_IN_PROGRESS;
                  }
                }
              }

              return (
                <SelectInput
                  source="cancellation_reason"
                  label="Cancellation Reason"
                  fullWidth
                  validate={[required()]}
                  resettable
                  choices={options.map((option) => ({ id: option, name: option }))}
                  data-testid="assignments-cancel-dialog-cancellation-reason"
                />
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.reason === 'Other') {
                return (
                  <TextInput
                    source="other_reason"
                    label="Other Reason"
                    fullWidth
                    validate={[required()]}
                    multiline
                    rows={3}
                  />
                );
              } else if (formData.reason === 'Unfilled') {
                return (
                  <SelectInput
                    source="unfilled_reason"
                    label="Unfilled Reason"
                    fullWidth
                    validate={[required()]}
                    resettable
                    choices={unfilled_reasons.map((option) => ({ id: option, name: option }))}
                  />
                );
              } else {
                return null;
              }
            }}
          </FormDataConsumer>
          {withDate && (
            <DateInput
              label="Cancellation date"
              source="cancellation_date"
              fullWidth
              validate={[required()]}
            />
          )}
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default CancelButton;
