import React from 'react';
import { required, SimpleForm } from 'react-admin';
import { CreateDialog } from '@react-admin/ra-form-layout';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import StateInput from 'components/StateInput';
import ConditionInput from 'components/ConditionInput';

const CreateApplicationRequirementDialog = () => (
  <CreateDialog>
    <SimpleForm>
      <EnumSelectInput fullWidth source="kind" />
      <StateInput fullWidth />
      <PositionInput fullWidth />
      <SpecialtyInput fullWidth />
      <ConditionInput validate={required()} fullWidth />
    </SimpleForm>
  </CreateDialog>
);

export default CreateApplicationRequirementDialog;
