import React, { useEffect } from 'react';
import { useGetList, Labeled } from 'react-admin';
import { FormControl, TextField, Autocomplete } from '@mui/material';

const StateInput = ({ value, onChange }) => {
  const { data = [] } = useGetList('State', {
    sort: { field: 'name', order: 'ASC' },
    pagination: {
      perPage: 100,
      page: 1,
    },
  });
  const [selectedState, setSelectedState] = React.useState<any>(null);
  const states = Object.values(data);

  useEffect(() => {
    if (selectedState && selectedState.id !== value) {
      onChange(selectedState.id);
    }
  }, [selectedState, onChange, value]);

  return (
    <FormControl>
      <Labeled label="State">
        <Autocomplete
          options={states}
          onChange={(_e, item) => setSelectedState(item)}
          disableClearable
          value={states.find(({ id }) => id === value) ?? ''}
          fullWidth
          getOptionLabel={(option: any) => option?.name ?? ''}
          renderInput={(params) => <TextField {...params} label="State" />}
        />
      </Labeled>
    </FormControl>
  );
};

export default StateInput;
