import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  BooleanField,
  BooleanInput,
  SelectInput,
  TextInput,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import VmsTypeInput from 'components/VmsTypeInput';
import DateTimeField from 'components/DateTimeField';
import DateFilter from 'components/Filter/DateFilter';
import List from 'components/List';

const assignmentFilterChoices = [
  { id: 'has_assignment', name: 'Has Assignment' },
  { id: 'no_assignment', name: 'No Assignment' },
];

const VmsAssignmentsFilters = [
  <IdFilter />,
  <VmsTypeInput source="search.vms_type_id" alwaysOn resettable />,
  <TextInput label="VMS Order ID" source="vms_external_id" alwaysOn resettable />,
  <SelectInput
    label="Assignment"
    alwaysOn
    emptyText="All"
    source="search.assignments"
    translateChoice={true}
    choices={assignmentFilterChoices}
    optionText="name"
    optionValue="id"
    resettable
  />,
  <DateFilter label="Start Date" alwaysOn source="search.start_date" />,
  <BooleanInput label="Needs Review" alwaysOn source="search.updated" />,
];

const VmsAssignmentsList = () => (
  <List filters={VmsAssignmentsFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField label="VMS Order ID" source="vms_external_id" />
      <TextField label="VMS Type" source="vms_user.vms_type.label" />
      <ArrayField source="assignments">
        <SingleFieldList linkType={false}>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <DateTimeField label="Start Date" format="MM/dd/yyyy" source="new_data.starts_date" />
      <BooleanField label="Needs Review" source="updated" />
      <TextField label="Assignment not Created" source="reason_assignment_not_created" />
    </Datagrid>
  </List>
);

VmsAssignmentsList.query = gql`
  query GET_LIST($input: VmsAssignmentQueryInput) {
    _vmsAssignmentsMeta(input: $input) {
      count
    }
    vmsAssignments(input: $input) {
      id
      vms_external_id
      bullhorn_id
      bullhorn_client_id
      reason_assignment_not_created
      updated
      new_data
      vms_user {
        id
        vms_type {
          id
          label
        }
      }
      assignments {
        id
      }
      created_at
      updated_at
    }
  }
`;

export default VmsAssignmentsList;
