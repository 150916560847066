import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  DateField,
} from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';

const DisputeShiftWitnessesShow = () => (
  <Show>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
      <Tab label="Dispute">
        <IdField />
        <LinkField label="Dispute" source="dispute_id" />
        <TextField source="name" />
        <TextField source="position" />
        <TextField source="phone" />
        <TextField source="email" />
        <DateField showTime options={{ hour12: false }} source="created_at" />
        <DateField showTime options={{ hour12: false }} source="updated_at" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

DisputeShiftWitnessesShow.query = gql`
  query GET_ONE($id: Int!) {
    disputeShiftWitness(id: $id) {
      id
      dispute_id
      name
      position
      phone
      email
      created_at
      updated_at
      __typename
    }
  }
`;

export default DisputeShiftWitnessesShow;
