import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import create from './Create';

export default {
  name: 'PositionPostingFields',
  inputShow: gql`
    query positionPostingFields($input: PositionPostingFieldQueryInput) {
      positionPostingFields(input: $input) {
        id
        position_id
        position {
          id
          display_name
        }
        posting_field_id
        posting_field {
          id
          label
        }
      }
    }
  `,
  list,
  show,
  create,
  options: {
    group: 'Misc',
    label: 'Position Posting Fields',
  },
};
