import React from 'react';
import { gql } from '@apollo/client';
import {
  BooleanField,
  Datagrid,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import RichTextField from 'components/RichTextField';

const QualificationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="long_name" />
      <TextField source="slug" />
      <TextField source="qualification_type" />
      <RichTextField source="instructions" />
      <ReferenceManyField
        label="Qualification Attributes"
        reference="QualificationAttribute"
        target="qualification_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="field_type" />
          <BooleanField source="required" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

QualificationShow.query = gql`
  query GET_ONE($id: Int!) {
    qualification(id: $id) {
      id
      long_name
      slug
      qualification_type
      instructions
      status
    }
  }
`;

export default QualificationShow;
