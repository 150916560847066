import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'jobs_boards',
  inputShow: gql`
    query jobsBoards($input: JobsBoardQueryInput) {
      jobsBoards(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Jobs Boards',
  },
};
