import React from 'react';
import { Create, SimpleForm, DateInput } from 'react-admin';

const BalanceSheetsCreate = () => (
  <Create>
    <SimpleForm>
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export default BalanceSheetsCreate;
