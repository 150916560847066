import List from './List';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'professional_profile_ehr_systems',
  inputShow: gql`
    query professionalProfileEhrSystems($input: EducationQueryInput) {
      professionalProfileEhrSystems(input: $input) {
        id
        ehr_system_id
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: false,
  },
};
