import JobInput from 'components/JobInput';
import LocationInput from 'components/LocationInput';
import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Edit, SimpleForm, NumberInput, BooleanInput, DateTimeInput, TextInput } from 'react-admin';

const CheckInsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="check-ins-edit">
    <SimpleForm>
      <JobInput data-testid="check-ins-edit-job" />
      <LocationInput data-testid="check-ins-edit-location" />
      <ProfessionalInput data-testid="check-ins-edit-professional" />
      <NumberInput
        options={{ maximumFractionDigits: 10 }}
        source="latitude"
        data-testid="check-ins-edit-latitude"
      />
      <NumberInput
        options={{ maximumFractionDigits: 10 }}
        source="longitude"
        data-testid="check-ins-edit-longitude"
      />
      <TextInput source="distance" label="Distance (ft)" data-testid="check-ins-edit-distance" />
      <BooleanInput source="accepted" data-testid="check-ins-edit-accepted" />
      <DateTimeInput
        source="time"
        label="Professional Submitted Time"
        data-testid="check-ins-edit-time"
      />
    </SimpleForm>
  </Edit>
);

export default CheckInsEdit;
