import React from 'react';
import { gql } from '@apollo/client';
import { SimpleList } from 'react-admin';
import List from 'components/List';

const Net_termsList = () => (
  <List exporter={false}>
    <SimpleList
      primaryText={(r) => `${r.number_of_days} days`}
      secondaryText={(r) => `ID: ${r.id}`}
    />
  </List>
);

Net_termsList.query = gql`
  query GET_LIST($input: NetTermQueryInput) {
    _netTermsMeta(input: $input) {
      count
    }
    netTerms(input: $input) {
      id
      number_of_days
    }
  }
`;

export default Net_termsList;
