import { AxiosHeaders, AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import config from 'config';
import getAxios from 'getAxios';

const axios = getAxios();

const headers = new AxiosHeaders({
  Accept: 'application/pdf',
});

export default function useGetPDF() {
  const getPDF = useCallback(
    ({ url, ...rest }: Partial<AxiosRequestConfig>, name: string = 'download.pdf') => {
      return axios({
        url: `${config.pdf_url}${url}`,
        method: 'GET',
        withCredentials: false,
        responseType: 'blob',
        ...rest,
        headers,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      });
    },
    [],
  );
  return getPDF;
}
