import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  BooleanField,
  ArrayField,
  Datagrid,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const QuestionnaireShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField label="ID" source="id" />
      <TextField source="label" />
      <ArrayField source="questions">
        <Datagrid bulkActionButtons={false}>
          <TextField source="question" />
          <TextField source="type" />
          <TextField source="expected_answer" />
          <BooleanField source="required" />
          <TextField source="options" />
        </Datagrid>
      </ArrayField>
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

QuestionnaireShow.query = gql`
  query questionnaire($id: Int!) {
    questionnaire(id: $id) {
      id
      label
      questions
      created_at
      updated_at
    }
  }
`;

export default QuestionnaireShow;
