import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { Box } from '@mui/material';

type Predicate<T> = (arg: T) => boolean;

export function createConditionalInputWrapper<FormData = any>(predicate: Predicate<FormData>) {
  // eslint-disable-next-line react/display-name
  return ({ children, ...rest }) => (
    <FormDataConsumer {...rest}>
      {({ formData, ...otherProps }) =>
        predicate(formData) &&
        React.Children.toArray(children).map((child: any, index: number) => (
          <Box key={index}>{React.cloneElement(child, otherProps)}</Box>
        ))
      }
    </FormDataConsumer>
  );
}
