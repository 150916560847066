import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const Net_termsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="number_of_days" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

Net_termsShow.query = gql`
  query GET_ONE($id: Int!) {
    netTerm(id: $id) {
      id
      number_of_days
    }
  }
`;

export default Net_termsShow;
