import React, { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

const ButtonProgress: FC<ButtonProps & { loading: boolean }> = (props) => {
  const { loading, children, ...rest } = props;
  return (
    <Button disabled={loading} color="primary" {...rest}>
      {loading ? <CircularProgress size={18} /> : children}
    </Button>
  );
};

export default ButtonProgress;
