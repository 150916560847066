import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const ProfessionalAssignmentFavoritesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Assignment ID" source="assignment_id" />
    </Datagrid>
  </List>
);

ProfessionalAssignmentFavoritesList.query = gql`
  query GET_LIST($input: ProfessionalAssignmentFavoriteQueryInput) {
    _professionalAssignmentFavoritesMeta(input: $input) {
      count
    }
    professionalAssignmentFavorites(input: $input) {
      id
      assignment_id
      assignment {
        id
        starts_date
        ends_date
        status
        is_w2
        company {
          id
          name
          vms_type {
            id
            label
          }
          health_system {
            id
            label
          }
        }
        number_of_openings
      }
      professional_id
    }
  }
`;

export default ProfessionalAssignmentFavoritesList;
