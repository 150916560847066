import { useGetList } from 'react-admin';
import useApi from 'hooks/useApi';

export const useTransactions = ({
  billingGroupId,
  type,
}: {
  billingGroupId: number;
  type: string;
}) =>
  useGetList('FinancialTransaction', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'asc' },
    filter: {
      search: {
        can_refund: true,
        billing_group_id: billingGroupId,
        kind: type,
      },
    },
  });

export const useCreateRefund = () => {
  const api = useApi();

  return async ({
    billingGroupId,
    amount,
    transactionId,
  }: {
    billingGroupId: number;
    amount: number;
    transactionId: number;
  }) => {
    const data = await api({
      url: `/v3/admin/financial_transactions/${transactionId}/refund`,
      method: 'POST',
      data: {
        refund: {
          billing_group_id: billingGroupId,
          amount_cents: amount,
        },
      },
    });

    return data;
  };
};
