import * as React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { RadioButtonGroupInput, FormDataConsumer, AutocompleteInput } from 'react-admin';
import inflection from 'inflection';

interface PolymorphicInputProps<T> {
  field: string;
  choices: Array<{ id: string; name: string }>;
  render?: (formData: any) => (polymorphicRecord: any) => string;
  gql?: string[];
  filter?: T;
}

const PolymorphicInput = <T,>(props: PolymorphicInputProps<T>) => {
  const { field, render, choices, gql, filter } = props;
  const type = `${field}_type`;
  return (
    <>
      <RadioButtonGroupInput source={type} choices={choices} />

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData || !formData[type]) {
            return null;
          }
          return (
            <ReferenceInput
              source={`${field}_id`}
              data-testid={`${field}_id`}
              label={inflection.humanize(formData[type])}
              reference={formData[type]}
              format={parseInt}
              gql={gql}
              filter={filter}
            >
              <AutocompleteInput
                optionText={
                  render
                    ? render(formData)
                    : (r) => (r ? `${inflection.humanize(formData[type])} ${r.id}` : '')
                }
              />
            </ReferenceInput>
          );
        }}
      </FormDataConsumer>
    </>
  );
};

export default PolymorphicInput;
