import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';
import MoneyField from 'components/MoneyField';

const CreditNotesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <MoneyField label="amount" source="amount_cents" />
      <TextField source="memo" />
      <TextField source="statement.id" />
      <TextField source="stripe_invoice_item_id" />
    </Datagrid>
  </List>
);

CreditNotesList.query = gql`
  query GET_LIST($input: CreditNoteQueryInput) {
    _creditNotesMeta(input: $input) {
      count
    }
    creditNotes(input: $input) {
      id
      amount_cents
      memo
      statement {
        id
      }
      stripe_invoice_item_id
    }
  }
`;

export default CreditNotesList;
