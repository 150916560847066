import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput } from 'react-admin';

const CredentialRejectedReasonsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="rank" />
    </SimpleForm>
  </Edit>
);

export default CredentialRejectedReasonsEdit;
