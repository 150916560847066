import React from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { Button, Grid, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import getAxios from 'getAxios';

const axios = getAxios();

const TimesheetUpload = () => {
  const record = useRecordContext();

  const uploadExists = record.attachment;

  const { url, label, file } = {
    label: '530 Form/Timesheet',
    url: `${config.rails_url}/v3/admin/disputes/${record.id}/upload`,
    file: record.attachment,
  };

  const handleChange = async (e, handleSuccess, showError, setUploading, headers, ref, config) => {
    const fileToBase64String = async (file: File) =>
      new Promise<string>((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
            resolve(base64String);
          };
          reader.readAsDataURL(file);
        } catch {
          setUploading(false);
          reject('unable to encode file');
        }
      });

    setUploading(true);
    const [file] = e.target.files;

    try {
      const encodedFile = await fileToBase64String(file);
      const attrs = {
        dispute: {
          file: {
            contents: encodedFile,
            filename: file.name,
          },
        },
      };

      axios
        .post(url, attrs, { headers } ?? config)
        .then(handleSuccess)
        .catch(showError)
        .finally(() => {
          setUploading(false);
          if (ref.current) {
            ref.current.value = '';
          }
        });
    } catch {
      showError({ message: 'Unable to encode file' });
    }
  };

  const { hiddenInput, uploading, openFilePrompt } = useFileUpload({
    url,
    accept: '.png, .jpg',
    passedHandleChange: handleChange,
  });

  return (
    <>
      {hiddenInput}
      <Labeled label={label} data-testid="job-file-upload">
        <Grid container spacing={2}>
          {uploadExists && (
            <Grid item>
              <Button size="small" variant="outlined" color="primary" href={file} target="_blank">
                Download
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              disabled={uploading}
              startIcon={uploading ? <CircularProgress size={20} /> : null}
              onClick={openFilePrompt}
            >
              {uploadExists ? 'Replace Attachment' : 'Upload Attachment'}
            </Button>
          </Grid>
        </Grid>
      </Labeled>
    </>
  );
};

export default TimesheetUpload;
