import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const ReferralSourceInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput optionText="label" {...getChildrenProps(props)} />
  </ReferenceInput>
);

ReferralSourceInput.defaultProps = {
  label: 'Referral Source',
  alwaysOn: true,
  reference: 'ReferralSource',
  emptyValue: null,
  source: 'referral_source_id',
};

export default ReferralSourceInput;
