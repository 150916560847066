import Alert from '@mui/material/Alert';
import React from 'react';
import { FormDataConsumer, NullableBooleanInput, TextField } from 'react-admin';
import MoneyInput from 'components/MoneyInput';
import { Box, Grid } from '@mui/material';

const OverrideRates = () => (
  <FormDataConsumer>
    {({ formData }) => (
      <>
        <NullableBooleanInput
          label="Override Minimum Rate"
          source="override_rates"
          data-testid="jobs-override_rates_field"
        />
        {/* <BooleanInput label="Override Minimum Rate" source="override_rates" /> */}
        {formData.override_rates && (
          <>
            <Grid container alignItems="flex-start">
              <MoneyInput
                label="Charge Base Hourly Rate Cents"
                source="charge_base_hourly_rate_cents"
                style={{ width: 320 }}
              />
              {formData.charge_base_hourly_rate_cents >= 30000 && (
                <Box ml={3} mt={1}>
                  <Alert severity="warning">Bill rate is $300 or higher</Alert>
                </Box>
              )}
            </Grid>
            <br />
            <TextField
              label="Margin"
              defaultValue={
                formData.charge_base_hourly_rate_cents
                  ? `${Math.floor(
                      ((formData.payout_base_hourly_rate_cents || 0) /
                        formData.charge_base_hourly_rate_cents) *
                        100,
                    )}%`
                  : '0%'
              }
              style={{ width: 320 }}
            />
          </>
        )}
      </>
    )}
  </FormDataConsumer>
);

export default OverrideRates;
