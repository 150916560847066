import { gql } from '@apollo/client';
import List from './List';

export default {
  name: 'ClockEventAttachments',
  inputShow: gql`
    query ClockEventAttachments($input: ClockEventAttachmentQueryInput) {
      clockEventAttachments(input: $input) {
        id
        clock_event_type
        clock_event_id
        file
        reasons
        created_at
        updated_at
        metadata
      }
    }
  `,
  list: List,
  show: List,
  options: {
    label: 'ClockEventAttachments',
    group: 'Misc',
    order: 0,
  },
};
