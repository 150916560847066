import React from 'react';
import { ReferenceInput, AutocompleteInput, Edit, SimpleForm } from 'react-admin';
import InputUtils from 'utils/input';
import LocationInput from 'components/LocationInput';

const AssignmentLocationsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ReferenceInput label="Assignment ID" source="assignment_id" reference="Assignment">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <LocationInput />
    </SimpleForm>
  </Edit>
);

export default AssignmentLocationsEdit;
