import React from 'react';
import { gql } from '@apollo/client';
import { TextField, NumberField, BooleanField, Show, SimpleShowLayout } from 'react-admin';
import JsonField from 'components/JsonField';
import TimestampFields from 'components/TimestampFields';

const EhrSystemShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField source="active" />
      <NumberField source="medefis_identifier" />
      <JsonField source="maestro_identifier" rootKey="Maestro Identifier" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

EhrSystemShow.query = gql`
  query ehrSystem($id: Int!) {
    ehrSystem(id: $id) {
      id
      name
      label
      medefis_identifier
      maestro_identifier
      active
      created_at
      updated_at
    }
  }
`;

export default EhrSystemShow;
