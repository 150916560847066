import React from 'react';
import { SelectInput, TextInput, required, SimpleForm, SaveButton, Toolbar } from 'react-admin';
import { Button, Box, Typography, Dialog } from '@mui/material';

const reasonChoices = [
  { id: 'stop_payment', name: 'Stop Payment' },
  { id: 'user_error', name: 'User Error' },
  { id: 'other', name: 'Other' },
];

const ReverseTransactionDialog = ({ transaction, onSubmit, open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <Typography variant="h6" px={2} pt={3}>
      Reverse Transaction #{transaction?.id}
    </Typography>
    <SimpleForm
      onSubmit={onSubmit}
      record={{}}
      toolbar={
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SaveButton label="Submit" />
          <Button onClick={onClose}>Cancel</Button>
        </Toolbar>
      }
    >
      <Box mb={2}>
        <Typography>
          Are you sure you would like to reverse this Financial Transaction and any of its Itemized
          Transactions? Any paid invoices will be marked back as due/past due. This action cannot be
          undone.
        </Typography>
      </Box>
      <SelectInput choices={reasonChoices} fullWidth validate={[required()]} source="reason" />
      <TextInput source="note" fullWidth multiline />
    </SimpleForm>
  </Dialog>
);

export default ReverseTransactionDialog;
