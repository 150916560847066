import React from 'react';
import { Edit, SimpleForm, NumberInput } from 'react-admin';

const Net_termsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <NumberInput source="number_of_days" />
    </SimpleForm>
  </Edit>
);

export default Net_termsEdit;
