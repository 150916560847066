import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Create, SimpleForm, AutocompleteInput, TextInput } from 'react-admin';

const Friendly_id_slugsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="slug" />
      <ReferenceInput reference="sluggables" source="sluggable_id">
        <AutocompleteInput optionText="id" />
      </ReferenceInput>
      <TextInput source="sluggable_type" />
      <TextInput source="scope" />
    </SimpleForm>
  </Create>
);

export default Friendly_id_slugsCreate;
