import List from './List';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'reviews',
  inputShow: gql`
    query reviews($input: ReviewQueryInput) {
      reviews(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Reviews',
  },
};
