import * as React from 'react';
import { TextInput } from 'react-admin';

export const formatter = {
  format: (v: string) => {
    if (!v || v === '+1') {
      return '';
    }
    if (v.length < 3) {
      return '+1 ';
    }
    if (v.length < 6) {
      return `${v.slice(0, 2)} (${v.slice(2)}`;
    }
    if (v.length < 9) {
      return `${v.slice(0, 2)} (${v.slice(2, 5)}) ${v.slice(5)}`;
    }
    return `${v.slice(0, 2)} (${v.slice(2, 5)}) ${v.slice(5, 8)} ${v.slice(8)}`;
  },
  parse: (v?: string) => {
    if (!v) {
      return null;
    }
    if (v.length < 5) {
      const val = `+1${v.replace(/\D/g, '').replace(/[\+1]/, '')}`;
      if (val === '+1') {
        return null;
      }
      return val;
    }
    return `+1${v.slice(3).replace(/\D/g, '')}`.slice(0, 12);
  },
};

const validatePhoneNumber = (v: string) =>
  v && v.length < 12 ? 'Phone number is not valid' : undefined;

const PhoneInput = (props) => (
  <TextInput {...formatter} validate={validatePhoneNumber} {...props} data-testid="phone-input" />
);

export default PhoneInput;
