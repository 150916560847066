import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const BillingGroupParentsCreate = () => {
  const checkAccess = useCheckAccess();

  return (
    <Create redirect="show">
      <SimpleForm>
        {checkAccess('create', 'BillingGroupParent', 'name') && <TextInput source="name" />}
      </SimpleForm>
    </Create>
  );
};

export default BillingGroupParentsCreate;
