import React from 'react';
import { gql } from '@apollo/client';
import { BooleanField, Show, SimpleShowLayout, TextField } from 'react-admin';
import StateField from 'components/StateField';
import EnumField from 'components/Enum/EnumField';
import LinkField from 'components/LinkField';

const ApplicationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="medely" />
      <StateField />
      <BooleanField source="base" />
      <EnumField source="kind" />
      <LinkField source="position_id" reference="Position" />
      <LinkField source="specialty_id" reference="Specialty" />
      <LinkField source="condition_id" reference="Condition" />
      <TextField source="application_type" />
      <TextField source="payload" />
    </SimpleShowLayout>
  </Show>
);

ApplicationShow.query = gql`
  query GET_ONE($id: Int!) {
    applicationRequirement(id: $id) {
      id
      medely
      state_id
      base
      kind
      position_id
      specialty_id
      condition_id
    }
  }
`;

export default ApplicationShow;
