import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField, BooleanInput } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import PolymorphicField from 'components/PolymorphicField';
import List from 'components/List';
import { SearchFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const CancellationsFilters = [
  <SearchFilter alwaysOn />,
  <BooleanInput alwaysOn source="cleared" />,
  <EnumSelectInput alwaysOn source="window" />,
];

const CancellationsList = () => (
  <List exporter={false} filters={CancellationsFilters} data-testid="cancellations-list">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <PolymorphicField source="personable" />
      <LinkField label="Job ID" source="job.id" />
      <TextField label="Cancellation Reason" source="cancellation_reason.label" />
      <DateTimeField
        label="Starts at"
        tzSource="job.location.timezone_lookup"
        source="job.starts_time"
      />
      <TextField label="Market" source="market_name" />
      <TextField label="State" source="state_name" />
      <BooleanField source="cleared" />
      <TextField label="Cancellation Window" source="window" />
      <DateTimeField
        label="Cancelled at"
        tzSource="job.location.timezone_lookup"
        source="created_at"
      />
    </Datagrid>
  </List>
);

CancellationsList.query = gql`
  query GET_LIST($input: CancellationQueryInput) {
    _cancellationsMeta(input: $input) {
      count
    }
    cancellations(input: $input) {
      id
      cancellation_reason {
        id
        label
      }
      cleared
      personable {
        ... on Location {
          id
          __typename
        }
        ... on Professional {
          id
          __typename
        }
      }
      job {
        id
        starts_time
        location {
          id
          timezone_lookup
        }
      }
      window
      market_name
      state_name
      created_at
    }
  }
`;

export default CancellationsList;
