import React from 'react';
import Heading from 'components/Heading';
import { Labeled } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import { Typography } from '@mui/material';

export const TotalPayPackage = ({ pay, taxablePay, nonTaxable }) => (
  <>
    <Heading>Professional Total Pay Package</Heading>
    <Labeled fullWidth label="Total Pay">
      <Typography variant="body2" data-testid="assignments_edit-total_pay">
        {DisplayHelpers.money(pay)}
      </Typography>
    </Labeled>
    <Labeled fullWidth label="Total Taxable Income">
      <Typography variant="body2" data-testid="assignments_edit-total_taxable_income">
        {DisplayHelpers.money(taxablePay)}
      </Typography>
    </Labeled>
    <Labeled fullWidth label="Total Non-Taxable Income">
      <Typography variant="body2" data-testid="assignments_edit-total_non_taxable_income">
        {DisplayHelpers.money(nonTaxable)}
      </Typography>
    </Labeled>
  </>
);
