import React from 'react';
import { Create, SimpleForm, DateTimeInput, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';

const DisputesCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <JobInput />
      <InvoiceInput />
      <DateTimeInput source="adjusted_clock_in" />
      <DateTimeInput source="adjusted_clock_out" />
      <EnumSelectInput source="reason" />
      <EnumSelectInput source="category" />
      <EnumSelectInput source="resolution" />
      <EnumSelectInput source="status" />
      <AdministratorInput
        label="Assigned To"
        source="assigned_to_id"
        resettable
        filter={{ department: 'Dispute' }}
        sort={{ field: 'first_name', order: 'ASC' }}
      />
      <TextInput fullWidth multiline source="facility_notes" />
      <TextInput fullWidth multiline source="admin_notes" />
    </SimpleForm>
  </Create>
);

export default DisputesCreate;
