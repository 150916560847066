import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import MoneyField from 'components/MoneyField';
import IdField from 'components/IdField';

const CheckList = () => (
  <List exporter={false} filters={[<IdInput />]} data-testid="check-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Statement ID" source="statement.id" />
      <MoneyField label="Amount" source="amount_cents" />
      <TextField source="number" />
      <TextField label="Stripe Invoice Item ID" source="stripe_invoice_item_id" />
    </Datagrid>
  </List>
);

CheckList.query = gql`
  query checks($input: CheckQueryInput) {
    _checksMeta(input: $input) {
      count
    }
    checks(input: $input) {
      id
      statement {
        id
      }
      amount_cents
      number
      stripe_invoice_item_id
      note
    }
  }
`;

export default CheckList;
