import React, { useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import useGetPDF from 'hooks/useGetPDF';
import { useNotify } from 'react-admin';

const PdfButton = (props: ButtonProps & { download: string; href: string }) => {
  const { download, href, size, children, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const getPDF = useGetPDF();
  const notify = useNotify();

  const handleClick = () => {
    setLoading(true);
    getPDF({ url: href }, download)
      .catch((e) => {
        notify(e.message, { type: 'error' });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button disabled={loading} size={size} onClick={handleClick} data-testid="pdf-button" {...rest}>
      {loading ? <CircularProgress size={size === 'small' ? 22 : 24} /> : children}
    </Button>
  );
};

export default PdfButton;
