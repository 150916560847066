import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import { Confirm } from 'react-admin';
import { ApplicationStatus, PhoneScreenPendingStatus } from '../ApplicationStatusControl';

const PhoneScreenLabel = {
  resume_review: 'Advance to Phone Screen',
  phone_screen_scheduled: 'Reschedule Phone Screen',
  phone_screen_pending: 'Move to Phone Screen Scheduled (manual)',
  archived: 'Return to Phone Screen',
  rejected: 'Return to Phone Screen',
};

interface PhoneScreenPendingItemProps {
  updateApplicationStatus: (val: any) => void;
  currentApplicationStatus: ApplicationStatus;
}

const PhoneScreenPendingItem = ({
  updateApplicationStatus,
  currentApplicationStatus,
}: PhoneScreenPendingItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        {PhoneScreenLabel[currentApplicationStatus]}
      </MenuItem>
      <Confirm
        isOpen={open}
        title={`${PhoneScreenLabel[currentApplicationStatus]}?`}
        content="Are you sure you would like to proceed?"
        onConfirm={() => updateApplicationStatus({ application_status: PhoneScreenPendingStatus })}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default PhoneScreenPendingItem;
