import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import useCheckAccess from 'hooks/useCheckAccess';
import SummaryTab from './tabs/SummaryTab';
import LocationTypePositionsTab from './tabs/LocationTypePositionsTab';

const LocationTypeShow = () => {
  const checkAccess = useCheckAccess();
  const canSeePositions = checkAccess('view', 'LocationTypePosition');
  const canCreate = checkAccess('create', 'LocationTypePosition');
  const canDelete = checkAccess('delete', 'LocationTypePosition');
  console.log(canDelete);

  return (
    <Show>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Summary">
          <SummaryTab />
        </Tab>
        {canSeePositions && (
          <Tab label="Positions" path="positions">
            <LocationTypePositionsTab canCreate={canCreate} canDelete={canDelete} />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

LocationTypeShow.query = gql`
  query locationType($id: Int!) {
    locationType(id: $id) {
      id
      label
      active
      admin_only
      created_at
      updated_at
    }
  }
`;

export default LocationTypeShow;
