import React from 'react';
import { Create, SimpleForm, NumberInput, TextInput } from 'react-admin';

const CredentialRejectedReasonsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="rank" />
    </SimpleForm>
  </Create>
);

export default CredentialRejectedReasonsCreate;
