import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';
import PositionInput from 'components/PositionInput';

const ProfessionalTravelPositionsCreate = () => (
  <Create
    redirect={(_resource, _id, data) => `Professional/${data.professional_id}/show/positions`}
  >
    <SimpleForm>
      <ProfessionalInput />
      <PositionInput />
    </SimpleForm>
  </Create>
);

export default ProfessionalTravelPositionsCreate;
