import React, { useEffect, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { FormDataConsumer } from 'react-admin';
import DateTimeInput from 'components/DateTimeInput';
import Duration from 'components/Duration';
import * as R from 'ramda';
import useLocation from 'hooks/useLocation';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

const getTimeZone = ({ formData, location }) => {
  if (formData.id) {
    return formData.location.timezone_lookup;
  }
  return location?.timezone_lookup;
};

const isType = R.propEq('shift_type');
const setStartEnd = (fields): any =>
  _.flowRight<any, any, any, any>(R.zipObj(['start', 'end']), R.values, R.pick(fields));

const getStartEndTimes = R.cond<any, { start: string; end: string }>([
  [isType('call_back'), setStartEnd(['callback_starts_time', 'callback_ends_time'])],
  [isType('on_call'), setStartEnd(['on_call_starts_time', 'on_call_ends_time'])],
  [R.T, setStartEnd(['starts_time', 'ends_time'])],
]);

const JobTimeInputs = (props) => {
  const { formData, isJobCreate } = props;
  const { data: location } = useLocation(formData.location_id);
  const timezone = getTimeZone({ ...props, location });
  const { watch, setValue } = useFormContext();
  const onCallStartsTime = watch('on_call_starts_time');
  const onCallEndsTime = watch('on_call_ends_time');
  const callbackStartsTime = watch('callback_starts_time');
  const callbackEndsTime = watch('callback_ends_time');

  const updateTimes = useCallback(
    (newDatetime: string, updateStartTimes: boolean) => {
      if (isJobCreate || !['call_back', 'on_call'].includes(formData.shift_type)) {
        return;
      }

      const fieldsToUpdate = updateStartTimes
        ? ['starts_time', 'approved_clock_in']
        : ['ends_time', 'approved_clock_out'];
      fieldsToUpdate.forEach((field) => setValue(field, newDatetime));
      // must set these fields as null so that backend will update them automagically
      setValue('starts_date', null);
      setValue('ends_date', null);
    },
    [isJobCreate, formData.shift_type, setValue],
  );

  useEffect(() => {
    updateTimes(onCallStartsTime, true);
  }, [onCallStartsTime, updateTimes]);

  useEffect(() => {
    updateTimes(onCallEndsTime, false);
  }, [onCallEndsTime, updateTimes]);

  useEffect(() => {
    updateTimes(callbackStartsTime, true);
  }, [callbackStartsTime, updateTimes]);

  useEffect(() => {
    updateTimes(callbackEndsTime, false);
  }, [callbackEndsTime, updateTimes]);

  if (formData.shift_type === 'call_back') {
    return (
      <Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="Callback Starts Time"
            source="callback_starts_time"
          />
        </Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="Callback Ends Time"
            source="callback_ends_time"
          />
        </Box>
      </Box>
    );
  } else if (formData.shift_type === 'on_call') {
    return (
      <Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="On Call Starts Time"
            source="on_call_starts_time"
          />
        </Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="On Call Ends Time"
            source="on_call_ends_time"
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="Starts Time"
            source="starts_time"
            isJobCreate={isJobCreate}
          />
        </Box>
        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="Ends Time"
            source="ends_time"
            isJobCreate={isJobCreate}
          />
        </Box>

        <Box>
          <DateTimeInput
            time_zone={timezone}
            label="Approved Clock In"
            source="approved_clock_in"
            isJobCreate={isJobCreate}
          />
        </Box>
        <Box position="relative">
          <DateTimeInput
            time_zone={timezone}
            label="Approved Clock Out"
            source="approved_clock_out"
            isJobCreate={isJobCreate}
          />
          <Box width="100%" position="absolute" top={0} left="100%" ml={3} mt={1}>
            <Duration
              start={formData.approved_clock_in}
              end={formData.approved_clock_out}
              timezone={formData.location?.timezone_lookup}
            />
          </Box>
        </Box>
      </Box>
    );
  }
};

const JobTimeInfoSection = (props) => (
  <FormDataConsumer>
    {({ formData }) => (
      <Grid container alignItems="flex-start">
        <JobTimeInputs formData={formData} isJobCreate={props.isJobCreate} />
        <Box ml={3} mt={1}>
          <Duration {...getStartEndTimes(formData)} timezone={formData.location?.timezone_lookup} />
        </Box>
      </Grid>
    )}
  </FormDataConsumer>
);

export default JobTimeInfoSection;
