import * as R from 'ramda';
import {
  TextField,
  BooleanField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
  RaRecord,
} from 'react-admin';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import DateTimeField from 'components/DateTimeField';
import React from 'react';
import ScreenRequestsField from 'resources/assignments/ScreenRequestsField';
import { Link } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';
import { RecommendedAssignmentsButton } from '../RecommendedAssignmentsButton';

const AssignmentsTab = ({ favorites }: { favorites?: string }) => {
  const source = favorites ? 'favorite_assignment_ids' : 'id';
  const target = favorites ? 'id' : 'professional_id';
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        source={source}
        reference="Assignment"
        target={target}
      >
        <Datagrid bulkActionButtons={false} data-testid="professional_show-assignments_tab_table">
          <IdField />
          <TextField
            label="Status"
            source="status"
            data-testid="professional_show-assignments_tab_status"
          />

          <LinkField label="Primary Location ID" source="primary_location.id" />
          <FunctionField
            label="Other Locations"
            render={(v) =>
              R.map(
                (l) => (
                  <>
                    <Link component={RRLink} to={`/Location/${l.id}/show`}>
                      {l.id}
                    </Link>
                    <br />
                  </>
                ),
                v.locations.filter((l) => l.id !== v.primary_location.id),
              )
            }
            data-testid="professional_show-assignments_tab_other_locations"
          />

          <DateTimeField label="Starts Date" format="MM/dd/yyyy" source="starts_date" />
          <DateTimeField label="Ends Date" format="MM/dd/yyyy" source="ends_date" />
          <BooleanField
            label="W2"
            source="is_w2"
            data-testid="professional_show-assignments_tab_is_w2"
          />
          <TextField
            label="VMS Type"
            source="company.vms_type.label"
            data-testid="professional_show-assignments_tab_vms_type"
          />
          <TextField
            label="VMS Order #"
            source="order_number"
            data-testid="professional_show-assignments_tab_vms_order_number"
          />
          <TextField
            label="Shifts per Week"
            source="number_of_shifts_per_week"
            data-testid="professional_show-assignments_tab_shifts_per_week"
          />
          <TextField
            label="Hours per Shift"
            source="hours_per_shift"
            data-testid="professional_show-assignments_tab_hours_per_shift"
          />
          <FunctionField
            label="Applications"
            render={(v?: RaRecord) =>
              v?.assignment_requests && (
                <LinkField to={`/Assignment/${v.id}/show/requests`}>
                  {v.assignment_requests.length}
                </LinkField>
              )
            }
          />
          <ScreenRequestsField />
          <FunctionField
            label="Markets"
            render={(v) => R.uniq(R.map((l) => l.market?.display_name, v.locations)).join(', ')}
            data-testid="professional_show-assignments_tab_markets"
          />
          <FunctionField
            label="Account Manager"
            render={(r) =>
              r.administrator?.account && (
                <Link component={RRLink} to={`/Administrator/${r.administrator_id}/show`}>
                  {r.administrator.account.name}
                </Link>
              )
            }
            data-testid="professional_show-assignments_tab_account_manager"
          />
          <FunctionField
            label="Clinical Account Manager"
            render={(r) =>
              r.recruiter?.account && (
                <Link component={RRLink} to={`/Administrator/${r.recruiter_id}/show`}>
                  {r.recruiter.account.name}
                </Link>
              )
            }
            data-testid="professional_show-assignments_tab_clinical_account_manager"
          />
          {/* TODO: Verify works */}
          <RecommendedAssignmentsButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(AssignmentsTab);
