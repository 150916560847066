import React from 'react';
import { useNotify, useRefresh, TopToolbar, EditButton, useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import useDialog from 'hooks/useDialog';
import useApi from 'hooks/useApi';
import ReverseTransactionDialog from './ReverseTransactionDialog';

const FinancialTransactionShowActions = () => {
  const api = useApi();
  const { open, closeDialog, openDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();

  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const handleSubmit = async (values) => {
    try {
      await api({
        url: `/v3/admin/financial_transactions/${record.id}/reverse`,
        method: 'POST',
        data: values,
      });
      closeDialog();
      notify('Transaction has been successfully reversed');
      refresh();
    } catch (e: any) {
      notify(e.response.data.message ?? 'Something went wrong', { type: 'warning' });
    }
  };

  return (
    <TopToolbar>
      {!record.original_financial_transaction_id && (
        <>
          <Button onClick={openDialog} color="primary" size="small">
            Reverse Transaction
          </Button>
          <ReverseTransactionDialog
            transaction={record}
            open={open}
            onSubmit={handleSubmit}
            onClose={closeDialog}
          />
        </>
      )}
      <EditButton />
    </TopToolbar>
  );
};

export default FinancialTransactionShowActions;
