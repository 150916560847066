import * as React from 'react';
import { RadioButtonGroupInput, FormDataConsumer, NumberInput } from 'react-admin';
import inflection from 'inflection';

const PolymorphicInput: React.FunctionComponent<{
  field: string;
  choices: Array<{ id: string; name: string }>;
  render?: (formData: any) => (polymorphicRecord: any) => string;
  gql?: any;
  [K: string]: any;
}> = (props) => {
  const { field, choices } = props;
  const type = `${field}_type`;
  return (
    <>
      <RadioButtonGroupInput source={type} choices={choices} />

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData || !formData[type]) {
            return null;
          }
          return (
            <NumberInput
              source={`${field}_id`}
              data-testid={`${field}_id`}
              label={inflection.humanize(formData[type])}
              resource={formData[type]}
            />
          );
        }}
      </FormDataConsumer>
    </>
  );
};

export default PolymorphicInput;
