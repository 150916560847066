import React from 'react';
import {
  NumberInput,
  BooleanInput,
  TextInput,
  Create,
  SimpleForm,
  AutocompleteArrayInput,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import SpecialtyInput from 'components/SpecialtyInput';

const JobTemplateCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <NumberInput label="User" source="user_id" />
      <EnumSelectInput source="kind" />
      <PositionInput multiple filter={{ active: true }} />
      <SpecialtyInput multiple positionSource="position_ids" />
      <ReferenceArrayInput label="Surgery types" reference="SurgeryType" source="surgery_type_ids">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
      <EnumSelectInput source="patient_population" />
      <BooleanInput source="covid_care" />
      <TextInput multiline fullWidth source="detail" />
    </SimpleForm>
  </Create>
);

export default JobTemplateCreate;
