import { isEmptyObject } from 'utils/object';

export const getQualificationSlug = (andCriteria: Qualification | undefined) => {
  if (andCriteria) {
    const qualificationSlug = Object.keys(andCriteria)?.[0] ?? '';
    return qualificationSlug;
  }
  return '';
};

type EmptyObject = Record<string, never>;
type KnownAttributeValues = number | EmptyObject;
export type UnzippedAttributes = [string, KnownAttributeValues][];

type QualificationAttribute = Record<string, KnownAttributeValues>;
type Qualification = Record<string, QualificationAttribute>;

export const DEFAULT_ATTRIBUTES: UnzippedAttributes = [['', {}]];

export const getUnzippedAttributes = (
  andCriteria: Qualification | undefined,
): UnzippedAttributes => {
  if (!andCriteria) {
    return [];
  }

  const attributes: QualificationAttribute = Object.values(andCriteria)[0];
  if (!isEmptyObject(attributes)) {
    return Object.entries(attributes);
  }

  return [];
};

export const getDisplayableAttributes = (qualificationAttributes, andCriteria) => {
  const unzippedAttributes: any[] = getUnzippedAttributes(andCriteria).map(
    ([attributeSlug, value]) => {
      const attribute = qualificationAttributes.find(({ slug }: any) => slug === attributeSlug);
      // Needs to be null to be displayed as empty
      const showValue = isEmptyObject(value as any) ? null : value;
      if (attribute) {
        return [attribute, showValue];
      }
      return [attributeSlug, showValue];
    },
  );
  return unzippedAttributes;
};
