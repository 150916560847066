import React from 'react';
import { Typography } from '@mui/material';
import { ENUMS } from './enums';
import EnumError from './EnumError';
import { getName } from './getName';
import { useRecordContext, useResourceContext } from 'ra-core';
import _ from 'lodash';

const EnumField = (props) => {
  const { source, emptyText } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  try {
    const value = record[source];
    const enumeration = _.get<any>(ENUMS, [record.__typename ?? resource, source]);
    const reverseEnumeration = {};
    const displayValue = getName(resource, source, {
      id: value,
      name: reverseEnumeration[value] || enumeration[value],
    });
    return (
      <Typography component="span" variant="body2" data-testid="enum-field">
        {!displayValue ? emptyText : displayValue}
      </Typography>
    );
  } catch {
    return <EnumError />;
  }
};

EnumField.defaultProps = {
  emptyText: 'None',
};

export default EnumField;
