import React from 'react';
import { TextInput, NumberInput, Create, SimpleForm } from 'react-admin';
import PolymorphicInput from 'components/PolymorphicInput';

const FundingSourceCreate = () => (
  <Create data-testid="funding-source-create" redirect="show">
    <SimpleForm>
      <PolymorphicInput
        field="personable"
        choices={[
          { id: 'Professional', name: 'Professional' },
          { id: 'BillingGroup', name: 'BillingGroup' },
        ]}
      />
      <TextInput source="stripe_id" />
      <TextInput source="brand" />
      <NumberInput source="exp_month" />
      <NumberInput source="exp_year" />
      <TextInput source="last4" />
      <TextInput source="account_type" />
      <TextInput source="bank_name" />
    </SimpleForm>
  </Create>
);

export default FundingSourceCreate;
