import React, { useEffect } from 'react';
import { usePermissions } from 'react-admin';

type Handler = () => void;

export function withReport(handler: Handler) {
  return (Component) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
      const permissions = usePermissions();
      useEffect(() => {
        if (!permissions.isLoading) {
          handler();
        }
      }, [permissions]);
      return <Component {...props} />;
    };
  };
}
