import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const PositionSpecialtiesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Position ID" source="position.id" />
      <TextField label="Position" source="position.display_name" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

PositionSpecialtiesShow.query = gql`
  query GET_ONE($id: Int!) {
    positionSpecialty(id: $id) {
      id
      position {
        id
        display_name
      }
      specialty {
        id
        name
        label
      }
      created_at
      updated_at
    }
  }
`;

export default PositionSpecialtiesShow;
