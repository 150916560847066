import { useCallback, useState } from 'react';
import { DataProvider, useDataProvider, useNotify } from 'react-admin';
import config from '../config';

export default function useImpersonate() {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const dataProvider: DataProvider = useDataProvider();
  const impersonate = useCallback(
    (account) => {
      const { account_type, id, first_name, last_name } = account;
      setLoading(true);
      notify(`Signing in as ${account_type} ${first_name} ${last_name} (Account ID: ${id})`, {
        type: 'info',
      });
      dataProvider
        .impersonate(id)
        .then((json) => {
          const url = `${config.client_url[account_type]}?admin_referrer=${window.location.pathname}&impersonateToken=${json.data.token}`;
          console.log(url);
          window.open(url, '_blank');
        })
        .catch((err) => {
          setError(err);
          notify(`Failed impersonating`, { type: 'warning' });
        })
        .finally(() => setLoading(false));
    },
    [dataProvider, notify],
  );
  return { impersonate, loading, error };
}
