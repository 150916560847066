import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { Datagrid, FunctionField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import EnumField from 'components/Enum/EnumField';
import { DisplayHelpers } from 'utils/helpers';
import * as R from 'ramda';
import LocationInput from 'components/LocationInput';
import _ from 'lodash';

const JobTemplateListFilters = [<IdInput />, <LocationInput alwaysOn />];

const JobTemplateList = () => (
  <List exporter={false} filters={JobTemplateListFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Location ID" source="location.id" />
      <FunctionField label="Positions" render={DisplayHelpers.professional.positionNames} />
      <FunctionField
        label="Specialties"
        render={_.flowRight<any, any, any, any, string>(
          (s) => s.join(', '),
          R.map(_.property('label')),
          R.defaultTo([]),
          _.property('specialties'),
        )}
      />
      <FunctionField
        label="Surgery Types"
        render={_.flowRight<any, any, any, any, string>(
          (s) => s.join(', '),
          R.map(_.property('display_name')),
          R.defaultTo([]),
          _.property('surgery_types'),
        )}
      />
      <EnumField label="Patient Population" source="patient_population" />
    </Datagrid>
  </List>
);

JobTemplateList.query = gql`
  query GET_LIST($input: JobTemplateQueryInput) {
    _jobTemplatesMeta(input: $input) {
      count
    }
    jobTemplates(input: $input) {
      id
      location {
        id
      }
      positions {
        id
        display_name
      }
      specialties {
        id
        label
      }
      surgery_types {
        id
        display_name
      }
      patient_population
    }
  }
`;

export default JobTemplateList;
