import React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';
import MarketInput from 'components/MarketInput';

const MarketLocationEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="city" />
      <TextInput source="county" />
      <TextInput source="zip_code" />
      <MarketInput />
    </SimpleForm>
  </Edit>
);

export default MarketLocationEdit;
