import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  BooleanField,
  Datagrid,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import List from 'components/List';
import IdInput from 'components/Filter/IdInput';
import SearchInput from 'components/Filter/SearchInput';

const SpecialtyListFilters = [<IdInput />, <SearchInput alwaysOn resettable />];

const SpecialtyList = () => (
  <List filters={SpecialtyListFilters} exporter={false} data-testid="specialty-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <ArrayField label="Position List" source="positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="label" />
      <BooleanField source="active" />
      <NumberField label="Rate Differential" source="rate_differential" />
    </Datagrid>
  </List>
);

SpecialtyList.query = gql`
  query specialties($input: SpecialtyQueryInput) {
    _specialtiesMeta(input: $input) {
      count
    }
    specialties(input: $input) {
      id
      name
      label
      active
      rate_differential
      positions {
        id
        display_name
      }
    }
  }
`;

export default SpecialtyList;
