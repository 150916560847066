import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import { currentTimeZone } from 'utils/time';

const DisputesTab = ({ target }) => {
  const checkAccess = useCheckAccess();
  return (
    <SimpleShowLayout>
      <ReferenceManyField label="" pagination={<Pagination />} reference="Dispute" target={target}>
        <Datagrid bulkActionButtons={false} data-testid="job_show-disputes_table">
          {checkAccess('view', 'Dispute', 'id') && <IdField />}
          {checkAccess('view', 'Dispute', 'job_id') && <LinkField label="Job" source="job_id" />}
          {checkAccess('view', 'Dispute', 'invoice_id') && (
            <LinkField label="Invoice" source="invoice_id" />
          )}
          {checkAccess('view', 'BillingGroup', 'id') && (
            <FunctionField
              label="Billing Group ID"
              render={(v) => {
                const billingGroupId = v.job?.billing_group_id || v.invoice?.billing_group_id;
                return (
                  <LinkField to={`/BillingGroup/${billingGroupId}/show`}>
                    {billingGroupId}
                  </LinkField>
                );
              }}
            />
          )}
          {checkAccess('view', 'Dispute', 'assigned_to_id') && (
            <LinkField label="Assigned To ID" source="assigned_to_id" typename="Administrator" />
          )}
          {checkAccess('view', 'Dispute', 'assigned_to') &&
            checkAccess('view', 'Account', 'first_name') &&
            checkAccess('view', 'Account', 'last_name') && (
              <TextField
                label="Assigned To"
                source="assigned_to.account.name"
                data-testid="job_show-disputes_assigned_to"
              />
            )}
          {checkAccess('view', 'Dispute', 'original_clock_in') && (
            <DateTimeField
              label="Original Clock In"
              format="MM/dd/yyyy HH:mm zzz"
              source="original_clock_in"
              timeZone={currentTimeZone()}
            />
          )}
          {checkAccess('view', 'Dispute', 'original_clock_out') && (
            <DateTimeField
              label="Original Clock Out"
              format="MM/dd/yyyy HH:mm zzz"
              source="original_clock_out"
              timeZone={currentTimeZone()}
            />
          )}
          {checkAccess('view', 'Dispute', 'adjusted_clock_in') && (
            <DateTimeField
              label="Adjusted Clock In"
              format="MM/dd/yyyy HH:mm zzz"
              source="adjusted_clock_in"
              timeZone={currentTimeZone()}
            />
          )}
          {checkAccess('view', 'Dispute', 'adjusted_clock_out') && (
            <DateTimeField
              label="Adjusted Clock Out"
              format="MM/dd/yyyy HH:mm zzz"
              source="adjusted_clock_out"
              timeZone={currentTimeZone()}
            />
          )}
          {checkAccess('view', 'Dispute', 'reason') && (
            <TextField label="Reason" source="reason" data-testid="job_show-disputes_reason" />
          )}
          {checkAccess('view', 'Dispute', 'category') && (
            <TextField
              label="Category"
              source="category"
              data-testid="job_show-disputes_category"
            />
          )}
          {checkAccess('view', 'Dispute', 'status') && (
            <TextField label="Status" source="status" data-testid="job_show-disputes_status" />
          )}
          {checkAccess('view', 'Dispute', 'resolution') && (
            <TextField
              label="Resolution"
              source="resolution"
              data-testid="job_show-disputes_resolution"
            />
          )}
          {checkAccess('view', 'Dispute', 'created_at') && <TimestampFields />}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(DisputesTab);
