import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const MarketLocationList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="city" />
      <TextField source="county" />
      <TextField label="Zip Code" source="zip_code" />
      <TextField label="Market ID" source="market.id" />
    </Datagrid>
  </List>
);

MarketLocationList.query = gql`
  query marketLocations($input: MarketLocationQueryInput) {
    _marketLocationsMeta(input: $input) {
      count
    }
    marketLocations(input: $input) {
      id
      city
      county
      zip_code
      market {
        id
      }
    }
  }
`;

export default MarketLocationList;
