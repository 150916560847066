import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const Friendly_id_slugsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="slug" />
      <ReferenceField reference="sluggables" source="sluggable_id">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="sluggable_type" />
      <TextField source="scope" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

Friendly_id_slugsShow.query = gql`
  query GET_ONE($id: Int!) {
    friendlyIDSlug(id: $id) {
      id
    }
  }
`;

export default Friendly_id_slugsShow;
