import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify, useRecordContext } from 'react-admin';
import create from 'utils/api/create';

const useAssignmentDuplicate = () => {
  const assignment = useRecordContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notify = useNotify();

  const duplicate = async () => {
    try {
      setLoading(true);
      await create(
        `/v3/admin/assignments/${assignment.id}/duplicate`,
        { assignment_request_id: null },
        'Assignment',
      );
      notify('Assignment duplicate successful!', { type: 'success' });
      navigate(`/Assignment/${assignment.id}/show`);
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'warning' });
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, duplicate };
};

export default useAssignmentDuplicate;
