import React from 'react';
import { TextInput, Edit, SimpleForm, BooleanInput } from 'react-admin';

const ReferralSourceEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="referral-source-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="referral-source-edit-name" />
      <TextInput source="label" data-testid="referral-source-edit-label" />
      <BooleanInput source="admin_only" />
      <BooleanInput label="Applies to Professional" source="applies_to_professionals" />
      <BooleanInput label="Applies to Facilities" source="applies_to_facilities" />
    </SimpleForm>
  </Edit>
);

export default ReferralSourceEdit;
