import { useCallback } from 'react';
import { useNotify } from 'react-admin';

type Fn<T> = (...args: any[]) => Promise<T>;
type Mapper<T> = (arg: T) => string;

type Options<T, E> = {
  onSuccess?: Mapper<T> | string;
  onError?: Mapper<E> | string;
};

export default function useNotifyWrap<T, E = Error>(fn: Fn<T>, options?: Options<T, E>) {
  const notify = useNotify();
  const wrapped = useCallback<typeof fn>(
    (...args) => {
      return fn(...args)
        .then((res) => {
          if (options?.onSuccess) {
            notify(
              typeof options.onSuccess === 'function' ? options.onSuccess(res) : options.onSuccess,
              { type: 'success' },
            );
          }
          return res;
        })
        .catch((e) => {
          if (options?.onError) {
            notify(typeof options.onError === 'function' ? options.onError(e) : options.onError, {
              type: 'error',
            });
          } else {
            notify(e.message, { type: 'error' });
          }
          return e;
        });
    },
    [fn, notify, options],
  );
  return wrapped;
}
