import React from 'react';
import { TextInput, Create, SimpleForm, required } from 'react-admin';

const HealthSystemCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput label="Health System Name" source="name" validate={[required()]} />
      <TextInput label="Health System Label" source="label" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default HealthSystemCreate;
