import React from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';
import useDialog from 'hooks/useDialog';
import {
  SimpleForm,
  SelectInput,
  useRefresh,
  useNotify,
  Toolbar,
  SaveButton,
  useRecordContext,
} from 'react-admin';
import useCheckAccess from 'hooks/useCheckAccess';
import { ProfessionalStatusEnum } from 'typings/enums';
import create from 'utils/api/create';

const UniversalBackground = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const { id, status } = record;
  const label = 'Order Supplemental Background Check';
  const { open, openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();
  const checkAccess = useCheckAccess();

  const handleSubmit = async ({ packageType }: any) => {
    try {
      const response = await create('/v3/admin/universal/submit', {
        package: packageType,
        professional_id: id,
      });
      notify('Background Check opened in new tab');
      refresh();
      closeDialog();
      window.open(response.data.redirect_url);
    } catch (error: any) {
      notify(error.message, { type: 'error' });
    }
  };

  if (!checkAccess('actions', 'UniversalBackgroundCheck', 'submit')) {
    return null;
  }

  if (status !== ProfessionalStatusEnum.active) {
    return null;
  }

  return (
    <>
      <Button
        data-testid="ssn-dialog"
        onClick={openDialog}
        variant={'outlined'}
        color="primary"
        size="small"
      >
        {label}
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{label}</DialogTitle>
        <SimpleForm
          onSubmit={handleSubmit}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" />
              <Button onClick={closeDialog}>Cancel</Button>
            </Toolbar>
          }
        >
          <SelectInput
            label="Package"
            source="packageType"
            choices={[
              { id: 'Basic', name: 'Basic' },
              { id: 'Traveler 100', name: 'Traveler 100' },
              { id: 'MS Traveler 100', name: 'MS Traveler 100' },
            ]}
          />
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default UniversalBackground;
