import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useInput } from 'react-admin';

interface JsonTextInputProps {
  label?: string;
  source: string;
}

const JsonTextInput = ({ label, source, ...restProps }: JsonTextInputProps) => {
  const {
    field: { name, onBlur, onChange, value },
    fieldState: { isDirty },
  } = useInput({ source });
  const [isJsonValid, setIsJsonValid] = useState(true);

  const validateJson = () => {
    try {
      const parsedJson = JSON.parse(value);
      setIsJsonValid(true);
      onChange(JSON.stringify(parsedJson, null, 2));
      onBlur();
    } catch (e) {
      setIsJsonValid(false);
    }
  };

  return (
    <TextField
      label={label}
      multiline
      variant="filled"
      minRows={4}
      error={isDirty && !isJsonValid}
      helperText={isDirty && !isJsonValid && 'Invalid JSON'}
      name={name}
      onBlur={validateJson}
      onChange={onChange}
      value={value}
      {...restProps}
    />
  );
};

export default JsonTextInput;
