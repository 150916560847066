import React from 'react';
import { TextInput, NumberInput, Edit, SimpleForm, required } from 'react-admin';

const VmsTypeEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="fee_percent" validate={[required()]} />
      <NumberInput source="non_billable_orientation_burden_hours" />
      <NumberInput source="billable_orientation_hours" />
      <TextInput label="Bullhorn ID" source="bullhorn_id" />
    </SimpleForm>
  </Edit>
);

export default VmsTypeEdit;
