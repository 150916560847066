import React from 'react';
import { gql } from '@apollo/client';
import { SelectInput, Datagrid, TextField, TextInput, BooleanInput } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import { MarketFilter, StateFilter, SearchFilter } from 'components/Filter';
import ReferenceInput from 'components/ReferenceInput';
import IdField from 'components/IdField';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import VmsTypeInput from 'components/VmsTypeInput';
import HealthSystemInput from 'components/HealthSystemInput';
import MspInput from 'components/MspInput';

const CompaniesFilters = [
  <SearchFilter />,
  <IdInput />,
  <TextInput alwaysOn source="search.phone_number" label="Phone Search" />,
  <MarketFilter alwaysOn />,
  <StateFilter alwaysOn />,
  <EnumSelectInput alwaysOn source="search.plan" label="Plan" />,
  <ReferenceInput
    alwaysOn
    emptyText="All"
    label="Location Type"
    source="search.location_type_ids"
    reference="LocationType"
  >
    <SelectInput optionText="label" label="Location Type" />
  </ReferenceInput>,
  <BooleanInput source="search.saas_credentialing_enabled" label="BYOP Credentialing Enabled" />,
  <BooleanInput source="search.saas_scheduling_enabled" label="BYOP Scheduling Enabled" />,
  <BooleanInput alwaysOn source="search.onboarding_complete" label="Onboard Complete" />,
  <BooleanInput alwaysOn source="search.verified" label="Verified" />,
  <SelectInput
    choices={[
      { id: 1, name: '0-69%' },
      { id: 2, name: '70-79%' },
      { id: 3, name: '80-89%' },
      { id: 4, name: '90-100%' },
    ]}
    alwaysOn
    source="search.fill_ratio"
    label="Fill Ratio"
  />,
  <VmsTypeInput />,
  <MspInput />,
  <HealthSystemInput />,
  <BooleanInput alwaysOn source="search.no_vms" label="Not A VMS" />,
  <BooleanInput alwaysOn source="search.has_balance" label="Has Balance" />,
  <TextInput label="Referral" source="search.referral" />,
  <TextInput label="UTM Source" source="search.utm_source" />,
  <TextInput label="UTM Campaign" source="search.utm_campaign" />,
];

const CompaniesList = () => (
  <List hasCreate exporter={false} filters={CompaniesFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField label="Office Phone" source="phone_number" />
      <TextField label="VMS Type" source="vms_type.label" />
      <TextField label="MSP" source="msp.label" />
      <TextField label="Health System" source="health_system.label" />
    </Datagrid>
  </List>
);

CompaniesList.query = gql`
  query GET_LIST($input: CompanyQueryInput) {
    _companiesMeta(input: $input) {
      count
    }
    companies(input: $input) {
      id
      name
      phone_number
      vms_type {
        id
        label
      }
      msp {
        id
        label
      }
      health_system {
        id
        label
      }
    }
  }
`;

export default CompaniesList;
