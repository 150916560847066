import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'breaks',
  inputShow: gql`
    query breaks($input: BreakQueryInput) {
      breaks(input: $input) {
        id
      }
    }
  `,
  create: Create,
  edit: Edit,
  list: List,
  show: Show,
  options: {
    group: false,
  },
};
