import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import List from 'components/List';
import MoneyField from 'components/MoneyField';
import IdField from 'components/IdField';

const RefundList = () => (
  <List filters={[<IdInput />]} exporter={false} data-testid="refund-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Invoice ID" source="invoice.id" />
      <TextField label="Billing Group ID" source="invoice.billing_group_id" />
      <MoneyField label="Amount of Cents" source="amount_cents" />
      <EnumField source="reason" />
      <TextField source="status" />
      <EnumField source="kind" />
    </Datagrid>
  </List>
);

RefundList.query = gql`
  query refunds($input: RefundQueryInput) {
    _refundsMeta(input: $input) {
      count
    }
    refunds(input: $input) {
      id
      invoice {
        id
        billing_group_id
      }
      amount_cents
      reason
      status
      kind
    }
  }
`;

export default RefundList;
