import React from 'react';
import { Link, Typography } from '@mui/material';
import config from 'config';
import { useRecordContext } from 'ra-core';

const connectUrl = config.stripe_connect_url;
const customerUrl = config.stripe_customer_url;

const StripeUrlField = () => {
  const record = useRecordContext();
  const stripeId = record.stripe_id || record.stripe_customer_id;
  const href = !!record.stripe_customer_id
    ? `${customerUrl}${stripeId}`
    : `${connectUrl}${stripeId}`;

  return (
    <Typography>
      <Link target="_blank" href={href} data-testid="stripe-url-field">
        {stripeId}
      </Link>
    </Typography>
  );
};

StripeUrlField.defaultProps = {
  label: 'Stripe URL',
};

export default StripeUrlField;
