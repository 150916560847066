import React, { useState } from 'react';
import {
  required,
  TextInput,
  SelectInput,
  FormDataConsumer,
  useNotify,
  useRedirect,
  Form,
  useRecordContext,
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ReferenceInput from 'components/ReferenceInput';
import { JobStatusEnum } from 'typings/enums';
import create from 'utils/api/create';

const dangerSx = (theme) => ({
  backgroundColor: theme.palette.error.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
});

const CancelModal = (props) => {
  const record = useRecordContext(props);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async ({ canceled_by, cancellation_reason, other_reason }: any) => {
    setDisabled(true);
    try {
      const payload = {
        personable_type: canceled_by === '0' ? 'Location' : 'Professional',
        cancellation_reason_id: cancellation_reason,
        other_reason: other_reason,
      };
      if (record.assignment_id) {
        await create(
          `/v3/admin/assignments/${record.assignment_id}/jobs/${record.id}/cancel`,
          payload,
        );
      } else {
        await create(`/v3/admin/jobs/${record.id}/cancel`, payload);
      }
      redirect(`/Job/${record.id}/show`);
    } catch (error) {
      notify(`Error: ${error}`, { type: 'warning' });
    }
    setDisabled(false);
  };

  const { canceled, canceled_with_fee, completed, late_cancellation } = JobStatusEnum;
  if ([canceled, canceled_with_fee, completed, late_cancellation].includes(record.status)) {
    return null;
  }
  const choices = [{ id: '0', name: 'Location' }];
  if (record.professional) {
    choices.push({ id: '1', name: 'Professional' });
  }
  return (
    <>
      <Button
        aria-label="export profile"
        onClick={() => setOpen(true)}
        sx={dangerSx}
        data-testid="jobs-cancel_button"
      >
        CANCEL
      </Button>

      <Dialog fullWidth open={open} onClose={() => setOpen(false)} data-testid="jobs-cancel_modal">
        <DialogTitle data-testid="jobs-cancel_modal_title">Cancel Job</DialogTitle>
        <Form onSubmit={onSubmit}>
          <DialogContent>
            <SelectInput
              fullWidth
              validate={[required()]}
              source="canceled_by"
              choices={choices}
              data-testid="jobs-cancel_modal_canceled_by_input"
            />
          </DialogContent>
          <FormDataConsumer>
            {({ formData }) => (
              <DialogContent>
                {formData.canceled_by && (
                  <ReferenceInput
                    fullWidth
                    source="cancellation_reason"
                    filter={{ role: +formData.canceled_by }}
                    reference="CancellationReason"
                  >
                    <SelectInput
                      fullWidth
                      validate={[required()]}
                      optionText="label"
                      data-testid="jobs-cancel_modal_reason_input"
                    />
                  </ReferenceInput>
                )}
                {formData.canceled_by === '1' && formData.cancellation_reason === 15 && (
                  <TextInput
                    fullWidth
                    validate={[required()]}
                    label="Other Reason"
                    source="other_reason"
                    defaultValue=""
                    data-testid="jobs-cancel_modal_other_reason_input"
                  />
                )}
              </DialogContent>
            )}
          </FormDataConsumer>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              data-testid="jobs-cancel_modal_close_button"
            >
              Close
            </Button>

            <Button
              aria-label="Cancel"
              type="submit"
              variant="contained"
              sx={dangerSx}
              data-testid="jobs-cancel_modal_cancel_button"
              disabled={disabled}
            >
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default CancelModal;
