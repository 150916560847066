import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import JobBoardShowActions from './ShowActions';
import TimestampFields from 'components/TimestampFields';

const JobsBoardShow = () => (
  <Show actions={<JobBoardShowActions />} data-testid="jobs-board-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="board" />
      <TextField source="role" />
      <ArrayField source="positions">
        <SingleFieldList>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Position Ids" source="positions">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="title" />
      <TextField label="Market" source="market.display_name" />
      <TextField label="Market Id" source="market.id" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip" />
      <TextField source="description" />
      <TextField source="salary" />
      <TextField label="Job Type" source="job_type" />
      <TextField source="category" />
      <TimestampFields />
      <BooleanField label="Active Job" source="active_job" />
    </SimpleShowLayout>
  </Show>
);

JobsBoardShow.query = gql`
  query jobsBoard($id: Int!) {
    jobsBoard(id: $id) {
      id
      positions {
        id
        display_name
      }
      board
      role
      title
      city
      state
      zip
      description
      market {
        id
        display_name
      }
      salary
      job_type
      category
      created_at
      updated_at
      active_job
    }
  }
`;

export default JobsBoardShow;
