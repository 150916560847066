import { useGetList } from 'react-admin';

const useClockEventAttachments = (job_id: number) =>
  useGetList('ClockEventAttachment', {
    filter: {
      search: {
        job_id,
      },
    },
  });

export default useClockEventAttachments;
