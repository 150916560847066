import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, BooleanField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const EhrSystemList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

EhrSystemList.query = gql`
  query ehrSystems($input: EhrSystemQueryInput) {
    _ehrSystemsMeta(input: $input) {
      count
    }
    ehrSystems(input: $input) {
      id
      name
      label
      active
    }
  }
`;

export default EhrSystemList;
