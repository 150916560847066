import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  DateField,
  FunctionField,
  NumberField,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';

const AssignmentRequestsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="ProfessionalAssignmentFavorite"
      target="professional_id"
    >
      <Datagrid
        bulkActionButtons={false}
        data-testid="professional_show-assignment_favorites_tab_table"
      >
        <TextField
          label="ID"
          source="id"
          data-testid="professional_show-assignment_favorites_tab_id"
        />
        <LinkField source="assignment_id" />
        <TextField
          label="Status"
          source="assignment.status"
          data-testid="professional_show-assignment_favorites_tab_status"
        />
        <DateField
          label="Start Date"
          source="assignment.starts_date"
          data-testid="professional_show-assignment_favorites_tab_starts_date"
        />
        <DateField
          label="End Date"
          source="assignment.ends_date"
          data-testid="professional_show-assignment_favorites_tab_ends_date"
        />
        <FunctionField
          label="Type"
          render={(v) => (v.assignment.is_w2 ? 'W2' : '1099')}
          data-testid="professional_show-assignment_favorites_tab_type"
        />
        <TextField
          label="Health System"
          source="assignment.company.health_system.label"
          data-testid="professional_show-assignment_favorites_tab_health_system"
        />
        <TextField
          label="VMS Type"
          source="assignment.company.vms_type.label"
          data-testid="professional_show-assignment_favorites_tab_vms_type"
        />
        <NumberField
          label="# of Openings"
          source="assignment.number_of_openings"
          data-testid="professional_show-assignment_favorites_tab_number_of_openings"
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default AssignmentRequestsTab;
