import { Typography, Badge, Tooltip } from '@mui/material';
import { parseISO, isValid, format } from 'date-fns/fp';
import { OptionsWithTZ } from 'date-fns-tz';
import * as R from 'ramda';
import React, { useMemo } from 'react';
import { RA } from '../typings/react-admin';
import { displayInTimezone, startOfDay } from 'utils/time';
import { isDateOnly } from 'utils/date';
import { useRecordContext } from 'react-admin';
import _ from 'lodash';

const parseDate = (date, pattern) => {
  if (isValid(date)) {
    return format(pattern)(date);
  }
  return _.flowRight<any, Date, string>(format(pattern), parseISO)(date);
};

export const formatDateTime = R.curry(
  (options: OptionsWithTZ, pattern: string, date: string | Date) => {
    if (!date) {
      return null;
    }
    if (date instanceof Date) {
      date = date.toISOString();
    }
    if (options.timeZone) {
      return displayInTimezone(options.timeZone, date, pattern);
    }

    const shouldOffsetTimeZone = isDateOnly(new Date(date).toISOString()) && !date.endsWith('Z');
    const offsetDate = shouldOffsetTimeZone ? startOfDay(date) : date;

    return parseDate(offsetDate, pattern);
  },
);

export interface DateTimeFieldProps extends RA.FieldProps {
  label?: string;
  format?: string;
  tzSource?: string;
  timeZone?: string;
}

const DateTimeField = (props: DateTimeFieldProps) => {
  const { source, tzSource, timeZone, format = 'MM/dd/yyyy' } = props;
  const record = useRecordContext();

  const options: OptionsWithTZ = useMemo(() => ({}), []);

  if (!source) {
    console.error('source prop is required');
    return null;
  }

  const value = _.get<any>(record, source.split('.'));

  if (tzSource) {
    const tz = _.get<any>(record, tzSource.split('.'));
    if (tz) {
      options.timeZone = tz;
    }
  }

  // only use timezone if value was not set from tzSource
  if (timeZone && !options.timeZone) {
    options.timeZone = timeZone;
  }

  if (!value) {
    return null;
  }

  const displayValue = formatDateTime(options)(format)(value);
  if (!options.timeZone && tzSource) {
    return (
      <Tooltip arrow title={`Timezone is not defined`}>
        <Badge variant="dot" color="error" badgeContent={1}>
          <Typography component="span" variant="body2" data-testid="date-time-field">
            {displayValue}
          </Typography>
        </Badge>
      </Tooltip>
    );
  }
  return (
    <Typography component="span" variant="body2" data-testid="date-time-field">
      {displayValue}
    </Typography>
  );
};

DateTimeField.defaultProps = {
  format: 'MM/dd/yyyy HH:mm zzz',
};

export default DateTimeField;
