export const isLastIndex = (array: unknown[], index: number): boolean => {
  if (array.length === 0) {
    return false;
  }
  if (index < 0) {
    return false;
  }
  if (index >= array.length) {
    return false;
  }

  return index === array.length - 1;
};
