import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import TimestampFields from 'components/TimestampFields';

const PaymentShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField label="ID" source="id" />
      <LinkField label="Payout ID" source="payout_id" />
      <TextField label="Status" source="status" />
      <MoneyField label="Amount of Cents" source="amount_cents" />
      <DateField
        showTime
        options={{ hour12: false }}
        label="Last Payment Attempt at"
        source="last_payment_attempt_at"
      />
      <DateField showTime options={{ hour12: false }} label="Processed On" source="processed_on" />
      <DateField showTime options={{ hour12: false }} label="Arrival Date" source="arrival_date" />
      <TextField label="Account Type" source="account_type" />
      <BooleanField source="locked" />
      <TextField source="provider" />
      <TextField label="Provider Transaction ID" source="provider_transaction_id" />
      <TextField label="Provider Status" source="provider_status" />
      <TextField
        label="Provider Payment Delivery Method"
        source="provider_payment_delivery_method"
      />
      <TextField label="Last Error Message" source="last_error_message" />
      <NumberField label="Failed Count" source="failed_count" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

PaymentShow.query = gql`
  query payment($id: Int!) {
    payment(id: $id) {
      id
      payout_id
      status
      amount_cents
      last_payment_attempt_at
      processed_on
      arrival_date
      account_type
      locked
      provider
      provider_transaction_id
      provider_status
      provider_payment_delivery_method
      last_error_message
      failed_count
      payout_id
      created_at
      updated_at
    }
  }
`;

export default PaymentShow;
