import React, { useState } from 'react';
import { Confirm, useNotify, useResourceContext, useRefresh } from 'react-admin';
import { Button, ButtonProps } from '@mui/material';
import inflection from 'inflection';
import useDialog from 'hooks/useDialog';
import update from 'utils/api/update';
import create from 'utils/api/create';

interface ActionButtonProps {
  children?: any;
  data?: any;
  previousData?: any;
  withConfirmation?: boolean;
  ButtonProps?: ButtonProps;
  danger?: boolean;
  confirmTitle?: string;
  confirmDescription?: string;
  confirmCancel?: string;
  resource?: string;
  url: string;
  method: 'PUT' | 'POST';
  text: string;
}

const ActionButton = ({
  url,
  method,
  text,
  children,
  data = {},
  withConfirmation = false,
  ButtonProps,
  danger,
  confirmTitle = `Are you sure you want to ${text.toLowerCase()}?`,
  confirmDescription = '',
  confirmCancel = 'Cancel',
  previousData = {},
  ...props
}: ActionButtonProps) => {
  const { open, openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const resourceContext = useResourceContext(props);

  const action = async () => {
    try {
      setIsLoading(true);
      let response;
      if (method === 'PUT') {
        await update(url, data, previousData, resourceContext);
      } else if (method === 'POST') {
        response = await create(url, data, resourceContext);
      }

      notify(response?.data?.message ?? 'Success', { type: 'success' });
    } catch (error: any) {
      notify(error?.message ?? 'Error', { type: 'error' });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return (
    <>
      <Button
        data-testid="action-button"
        size="small"
        color="primary"
        sx={(theme) => {
          if (danger) {
            return {
              backgroundColor: theme.palette.error.main,
              color: '#fff',
              '&:hover': {
                backgroundColor: theme.palette.error.dark,
              },
            };
          }

          return {};
        }}
        onClick={withConfirmation ? openDialog : action}
        {...ButtonProps}
      >
        {children ?? inflection.humanize(text)}
      </Button>
      <Confirm
        loading={isLoading}
        onClose={closeDialog}
        isOpen={open}
        onConfirm={action}
        title={confirmTitle}
        content={confirmDescription}
        cancel={confirmCancel}
      />
    </>
  );
};

export default ActionButton;
