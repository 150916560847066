import React, { useMemo } from 'react';
import { RichTextField as RARichTextField, useRecordContext } from 'react-admin';
import * as R from 'ramda';
import { RichText } from '@medely/web-components';

const RichTextField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  return <RichText value={record[source]} />;
};

export default RichTextField;

const defaultFormat = (text: string) => text.replace(/[\r]/g, '<br />');

const LegacyRichTextField = ({ format = defaultFormat, source }) => {
  const record = useRecordContext();
  const lens = R.lensPath<any>(source.split('.'));
  const rawText = R.view(lens, record);
  const newRecord = useMemo(() => {
    const formattedText = typeof rawText === 'string' ? format(rawText) : '';
    return R.set<any, any>(lens, formattedText, record);
  }, [rawText, format, lens, record]);
  return <RARichTextField source={source} record={newRecord} />;
};

export { LegacyRichTextField };
