type Props = {
  status: number;
  message?: string;
};

export default class RequestError {
  status: number;
  message?: string;

  constructor({ status, message }: Props) {
    this.status = status;
    this.message = message;
  }
}
