import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';

const PositionSpecialtiesCreate = () => (
  <Create>
    <SimpleForm>
      <PositionInput />
      <SpecialtyInput />
    </SimpleForm>
  </Create>
);

export default PositionSpecialtiesCreate;
