import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import PermissiveAssignmentForm from './permissive';
import RestrictedAssignmentForm from './restricted';

const AssignmentsEdit = () => {
  const checkAccess = useCheckAccess();
  // TODO: Check if this is the correct permission to show the update assignment form
  if (checkAccess('update', 'Assignment')) {
    return <PermissiveAssignmentForm />;
  }
  return <RestrictedAssignmentForm />;
};

export default AssignmentsEdit;
