import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';

const Work_gapsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <LinkField source="professional_id" />
      <TextField source="explanation" />
      <TextField label="State" source="state.name" />
      <TextField label="Location" source="location" />
      <BooleanField source="include_in_work_histories" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

Work_gapsShow.query = gql`
  query GET_ONE($id: Int!) {
    workGap(id: $id) {
      id
      explanation
      include_in_work_histories
      start_date
      end_date
      professional_id
      location
      state {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

export default Work_gapsShow;
