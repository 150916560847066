import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';

const AuditsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
    </Datagrid>
  </List>
);

AuditsList.query = gql`
  query GET_LIST($input: AuditQueryInput) {
    _auditsMeta(input: $input) {
      count
    }
    audits(input: $input) {
      id
    }
  }
`;

export default AuditsList;
