import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, FunctionField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import { Link as MUILink } from '@mui/material';

const UniversalBackgroundCheckShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Professional ID" source="professional.id" />
      <TextField label="Order Status" source="order_status" />
      <TextField label="Order Number" source="order_number" />
      <FunctionField
        label="Order URL"
        render={(r) => (
          <MUILink target="_blank" href={r.order_url}>
            {r.order_url}
          </MUILink>
        )}
      />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

UniversalBackgroundCheckShow.query = gql`
  query GET_ONE($id: Int!) {
    universalBackgroundCheck(id: $id) {
      id
      professional {
        id
      }
      order_number
      order_status
      order_url
      created_at
      updated_at
    }
  }
`;

export default UniversalBackgroundCheckShow;
