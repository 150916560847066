import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'work_histories',
  inputShow: gql`
    query workHistories($input: WorkHistoryQueryInput) {
      workHistories(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: false,
    label: 'Work Histories',
  },
};
