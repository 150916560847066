import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  required,
  BooleanInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const EditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const LocationBlockReasonEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm toolbar={<EditToolbar />}>
      <EnumSelectInput source="kind" validate={[required()]} />
      <TextInput source="label" validate={[required()]} />
      <BooleanInput source="active" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export default LocationBlockReasonEdit;
