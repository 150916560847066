import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import MoneyField from 'components/MoneyField';
import TimestampFields from 'components/TimestampFields';

const CheckShow = () => (
  <Show data-testid="check-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Statement ID" source="statement.id" />
      <MoneyField label="Amount" source="amount_cents" />
      <TextField source="number" />
      <TextField source="note" />
      <TextField label="Stripe Invoice Item ID" source="stripe_invoice_item_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

CheckShow.query = gql`
  query check($id: Int!) {
    check(id: $id) {
      id
      statement {
        id
      }
      amount_cents
      number
      note
      stripe_invoice_item_id
      created_at
      updated_at
    }
  }
`;

export default CheckShow;
