import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const HealthSystemShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

HealthSystemShow.query = gql`
  query vms($id: Int!) {
    healthSystem(id: $id) {
      id
      name
      label
      created_at
      updated_at
    }
  }
`;

export default HealthSystemShow;
