import React from 'react';
import {
  TextField,
  Datagrid,
  BooleanField,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import { omit } from 'ramda';

const BillingGroupsTab = ({ target }) => {
  const record = useRecordContext();
  const resource = useResourceContext();
  const showCreate = resource === 'Company';

  return (
    <>
      {showCreate && (
        <QuickCreateButton
          initialValues={{
            // Values are specific to quick add form
            company_id: record.id,
            billing_group_name: `${record.name} Billing Group`,
            status: 'active',
            plan: 'standard',
            billing: 'weekly_billing',
            auto_pay: 'false',
            statement_fee_percent: 0.04,
            line_of_credit_cents: 2000000,
            net_term: 30,
            check_line_of_credit: true,
            tier: 'Low risk (new)',
            past_due_allowance_amount_cents: 100_000_000,
          }}
          resource="BillingGroup"
          url="/v3/admin/billing_groups"
          transform={(data) => {
            const payload = { ...data, name: data.billing_group_name };
            return omit(['billing_group_name'], payload);
          }}
        >
          <ReferenceInput source="company_id" reference="Company" fullWidth disabled>
            <SelectInput source="billing_group_name" disabled />
          </ReferenceInput>
          <TextInput label="Name" source="billing_group_name" fullWidth />
        </QuickCreateButton>
      )}
      <SimpleShowLayout>
        <ReferenceManyField
          pagination={<Pagination />}
          label=""
          reference="BillingGroup"
          target={target}
        >
          <Datagrid bulkActionButtons={false}>
            <LinkField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <TextField label="Status" source="status" />
            <TextField label="Plan" source="plan" />
            <TextField label="Billing" source="billing" />
            <BooleanField label="Auto Pay" source="auto_pay" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </>
  );
};

export default React.memo(BillingGroupsTab);
