import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  BooleanInput,
  NumberField,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import { IdFilter } from 'components/Filter';
import List from 'components/List';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PayoutsListActions from './ListActions';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import ProfessionalInput from 'components/ProfessionalInput';
import DateFilter from 'components/Filter/DateFilter';

const PayoutsFilter = [
  <IdFilter source="search.id" />,
  <ProfessionalInput alwaysOn source="search.professional_id" />,
  <EnumSelectInput alwaysOn label="Status" source="search.status" />,
  <DateFilter alwaysOn label="Created: From Date" source="search.created_from_date" />,
  <DateFilter alwaysOn label="Created: To Date" source="search.created_to_date" />,
  <DateFilter label="W2 Submit By: From Date" source="search.w2_submit_from_date" />,
  <DateFilter label="W2 Submit By: To Date" source="search.w2_submit_to_date" />,
  <BooleanInput alwaysOn label="Failing Payouts" source="search.failing_payouts" />,
  <BooleanInput alwaysOn label="Has Balance" source="search.has_balance" />,
  <BooleanInput alwaysOn label="Failed Payouts" source="search.failed_payouts" />,
];

const PayoutsList = () => {
  const checkAccess = useCheckAccess();

  return (
    <List
      exporter={false}
      actions={<PayoutsListActions />}
      filters={PayoutsFilter}
      bulkActionButtons={false}
    >
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'Payout', 'id') && <LinkField label="ID" source="id" />}
        {checkAccess('view', 'Payout', 'amount_cents') && (
          <MoneyField label="Amount Cents" source="amount_cents" />
        )}
        {checkAccess('view', 'Payout', 'amount_currency') && (
          <TextField label="Amount Currency" source="amount_currency" />
        )}
        {checkAccess('view', 'Payout', 'arrival_date') && (
          <DateField label="Arrival Date" source="arrival_date" />
        )}
        {checkAccess('view', 'Payout', 'status') && <TextField label="Status" source="status" />}
        {checkAccess('view', 'Payout', 'account_type') && (
          <TextField label="Account Type" source="account_type" />
        )}
        {checkAccess('view', 'Payout', 'professional_id') && (
          <LinkField label="Professional ID" source="professional.id" />
        )}
        {checkAccess('view', 'Payout', 'assignment_id') && (
          <LinkField label="Assignment ID" source="assignment_id" />
        )}
        {checkAccess('view', 'Payout', 'stripe_id') && (
          <TextField label="Stripe ID" source="stripe_id" />
        )}
        {checkAccess('view', 'Professional', 'balance_cents') && (
          <MoneyField label="Professional Balance" source="professional.balance_cents" />
        )}
        {checkAccess('view', 'Payout', 'pause') && <BooleanField label="Pause" source="pause" />}
        {checkAccess('view', 'Payout', 'source_type') && (
          <TextField label="Source Type" source="source_type" />
        )}
        {checkAccess('view', 'Payout', 'notes') && <TextField label="Notes" source="notes" />}
        {checkAccess('view', 'Payout', 'processed_on') && (
          <DateField
            label="Processed On"
            source="processed_on"
            showTime
            options={{ hour12: false }}
          />
        )}
        {checkAccess('view', 'Payout', 'failed_count') && (
          <NumberField label="Failed Count" source="failed_count" />
        )}
        {checkAccess('view', 'Payout', 'failed_reason') && (
          <TextField label="Failed Reason" source="failed_reason" />
        )}
        {checkAccess('view', 'Payout', 'created_at') && (
          <DateField label="Created At" source="created_at" showTime options={{ hour12: false }} />
        )}
      </Datagrid>
    </List>
  );
};
PayoutsList.query = gql`
  query GET_LIST($input: PayoutQueryInput) {
    _payoutsMeta(input: $input) {
      count
    }
    payouts(input: $input) {
      id
      amount_cents
      amount_currency
      arrival_date
      created_at
      assignment_id
      starts_time
      ends_time
      w2_submit_by_time
      status
      account_type
      professional_id
      professional {
        id
        balance_cents
      }
      stripe_id
      stripe_transfer_id
      adp_id
      pause
      source_type
      notes
      processed_on
      failed_count
      failed_reason
      signature_url
      aws_signature_url
    }
  }
`;

export default PayoutsList;
