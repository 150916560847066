import React, { useCallback } from 'react';
import { EditButton, useRefresh, useNotify, useRecordContext, TopToolbar } from 'react-admin';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import getAxios from 'getAxios';

const CreateVmsAssignmentButton = () => {
  const { valid_data, id } = useRecordContext();
  let defaultValues = {};
  if (valid_data) {
    defaultValues = {
      staff_can_create_job: true,
      user_id: valid_data.userId && parseInt(valid_data.userId),
      vms_assignment_id: id,
      is_extension: false,
      charge_base_hourly_rate_cents: valid_data.facilityRate * 100,
      charge_on_call_hourly_rate_cents: (valid_data.onCallRate || 0) * 100,
      order_number: valid_data.job_id,
      jobs_detail: valid_data.description,
      position_ids: valid_data.position_ids,
      notes: valid_data.description,
      starts_date: valid_data.startDate,
      ends_date: valid_data.endDate,
      flexible_starts_date: true,
      starts_time: valid_data.startDate,
      guaranteed_minimum_hours: valid_data.guaranteedHoursByWeek,
    };
  }

  return (
    <Button
      component={Link}
      target="_blank"
      to={{
        pathname: '/Assignment/create',
        search: `?source=${encodeURIComponent(JSON.stringify(defaultValues))}`,
      }}
    >
      Create Assignment
    </Button>
  );
};

const axios = getAxios();

const VmsAssignmentShowActions = (props) => {
  const record = useRecordContext();
  const { hasEdit } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const validateVmsAssignment = useCallback(async () => {
    return axios
      .post(`/v3/admin/vms_assignments/${record.id}/validate`, {})
      .then(() => {
        notify('Validated');
        refresh();
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  }, [record, notify, refresh]);
  if (!record) {
    return null;
  }
  return (
    <TopToolbar>
      <CreateVmsAssignmentButton />
      <Button disabled={!record.updated} onClick={validateVmsAssignment}>
        Confirm Changes
      </Button>
      {hasEdit && <EditButton record={record} />}
    </TopToolbar>
  );
};

export default VmsAssignmentShowActions;
