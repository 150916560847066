export default () => {
  return async ({ url, name }) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Unable to download the resource (code: ${response.status})`);
    }

    const blob = await response.blob();

    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  };
};
