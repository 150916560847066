import { gql } from '@apollo/client';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create from './Create';

export default {
  name: 'financial_transactions',
  inputShow: gql`
    query financialTransactions($input: FinancialTransactionQueryInput) {
      financialTransactions(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Financial Transactions',
  },
};
