import React from 'react';
import { gql } from '@apollo/client';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import { Datagrid, BooleanInput, NumberInput, RaRecord } from 'react-admin';
import { AccountManagerFilter, IdFilter, SearchFilter } from 'components/Filter';
import List from 'components/List';
import LinkField from 'components/LinkField';
import * as R from 'ramda';
import { useTheme, Theme } from '@mui/material/styles';
import { ENUMS } from 'components/Enum/enums';
import IdField from 'components/IdField';
import EnumField from 'components/Enum/EnumField';
import DateFilter from 'components/Filter/DateFilter';
import useCheckAccess from 'hooks/useCheckAccess';

const StatementsFilters = [
  <IdFilter />,
  <SearchFilter />,
  <AccountManagerFilter label="Account Manager" alwaysOn source="search.account_manager_id" />,
  <NumberInput label="Billing Group ID" alwaysOn source="billing_group_id" />,
  <BooleanInput label="Paid" source="search.paid" />,
  <BooleanInput label="Past Due" source="search.past_due" />,
  <DateFilter label="Starts Date" source="search.pay_period_start" />,
  <DateFilter label="Ends Date" source="search.pay_period_end" />,
];

const status = R.propEq('status');
const borderColor = (color) => R.always({ borderColor: color });

const statementRowStyle = (theme: Theme) => (record: RaRecord) =>
  R.mergeRight(
    { borderLeft: '8px solid' },
    R.cond<any, any>([
      [status(ENUMS.Statement.status.paid), borderColor(theme.palette.success.main)],
      [status(ENUMS.Statement.status.past_due), borderColor(theme.palette.error.main)],
      [R.T, borderColor(theme.palette.grey[100])],
    ])(record),
  );

const StatementsList = () => {
  const theme = useTheme();
  const checkAccess = useCheckAccess();

  return (
    <List exporter={false} filters={StatementsFilters}>
      <Datagrid
        rowStyle={statementRowStyle(theme)}
        bulkActionButtons={false}
        data-testid="statements-list"
      >
        {checkAccess('view', 'Statement', 'id') && <IdField />}
        {checkAccess('view', 'Statement', 'billing_group_id') && (
          <LinkField label="Billing Group" source="billing_group_id" typename="BillingGroup" />
        )}
        {checkAccess('view', 'Statement', 'status') && <EnumField label="Status" source="status" />}
        {checkAccess('view', 'Statement', 'pay_period_start') && (
          <DateTimeField label="Pay Period Start" source="pay_period_start" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Statement', 'pay_period_end') && (
          <DateTimeField label="Pay Period End" source="pay_period_end" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Statement', 'due_date') && (
          <DateTimeField label="Due Date" source="due_date" format="MM/dd/yyyy" />
        )}
        {checkAccess('view', 'Statement', 'total_cents') && (
          <MoneyField label="Total Cents" source="total_cents" />
        )}
        {checkAccess('view', 'Statement', 'amount_due_cents') && (
          <MoneyField label="Amount Due Cents" source="amount_due_cents" />
        )}
        {checkAccess('view', 'Statement', 'paid_date') && (
          <DateTimeField label="Paid Date" source="paid_date" format="MM/dd/yyyy" />
        )}
      </Datagrid>
    </List>
  );
};

StatementsList.query = gql`
  query GET_LIST($input: StatementQueryInput) {
    _statementsMeta(input: $input) {
      count
    }
    statements(input: $input) {
      id
      paid_date
      pay_period_start
      billing_group_id
      amount_due_cents
      total_cents
      pay_period_end
      due_date
      status
      invoices {
        id
        stripe_invoice_url
        sub_total_cents
        fee_percent
        status
        processing_fee_cents
        balance_cents
        stripe_invoice_id
        stripe_invoice_url
        processing_fee_stripe_invoice_item_id
        stripe_invoice_number
      }
    }
  }
`;

export default StatementsList;
