import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const UniversalBackgroundChecksEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput source="order_status" />
      <TextInput source="order_number" />
      <TextInput source="order_url" />
    </SimpleForm>
  </Edit>
);

export default UniversalBackgroundChecksEdit;
