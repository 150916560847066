import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { AutocompleteInput, NumberInput, TextInput, Edit, SimpleForm } from 'react-admin';
import InputUtils from 'utils/input';

const CheckEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="check-edit">
    <SimpleForm>
      <ReferenceInput label="Statement" source="statement_id" reference="Statement">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <NumberInput source="amount_cents" data-testid="check-edit-amount-cents" />
      <TextInput source="number" />
      <TextInput source="stripe_invoice_item_id" />
      <TextInput source="note" data-testid="check-edit-note" />
    </SimpleForm>
  </Edit>
);

export default CheckEdit;
