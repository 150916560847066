import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import {
  TextField,
  Datagrid,
  BooleanField,
  NumberField,
  NumberInput,
  BooleanInput,
  FunctionField,
  SelectInput,
  ReferenceField,
} from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import VmsTypeInput from 'components/VmsTypeInput';
import HealthSystemInput from 'components/HealthSystemInput';
import MspInput from 'components/MspInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import LinkField from 'components/LinkField';
import { ENUMS } from 'components/Enum/enums';
import { choicesFromEnum } from 'utils/helpers';
import LocationInput from 'components/LocationInput';

const Filters = [
  <IdInput />,
  <NumberInput label="VMS Type ID" source="vms_type_id" alwaysOn />,
  <VmsTypeInput />,
  <NumberInput label="MSP ID" source="msp_id" alwaysOn />,
  <MspInput />,
  <HealthSystemInput />,
  <LocationInput alwaysOn />,
  <EnumSelectInput emptyText="" source="applies_to" alwaysOn />,
  <BooleanInput source="local_okay" />,
  <NumberInput source="mileage_cutoff" />,
  <SelectInput source="rate_origin" choices={choicesFromEnum(ENUMS.LocalRateRule.rate_origin)} />,
  <NumberInput source="offset_amount_cents" />,
  <NumberInput source="offset_percentage" />,
  <BooleanInput source="force_draft" />,
];

const LocalRateRulesList = () => (
  <List filters={Filters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <FunctionField
        label="VMS Type ID"
        render={(v) =>
          v.vms_type_id !== null ? (
            <LinkField to={`/VmsType/${v.vms_type_id}/show`}>{v.vms_type_id}</LinkField>
          ) : null
        }
      />
      <ReferenceField label="VMS Type" source="vms_type_id" reference="vms_types">
        <TextField source="label" />
      </ReferenceField>
      <FunctionField
        label="MSP ID"
        render={(v) =>
          v.msp_id !== null ? <LinkField to={`/Msp/${v.msp_id}/show`}>{v.msp_id}</LinkField> : null
        }
      />
      <ReferenceField label="MSP" source="msp_id" reference="msps">
        <TextField source="label" />
      </ReferenceField>
      <FunctionField
        label="Health System ID"
        render={(v) =>
          v.health_system_id !== null ? (
            <LinkField to={`/HealthSystem/${v.health_system_id}/show`}>
              {v.health_system_id}
            </LinkField>
          ) : null
        }
      />
      <ReferenceField label="Health System" source="health_system_id" reference="health_systems">
        <TextField source="label" />
      </ReferenceField>
      <FunctionField
        label="Location ID"
        render={(v) =>
          v.location_id !== null ? (
            <LinkField to={`/Location/${v.location_id}/show`}>{v.location_id}</LinkField>
          ) : null
        }
      />
      <ReferenceField label="Location" source="location_id" reference="locations">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Applies To"
        render={(v) =>
          v.applies_to !== null ? ENUMS.LocalRateRule.applies_to[v.applies_to] : null
        }
      />
      <BooleanField source="local_okay" sortable={false} />
      <NumberField source="mileage_cutoff" sortable={false} />
      <TextField source="rate_origin" sortable={false} />
      <FunctionField
        label="Rate Origin Rule"
        render={(v) =>
          v.rate_origin !== null ? ENUMS.LocalRateRule.rule_name[v.rate_origin] : null
        }
      />
      <NumberField source="offset_amount_cents" sortable={false} />
      <NumberField source="offset_percentage" sortable={false} />
      <BooleanField source="force_draft" sortable={false} />
    </Datagrid>
  </List>
);

LocalRateRulesList.query = gql`
  query localRateRules($input: LocalRateRuleQueryInput) {
    localRateRules(input: $input) {
      id
      vms_type_id
      msp_id
      health_system_id
      location_id
      applies_to
      local_okay
      mileage_cutoff
      rate_origin
      offset_amount_cents
      offset_percentage
      force_draft
    }
    _localRateRulesMeta(input: $input) {
      count
    }
  }
`;

export default LocalRateRulesList;
