import { Datagrid, TextField } from 'react-admin';
import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';

const QualificationsAttributesList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="field_type" />
    </Datagrid>
  </List>
);

QualificationsAttributesList.query = gql`
  query GET_LIST($input: QualificationAttributeQueryInput) {
    qualificationAttributes(input: $input) {
      id
      name
      slug
      field_type
      data
      required
    }
    _qualificationAttributesMeta(input: $input) {
      count
    }
  }
`;

export default QualificationsAttributesList;
