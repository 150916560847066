import React from 'react';
import { Create, SimpleForm, BooleanField } from 'react-admin';
import JobInput from 'components/JobInput';

const JobProfileCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <JobInput />
      <BooleanField source="marketplace" />
    </SimpleForm>
  </Create>
);

export default JobProfileCreate;
