import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
} from 'react-admin';
import MspInput from 'components/MspInput';
import HealthSystemInput from 'components/HealthSystemInput';
import VmsTypeInput from 'components/VmsTypeInput';
import PhoneInput from 'components/PhoneInput';
import ReferralSourceInput from 'components/ReferralSourceInput';

const CompaniesCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="referral" />
      <TextInput label="RFM" source="rfm" />
      <ReferralSourceInput filter={{ applies_to_facilities: true }} />
      <FormDataConsumer>
        {({ formData }) =>
          formData.referral_source_id === 9 && (
            <TextInput label="Other Referral Source" source="other_referral_source" />
          )
        }
      </FormDataConsumer>
      <MspInput />
      <HealthSystemInput />
      <VmsTypeInput />
      <BooleanInput label="Onboarding Complete" source="onboarding_complete" />
      <BooleanInput label="Verified" source="verified" />
      <PhoneInput label="Phone Number" source="phone_number" />
      <DateTimeInput label="Disabled At" source="disabled_at" />
      <DateTimeInput label="Last Time Job Posted" source="last_time_job_posted_at" />
      <TextInput source="detailed_notes" multiline />
    </SimpleForm>
  </Create>
);

export default CompaniesCreate;
