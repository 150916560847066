import { BooleanField, Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import { SearchFilter } from 'components/Filter';
import IdInput from 'components/Filter/IdInput';

const PostingFieldsListFilters = [<SearchFilter alwaysOn />, <IdInput />];

const PostingFieldsList = () => (
  <List exporter={false} filters={PostingFieldsListFilters} hasCreate>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="label" />
      <TextField source="slug" />
      <BooleanField source="show_on_per_diem" label="Per Diem" />
      <BooleanField source="show_on_assignment" label="Assignment" />
    </Datagrid>
  </List>
);

PostingFieldsList.query = gql`
  query GET_LIST($input: PostingFieldQueryInput) {
    postingFields(input: $input) {
      id
      label
      description
      slug
      show_on_per_diem
      show_on_assignment
      required
      field_type
      constraints
    }
    _postingFieldsMeta(input: $input) {
      count
    }
  }
`;

export default PostingFieldsList;
