import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, ReferenceField } from 'react-admin';
import List from 'components/List';

const Work_gapsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField reference="professional_profiles" source="professional_profile_id">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="explanation" />
    </Datagrid>
  </List>
);

Work_gapsList.query = gql`
  query GET_LIST($input: WorkGapQueryInput) {
    _workGapsMeta(input: $input) {
      count
    }
    workGaps(input: $input) {
      id
      explanation
      include_in_work_histories
      start_date
      end_date
      location
      state_id
      state {
        id
        name
      }
    }
  }
`;

export default Work_gapsList;
