import * as React from 'react';
import {
  TextField,
  FunctionField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
} from 'react-admin';
import IdField from 'components/IdField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Location"
      target="vms_user_id"
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <TextField label="Name" source="name" />
        <TextField label="Market" source="market.display_name" />
        <TextField label="VMS Type" source="company.vms_type.label" />,
        <TextField label="MSP" source="company.msp.label" />
        <TextField label="Health System" source="company.health_system.label" />
        <FunctionField
          label="Sales associate"
          render={(u) =>
            u.sales_associate?.account &&
            `${u.sales_associate.account.first_name} ${u.sales_associate.account.last_name}`
          }
        />
        <FunctionField
          label="Account manager"
          render={(u) =>
            u.account_manager?.account &&
            `${u.account_manager.account.first_name} ${u.account_manager.account.last_name}`
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);
