import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TextField,
  BooleanField,
  Show,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const SpecialtyShow = () => (
  <Show data-testid="specialty-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField source="active" />
      <NumberField label="Rate Differential" source="rate_differential" />
      <ArrayField source="positions">
        <SingleFieldList>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

SpecialtyShow.query = gql`
  query specialty($id: Int!) {
    specialty(id: $id) {
      id
      name
      label
      active
      rate_differential
      positions {
        id
        display_name
      }
      created_at
      updated_at
    }
  }
`;

export default SpecialtyShow;
