import React from 'react';
import PolymorphicInput from 'components/PolymorphicInput';
import { format, reasonsOptions } from './utils';
import { useFormContext } from 'react-hook-form';
import { ENUMS } from 'components/Enum/enums';

const ReasonInput = () => {
  const { watch } = useFormContext();
  const reasonType = watch('reason_type');
  const isLocationBlockReason = reasonType === ENUMS.BlockReason.type.LocationBlockReason;

  return (
    <PolymorphicInput
      field="reason"
      render={format}
      gql={['label']}
      choices={reasonsOptions}
      filter={isLocationBlockReason ? { active: true } : {}}
    />
  );
};

export default ReasonInput;
