import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const ProfessionalTravelPositionsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Position" source="position.display_name" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ProfessionalTravelPositionsShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalTravelPosition(id: $id) {
      id
      position_id
      professional_id
      position {
        id
        display_name
      }
      created_at
      updated_at
    }
  }
`;

export default ProfessionalTravelPositionsShow;
