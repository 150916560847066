// calculates the total hours for the assignment per week taking into account weekly or daily overtime
const safeDiv = (dividend: number, divisor: number, fallbackValue = 0) =>
  divisor ? dividend / divisor : fallbackValue;

export const calculateHours = (
  total_time,
  number_of_shifts_per_week,
  max_weekly_regular_hours,
  max_daily_regular_hours,
  max_daily_ot_hours,
) => {
  let total_regular_hours;
  let total_overtime_hours;
  let total_double_hours;
  if (max_daily_regular_hours >= 24) {
    total_time = total_time * number_of_shifts_per_week;
    total_regular_hours = Math.min(total_time, max_weekly_regular_hours);
    total_overtime_hours = Math.max(total_time - total_regular_hours, 0);
    total_double_hours = 0;
  } else {
    total_regular_hours = Math.min(total_time, max_daily_regular_hours);
    total_overtime_hours = Math.min(total_time - total_regular_hours, max_daily_ot_hours);
    total_double_hours = Math.max(total_time - total_regular_hours - total_overtime_hours, 0);
    total_regular_hours = total_regular_hours * number_of_shifts_per_week;
    total_overtime_hours = total_overtime_hours * number_of_shifts_per_week;
    total_double_hours = total_double_hours * number_of_shifts_per_week;
    total_time = total_time * number_of_shifts_per_week;
  }
  if (!Number.isInteger(total_time)) {
    total_time = Math.round(total_time * 1e3) / 1e3;
  }
  return {
    total_regular_hours,
    total_overtime_hours,
    total_double_hours,
    total_time,
  };
};

export const getEstimatedBreakTimeMinutes = (
  hours_per_shift: number,
  is_w2: boolean,
  track_unpaid_breaks: boolean,
) => {
  if (is_w2 || !track_unpaid_breaks) {
    return 0;
  }
  if (hours_per_shift >= 16) {
    return 60;
  }
  if (hours_per_shift >= 6) {
    return 30;
  }
  return 0;
};

// gets the rates based on overtime_ratios, and regular hours
export const getRates = (
  base_rate,
  overtime_ratio,
  double_ratio,
  { total_regular_hours, total_overtime_hours, total_double_hours, total_time },
) => {
  const overtime_rate = base_rate * overtime_ratio;
  const double_rate = base_rate * double_ratio;
  const base_amount = base_rate * total_regular_hours;
  const overtime_amount = overtime_rate * total_overtime_hours;
  const double_amount = double_rate * total_double_hours;
  const total_amount = base_amount + overtime_amount + double_amount;
  const blended_rate = safeDiv(total_amount, total_time);
  return {
    base_rate,
    overtime_rate,
    double_rate,
    base_amount,
    overtime_amount,
    double_amount,
    total_amount,
    blended_rate,
  };
};

export const getPayoutRates = (
  facility_blended_rate,
  fee_percent,
  overtime_ratio,
  double_ratio,
  hours,
) => {
  const blended_rate = facility_blended_rate / (1 + fee_percent);
  const { total_regular_hours, total_overtime_hours, total_double_hours, total_time } = hours;
  const total_hours =
    total_regular_hours + total_overtime_hours * overtime_ratio + total_double_hours * double_ratio;
  const base_rate = Math.round(safeDiv(blended_rate * total_time, total_hours));
  return getRates(base_rate, overtime_ratio, double_ratio, hours);
};

interface TaxableRates {
  blended_rate: number;
  max_mie_per_diem_cents: number;
  max_lodging_per_diem_cents: number;
  overtime_ratio: number;
  double_ratio: number;
  professionalHours: any;
  mie_per_diem_cents?: number;
  lodging_per_diem_cents?: number;
  min_taxable_rate_cents?: number;
}

const isEmpty = (value?: number) => value === null || value === undefined;

const getRate = (maxRate, total_time, rate) => {
  rate = isEmpty(rate) ? maxRate : rate;
  return safeDiv(rate * 7, total_time);
};

// gets the taxable rates which is the difference between the total pay rate and the lodging and meals
export const getTaxableRates = ({
  blended_rate,
  max_mie_per_diem_cents,
  max_lodging_per_diem_cents,
  overtime_ratio,
  double_ratio,
  professionalHours,
  mie_per_diem_cents,
  lodging_per_diem_cents,
  min_taxable_rate_cents,
}: TaxableRates) => {
  // get the previously calculated values for professionalHours
  const { total_regular_hours, total_overtime_hours, total_double_hours, total_time } =
    professionalHours;
  // get the min allowed blended rate based on the min $12/$16 base rate and calculating all overtime amounts
  const { blended_rate: minBlendedRate } = getRates(
    min_taxable_rate_cents,
    overtime_ratio,
    double_ratio,
    professionalHours,
  );
  // get the max hourly allowed meals value (comes from gsa api)
  let mie_per_diem_rate = getRate(max_mie_per_diem_cents, total_time, mie_per_diem_cents);
  // get the max hourly allowed lodging value (comes from gsa api)
  let lodging_per_diem_rate = getRate(
    max_lodging_per_diem_cents,
    total_time,
    lodging_per_diem_cents,
  );
  // if blended_rate is smaller then the min blended rate and the max per diem and lodging reduce the value of those
  if (blended_rate < mie_per_diem_rate + lodging_per_diem_rate + minBlendedRate) {
    //set the taxable_blended_rate to the blended rate minus the two
    //the isEmpty is here to allow them to manually set those values. You won't need them when getting these from the user
    if (isEmpty(lodging_per_diem_cents)) {
      //reduce the size of the lodging rate
      lodging_per_diem_rate = Math.max(blended_rate - (minBlendedRate + mie_per_diem_rate), 0);
    }
    //the if is here to allow for them to manually update this value
    if (isEmpty(mie_per_diem_cents)) {
      //reduce the size of the per diem rate
      mie_per_diem_rate = Math.max(blended_rate - (minBlendedRate + lodging_per_diem_rate), 0);
    }
  }
  const taxable_blended_rate = blended_rate - lodging_per_diem_rate - mie_per_diem_rate;
  const total_taxable_amount = taxable_blended_rate * total_time;
  const total_calculated_hours =
    total_regular_hours + total_overtime_hours * overtime_ratio + total_double_hours * double_ratio;
  const taxable_rate = Math.round(safeDiv(total_taxable_amount, total_calculated_hours));
  return {
    mie_per_diem_cents: Math.round((mie_per_diem_rate * total_time) / 7),
    lodging_per_diem_cents: Math.round((lodging_per_diem_rate * total_time) / 7),
    taxable_blended_rate: taxable_blended_rate,
    total_taxable_amount: total_taxable_amount,
    taxable_rate: taxable_rate,
  };
};

type FederalStateBurdens = {
  total_pay_cents: number;
  total_taxable_pay_cents: number;
  social_security_burden_multiplier: number;
  medicare_burden_multiplier: number;
  federal_unemployment_percent_burden_multiplier: number;
  federal_unemployment_max_burden_cents: number;
  state_unemployment_percent_burden_multiplier: number;
  state_unemployment_max_burden_cents: number;
};
export type GrossProfitParams = FederalStateBurdens & {
  total_charge_amount_cents: number;
  compliance_burden_cents: number;
  non_billable_orientation_burden_hours: number;
  taxable_base_rate_cents: number;
  vms_fee_burden_multiplier: number;
  travel_reimbursement_burden_cents: number;
  medical_burden_cents: number;
  one_time_bonus_burden_cents: number;
  adp_fee_burden_cents: number;
  number_of_weeks: number;
  billable_orientation_hours: number;
  charge_base_hourly_rate_cents: number;
};
