import * as React from 'react';
import MoneyInput from 'components/MoneyInput';
import useCheckAccess from 'hooks/useCheckAccess';
import BillingGroupInput from 'components/BillingGroupInput';
import { useRecordContext } from 'ra-core';

const SaasFeesFormTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();

  return (
    <>
      {checkAccess('update', 'Company', 'saas_per_job_flat_fee_cents') && (
        <MoneyInput label="Per Job Flat Fee" source="saas_per_job_flat_fee_cents" />
      )}
      {checkAccess('update', 'Company', 'saas_per_seat_monthly_cents') && (
        <MoneyInput label="Per Seat Monthly Fee" source="saas_per_seat_monthly_cents" />
      )}
      {checkAccess('update', 'Company', 'saas_base_monthly_cents') && (
        <MoneyInput label="Saas Base Monthly Fee" source="saas_base_monthly_cents" />
      )}
      <BillingGroupInput
        label="Saas Billing Group"
        source="saas_billing_group_id"
        filter={{ company_id: record.id }}
      />
    </>
  );
};

export default React.memo(SaasFeesFormTab);
