import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, required, SelectInput } from 'react-admin';
import { validateSlug } from 'utils/custom-validations';
import SelectLabelInputs from './SelectLabelInputs';
import { transformPayload } from './utils';

const PostingFieldCreate = () => (
  <Create redirect="show" transform={transformPayload}>
    <SimpleForm>
      <TextInput source="label" validate={[required()]} />
      <TextInput source="description" validate={[required()]} />
      <TextInput source="slug" validate={[required(), validateSlug]} />
      <SelectInput
        source="field_type"
        choices={[
          { id: 'string', name: 'String' },
          { id: 'boolean', name: 'Boolean' },
          { id: 'number', name: 'Number' },
          { id: 'single_select', name: 'Single Select' },
          { id: 'multi_select', name: 'Multi Select' },
        ]}
        validate={[required()]}
      />
      <BooleanInput source="show_on_per_diem" validate={[required()]} />
      <BooleanInput source="show_on_assignment" validate={[required()]} />
      <BooleanInput source="required" validate={[required()]} />
      <SelectLabelInputs />
    </SimpleForm>
  </Create>
);

export default PostingFieldCreate;
