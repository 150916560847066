import React from 'react';
import ReferenceInput, { getChildrenProps } from '../ReferenceInput';
import { AutocompleteInput, SelectArrayInput } from 'react-admin';
import ReferenceArrayInput from '../ReferenceArrayInput';

const StateFilter = (props) => {
  if (props.multiple) {
    return (
      <ReferenceArrayInput sx={{ minWidth: 256 }} {...props}>
        <SelectArrayInput
          optionText="name"
          {...getChildrenProps(props)}
          data-testid="state-filter"
        />
      </ReferenceArrayInput>
    );
  }
  return (
    <ReferenceInput {...props}>
      <AutocompleteInput
        optionText="name"
        {...getChildrenProps(props)}
        data-testid="state-filter"
      />
    </ReferenceInput>
  );
};

StateFilter.defaultProps = {
  label: 'State',
  reference: 'State',
  multiple: false,
  emptyText: 'All',
  emptyValue: null,
  source: 'search.state_id',
  perPage: 60,
  sort: { field: 'name', order: 'ASC' },
};

export default StateFilter;
