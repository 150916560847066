import React from 'react';
import { ReferenceInput as ReactAdminReferenceInput } from 'react-admin';
import { useTheme } from '@mui/material';

export const getChildrenProps = (props) => ({
  label: props.label,
  defaultValue: props.defaultValue,
  disabled: props.disabled,
  fullWidth: props.fullWidth,
  validate: props.validate,
  'data-testid': props['data-testid'],
});

const ReferenceInput = (props) => {
  const theme = useTheme();
  return (
    <ReactAdminReferenceInput
      options={{
        suggestionsContainerProps: {
          style: { zIndex: theme.zIndex.tooltip },
        },
      }}
      {...props}
      filter={{
        ...props.filter,
        _input: {
          optionText: props.children.props.optionText,
          gql: props.gql,
        },
      }}
    />
  );
};

export default ReferenceInput;
