import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import LinkField from 'components/LinkField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Location"
      target="search.billing_group_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Status" source="status" />
        <TextField label="Type" source="location_type.label" />
        <TextField label="Phone Number" source="phone_number" />
        <FunctionField
          label="Account Manager"
          render={(r) =>
            r.account_manager &&
            r.account_manager.account && (
              <Link to={`/Administrator/${r.account_manager_id}/show`}>
                {r.account_manager.account.name}
              </Link>
            )
          }
        />
        <FunctionField
          label="Sales Associate"
          render={(r) =>
            r.sales_associate &&
            r.sales_associate.account && (
              <Link to={`/Administrator/${r.sales_associate_id}/show`}>
                {r.sales_associate.account.name}
              </Link>
            )
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);
