import React from 'react';
import { SelectArrayInput } from 'react-admin';
import { choicesFromEnum } from 'utils/helpers';

enum Day {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
}

const DaysSelect = (props) => (
  <SelectArrayInput {...props} choices={choicesFromEnum(Day)} data-testid="days-select" />
);

export default DaysSelect;
