import useCheckAccess, { Action } from 'hooks/useCheckAccess';
import { useResourceContext } from 'ra-core';
import React from 'react';

interface RestrictedInputProps {
  action: Action;
  resource?: string;
  source?: string;
  sources?: string[];
  component: (props: any) => JSX.Element;
  [prop: string]: unknown;
}

const RestrictedInput = ({
  action,
  resource,
  source,
  sources,
  component: InputComponent,
  ...rest
}: RestrictedInputProps) => {
  const resourceContext = useResourceContext({ resource });
  const checkAccess = useCheckAccess();
  const canAccess = checkAccess(action, resourceContext, source ?? sources);

  if (!canAccess) {
    return null;
  }

  return <InputComponent resource={resourceContext} source={source} {...rest} />;
};

export default RestrictedInput;
