import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  Datagrid,
  BooleanField,
  FunctionField,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Link, Typography } from '@mui/material';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';

const ExternalAccountListFilters = [
  <IdInput />,
  <ProfessionalInput alwaysOn source="search.professional_id" />,
  <NumberInput label="Stripe Account ID" alwaysOn source="stripe_account_id" />,
  <BooleanInput label="Active Stripe Account" alwaysOn source="search.active" />,
  <BooleanInput label="Verified Stripe Account" alwaysOn source="search.verified" />,
  <BooleanInput label="Instant Pay Eligible" alwaysOn source="instant_pay_eligible" />,
];

const ExternalAccountList = () => (
  <List filters={ExternalAccountListFilters} exporter={false} title="External Accounts">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Stripe Account" source="stripe_account.id" />
      <FunctionField
        label="Professional ID"
        render={(record) => (
          <LinkField
            record={record.stripe_account}
            label="Professional ID"
            source="professional_id"
          />
        )}
      />
      <BooleanField label="Stripe Account Active" source="stripe_account.active" />
      <BooleanField label="Stripe Account Verified" source="stripe_account.verified" />
      <TextField source="stripe_id" />
      <BooleanField label="Instant Pay Eligible" source="instant_pay_eligible" />
      <FunctionField
        label="Stripe URL"
        render={(record) => (
          <Typography>
            <Link
              target="_blank"
              href={`https://dashboard.stripe.com/connect/accounts/${record.stripe_account.stripe_id}`}
            >
              Stripe URL
            </Link>
          </Typography>
        )}
      />
    </Datagrid>
  </List>
);

ExternalAccountList.query = gql`
  query externalAccounts($input: ExternalAccountQueryInput) {
    _externalAccountsMeta(input: $input) {
      count
    }
    externalAccounts(input: $input) {
      id
      stripe_id
      instant_pay_eligible
      account_type
      bank_name
      last4
      exp_month
      exp_year
      stripe_account {
        id
        professional_id
        active
        stripe_id
        verified
      }
    }
  }
`;

export default ExternalAccountList;
