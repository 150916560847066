import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberInput, Datagrid, DateField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import { SearchFilter } from 'components/Filter';

const QuestionnaireResponseListFilters = [
  <IdInput />,
  <SearchFilter label="Professional Search" alwaysOn />,
  <NumberInput label="Professional ID" source="professional_id" alwaysOn />,
];

const QuestionnaireResponseList = () => (
  <List exporter={false} filters={QuestionnaireResponseListFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional.id" />
      <LinkField label="Questionnaire ID" source="questionnaire.id" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

QuestionnaireResponseList.query = gql`
  query questionnaireResponses($input: QuestionnaireResponseQueryInput) {
    _questionnaireResponsesMeta(input: $input) {
      count
    }
    questionnaireResponses(input: $input) {
      id
      professional {
        id
      }
      questionnaire {
        id
      }
      created_at
      updated_at
      questions
    }
  }
`;

export default QuestionnaireResponseList;
