import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { AutocompleteInput, NumberInput, TextInput, Create, SimpleForm } from 'react-admin';
import InputUtils from 'utils/input';

const CheckCreate = () => (
  <Create data-testid="check-create" redirect="show">
    <SimpleForm>
      <ReferenceInput label="Statement" source="statement_id" reference="Statement">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <NumberInput source="amount_cents" />
      <TextInput source="number" />
      <TextInput source="stripe_invoice_item_id" />
      <TextInput source="note" />
    </SimpleForm>
  </Create>
);

export default CheckCreate;
