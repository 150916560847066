import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import JsonField from 'components/JsonField';

const PermissionGroupsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="display_name" />
      <TextField source="account_type" />
      <JsonField source="permissions" rootKey="Permissions" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

PermissionGroupsShow.query = gql`
  query GET_ONE($id: Int!) {
    permissionGroup(id: $id) {
      id
      display_name
      account_type
      permissions
      created_at
      updated_at
    }
  }
`;

export default PermissionGroupsShow;
