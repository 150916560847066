import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PhoneInput from 'components/PhoneInput';
import { pick } from 'ramda';
import AdministratorPermissionGroupsInput from 'resources/administrators/AdministratorPermissionGroupsInput';

const AdministratorsEdit = () => {
  const transformPayload = (payload) => {
    const filteredPayload = {
      ...pick(['id', 'role', 'department', 'paused'], payload),
      account: {
        ...pick(
          ['email', 'phone_number', 'first_name', 'last_name', 'permission_group_ids'],
          payload.account,
        ),
      },
    };
    return filteredPayload;
  };

  return (
    <Edit
      transform={transformPayload}
      mutationMode="pessimistic"
      data-testid="administrator-edit-form"
      redirect="show"
    >
      <SimpleForm>
        <PhoneInput label="Phone Number" source="account.phone_number" />
        <TextInput
          label="First Name"
          source="account.first_name"
          data-testid="administrator-edit-first-name"
        />
        <TextInput
          label="Last Name"
          source="account.last_name"
          data-testid="administrator-edit-last-name"
        />
        <AdministratorPermissionGroupsInput />
        <EnumSelectInput source="department" required />
        <BooleanInput source="paused" data-testid="administrator-edit-paused" />
      </SimpleForm>
    </Edit>
  );
};

export default AdministratorsEdit;
