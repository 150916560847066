import Heading from 'components/Heading';
import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  BooleanField,
  SimpleShowLayout,
} from 'react-admin';

const TravelPreferencesTab = () => (
  <SimpleShowLayout>
    <BooleanField
      label="W2 Enabled"
      source="w2_enabled"
      data-testid="professional_show-travel_preferences_tab_is_w2"
    />
    <Heading>Travel Positions</Heading>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="ProfessionalTravelPosition"
      target="professional_id"
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Display Name" source="position.display_name" />
        <TextField label="Position ID" source="position_id" />
      </Datagrid>
    </ReferenceManyField>
    <Heading>Travel States</Heading>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="ProfessionalTravelAssignmentState"
      target="professional_id"
    >
      <Datagrid
        bulkActionButtons={false}
        data-testid="professional_show-travel_preferences_tab_states_table"
      >
        <TextField
          label="State Name"
          source="state.name"
          data-testid="professional_show-travel_preferences_tab_states_table_name"
        />
        <TextField
          label="State ID"
          source="state_id"
          data-testid="professional_show-travel_preferences_tab_states_table_id"
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default TravelPreferencesTab;
