import React from 'react';
import { TextInput, BooleanInput, Create, SimpleForm, NumberInput } from 'react-admin';

const EhrSystemCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <BooleanInput source="active" />
      <NumberInput source="medefis_identifier" />
    </SimpleForm>
  </Create>
);

export default EhrSystemCreate;
