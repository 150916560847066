import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberField, TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const TaxClassificationList = () => (
  <List exporter={false} filters={[<IdInput />]} data-testid="tax-classification-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField source="sequence" />
    </Datagrid>
  </List>
);

TaxClassificationList.query = gql`
  query taxClassifications($input: TaxClassificationQueryInput) {
    _taxClassificationsMeta(input: $input) {
      count
    }
    taxClassifications(input: $input) {
      id
      name
      label
      sequence
    }
  }
`;

export default TaxClassificationList;
