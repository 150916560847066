import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';
import EnumField from 'components/Enum/EnumField';
import { Assignment } from '../../../typings/graphql';
import ScreenRequestsField from '../../assignments/ScreenRequestsField';
import { DisplayHelpers } from 'utils/helpers';
import { Link } from 'react-router-dom';

const AssignmentsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Assignment"
      target="vms_assignment_id"
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <EnumField source="status" />
        <LinkField label="Professional ID" source="professional_id" />
        <LinkField label="Primary Location ID" source="primary_location.id" />
        <DateTimeField format="MM/dd/yyyy" source="starts_date" />
        <DateTimeField format="MM/dd/yyyy" source="ends_date" />
        <TextField label="Shifts per wk" source="number_of_shifts_per_week" />
        <TextField source="hours_per_shift" />
        <FunctionField
          label="Applications"
          render={(v: Assignment) =>
            v.assignment_requests && (
              <LinkField to={`/Assignment/${v.id}/show/requests`}>
                {v.assignment_requests.length}
              </LinkField>
            )
          }
        />
        <ScreenRequestsField />
        <TextField label="Market" source="user.market.display_name" />
        <FunctionField label="Account Manager" render={DisplayHelpers.nameOf('administrator')} />
        <FunctionField
          label="Clinical Account Manager"
          render={(r) =>
            r.recruiter && (
              <Link to={`/Administrator/${r.recruiter.id}/show`}>
                {DisplayHelpers.nameOf('recruiter')(r)}
              </Link>
            )
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(AssignmentsTab);
