import React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import InvoiceInput from 'components/InvoiceInput';

const RefundCreate = () => (
  <Create data-testid="refund-create" redirect="show">
    <SimpleForm>
      <InvoiceInput />
      <EnumSelectInput source="reason" />
      <TextInput source="status" />
      <EnumSelectInput source="kind" />
      <MoneyInput source="amount_cents" />
    </SimpleForm>
  </Create>
);

export default RefundCreate;
