import React, { useState } from 'react';
import { useRefresh, useNotify, required, useGetRecordId, SimpleForm } from 'react-admin';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import MoneyInput from 'components/MoneyInput';
import { IHash } from 'typings/common';
import useApi from 'hooks/useApi';
import { DisplayHelpers } from 'utils/helpers';

const validation = (max: number) => (values) => {
  const errors: IHash<string[]> = {};

  if (values.balance_cents <= 0) {
    errors.balance_cents = ['Required'];
  } else if (values.balance_cents > max) {
    const maxInUSD = (max / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    errors.balance_cents = [`Should be less than ${maxInUSD}`];
  }

  return errors;
};

const ApplyStaffBalance = ({ amount, balance }) => {
  const api = useApi();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const payoutId = useGetRecordId();

  const handleApply = (values) => {
    const perform = async () => {
      setLoading(true);

      try {
        await api({
          url: '/v3/admin/staff_balances/apply',
          method: 'POST',
          data: {
            payout_id: payoutId,
            balance_cents: values.balance_cents,
          },
        });
        setDialogVisible(false);
        refresh();
        notify(`${DisplayHelpers.cents(values.balance_cents)} has been successfully applied!`);
      } catch (error: any) {
        notify(error.message, { type: 'warning' });
      } finally {
        setLoading(false);
      }
    };

    perform();
  };

  return (
    <SimpleForm
      defaultValues={{ balance_cents: 0 }}
      validate={validation(Math.min(amount, balance))}
      onSubmit={handleApply}
    >
      <Button
        disabled={loading}
        onClick={() => setDialogVisible(true)}
        variant="contained"
        color="primary"
      >
        {loading ? <CircularProgress size={24} /> : 'Apply Staff Balance'}
      </Button>

      <Dialog fullWidth open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <DialogTitle>Apply Staff Balance</DialogTitle>
        <DialogContent>
          <form onSubmit={handleApply}>
            <MoneyInput validate={[required()]} label="Balance" source="balance_cents" />
          </form>
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={loading}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
};

export default ApplyStaffBalance;
