import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import PolymorphicIdInput from 'components/PolymorphicIdInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { referencesOptions } from './utils';
import CommentsField from './CommentsField';
import ReasonInput from './ReasonInput';

const RelationshipsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <EnumSelectInput source="kind" />
      <CommentsField />
      <ReasonInput />
      <PolymorphicIdInput field="requester" choices={referencesOptions} />
      <PolymorphicIdInput field="requestee" choices={referencesOptions} />
    </SimpleForm>
  </Edit>
);

export default RelationshipsEdit;
