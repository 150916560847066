import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const CancellationReasonsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="cancellation-reasons-edit">
    <SimpleForm>
      <TextInput source="label" data-testid="cancellation-reasons-edit-label" />
      <EnumSelectInput source="role" />
    </SimpleForm>
  </Edit>
);

export default CancellationReasonsEdit;
