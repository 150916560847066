import React from 'react';
import {
  TextField,
  Datagrid,
  Pagination,
  ReferenceManyField,
  SimpleForm,
  useRecordContext,
  DeleteWithConfirmButton,
  FunctionField,
} from 'react-admin';
import IdField from 'components/IdField';
import { CreateInDialogButton } from '@react-admin/ra-form-layout';
import ConditionInput from 'components/ConditionInput';
import CompanyInput from 'components/CompanyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import { Box } from '@mui/material';
import { currentTimeZone } from 'utils/time';
import DateTimeField from 'components/DateTimeField';

const transformPayload = (payload) => {
  // do not send position_id if specialty was selected
  if (payload.specialty_id) {
    return {
      ...payload,
      position_id: null,
    };
  }
  return payload;
};

const AdditiveRequirementsTable = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Requirement"
      target="company_id"
      filter={{ medely: false, cohort_eligibility: 'saas' }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h3>Additional Requirements</h3>
        <CreateInDialogButton
          title="Create Additional Requirements"
          fullWidth
          transform={transformPayload}
        >
          <SimpleForm defaultValues={{ company_id: record.id, cohort_eligibility: 'saas' }}>
            <CompanyInput fullWidth disabled />
            <EnumSelectInput
              label="Requirement Type"
              source="cohort_eligibility"
              fullWidth
              disabled
            />
            <ConditionInput fullWidth />
            <EnumSelectInput resettable source="kind" fullWidth />
            <PositionInput fullWidth />
            <SpecialtyInput positionSource="position_id" fullWidth />
          </SimpleForm>
        </CreateInDialogButton>
      </Box>
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <TextField source="condition.label" />
        <DateTimeField label="Created At" source="created_at" timeZone={currentTimeZone()} />
        <TextField source="kind" />
        <TextField label="Position" source="position.display_name" />
        <TextField label="Specialty" source="specialty.label" />
        <FunctionField render={DeleteWithConfirmButton} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default React.memo(AdditiveRequirementsTable);
