import React from 'react';
import { TextInput, Create, SimpleForm, required } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const LocationBlockReasonCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <EnumSelectInput source="kind" validate={[required()]} />
      <TextInput source="label" validate={[required()]} />
      <TextInput source="slug" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default LocationBlockReasonCreate;
