import { useState, useMemo, useCallback } from 'react';

const shortid = () => Math.random().toString(16);

export function useMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const id = useMemo(() => `menu-${shortid()}`, []);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const triggerProps = useMemo(
    () => ({
      'aria-controls': id,
      'aria-haspopup': true,
      onClick: handleClick,
    }),
    [id, handleClick],
  );

  const menuProps = useMemo(
    () => ({
      id,
      onClose: handleClose,
      keepMounted: true,
      anchorEl,
      open: Boolean(anchorEl),
    }),
    [id, handleClose, anchorEl],
  );

  return { triggerProps, menuProps };
}
