import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, ReferenceField } from 'react-admin';
import List from 'components/List';

const ContractsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField reference="assignments" source="assignment_id">
        <TextField source="id" />
      </ReferenceField>
    </Datagrid>
  </List>
);

ContractsList.query = gql`
  query GET_LIST($input: ContractQueryInput) {
    contracts(input: $input) {
      id
      document_id
      signed_at
      assignment_id
      professional_id
      contract_type
      administrator_id
      upload
    }
  }
`;

export default ContractsList;
