import React from 'react';
import { Edit, FormTab, TabbedForm } from 'react-admin';
import ChargeRatesTab from '../formTabs/ChargeRatesTab';
import AssignmentFormToolbar from '../FormToolbar';
import PayoutRatesTab from '../formTabs/PayoutRatesTab';
import JobDetailsTab from '../formTabs/JobDetailsTab';

const AssignmentsEdit = () => (
  <Edit redirect="show" mutationMode="pessimistic" data-testid="assignments-edit">
    <TabbedForm toolbar={<AssignmentFormToolbar />} mode="onTouched">
      <FormTab label="Job Details" data-testid="job-details-tab">
        <JobDetailsTab />
      </FormTab>
      <FormTab label="Bill rate" data-testid="bill-rate-tab">
        <ChargeRatesTab />
      </FormTab>
      <FormTab label="Pay rate" data-testid="pay-rate-tab">
        <PayoutRatesTab />
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default AssignmentsEdit;
