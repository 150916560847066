import * as React from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';
import { useRecordContext } from 'ra-core';

const text = (r) => `${r.__typename}: ${r.id}`;

const PolymorphicField = (props) => {
  const { source, render = text, linkType = 'show' } = props;
  const record = useRecordContext();

  const polymorphicRecord = record?.[source];
  if (!polymorphicRecord) {
    return null;
  }

  const polymorphicRender = render(polymorphicRecord);
  return linkType ? (
    <Link component={RRLink} to={`/${polymorphicRecord.__typename}/${polymorphicRecord.id}/show`}>
      <Typography data-testid="polymorphic-field">{polymorphicRender}</Typography>
    </Link>
  ) : (
    <Typography data-testid="polymorphic-field">{polymorphicRender}</Typography>
  );
};

export default PolymorphicField;
