import React from 'react';
import { TextInput, required, ArrayInput, SimpleFormIterator } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const SelectLabelInputs = () => {
  const { watch } = useFormContext();
  const fieldType = watch('field_type');

  if (fieldType !== 'single_select' && fieldType !== 'multi_select') {
    return null;
  }

  return (
    <ArrayInput label="Constraints" source="constraints" validate={[required()]}>
      <SimpleFormIterator>
        <TextInput label="Label" source="label" validate={[required()]} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default SelectLabelInputs;
