import React, { useState } from 'react';
import { ENUMS } from 'components/Enum/enums';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { format, parseISO } from 'date-fns/fp';
import { DisplayHelpers } from 'utils/helpers';
import { useRecordContext } from 'ra-core';
import _ from 'lodash';

const dateFmt = 'MM/dd/yyyy';
const timeFmt = 'HH:mm';

const displayDateTime = (fmt: string) => _.flowRight(format(fmt), parseISO);

const dateTime = displayDateTime(`${dateFmt} ${timeFmt}`);
const date = displayDateTime(dateFmt);
const time = displayDateTime(timeFmt);

export default function PhoneScreening() {
  const record = useRecordContext();
  const [opened, setOpened] = useState(false);
  if (
    record?.application_status !== ENUMS.Professional.application_status.phone_screen_scheduled ||
    !record?.phone_screen_date_and_time
  ) {
    return null;
  }

  return (
    <>
      <Button color="primary" variant="text" onClick={() => setOpened(true)}>
        {dateTime(record.phone_screen_date_and_time)}
      </Button>
      <Dialog
        open={opened}
        onClose={() => setOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {DisplayHelpers.name(record)} Phone Screening
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary={date(record.phone_screen_date_and_time)} secondary="Date" />
            </ListItem>
            <ListItem>
              <ListItemText primary={time(record.phone_screen_date_and_time)} secondary="Time" />
            </ListItem>
            <ListItem>
              <ListItemText primary={record.account?.email} secondary="Email" />
            </ListItem>
            <ListItem>
              <ListItemText primary={record.account?.phone_number} secondary="Phone" />
            </ListItem>
            <ListItem>
              <ListItemText primary={DisplayHelpers.name(record.recruiter)} secondary="Recruiter" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            component="a"
            href={`https://calendly.com/reschedulings/${record.calendly_invitee_uuid}`}
            variant="outlined"
            color="primary"
            target="_blank"
          >
            Reschedule
          </Button>
          <Button
            href={`https://calendly.com/cancellations/${record.calendly_invitee_uuid}`}
            component="a"
            variant="outlined"
            color="primary"
            target="_blank"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PhoneScreening.defaultProps = {
  label: 'Screening',
  sortBy: 'phone_screen_date_and_time',
};
