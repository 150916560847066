import * as R from 'ramda';
import _ from 'lodash';

const parseInteger = R.partialRight(parseInt, [10]);

const safeParseInt = R.ifElse(
  R.isEmpty,
  R.always(null),
  _.flowRight(R.when(isNaN, R.always(null)), parseInteger),
);

const optionText = (...args: string[]) =>
  _.flowRight<any, any, any, string>(R.join(' '), R.props(args), R.defaultTo({}));

const InputUtils = {
  parse: {
    int: safeParseInt,
  },
  optionText,
};

export default InputUtils;
