import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import PolymorphicIdInput from 'components/PolymorphicIdInput';
import { defaultValuesFromProps } from 'utils/helpers';
import { ENUMS } from 'components/Enum/enums';

const recipientOptions = Object.entries(ENUMS.Note.recipient).map(([_, value]) => ({
  id: value,
  name: value,
}));

const NotesCreate = (props) => {
  const values = defaultValuesFromProps(props);
  const { recipient_type, recipient_id } = values;
  let redirectUrl = 'show';
  if (recipient_type && recipient_id) {
    redirectUrl = `/${recipient_type}/${recipient_id}/show/notes`;
  }
  return (
    <Create data-testid="notes-create" redirect={redirectUrl}>
      <SimpleForm defaultValues={values}>
        <PolymorphicIdInput field="recipient" choices={recipientOptions} />
        <TextInput multiline source="text" />
      </SimpleForm>
    </Create>
  );
};

export default NotesCreate;
