import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import { Datagrid, Pagination, ReferenceArrayField, TextField } from 'react-admin';

const PayoutsTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <ReferenceArrayField
      pagination={<Pagination />}
      reference="Payout"
      source="payout_ids"
      label=""
    >
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'Payout', 'id') && <IdField />}
        <LinkField label="Assignment ID" source="assignment_id" />
        <LinkField label="Professional ID" source="professional_id" />
        {checkAccess('view', 'Payout', 'status') && <TextField label="Status" source="status" />}
      </Datagrid>
    </ReferenceArrayField>
  );
};

export default React.memo(PayoutsTab);
