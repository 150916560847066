import { InvoicesDatagrid } from 'components/tabs/InvoicesTab';
import React from 'react';
import { Pagination, ReferenceArrayField, useResourceContext } from 'react-admin';

const InvoicesTab = () => {
  const resource = useResourceContext();

  return (
    <ReferenceArrayField
      pagination={<Pagination />}
      reference="Invoice"
      source="invoice_ids"
      label=""
    >
      <InvoicesDatagrid resource={resource} />
    </ReferenceArrayField>
  );
};

export default React.memo(InvoicesTab);
