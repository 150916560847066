import React from 'react';
import { Create, SimpleForm, AutocompleteInput } from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import LocationInput from 'components/LocationInput';

const LocationBillingGroupsCreate = () => (
  <Create>
    <SimpleForm>
      <LocationInput />
      <ReferenceInput source="billing_group_id" reference="BillingGroup">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default LocationBillingGroupsCreate;
