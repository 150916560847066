import React from 'react';
import { FunctionField, Labeled, Link } from 'react-admin';

const LastUpdatedByAdminField = () => (
  <Labeled label="Last Updated By">
    <FunctionField
      render={(record) =>
        record?.last_updated_by_admin_id ? (
          <Link to={`/Administrator/${record.last_updated_by_admin_id}/show`}>
            {record.last_updated_by_admin?.name ?? record.last_updated_by_admin_id}
          </Link>
        ) : (
          'N/A'
        )
      }
    />
  </Labeled>
);

export default LastUpdatedByAdminField;
