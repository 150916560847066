import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const BillingGroupParentsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        {checkAccess('update', 'BillingGroupParent', 'name') && <TextInput source="name" />}
      </SimpleForm>
    </Edit>
  );
};

export default BillingGroupParentsEdit;
