import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, NumberField, DateField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';

const BreaksList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <DateField showTime options={{ hour12: false }} source="starts_time" />
      <NumberField source="duration_minutes" />
      <LinkField source="job_id" />
    </Datagrid>
  </List>
);

BreaksList.query = gql`
  query GET_LIST($input: BreakQueryInput) {
    breaks(input: $input) {
      id
      starts_time
      duration_minutes
      job_id
      job {
        id
        location {
          id
          timezone_lookup
        }
      }
    }
  }
`;

export default BreaksList;
