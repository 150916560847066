import React from 'react';
import { gql } from '@apollo/client';
import TimestampFields from 'components/TimestampFields';
import { Show, SimpleShowLayout, TextField, DateField, NumberField } from 'react-admin';
import LinkField from 'components/LinkField';

const BreaksShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField showTime options={{ hour12: false }} source="starts_time" />
      <NumberField source="duration_minutes" />
      <LinkField source="job_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

BreaksShow.query = gql`
  query GET_ONE($id: Int!) {
    break(id: $id) {
      id
    }
  }
`;

export default BreaksShow;
