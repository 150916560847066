import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';

export default {
  name: 'QualificationAttributes',
  inputShow: gql`
    query QualificationAttributes($input: QualificationAttributeQueryInput) {
      qualificationAttributes(input: $input) {
        id
        name
        slug
        field_type
        data
        required
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  // create,
  options: {
    group: 'Qualifications',
    label: 'Qualification Attributes',
  },
};
