import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, ReferenceField } from 'react-admin';
import List from 'components/List';

const AttachmentsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField source="license_field_id" reference="license_fields">
        <TextField source="label" />
      </ReferenceField>
    </Datagrid>
  </List>
);

AttachmentsList.query = gql`
  query GET_LIST($input: AttachmentQueryInput) {
    _attachmentsMeta(input: $input) {
      count
    }
    attachments(input: $input) {
      id
    }
  }
`;

export default AttachmentsList;
