import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  AutocompleteArrayInput,
  Create,
  SimpleForm,
  required,
} from 'react-admin';

const SpecialtyCreate = () => (
  <Create redirect="show" data-testid="specialty-create">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <BooleanInput source="active" />
      <NumberInput source="rate_differential" validate={required()} />
      <ReferenceArrayInput label="Positions" source="position_ids" reference="Position">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default SpecialtyCreate;
