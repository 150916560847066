import React from 'react';
import { TextInput, NumberInput, Edit, SimpleForm } from 'react-admin';
import PolymorphicInput from 'components/PolymorphicInput';

const FundingSourceEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="funding-source-edit">
    <SimpleForm>
      <PolymorphicInput
        field="personable"
        choices={[
          { id: 'User', name: 'User' },
          { id: 'Staff', name: 'Staff' },
        ]}
      />
      <TextInput source="stripe_id" data-testid="funding-source-edit-stripe" />
      <TextInput source="brand" data-testid="funding-source-edit-brand" />
      <NumberInput source="exp_month" data-testid="funding-source-edit-exp-month" />
      <NumberInput source="exp_year" data-testid="funding-source-edit-exp-year" />
      <TextInput source="last4" data-testid="funding-source-edit-last4" />
      <TextInput source="account_type" data-testid="funding-source-edit-account" />
      <TextInput source="bank_name" />
    </SimpleForm>
  </Edit>
);

export default FundingSourceEdit;
