import React from 'react';
import { Create, SimpleForm, NumberInput } from 'react-admin';
import LocationInput from 'components/LocationInput';

const AccountLocationsCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="account_id" />
      <LocationInput />
    </SimpleForm>
  </Create>
);

export default AccountLocationsCreate;
