import { gql } from '@apollo/client';
import list from './List';
import show from './Show';

export default {
  name: 'ApplicationRequirements',
  inputShow: gql`
    query ApplicationRequirements($input: ApplicationRequirementQueryInput) {
      applicationRequirements(input: $input) {
        id
        medely
        state_id
        base
        kind
        position_id
        specialty_id
        condition_id
        __typename
      }
    }
  `,
  list,
  show,
  options: {
    group: 'Qualifications',
    label: 'Applications Requirements',
  },
};
