import React from 'react';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { BillingGroupBillingRestricted } from 'components/Enum/enums';

const BillingInput = ({ restricted = false, ...rest }) => (
  <EnumSelectInput
    source="billing"
    resource="BillingGroup"
    resettable
    enumeration={restricted ? BillingGroupBillingRestricted : undefined}
    {...rest}
  />
);

export default BillingInput;
