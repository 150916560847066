import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import InputUtils from 'utils/input';
import { Create, SimpleForm, AutocompleteInput, NumberInput, TextInput } from 'react-admin';

const Credit_notesCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <ReferenceInput reference="Statement" source="statement_id">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <TextInput source="stripe_invoice_item_id" />
      <NumberInput source="amount_cents" />
      <TextInput source="memo" />
    </SimpleForm>
  </Create>
);

export default Credit_notesCreate;
