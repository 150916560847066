import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import LocationInput from 'components/LocationInput';

const LocationRateSettingListFilters = [
  <IdInput />,
  <LocationInput alwaysOn />,
  <BooleanInput alwaysOn source="active" />,
];

const LocationRateSettingList = () => (
  <List filters={LocationRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Location ID" source="location_id" />
      <BooleanField source="active" />
      <BooleanField
        label="Track Unpaid Breaks"
        source="settings.track_unpaid_breaks"
        sortable={false}
      />
    </Datagrid>
  </List>
);

LocationRateSettingList.query = gql`
  query locationRateSettings($input: LocationRateSettingQueryInput) {
    _locationRateSettingsMeta(input: $input) {
      count
    }
    locationRateSettings(input: $input) {
      id
      location_id
      active
      settings
    }
  }
`;

export default LocationRateSettingList;
