import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonField } from 'react-admin-json-view';

const QualificationAttributeShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="slug" />
      <TextField source="field_type" />
      <JsonField source="data" />
    </SimpleShowLayout>
  </Show>
);

QualificationAttributeShow.query = gql`
  query GET_ONE($id: Int!) {
    qualificationAttribute(id: $id) {
      id
      name
      slug
      field_type
      data
      required
    }
  }
`;

export default QualificationAttributeShow;
