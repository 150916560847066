import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'surgery_types',
  inputShow: gql`
    query surgeryTypes($input: SurgeryTypeQueryInput) {
      surgeryTypes(input: $input) {
        id
        display_name
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Surgery Types',
  },
};
