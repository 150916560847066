import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const ProfessionalTravelPositionsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Position" source="position.display_name" />
    </Datagrid>
  </List>
);

ProfessionalTravelPositionsList.query = gql`
  query GET_LIST($input: ProfessionalTravelPositionQueryInput) {
    _professionalTravelPositionsMeta(input: $input) {
      count
    }
    professionalTravelPositions(input: $input) {
      id
      position_id
      professional_id
      position {
        id
        display_name
      }
    }
  }
`;

export default ProfessionalTravelPositionsList;
