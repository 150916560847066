import { gql } from '@apollo/client';
import list from './List';
import show from './Show';

export default {
  name: 'AccountBillingGroups',
  inputShow: gql`
    query accountBillingGroups($input: AccountBillingGroupQueryInput) {
      accountBillingGroups(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  options: {
    group: 'Misc',
    label: 'Account Billing Groups',
  },
};
