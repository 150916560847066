import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const LocationBillingGroupsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
    </Datagrid>
  </List>
);

LocationBillingGroupsList.query = gql`
  query GET_LIST($input: LocationBillingGroupQueryInput) {
    _locationBillingGroupsMeta(input: $input) {
      count
    }
    locationBillingGroups(input: $input) {
      id
      location_id
      location {
        id
        name
        location_type {
          id
          label
        }
        phone_number
        account_manager_id
        account_manager {
          id
          account {
            id
            name
          }
        }
        sales_associate_id
        sales_associate {
          id
          account {
            id
            name
          }
        }
      }
      primary_billing_group
      billing_group_id
      billing_group {
        id
        name
        status
        auto_pay
        plan
        billing
      }
    }
  }
`;

export default LocationBillingGroupsList;
