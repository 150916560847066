import React from 'react';
import {
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  NumberInput,
  Edit,
  SimpleForm,
} from 'react-admin';
import CredentialImageInput from 'components/ImageInput';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useCheckAccess from 'hooks/useCheckAccess';

const StateEdit = () => {
  const checkAccess = useCheckAccess();
  // TODO: Check if this is the correct permission to show the update state
  return (
    <Edit mutationMode="pessimistic" redirect="show" data-testid="state-edit">
      <SimpleForm>
        <TextInput source="name" data-testid="state-edit-name" />
        <TextInput source="abbreviation" />
        {checkAccess('update', 'State') && (
          <>
            <BooleanInput source="popular_travel_state" />
            <CredentialImageInput
              source="rectangle_image"
              aspectRatio={2.41}
              label="Rectangle Image"
            />
            <CredentialImageInput source="square_image" aspectRatio={1} label="Square Image" />
          </>
        )}
        <BooleanInput source="active" />
        <ReferenceArrayInput label="Markets" source="market_ids" reference="Market">
          <AutocompleteArrayInput optionText="display_name" />
        </ReferenceArrayInput>
        <BooleanInput source="nlc" />
        <BooleanInput source="display_by_hourly" />

        <NumberInput source="minimum_rate_cents" />
        <EnumSelectInput emptyText="None" sort="" source="covid_requirement" />
      </SimpleForm>
    </Edit>
  );
};

export default StateEdit;
