import DateTimeInput from 'components/DateTimeInput';
import ProfessionalInput from 'components/ProfessionalInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import { Edit, SimpleForm, NumberInput } from 'react-admin';

const TimecardsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="timecards-edit">
    <SimpleForm>
      <EnumSelectInput label="Status" source="status" alwaysOn />
      <DateTimeInput source="starts_time" />
      <DateTimeInput source="ends_time" />
      <ProfessionalInput />
      <NumberInput source="assignment_id" data-testid="timecards-edit-assignment" />
    </SimpleForm>
  </Edit>
);

export default TimecardsEdit;
