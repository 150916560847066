import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const UniversalBackgroundChecksCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput source="order_number" />
      <TextInput source="order_status" />
      <TextInput source="order_url" />
    </SimpleForm>
  </Create>
);

export default UniversalBackgroundChecksCreate;
