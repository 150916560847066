import { gql } from '@apollo/client';
import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';

export default {
  name: 'emergency_contacts',
  inputShow: gql`
    query emergencyContacts($input: EmergencyContactQueryInput) {
      emergencyContacts(input: $input) {
        id
        first_name
        last_name
        phone_number
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Emergency Contacts',
  },
};
