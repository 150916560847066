import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useInput, useRecordContext, useTranslate } from 'react-admin';
import inflection from 'inflection';
import React, { useEffect, useMemo } from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { format } from 'date-fns-tz';
import { isValid, parseISO } from 'date-fns';
import { displayInTimezone } from 'utils/time';
import _ from "lodash";

const DateInput = (props) => {
  const record = useRecordContext();
  const { source, label, disabled, time_zone, tzSource, ...rest } = props;
  const { field, fieldState, isRequired } = useInput(props);

  const onChange = (date: any) => {
    if (isValid(date)) {
      const formatted = format(date, 'yyyy-MM-dd', timezone ? { timeZone: timezone } : undefined);
      field.onChange(formatted);
    } else {
      field.onChange(null);
    }
  };
  const translate = useTranslate();
  const fieldLabel = useMemo(() => label || inflection.humanize(source), [source, label]);
  const timezone: string =
    time_zone || (tzSource && _.get(record, tzSource.split('.'))) || 'America/Los_Angeles';

  useEffect(() => {
    const dateInTimezone = field.value ? displayInTimezone(timezone, field.value) : null;
    const dateValue: Date | null = dateInTimezone ? new Date(dateInTimezone) : null;
    onChange(dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const parseOutputFormat = (v) => new Date(displayInTimezone(timezone, v));

  const isOutputFormatted = isValid(parseOutputFormat(field.value));

  const formattedValue = () => {
    if (!field.value) {
      return null;
    }
    if (isOutputFormatted) {
      return parseOutputFormat(field.value);
    }
    if (timezone) {
      return _.flowRight<any, any, any>(
        (v) => format(v, "yyyy-MM-dd'T'HH:mm:ssXX", timezone ? { timeZone: timezone } : undefined),
        parseISO,
      )(field.value);
    }
    return new Date(field.value);
  };

  return (
    <FormControl margin="dense">
      <MobileDatePicker
        {...rest}
        {...field}
        label={fieldLabel}
        disabled={disabled}
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        value={formattedValue()}
        renderInput={(props) => (
          <FormControl required={isRequired} error={fieldState.isTouched && !!fieldState.error}>
            <TextField variant="filled" {...props} data-testid="date-input" />
            <FormHelperText>
              {fieldState.isDirty && fieldState.error?.message
                ? translate(fieldState.error.message)
                : ' '}
            </FormHelperText>
          </FormControl>
        )}
        onChange={onChange}
        inputFormat="yyyy-MM-dd"
      />
    </FormControl>
  );
};

export default DateInput;
