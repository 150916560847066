import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberField, TextField, Datagrid, DateField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import IdField from 'components/IdField';

const PaymentList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Payout ID" source="payout_id" />
      <TextField label="Status" source="status" />
      <MoneyField label="Amount Cents" source="amount_cents" />
      <DateField
        showTime
        options={{ hour12: false }}
        label="Last Payment Attempt at"
        source="last_payment_attempt_at"
      />
      <DateField showTime options={{ hour12: false }} label="Processed on" source="processed_on" />
      <DateField showTime options={{ hour12: false }} label="Arrival Date" source="arrival_date" />
      <TextField source="provider" />
      <TextField label="Provider Transaction" source="provider_transaction_id" />
      <TextField label="Last Error Message" source="last_error_message" />
      <NumberField label="Failed Count" source="failed_count" />
    </Datagrid>
  </List>
);

PaymentList.query = gql`
  query payments($input: PaymentQueryInput) {
    _paymentsMeta(input: $input) {
      count
    }
    payments(input: $input) {
      id
      payout_id
      status
      amount_cents
      last_payment_attempt_at
      processed_on
      arrival_date
      provider
      provider_transaction_id
      last_error_message
      failed_count
    }
  }
`;

export default PaymentList;
