import TimestampFields from 'components/TimestampFields';
import React from 'react';
import { TextField, BooleanField, SimpleShowLayout } from 'react-admin';

const SummaryTab = () => (
  <SimpleShowLayout>
    <TextField label="ID" source="id" />
    <TextField source="label" />
    <BooleanField source="active" />
    <BooleanField label="Admin Only" source="admin_only" />
    <TimestampFields />
  </SimpleShowLayout>
);

export default React.memo(SummaryTab);
