import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const AssignmentLocationsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

AssignmentLocationsShow.query = gql`
  query GET_ONE($id: Int!) {
    assignmentLocation(id: $id) {
      id
      assignment_id
      location_id
      created_at
      updated_at
    }
  }
`;

export default AssignmentLocationsShow;
