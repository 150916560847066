import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const VmsTypeShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField label="Fee Percent" source="fee_percent" />
      <NumberField
        label="Non Billable Orientation Burden Hours"
        source="non_billable_orientation_burden_hours"
      />
      <NumberField label="Billable Orientation Hours" source="billable_orientation_hours" />
      <TextField label="Bullhorn ID" source="bullhorn_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

VmsTypeShow.query = gql`
  query vms($id: Int!) {
    vmsType(id: $id) {
      id
      name
      label
      fee_percent
      non_billable_orientation_burden_hours
      billable_orientation_hours
      bullhorn_id
      created_at
      updated_at
    }
  }
`;

export default VmsTypeShow;
