import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import EnumField from 'components/Enum/EnumField';

const NursysEnrollmentsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Professional Error" source="professional_error" />
      <EnumField source="status" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

NursysEnrollmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    nursysEnrollment(id: $id) {
      id
      professional_id
      professional_error
      status
      created_at
      updated_at
    }
  }
`;

export default NursysEnrollmentsShow;
