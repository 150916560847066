import React from 'react';
import DateTimeField, { DateTimeFieldProps } from 'components/DateTimeField';
import { utcToZonedTime } from 'date-fns-tz';
import { useRecordContext } from 'ra-core';

const isOvernight = ({ starts_time, ends_time, primary_location, location }) => {
  if (!starts_time || !ends_time) {
    return false;
  }
  const d = (t) => utcToZonedTime(t, (primary_location ?? location)?.timezone_lookup).getDate();
  return d(starts_time) - d(ends_time) !== 0;
};

export const JobTimeField = (props: DateTimeFieldProps) => {
  const record = useRecordContext(props);
  let format = props.format;
  if (format && props.source === 'ends_time' && record && isOvernight(record as any)) {
    format = `${format} '(overnight)'`;
  }
  return <DateTimeField tzSource="location.timezone_lookup" {...props} format={format} />;
};
