import React from 'react';
import { Edit, SimpleForm, BooleanInput, Toolbar, SaveButton } from 'react-admin';

const AssignmentsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <BooleanInput label="SSN Card Image" source="needs_item_url" />
    </SimpleForm>
  </Edit>
);

export default AssignmentsEdit;
