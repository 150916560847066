import { RichTextEditor } from '@medely/web-components';
import { Box } from '@mui/material';
import React from 'react';
import { InputProps, useInput } from 'react-admin';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type RichTextInputProps = Pick<InputProps, 'source'>;

const RichTextInput = (props: RichTextInputProps) => {
  const {
    field: { name, onBlur, onChange, value },
  } = useInput({ parse: (inputValue) => inputValue ?? {}, ...props });

  return (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '4px', mb: 2, '.ProseMirror': { p: 1 } }}>
      <RichTextEditor input={{ name, onBlur, onChange, onFocus: noop, value }} />
    </Box>
  );
};

export default RichTextInput;
