import React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';
import MarketInput from 'components/MarketInput';

const MarketLocationCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="city" />
      <TextInput source="county" />
      <TextInput source="zip_code" />
      <MarketInput />
    </SimpleForm>
  </Create>
);

export default MarketLocationCreate;
