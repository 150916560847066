import React from 'react';
import { Box, Card, CardHeader, CardContent, List, ListItem, ListItemText } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';
import { DisplayHelpers } from 'utils/helpers';
import config from 'config';
import { ReferenceManyField, FunctionField, Datagrid, useRecordContext } from 'react-admin';

const BillingGroupAside = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const amountDue = Math.max(0, record.total_due_cents - (record.balance_cents || 0));
  return (
    <Box ml={2} width={320} flexShrink={0}>
      <Card>
        <CardHeader title={record.name} subheader={`ID ${record.id}`} />
        <CardContent>
          <List>
            <ListItem>
              <ListItemText
                primary="Credit Limit"
                secondary={`${DisplayHelpers.money(record.line_of_credit_cents || 0)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Remaining Credit"
                secondary={`${DisplayHelpers.money(record.remaining_credit_cents || 0)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Past Due"
                secondary={`${DisplayHelpers.money(record.past_due_cents)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Current"
                secondary={`${DisplayHelpers.money(record.current_due_cents)}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{ style: { textDecoration: 'underline' } }}
                primary="Credit Balance"
                secondary={`${DisplayHelpers.money(
                  record.balance_cents || 0,
                  record.balance_currency,
                )}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Amount Due" secondary={`${DisplayHelpers.money(amountDue)}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Administrators"
                secondary={
                  <ReferenceManyField reference="Location" target="search.billing_group_id">
                    <Datagrid bulkActionButtons={false}>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary="Account Manager"
                            secondary={
                              <FunctionField
                                label="Account Manager"
                                render={(r) =>
                                  r?.account_manager?.account && (
                                    <ReactLink to={`/Administrator/${r.account_manager_id}/show`}>
                                      {r.account_manager.account.name}
                                    </ReactLink>
                                  )
                                }
                              />
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary="Sales Associate"
                            secondary={
                              <FunctionField
                                label="Sales Associate"
                                render={(r) =>
                                  r?.sales_associate?.account && (
                                    <ReactLink to={`/Administrator/${r.sales_associate_id}/show`}>
                                      {r.sales_associate.account.name}
                                    </ReactLink>
                                  )
                                }
                              />
                            }
                          />
                        </ListItem>
                      </List>
                    </Datagrid>
                  </ReferenceManyField>
                }
              />
            </ListItem>
            {record.payment_gateway && (
              <ListItem
                button
                component="a"
                target="_blank"
                href={`${config.client_url.facility}/hosted-billing/${record.uid}`}
              >
                <ListItemText
                  primaryTypographyProps={{ color: 'primary' }}
                  primary="Billing gateway"
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BillingGroupAside;
