import React, { memo, useState, useRef, ChangeEvent, useCallback } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Button } from '@mui/material';
import useApi from 'hooks/useApi';

const ImportCsv = () => {
  const api = useApi();
  const refresh = useRefresh();
  const notify = useNotify();
  const file = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [key, setKey] = useState<number>(+new Date());

  const toggleInput = useCallback(() => {
    if (file.current) {
      file.current.click();
    }
  }, [file]);

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files ? e.target?.files[0] : null;
      if (!file) {
        return;
      }

      const perform = async (file: File) => {
        try {
          const form = new FormData();
          form.append('csv_file', file);

          setLoading(true);

          await api({
            url: '/v3/admin/payouts/import',
            method: 'POST',
            data: form,
          });

          notify('Imported successfully');
          refresh();
        } catch (error: any) {
          notify(error.response?.data?.error ?? error.message, { type: 'warning' });
        } finally {
          setKey(+new Date());
          setLoading(false);
        }
      };

      perform(file);
    },
    [api, notify, refresh],
  );

  return (
    <>
      <input ref={file} type="file" hidden accept=".csv" key={key} onChange={handleFileUpload} />
      <Button size="small" variant="outlined" onClick={toggleInput} disabled={loading}>
        {loading ? 'Loading...' : 'Import W2'}
      </Button>
    </>
  );
};

export default memo(ImportCsv);
