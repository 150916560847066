import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

const AttachmentsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="license_field_id" reference="license_fields">
        <TextField source="label" />
      </ReferenceField>
      <TextField source="value" />
    </SimpleShowLayout>
  </Show>
);

AttachmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    attachment(id: $id) {
      id
    }
  }
`;

export default AttachmentsShow;
