import React from 'react';
import { required, TextInput, SelectInput, Form, FormDataConsumer } from 'react-admin';
import { useFormState, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ReferenceInput from 'components/ReferenceInput';

const LOCATION_OPTION = { id: '0', name: 'Location' };
const PROFESSIONAL_OPTION = { id: '1', name: 'Professional' };
const OTHER_CANCELLATION_REASON = 15;
const choices = [LOCATION_OPTION, PROFESSIONAL_OPTION];

const CancelDialog = ({ open, onClose, onSubmit }): JSX.Element => {
  const submit = async (values) => {
    await onSubmit?.({
      personable_type: values.canceled_by === LOCATION_OPTION.id ? 'Location' : 'Professional',
      cancellation_reason_id: values.bulk_cancellation_reason,
      note: values.other_reason,
    });
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Cancel Job</DialogTitle>
      <Form onSubmit={submit}>
        <CancelDialogContents onClose={onClose} onSubmit={submit} />
      </Form>
    </Dialog>
  );
};

const CancelDialogContents = ({ onClose, onSubmit }) => {
  const { isSubmitting } = useFormState();
  const methods = useFormContext();
  return (
    <>
      <DialogContent>
        <Box>
          <SelectInput fullWidth validate={[required()]} source="canceled_by" choices={choices} />
        </Box>
        <FormDataConsumer>
          {(formProps) => (
            <>
              {formProps.formData.canceled_by && (
                <Box>
                  <ReferenceInput
                    {...formProps}
                    fullWidth
                    validate={[required()]}
                    source="bulk_cancellation_reason"
                    filter={{ role: +formProps.formData.canceled_by }}
                    reference="CancellationReason"
                    label="Cancellation Reason"
                  >
                    <SelectInput optionText="label" />
                  </ReferenceInput>
                </Box>
              )}
              {formProps.formData.canceled_by === PROFESSIONAL_OPTION.id &&
                formProps.formData.bulk_cancellation_reason === OTHER_CANCELLATION_REASON && (
                  <Box>
                    <TextInput
                      fullWidth
                      label="Other Reason"
                      source="other_reason"
                      defaultValue=""
                    />
                  </Box>
                )}
            </>
          )}
        </FormDataConsumer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>

        <Button aria-label="Cancel" variant="contained" onClick={methods.handleSubmit(onSubmit)}>
          {isSubmitting ? <CircularProgress size="1.5em" /> : 'Cancel'}
        </Button>
      </DialogActions>
    </>
  );
};

export default CancelDialog;
