import React, { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ButtonProgress from './ButtonProgress';

const ButtonWithConfirmation = (props, ref) => {
  const {
    children,
    loading = false,
    onClick,
    confirmationTitle = 'Confirm',
    confirmationContent = 'Are you sure?',
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  useImperativeHandle(ref, () => ({
    open: handleClose,
    close: handleClose,
  }));
  return (
    <>
      <Button onClick={handleOpen} {...rest}>
        {children}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{confirmationTitle}</DialogTitle>
        <DialogContent>{confirmationContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ButtonProgress
            variant="contained"
            loading={loading}
            onClick={async () => {
              await onClick();
              handleClose();
            }}
          >
            Confirm
          </ButtonProgress>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default forwardRef(ButtonWithConfirmation);
