import PositionInput from 'components/PositionInput';
import React, { useEffect, useState } from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useCreate,
  SaveContextProvider,
  useNotify,
  useGetList,
} from 'react-admin';
import { useNavigate } from 'react-router';

const transformPayload = (payload) => {
  const data = payload.position_ids.map((position_id) => ({
    position_posting_field: {
      posting_field_id: payload.posting_field_id,
      position_id: position_id,
    },
  }));
  return data;
};

const PositionPostingFieldCreate = () => {
  const [create, { isLoading }] = useCreate();
  const navigate = useNavigate();
  const notify = useNotify();
  const [ids, setIds] = useState([]);
  const { data: positions = [] } = useGetList(
    'Positions',
    {
      filter: {
        search: {
          position_ids: ids,
        },
      },
      pagination: { perPage: 100, page: 1 },
      sort: { field: 'id', order: 'ASC' },
    },
    { enabled: !!ids.length },
  );
  useEffect(() => {
    if (ids.length && positions.length) {
      const position_names = positions.map(({ display_name }) => display_name);
      notify(`Position(s) ${position_names.join(', ')} already exists for this field`, {
        type: 'error',
      });
      setIds([]);
      navigate('/PositionPostingField');
    }
  }, [ids, positions, notify, navigate]);

  const handleSave = (values) => {
    const data = transformPayload(values);
    return create(
      // This adjusts the resource name that is passed into the useDataProvider hook
      // in order to hit the endpoint with PositionPostingFields being plural rather than singular.
      // This is the way backend structured the path for the endpoint since we are creating
      // multiple records rather than just one
      `PositionPostingFields`,
      { data },
      {
        // Due to the endpoint returning a multi-status response on any errors,
        // the errors are coming through on the onSuccess rather than onError.
        // This will handle those errors and display a useful message on why some
        // of the records failed to be created (since they already exist).
        onSuccess: ({ errors }) => {
          if (errors?.length) {
            const error_ids = errors.map(({ position_id }) => position_id);
            setIds(error_ids);
          } else {
            notify('Records Created', { type: 'success' });
            navigate('/PositionPostingField');
          }
        },
      },
    );
  };

  return (
    <Create redirect="show" transform={transformPayload}>
      <SaveContextProvider
        value={{
          save: handleSave,
          saving: isLoading,
          mutationMode: 'pessimistic',
        }}
      >
        <SimpleForm>
          <ReferenceInput label="Posting Field" reference="PostingFields" source="posting_field_id">
            <SelectInput resettable validate={[required()]} optionText="label" />
          </ReferenceInput>
          <PositionInput multiple validate={[required()]} />
        </SimpleForm>
      </SaveContextProvider>
    </Create>
  );
};

export default PositionPostingFieldCreate;
