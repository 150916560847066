import React from 'react';
import { EditButton, useRefresh, useNotify, useRecordContext, TopToolbar } from 'react-admin';
import { Button } from '@mui/material';
import config from 'config';
import getAxios from 'getAxios';

const axios = getAxios();

const VmsUserShowActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const validateVmsUser = async () => {
    return axios
      .post(
        `${config.rails_url}/vms_users/${record.id}/validate`,
        {},
        {
          withCredentials: false,
        },
      )
      .then(() => {
        notify('Validated');
        refresh();
      })
      .catch((error) => notify(error.message, { type: 'warning' }));
  };

  return (
    <TopToolbar>
      <Button disabled={!record.updated} onClick={validateVmsUser}>
        Confirm Changes
      </Button>
      <EditButton />
    </TopToolbar>
  );
};

export default VmsUserShowActions;
