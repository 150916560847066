import DateTimeInput from 'components/DateTimeInput';
import ProfessionalInput from 'components/ProfessionalInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import { Create, SimpleForm, NumberInput } from 'react-admin';

const TimecardsCreate = () => (
  <Create data-testid="timecards-create" redirect="show">
    <SimpleForm>
      <EnumSelectInput label="Status" source="status" />
      <DateTimeInput source="starts_time" />
      <DateTimeInput source="ends_time" />
      <ProfessionalInput />
      <NumberInput source="assignment_id" />
      <DateTimeInput source="submit_by" />
    </SimpleForm>
  </Create>
);

export default TimecardsCreate;
