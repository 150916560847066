import React from 'react';
import { TextInput, Edit, SimpleForm, required } from 'react-admin';

const MspEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput label="MSP Name" source="name" validate={[required()]} />
      <TextInput label="MSP Label" source="label" validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export default MspEdit;
