import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, DateField, TextInput } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import { IdFilter } from 'components/Filter';
import DisputeInput from 'components/DisputeInput';
import PhoneInput from 'components/PhoneInput';

const DisputeShiftWitnessesFilters = [
  <IdFilter alwaysOn />,
  <DisputeInput source="dispute_id" alwaysOn />,
  <TextInput source="name" alwaysOn />,
  <TextInput source="position" alwaysOn />,
  <PhoneInput source="phone" alwaysOn />,
  <TextInput source="email" alwaysOn />,
];

const DisputeShiftWitnessesList = () => (
  <List exporter={false} filters={DisputeShiftWitnessesFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Dispute" source="dispute_id" />
      <TextField source="name" />
      <TextField source="position" />
      <TextField source="phone" />
      <TextField source="email" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

DisputeShiftWitnessesList.query = gql`
  query GET_LIST($input: DisputeShiftWitnessQueryInput) {
    _disputeShiftWitnessesMeta(input: $input) {
      count
    }
    disputeShiftWitnesses(input: $input) {
      id
      dispute_id
      name
      position
      phone
      email
      created_at
      updated_at
    }
  }
`;

export default DisputeShiftWitnessesList;
