import JobInput from 'components/JobInput';
import React from 'react';
import { Edit, SimpleForm, DateTimeInput, NumberInput } from 'react-admin';

const BreaksEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <DateTimeInput source="starts_time" />
      <NumberInput source="duration_minutes" />
      <JobInput />
    </SimpleForm>
  </Edit>
);

export default BreaksEdit;
