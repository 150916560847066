import React from 'react';
import { TextInput, NumberInput, BooleanInput, Edit, SimpleForm } from 'react-admin';

const SurgeryTypeEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="surgery-type-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="surgery-type-edit-name" />
      <TextInput source="display_name" data-testid="surgery-type-edit-display-name" />
      <NumberInput source="sequence" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

export default SurgeryTypeEdit;
