import React, { useState } from 'react';
import { Loading, SimpleForm, Toolbar, useNotify, useRefresh, Button, RaRecord } from 'react-admin';
import useDialog from 'hooks/useDialog';
import useBillingGroup from 'hooks/useBillingGroup';
import PayInvoicesTable from 'components/PayInvoicesTable';
import getAxios from 'getAxios';
import { useFormContext } from 'react-hook-form';
import { Box, Dialog, DialogTitle } from '@mui/material';

const axios = getAxios();

interface IApplyCreditBalanceFormValues {
  invoices: any[];
  billing_group_ids: number[];
  billing_group_balances: { balance_cents?: number }[];
  blockNextButton: boolean;
}

const amountLeft = (record: RaRecord, invoices: any[]) =>
  record.balance_cents - invoices.reduce((acct, i) => acct + (i?.amount_cents || 0), 0);

const ApplyCreditBalanceToolbar = ({ closeDialog, loading, handleCreate }) => {
  const { getValues } = useFormContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={closeDialog} label="Cancel"></Button>
      <Button
        disabled={loading || getValues().blockNextButton}
        variant="contained"
        color="primary"
        onClick={() => handleCreate(getValues())}
        label={loading ? 'Please, wait...' : 'Submit'}
      ></Button>
    </Toolbar>
  );
};

const ApplyCreditBalanceButton = ({ record }) => {
  const refresh = useRefresh();
  const { open, closeDialog, openDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const { data: billingGroup, isLoading: isLoadingBillingGroups } = useBillingGroup(record.id);

  const couldBeRefunded = billingGroup?.balance_cents > 0;

  if (!couldBeRefunded || isLoadingBillingGroups) {
    return null;
  }

  const handleCreate = async (values: IApplyCreditBalanceFormValues) => {
    try {
      setLoading(true);
      await axios.post(`/v3/admin/billing_groups/${record.id}/apply`, {
        invoices: values.invoices
          .filter((inv) => inv?.amount_cents)
          .map((inv) => {
            return {
              id: inv.id,
              amount_cents: inv.amount_cents,
              notes: inv.notes,
            };
          }),
      });
      closeDialog();
      notify('Successfully applied credit balance', { type: 'success' });
      refresh();
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'warning' });
      }
    } finally {
      setLoading(false);
    }
  };

  if (isLoadingBillingGroups) {
    return <Loading />;
  }

  const initialValues: IApplyCreditBalanceFormValues = {
    invoices: [],
    billing_group_ids: [record.id],
    billing_group_balances: [
      {
        balance_cents: record.balance_cents,
      },
    ],
    blockNextButton: false,
  };

  return (
    <Box>
      <Button
        onClick={openDialog}
        label="Apply Credit Balance"
        variant="outlined"
        color="primary"
      />
      <Dialog fullWidth open={open} aria-label="Apply Credit Balance" maxWidth="md">
        <DialogTitle>Apply Credit Balance</DialogTitle>
        <SimpleForm
          record={initialValues}
          mode="onBlur"
          reValidateMode="onBlur"
          toolbar={
            <ApplyCreditBalanceToolbar
              closeDialog={closeDialog}
              loading={loading}
              handleCreate={handleCreate}
            />
          }
        >
          <PayInvoicesTable
            record={record}
            showBillingGroupIDField={false}
            amountLeft={amountLeft}
          />
        </SimpleForm>
      </Dialog>
    </Box>
  );
};

export default ApplyCreditBalanceButton;
