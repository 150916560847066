import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  NumberField,
  BooleanField,
  FunctionField,
  Show,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Link, Typography } from '@mui/material';

const ExternalAccountShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Stripe Account" source="stripe_account.id" />
      <FunctionField
        label="Professional ID"
        render={(record) => (
          <LinkField
            record={record.stripe_account}
            label="Professional ID"
            source="professional_id"
          />
        )}
      />
      <BooleanField label="Stripe Account Active" source="stripe_account.active" />
      <BooleanField label="Stripe Account Verified" source="stripe_account.verified" />
      <TextField source="stripe_id" />
      <TextField label="Instant Pay Eligible" source="instant_pay_eligible" />
      <TextField source="brand" />
      <NumberField label="Exp Month" source="exp_month" />
      <NumberField label="Exp Year" source="exp_year" />
      <TextField label="Last 4 Digits" source="last4" />
      <TextField label="Account Type" source="account_type" />
      <TextField label="Bank Name" source="bank_name" />
      <FunctionField
        label="Stripe URL"
        render={(record) => (
          <Typography>
            <Link
              target="_blank"
              href={`https://dashboard.stripe.com/connect/accounts/${record.stripe_account.stripe_id}`}
            >
              Stripe URL
            </Link>
          </Typography>
        )}
      />
    </SimpleShowLayout>
  </Show>
);

ExternalAccountShow.query = gql`
  query externalAccount($id: Int!) {
    externalAccount(id: $id) {
      id
      stripe_id
      instant_pay_eligible
      brand
      exp_month
      exp_year
      last4
      account_type
      bank_name
      stripe_account {
        id
        professional_id
        active
        stripe_id
        verified
      }
    }
  }
`;

export default ExternalAccountShow;
