import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, BooleanField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const SurgeryTypeShow = () => (
  <Show data-testid="surgery-type-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField label="Display Name" source="display_name" />
      <NumberField source="sequence" />
      <BooleanField source="active" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

SurgeryTypeShow.query = gql`
  query surgeryType($id: Int!) {
    surgeryType(id: $id) {
      id
      name
      display_name
      sequence
      active
      created_at
      updated_at
    }
  }
`;

export default SurgeryTypeShow;
