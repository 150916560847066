import React from 'react';
import { gql } from '@apollo/client';
import { Show, TextField, SimpleShowLayout } from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import TimestampFields from 'components/TimestampFields';
import ReverseTransaction from './ReverseTransaction';

const ItemizedTransactionsShow = () => (
  <Show actions={<ReverseTransaction />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Payment ID" source="financial_transaction.transaction_id" />
      <MoneyField label="Amount" source="amount_cents" />
      <TextField label="From" source="from_source" />
      <TextField label="To" source="to_source" />
      <TextField label="Notes" source="notes" />
      <LinkField label="Invoice ID" source="invoice.id" />
      <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      <LinkField label="Financial Transaction ID" source="financial_transaction.id" />
      <LinkField label="Balance Sheet ID" source="balance_sheet_id" typename="BalanceSheet" />
      <LinkField
        label="Reverse Itemized Transaction ID"
        source="reversed_itemized_transaction_id"
        typename="ItemizedTransaction"
      />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ItemizedTransactionsShow.query = gql`
  query GET_ONE($id: Int!) {
    itemizedTransaction(id: $id) {
      id
      billing_group_id
      balance_sheet_id
      reversed_itemized_transaction_id
      invoice {
        id
      }
      financial_transaction {
        id
        transaction_id
      }
      amount_cents
      amount_currency
      created_at
      from_source
      to_source
      notes
    }
  }
`;

export default ItemizedTransactionsShow;
