import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const BillingGroupParentInput = (props) => (
  <ReferenceInput gql={['id', 'name']} {...props}>
    <AutocompleteInput
      sx={{ width: 210 }}
      optionText={(o) => `${o.name} (${o?.id})`}
      {...getChildrenProps(props)}
    />
  </ReferenceInput>
);

BillingGroupParentInput.defaultProps = {
  label: 'Billing Group Parent',
  source: 'billing_group_parent_id',
  reference: 'BillingGroupParent',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
};

export default BillingGroupParentInput;
