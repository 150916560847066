import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import LinkField from 'components/LinkField';
import { QuestionnaireQuestionsField } from './QuestionsFields';
import TimestampFields from 'components/TimestampFields';

const QuestionnaireResponseShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Credential ID" source="credential.id" />
      <LinkField label="Professional ID" source="professional.id" />
      <LinkField label="Questionnaire ID" source="questionnaire.id" />
      <QuestionnaireQuestionsField />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

QuestionnaireResponseShow.query = gql`
  query questionnaireResponse($id: Int!) {
    questionnaireResponse(id: $id) {
      id
      professional {
        id
      }
      questionnaire {
        id
      }
      questions
      created_at
      updated_at
    }
  }
`;

export default QuestionnaireResponseShow;
