import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import {
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  NumberInput,
  Create,
  SimpleForm,
} from 'react-admin';

const StateCreate = () => (
  <Create data-testid="state-create" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="abbreviation" />
      <BooleanInput source="active" />
      <ReferenceArrayInput label="Markets" source="market_ids" reference="Market" perPage={100}>
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
      <BooleanInput source="nlc" />
      <BooleanInput source="display_by_hourly" />
      <NumberInput source="minimum_rate_cents" />
      <EnumSelectInput emptyText="None" sort="" source="covid_requirement" />
    </SimpleForm>
  </Create>
);

export default StateCreate;
