import React from 'react';
import { TextField, ReferenceField, ReferenceFieldProps } from 'react-admin';

interface StateFieldProps extends Omit<ReferenceFieldProps, 'children'> {}

const StateField = (props: StateFieldProps) => (
  <ReferenceField {...props}>
    <TextField source="name" />
  </ReferenceField>
);

StateField.defaultProps = {
  label: 'State',
  source: 'state_id',
  reference: 'State',
  emptyValue: null,
  emptyText: 'Clear',
  sort: { field: 'name', order: 'ASC' },
  perPage: 60,
};

export default StateField;
