import React from 'react';
import { gql } from '@apollo/client';
import { TextField, BooleanField, Show, SimpleShowLayout, FunctionField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import ReviewLink from './ReviewLink';
import LinkField from 'components/LinkField';

const ReviewShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <FunctionField label="Reviewer" render={({ reviewer }) => ReviewLink(reviewer)} />
      <LinkField label="Location ID" source="job.location.id" />
      <FunctionField label="Reviewee" render={({ reviewee }) => ReviewLink(reviewee)} />
      <LinkField label="Job ID" source="job.id" />
      <TextField label="Comments" source="comments" />
      <TextField label="Rating" source="rating" />
      <BooleanField label="Blocked" source="blocked" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ReviewShow.query = gql`
  query review($id: Int!) {
    review(id: $id) {
      id
      reviewer {
        id
        account_type
        company {
          id
        }
        professional {
          id
        }
      }
      reviewee {
        id
        account_type
        company {
          id
        }
        professional {
          id
        }
      }
      job {
        id
        location {
          id
        }
      }
      comments
      rating
      blocked
      created_at
      updated_at
    }
  }
`;

export default ReviewShow;
