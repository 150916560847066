import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, BooleanField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const SurgeryTypeList = () => (
  <List exporter={false} filters={[<IdInput />]} data-testid="surgery-type-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField label="Display Name" source="display_name" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

SurgeryTypeList.query = gql`
  query surgeryTypes($input: SurgeryTypeQueryInput) {
    _surgeryTypesMeta(input: $input) {
      count
    }
    surgeryTypes(input: $input) {
      id
      name
      display_name
      active
    }
  }
`;

export default SurgeryTypeList;
