import { Datagrid, TextField, TextInput } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import { SearchFilter } from 'components/Filter';
import IdInput from 'components/Filter/IdInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const QualificationFilters = [
  <SearchFilter alwaysOn />,
  <IdInput />,
  <EnumSelectInput label="Type" alwaysOn source="qualification_type" />,
  <TextInput source="slug" alwaysOn />,
];

const QualificationsList = () => (
  <List exporter={false} filters={QualificationFilters} hasCreate>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="long_name" />
      <TextField source="slug" />
      <TextField source="qualification_type" />
    </Datagrid>
  </List>
);

QualificationsList.query = gql`
  query GET_LIST($input: QualificationQueryInput) {
    qualifications(input: $input) {
      id
      long_name
      slug
      qualification_type
    }
    _qualificationsMeta(input: $input) {
      count
    }
  }
`;

export default QualificationsList;
