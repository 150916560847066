import { parse, isValid, parseISO, differenceInMinutes, addMinutes } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { formatWithOptions, utcToZonedTime } from 'date-fns-tz/fp';
import _ from 'lodash';

const INPUT_FORMAT = 'yyyy-MM-dd HH:mm:ss';

const parseMixedTime = (time: string, timezone: string) => {
  const inputTime = parse(time, INPUT_FORMAT, new Date());
  const isInputTime = isValid(inputTime);
  const date = isInputTime ? zonedTimeToUtc(inputTime, timezone) : parseISO(time);
  return date;
};

export const hoursDiff = (start, end, timezone) =>
  differenceInMinutes(parseMixedTime(end, timezone), parseMixedTime(start, timezone)) / 60;

export const displayInTimezone = (
  timeZone: string,
  date: string,
  format = 'MM/dd/yyyy HH:mm zzz',
) => {
  if (!date) {
    return '';
  }
  return _.flowRight<any, any, any, any>(
    formatWithOptions({ timeZone }, format),
    utcToZonedTime(timeZone),
    parseISO,
  )(date);
};

export const isDateOnly = (date: string | undefined): boolean =>
  date?.includes('T00:00:00.000Z') ?? false;

export const startOfDay = (timestamp: string) => {
  const date = new Date(timestamp);

  return addMinutes(date, date.getTimezoneOffset());
};

export const currentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
