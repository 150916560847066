import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  Show,
  SimpleShowLayout,
  TopToolbar,
  EditButton,
  useRecordContext,
} from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import TimestampFields from 'components/TimestampFields';
import ActionButton from 'components/ActionButton';

const ShowActions = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  return (
    <TopToolbar>
      <ActionButton
        text={record.active ? 'Deactivate' : 'Re-activate'}
        method="PUT"
        data={{
          active: !record.active,
        }}
        url={`/v3/admin/location_block_reasons/${record.id}`}
      />
      <EditButton />
    </TopToolbar>
  );
};

const LocationBlockReasonShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <EnumField source="kind" />
      <TextField source="label" />
      <TextField source="slug" />
      <TextField source="active" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

LocationBlockReasonShow.query = gql`
  query LocationBlockReason($id: Int!) {
    locationBlockReason(id: $id) {
      id
      kind
      label
      active
      slug
      created_at
      updated_at
    }
  }
`;

export default LocationBlockReasonShow;
