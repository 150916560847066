import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { AutocompleteInput, TextInput, DateTimeInput, Edit, SimpleForm } from 'react-admin';
import InputUtils from 'utils/input';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import ProfessionalInput from 'components/ProfessionalInput';

const PhoneScreeningRequestEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="phone-screening-request-edit">
    <SimpleForm>
      <ReferenceInput
        label="Assignment request"
        source="assignment_request_id"
        reference="AssignmentRequest"
      >
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <ProfessionalInput />
      <EnumSelectInput source="status" />
      <TextInput source="phone_number" />
      <TextInput source="facility_contact_phone_number" />
      <TextInput source="phone_number_sid" />
      <TextInput source="conference_sid" />
      <DateTimeInput source="confirmed_phone_screening_time" />
    </SimpleForm>
  </Edit>
);

export default PhoneScreeningRequestEdit;
