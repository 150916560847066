import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import TimestampFields from 'components/TimestampFields';

const ExpenseAdjustmentsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <MoneyField label="Amount of Cents" source="amount_cents" />
      <LinkField label="Job ID" source="job.id" />
      <LinkField source="dispute_id" />
      <LinkField label="Balance Sheet ID" source="balance_sheet.id" />
      <LinkField label="Payout ID" source="payout.id" />
      <LinkField label="Timecard ID" source="timecard.id" />
      <TextField label="Taxable Type" source="taxable_type" />
      <TextField source="notes" />
      <BooleanField label="Primary Adjustment" source="primary_adjustment" />
      <BooleanField label="Past Month Adjustment" source="past_month_adjustment" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ExpenseAdjustmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    expenseAdjustment(id: $id) {
      id
      amount_cents
      amount_currency
      job {
        id
      }
      balance_sheet {
        id
      }
      payout {
        id
      }
      timecard {
        id
      }
      dispute_id
      notes
      primary_adjustment
      taxable_type
      created_at
      updated_at
      past_month_adjustment
    }
  }
`;

export default ExpenseAdjustmentsShow;
