import React from 'react';
import { TextInput, BooleanInput, Edit, SimpleForm, NumberInput } from 'react-admin';

const EhrSystemEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <BooleanInput source="active" />
      <NumberInput source="medefis_identifier" />
    </SimpleForm>
  </Edit>
);

export default EhrSystemEdit;
