import React from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin';
import InputUtils from 'utils/input';
import LocationInput from 'components/LocationInput';

const AssignmentLocationsCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput label="Assignment ID" source="assignment_id" reference="Assignment">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <LocationInput />
    </SimpleForm>
  </Create>
);

export default AssignmentLocationsCreate;
