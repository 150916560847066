import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, DateField, Datagrid } from 'react-admin';
import List from 'components/List';
import { SearchFilter } from 'components/Filter';
import IdField from 'components/IdField';

const QualificationsGroupListFilters = [<IdInput />, <SearchFilter alwaysOn />];

const QualificationsGroupList = () => (
  <List
    exporter={false}
    filters={QualificationsGroupListFilters}
    data-testid="qualifications-group-list"
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

QualificationsGroupList.query = gql`
  query qualificationsGroups($input: QualificationsGroupQueryInput) {
    _qualificationsGroupsMeta(input: $input) {
      count
    }
    qualificationsGroups(input: $input) {
      id
      name
      created_at
      updated_at
    }
  }
`;

export default QualificationsGroupList;
