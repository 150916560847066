import React from 'react';
import { gql } from '@apollo/client';
import { FunctionField, Show, SimpleShowLayout, TextField } from 'react-admin';
import LinkField from 'components/LinkField';
import ProfessionalQualificationAttributeField from './ProfessionalQualificationAttributeField';
import { JsonField } from 'react-admin-json-view';

const QualificationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <LinkField label="Professional Qualification" source="professional_qualification.id" />
      <LinkField
        label="Professional"
        source="professional_qualification.professional_id"
        to={(record) => `/Professional/${record?.professional_qualification?.professional_id}/show`}
      />
      <FunctionField label="Type" render={(val) => val.type?.split('::')[1]} />
      <JsonField label="Metadata" source="metadata" />
      <ProfessionalQualificationAttributeField />
    </SimpleShowLayout>
  </Show>
);

QualificationShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalQualificationAttribute(id: $id) {
      id
      professional_qualification {
        id
        professional_id
      }
      type
      value
      metadata
    }
  }
`;

export default QualificationShow;
