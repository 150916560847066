import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import { SearchFilter } from 'components/Filter';

const HealthSystemListFilters = [<IdInput />, <SearchFilter alwaysOn />];

const HealthSystemList = () => (
  <List filters={HealthSystemListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
    </Datagrid>
  </List>
);

HealthSystemList.query = gql`
  query healthSystems($input: HealthSystemQueryInput) {
    _healthSystemsMeta(input: $input) {
      count
    }
    healthSystems(input: $input) {
      id
      name
      label
    }
  }
`;

export default HealthSystemList;
