import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import create from './Create';
import edit from './Edit';

export default {
  name: 'Conditions',
  inputShow: gql`
    query Conditions($input: ConditionQueryInput) {
      conditions(input: $input) {
        id
        label
        __typename
      }
    }
  `,
  list,
  show,
  create,
  edit,
  options: {
    group: 'Qualifications',
    label: 'Conditions',
  },
};
