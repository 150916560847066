import AccountCircle from '@mui/icons-material/AccountCircle';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'professionals',
  inputShow: gql`
    query professionals($input: ProfessionalQueryInput) {
      professionals(input: $input) {
        id
        name
        account {
          id
          first_name
          last_name
          name
        }
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  icon: AccountCircle,
  edit: Edit,
  // create: Create,
  options: {
    label: 'Professionals',
    group: 'root',
    order: 4,
  },
};
