import React from 'react';
import Heading from 'components/Heading';
import { Labeled } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import { Typography } from '@mui/material';

interface ProfitProps {
  grossProfitCents: number;
  grossMargin?: number;
}

const Profit = ({ grossProfitCents, grossMargin }: ProfitProps) => (
  <>
    <Heading>Profit</Heading>
    <Labeled label="Gross profit" sx={{ marginRight: 4 }}>
      <Typography>{DisplayHelpers.money(grossProfitCents)}</Typography>
    </Labeled>
    {grossMargin && (
      <Labeled label="Gross margin">
        <Typography>{grossMargin.toFixed(3)}</Typography>
      </Labeled>
    )}
  </>
);

export default Profit;
