import React from 'react';
import { Card, CardHeader, Grid, List, ListItem, ListItemText } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import config from '../config';

const Dashboard = () => (
  <Grid container spacing={3} data-testid="dashboard">
    <Grid item md={3}>
      <Card>
        <CardHeader title="External resources" data-testid="dashboard-external-resources-title" />
        <List>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://medely.ideas.aha.io"
            data-testid="dashboard-submit-an-ideia"
          >
            <ListItemText primary="Submit an idea" secondary="Submit an idea to the product team" />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href={`${config.rails_url}/admin2`}
            data-testid="dashboard-admin-v2"
          >
            <ListItemText primary="Admin v2" secondary="log in is required" />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href={`${config.rails_url}/admin`}
            data-testid="dashboard-admin-v1"
          >
            <ListItemText primary="Admin v1" secondary="log in is required" />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://admin-help.medely.com/en/"
            data-testid="dashboard-admin-help-articles"
          >
            <ListItemText
              primary="Admin help articles"
              secondary="Advice and answers from the Medely Team for admin"
            />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://helpdesk.medely.com/"
            data-testid="dashboard-medely-it-support"
          >
            <ListItemText
              primary="Medely IT Support"
              secondary="Submit a ticket, check status of a ticket, review help articles"
            />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://helpdesk.medely.com/support/catalog/items/49"
            data-testid="dashboard-business-intelligence"
          >
            <ListItemText
              primary="Business Intelligence"
              secondary="Submit a request to the Business Intelligence Team"
            />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://help.medely.com"
            data-testid="dashboard-help-article"
          >
            <ListItemText
              primary="Facility/Pro help articles"
              secondary="Advice and answers from the Medely Team for facilities and professionals"
            />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1Sbi8DiaRp9nHIDJ5dcj57ld2eYBx4RVkLDYvJ2Lzll0/edit#gid=1566394050"
            data-testid="dashboard-verification"
          >
            <ListItemText
              primary="License & Credentials Verification"
              secondary="State by state resource for license verification"
            />
            <OpenInNew color="action" />
          </ListItem>
          <ListItem
            button
            component="a"
            target="_blank"
            href="https://medely.com/docs/style_guide"
            data-testid="dashboard-style-guide"
          >
            <ListItemText
              primary="Style Guide"
              secondary="Components storybook, brand colors and style guides"
            />
            <OpenInNew color="action" />
          </ListItem>
        </List>
      </Card>
    </Grid>
  </Grid>
);

export default Dashboard;
