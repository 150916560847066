import React from 'react';
import { JsonField as RAJsonField } from 'react-admin-json-view';
import { useTheme } from '@mui/material/styles';

const JsonField = ({ source, label = '', rootKey = 'root', ...rest }): JSX.Element => {
  const theme = useTheme();
  const jsonFieldTheme = theme.palette.mode === 'dark' ? 'monokai' : 'rjv-default';

  return (
    <RAJsonField
      source={source}
      label={label}
      reactJsonOptions={{
        name: rootKey,
        collapsed: true,
        displayDataTypes: false,
        enableClipboard: false,
        theme: jsonFieldTheme,
        style: { backgroundColor: 'transparent' },
      }}
      {...rest}
    />
  );
};

export default JsonField;
