import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import LinkField from 'components/LinkField';

const EmergencyContactShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Professional ID" source="professional_id" />
      <TextField label="First Name" source="first_name" />
      <TextField label="Last Name" source="last_name" />
      <TextField label="Phone Number" source="phone_number" />
    </SimpleShowLayout>
  </Show>
);

EmergencyContactShow.query = gql`
  query emergencyContact($id: Int!) {
    emergencyContact(id: $id) {
      id
      first_name
      last_name
      phone_number
      professional_id
    }
  }
`;

export default EmergencyContactShow;
