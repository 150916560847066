import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, BooleanInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';
import StateInput from 'components/StateInput';

const Work_gapsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <StateInput />
      <StateInput />
      <TextInput source="location" />
      <TextInput source="explanation" multiline />
      <DateInput source="start_date" />
      <DateInput source="end_date" />
      <BooleanInput source="include_in_work_histories" />
    </SimpleForm>
  </Edit>
);

export default Work_gapsEdit;
