import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';
import StateInput from 'components/StateInput';

const ProfessionalTravelAssignmentStatesCreate = () => (
  <Create redirect={(_resource, _id, data) => `Professional/${data.professional_id}/show/states`}>
    <SimpleForm>
      <ProfessionalInput />
      <StateInput />
    </SimpleForm>
  </Create>
);

export default ProfessionalTravelAssignmentStatesCreate;
