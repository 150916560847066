import React, { memo } from 'react';
import { Toolbar } from 'react-admin';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const RefundFormToolbar = ({ onCancel, isLastStep }) => {
  const { reset } = useFormContext();
  const onClose = () => {
    onCancel();
    reset();
  };

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button variant="contained" type="submit" color="primary">
        {isLastStep ? 'Refund' : 'Next'}
      </Button>
    </Toolbar>
  );
};

export default memo(RefundFormToolbar);
