import List from './List';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'custom_professional_profile_ehr_systems',
  inputShow: gql`
    query customProfessionalProfileEhrSystems(
      $input: CustomProfessionalProfileEhrSystemQueryInput
    ) {
      customProfessionalProfileEhrSystems(input: $input) {
        id
        label
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: false,
  },
};
