import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'work_gaps',
  inputShow: gql`
    query workGaps($input: WorkGapQueryInput) {
      workGaps(input: $input) {
        id
        explanation
        include_in_work_histories
        start_date
        end_date
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: false,
  },
};
