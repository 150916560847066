import * as React from 'react';
import {
  TextField,
  Datagrid,
  DeleteWithConfirmButton,
  useRecordContext,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import QuickCreateButton from 'components/QuickCreateButton';
import PositionInput from 'components/PositionInput';

const transformPayload = (values: { professional_id: number; new_position_ids: number[] }) =>
  values.new_position_ids.map((id) => ({
    professional_id: values.professional_id,
    position_id: id,
  }));

const PositionsTab = (props) => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <QuickCreateButton
        initialValues={{ professional_id: record.id, new_position_ids: [] }}
        resource="ProfessionalPositions"
        transform={transformPayload}
      >
        <PositionInput multiple source="new_position_ids" />
      </QuickCreateButton>
      <ReferenceManyField
        reference="ProfessionalPosition"
        label={false}
        target="professional_id"
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} data-testid="professional_show-positions_tab_table">
          <TextField
            label="Position"
            source="position_id"
            data-testid="professional_show-positions_id_field"
          />
          <TextField
            label="Display Name"
            source="position.display_name"
            data-testid="professional_show-positions_display_name_field"
          />
          <TextField
            source="years_of_experience"
            data-testid="professional_show-positions_years_of_experience_field"
          />
          <TextField
            label="Position Onboarding Completed At"
            source="completed_onboarding_at"
            data-testid="professional_show-positions_completed_onboarding_at_field"
          />
          {props.canDelete && (
            <DeleteWithConfirmButton
              redirect={`/Professional/${record.id}/show/positions`}
              data-testid="professional_show-positions_delete_button"
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default PositionsTab;
