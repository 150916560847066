import { Create, FileField, FileInput, NumberInput, SimpleForm, TextInput } from 'react-admin';
import React from 'react';
import { transform } from '../../utils/formFileHelper';
const ConditionDocumentsCreate = () => (
  <Create redirect="show" transform={transform}>
    <SimpleForm>
      <NumberInput label="Condition ID" source="condition_id" />
      <TextInput label="Category" source="category" />
      <TextInput source="name" />
      <FileInput source="file" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);

export default ConditionDocumentsCreate;
