import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import SummaryTab from './tabs/SummaryTab';
import SpecialtiesTab from './tabs/SpecialtiesTab';
import RequirementsTab from './tabs/RequirmentsTab';
import useCheckAccess from 'hooks/useCheckAccess';

const PositionShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show data-testid="position-show">
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="summary">
          <SummaryTab />
        </Tab>
        {checkAccess('view', 'Specialty') && (
          <Tab label="Specialties" path="specialties">
            <SpecialtiesTab />
          </Tab>
        )}
        <Tab label="Requirements" path="requirements">
          <RequirementsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

PositionShow.query = gql`
  query position($id: Int!) {
    position(id: $id) {
      id
      name
      display_name
      sequence
      active
      requires_specialty
      lead_question
      lead_description
      requirements_description
      kind
      qualifications_group {
        id
        name
      }
      specialties {
        id
        name
        label
      }
      responsibilities
      hospital_mark_up
      created_at
      updated_at
    }
  }
`;

export default PositionShow;
