import * as React from 'react';
import { TextField, Datagrid, ArrayField, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import PdfButton from 'components/PdfButton';
import TimestampFields from 'components/TimestampFields';

export const QuestionnaireQuestionsField = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <>
      <TimestampFields />
      <ArrayField source="questions">
        <Datagrid bulkActionButtons={false}>
          <TextField source="question" />
          <TextField source="answer" />
          <TextField source="type" />
          <TextField source="expected_answer" />
          <TextField source="required" />
          <TextField source="options" />
        </Datagrid>
      </ArrayField>
      <Box my={2}>
        <PdfButton
          variant="outlined"
          color="primary"
          download={`${record?.staff?.last_name}.${record?.staff?.first_name}.tbquestionnaire.pdf`}
          href={`/questionnaire_responses/${record?.id}`}
        >
          Download PDF
        </PdfButton>
      </Box>
    </>
  );
};
