import { useRecordContext } from 'ra-core';
import React from 'react';
import LinkField from './LinkField';

const FavoriteIdsField = ({ label = 'Favorite IDs' }: { label?: string }) => {
  const record = useRecordContext();
  if (!record?.favorite_ids?.[0]) {
    return null;
  }
  return (
    <div>
      {record.favorite_ids.map((item) => (
        <LinkField label={label} to={`/Professional/${item}/show`}>
          {item}
        </LinkField>
      ))}
    </div>
  );
};

export default FavoriteIdsField;
