import { useState } from 'react';

const useIsOpen = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  return { isOpen, handleClose, handleOpen };
};

export default useIsOpen;
