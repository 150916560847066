import * as React from 'react';
import { TextInput } from 'react-admin';

const MoneyInput = (props) => (
  <TextInput
    data-testid="money-input"
    resettable
    parse={(v: string) => {
      const multiplier = v.includes('-') ? -1 : 1;
      return v && multiplier * parseInt(v.replace(/\D/g, ''));
    }}
    format={(v?: number) => {
      if (v !== 0 && !v) {
        return '';
      }
      return (v / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }}
    {...props}
  />
);

export default MoneyInput;
