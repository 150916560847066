import React from 'react';
import { TextInput, NumberInput, BooleanInput, Create, SimpleForm } from 'react-admin';

const SurgeryTypeCreate = () => (
  <Create data-testid="surgery-type-create" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="display_name" />
      <NumberInput source="sequence" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

export default SurgeryTypeCreate;
