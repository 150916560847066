import React from 'react';
import { SelectInput, TextInput, Create, SimpleForm } from 'react-admin';

const DisabledReasonCreate = () => (
  <Create redirect="show" data-testid="disabled-reason-create">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="display_name" />
      <SelectInput
        choices={['Staff', 'User', 'Both'].map((v) => ({ id: v, name: v }))}
        source="person_type"
      />
    </SimpleForm>
  </Create>
);

export default DisabledReasonCreate;
