import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, NumberField } from 'react-admin';
import IdField from 'components/IdField';

const CredentialRejectedReasonsShow = () => (
  <Show data-testid="Credential-rejected-reasons-show">
    <SimpleShowLayout>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField source="rank" />
    </SimpleShowLayout>
  </Show>
);

CredentialRejectedReasonsShow.query = gql`
  query GET_ONE($id: Int!) {
    credentialRejectedReason(id: $id) {
      id
      name
      label
      rank
    }
  }
`;

export default CredentialRejectedReasonsShow;
