import React from 'react';
import { gql } from '@apollo/client';
import TimestampFields from 'components/TimestampFields';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Labeled,
} from 'react-admin';
import PolymorphicField from 'components/PolymorphicField';
import EnumField from 'components/Enum/EnumField';

const RelationshipsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <EnumField label="Kind" source="kind" />
      <TextField label="Comments" source="comments" />
      <TextField label="Reason Type" source="reason_type" />
      <Labeled label="Reason">
        <PolymorphicField linkType={false} render={(v) => v.label} source="reason" />
      </Labeled>
      <Labeled label="Requester">
        <PolymorphicField source="requester" />
      </Labeled>
      <Labeled label="Requestee">
        <PolymorphicField source="requestee" />
      </Labeled>
      <ArrayField label="Requestee Positions" source="requestee.positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

RelationshipsShow.query = gql`
  query GET_ONE($id: Int!) {
    relationship(id: $id) {
      id
      kind
      requester {
        ... on Location {
          id
        }
        ... on Professional {
          id
        }
      }
      requestee {
        ... on Location {
          id
        }
        ... on Professional {
          id
        }
      }
      reason {
        ... on LocationBlockReason {
          id
          label
        }
        ... on ProfessionalBlockReason {
          id
          label
        }
      }
      reason_type
      comments
      created_at
      updated_at
    }
  }
`;

export default RelationshipsShow;
