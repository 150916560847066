import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const TaxClassificationShow = () => (
  <Show data-testid="tax-classification-show">
    <SimpleShowLayout>
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField source="sequence" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

TaxClassificationShow.query = gql`
  query taxClassification($id: Int!) {
    taxClassification(id: $id) {
      id
      name
      label
      sequence
      created_at
      updated_at
    }
  }
`;

export default TaxClassificationShow;
