import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const ProfessionalTravelAssignmentStatesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="State" source="state.name" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ProfessionalTravelAssignmentStatesShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalTravelAssignmentState(id: $id) {
      id
      state_id
      professional_id
      state {
        id
        name
      }
      created_at
      updated_at
    }
  }
`;

export default ProfessionalTravelAssignmentStatesShow;
