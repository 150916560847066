import { Identifier, useNotify, useRefresh } from 'react-admin';
import { useState } from 'react';
import config from 'config';
import useApi from 'hooks/useApi';
import useUrlDownload from 'hooks/useUrlDownload';

export function useTimesheetDownload() {
  const [downloading, setDownloading] = useState(false);
  const [timecardId, setTimecardId] = useState(null);
  const api = useApi();
  const notify = useNotify();
  const refresh = useRefresh();
  const downloadUrl = useUrlDownload();

  const downloadFile = async (timecardId: Identifier) => {
    setDownloading(true);
    setTimecardId(timecardId);
    try {
      const { data } = await api({
        url: `${config.rails_url}/v3/admin/timecards/${timecardId}/download`,
        method: 'POST',
      });
      downloadUrl({
        url: data.url,
        name: `timecard-${timecardId}-timesheet.${data.extension}`,
      }).then(() => {
        setDownloading(false);
        setTimecardId(null);
        refresh();
      });
    } catch (e: any) {
      notify(e.message, { type: 'error' });
      setDownloading(false);
      setTimecardId(null);
    }
  };
  return { downloadFile, downloading, timecardId };
}
