import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import DateInput from 'components/DateInput';
import ProfessionalInput from 'components/ProfessionalInput';
import StateInput from 'components/StateInput';

const Work_gapsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <StateInput data-testid="professional-work_gap_create_state_field" />
      <TextInput source="location" data-testid="professional-work_gap_create_location_field" />
      <TextInput
        source="explanation"
        multiline
        data-testid="professional-work_gap_create_explanation_field"
      />
      <DateInput source="start_date" />
      <DateInput source="end_date" />
      <BooleanInput
        source="include_in_work_histories"
        data-testid="professional-work_gap_create_include_in_work_histories_field"
      />
    </SimpleForm>
  </Create>
);

export default Work_gapsCreate;
