import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'phone_screening_requests',
  inputShow: gql`
    query phoneScreeningRequests($input: PhoneScreeningRequestQueryInput) {
      phoneScreeningRequests(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Phone Screening Requests',
  },
};
