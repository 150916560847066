import { useGetList } from 'react-admin';

const usePostingFields = (position_ids: number[], forAssignment: boolean) =>
  useGetList('PostingFields', {
    filter: {
      search: {
        position_ids: position_ids,
        ...(forAssignment ? { show_on_assignment: true } : {}),
        ...(!forAssignment ? { show_on_per_diem: true } : {}),
      },
    },
  });

export default usePostingFields;
