import * as React from 'react';
import { ReferenceOneField, SimpleShowLayout } from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';

const SaasFeesTab = () => (
  <SimpleShowLayout>
    <MoneyField label="Per Job Flat Fee Cents" source="saas_per_job_flat_fee_cents" />
    <MoneyField label="Per Seat Monthly Cents" source="saas_per_seat_monthly_cents" />
    <MoneyField label="Saas Base Monthly Cents" source="saas_base_monthly_cents" />
    <ReferenceOneField
      label="Saas Billing Group"
      target="company_id"
      reference="BillingGroup"
      filter={{ saas_primary: true }}
    >
      <LinkField source="name" />
    </ReferenceOneField>
  </SimpleShowLayout>
);

export default React.memo(SaasFeesTab);
