import React from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';

const QualificationAttributeInput = ({ value, onChange, qualificationAttributes }) => (
  <FormControl>
    <Select style={{ width: '100%', marginBottom: '12px' }} value={value} onChange={onChange}>
      {qualificationAttributes.map(({ slug, name }: any) => (
        <MenuItem key={slug} value={slug}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default QualificationAttributeInput;
