import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const LocationPositionsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Position" source="position.display_name" />
    </Datagrid>
  </List>
);

LocationPositionsList.query = gql`
  query GET_LIST($input: LocationPositionQueryInput) {
    _locationPositionsMeta(input: $input) {
      count
    }
    locationPositions(input: $input) {
      id
      position_id
      position {
        id
        display_name
      }
    }
  }
`;

export default LocationPositionsList;
