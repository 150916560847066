import list from './List';
import create from './Create';
import edit from './Edit';
import show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'LocalRateRules',
  inputShow: gql`
    query localRateRules($input: LocalRateRuleQueryInput) {
      localRateRules(input: $input) {
        id
        vms_type_id
        msp_id
        health_system_id
        location_id
        applies_to
        local_okay
        mileage_cutoff
        rate_origin
        offset_amount_cents
        offset_percentage
        __typename
      }
    }
  `,
  list,
  create,
  edit,
  show,
  options: {
    group: 'VMS',
    label: 'Local Rate Rules',
  },
};
