import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';

const UniversalBackgroundChecksFilters = [<IdFilter alwaysOn />, <ProfessionalInput alwaysOn />];

const UniversalBackgroundChecksList = () => (
  <List exporter={false} filters={UniversalBackgroundChecksFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Professional ID" source="professional.id" />
      <TextField label="Order Status" source="order_status" />
      <TextField label="Order Number" source="order_number" />
      <TextField label="Order URL" source="order_url" />
    </Datagrid>
  </List>
);

UniversalBackgroundChecksList.query = gql`
  query GET_LIST($input: UniversalBackgroundCheckQueryInput) {
    _universalBackgroundChecksMeta(input: $input) {
      count
    }
    universalBackgroundChecks(input: $input) {
      id
      professional {
        id
      }
      order_number
      order_status
      order_url
    }
  }
`;

export default UniversalBackgroundChecksList;
