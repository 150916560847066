import React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';

const QualificationsGroupCreate = () => (
  <Create data-testid="qualifications-group-create" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export default QualificationsGroupCreate;
