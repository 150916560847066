import DateTimeField from 'components/DateTimeField';
import LinkField from 'components/LinkField';
import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import MoneyField from './MoneyField';
import List from './List';
import IdField from './IdField';

const displayTime = (assignment) => {
  const timeZone = assignment.primary_location?.timezone_lookup;
  const { format } = new Intl.DateTimeFormat('en-us', {
    timeZone,
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  });
  return `${format(new Date(assignment.starts_time))} - ${format(
    new Date(assignment.ends_time),
  )} (${assignment.number_of_shifts_per_week * assignment.hours_per_shift} hrs/wk)`;
};

export const RecommendedAssignments = ({ assignment_id, professional_id }) => (
  <List
    filter={{
      search: {
        recommended_assignments: {
          id: assignment_id,
          professional_id,
        },
      },
    }}
    resource="Assignment"
    exporter={false}
  >
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Primary Location ID" source="primary_location.id" />
      <DateTimeField format="MM/dd/yyyy" source="starts_date" />
      <DateTimeField format="MM/dd/yyyy" source="ends_date" />
      <ArrayField label="Positions" source="positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <MoneyField source="pay_per_week_cents" label="Weekly Pay" />
      <MoneyField source="one_time_bonus_burden_cents" label="Bonus" />
      <FunctionField label="Shift" render={displayTime} />
      <TextField label="City" source="primary_location.address_city" />
      <TextField label="State" source="primary_location.market.state.abbreviation" />
      <TextField label="Market" source="primary_location.market.display_name" />
    </Datagrid>
  </List>
);
