import React from 'react';
import { gql } from '@apollo/client';
import TimestampFields from 'components/TimestampFields';
import { Show, SimpleShowLayout, TextField, BooleanField, NumberField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';

const CheckInsShow = () => (
  <Show data-testid="check-ins-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Job ID" source="job.id" />
      <TextField label="Professional ID" source="professional.id" />
      <TextField label="Location ID" source="location.id" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="latitude" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="longitude" />
      <TextField source="distance" label="Distance (ft)" />
      <BooleanField source="manual_override" />
      <BooleanField source="accepted" />
      <DateTimeField source="created_at" label="Check In Time" />
      <DateTimeField source="time" label="Professional Submitted Time" />
      <DateTimeField source="facility_adjusted_time" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

CheckInsShow.query = gql`
  query GET_ONE($id: Int!) {
    checkIn(id: $id) {
      id
      job {
        id
      }
      location {
        id
      }
      professional {
        id
      }
      latitude
      longitude
      accepted
      time
      facility_adjusted_time
      distance
      created_at
      updated_at
      manual_override
    }
  }
`;

export default CheckInsShow;
