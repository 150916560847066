import EnumSelectInput from 'components/Enum/EnumSelectInput';
import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const NursysEnrollmentsCreate = () => (
  <Create>
    <SimpleForm>
      <ProfessionalInput />
      <TextInput source="professional_error" />
      <EnumSelectInput source="status" />
    </SimpleForm>
  </Create>
);

export default NursysEnrollmentsCreate;
