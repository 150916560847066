import React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const EmergencyContactCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="phone_number" />
    </SimpleForm>
  </Create>
);

export default EmergencyContactCreate;
