import React from 'react';
import { SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';
import useCheckAccess from 'hooks/useCheckAccess';

const SummaryTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      {checkAccess('view', 'Statement', 'id') && <TextField label="ID" source="id" />}
      {checkAccess('view', 'Statement', 'paid_date') && (
        <DateTimeField label="Paid Date" source="paid_date" />
      )}
      {checkAccess('view', 'Statement', 'pay_period_start') && (
        <DateTimeField label="Pay Period Start" source="pay_period_start" />
      )}
      {checkAccess('view', 'Statement', 'billing_group_id') && (
        <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      )}
      {checkAccess('view', 'Statement', 'total_cents') && (
        <MoneyField label="Total Cents" source="total_cents" />
      )}
      {checkAccess('view', 'Statement', 'pay_period_end') && (
        <DateTimeField label="Pay Period End" source="pay_period_end" />
      )}
      {checkAccess('view', 'Statement', 'close_time') && (
        <DateTimeField label="Close Time" source="close_time" format="MM/dd/yyyy" />
      )}
      {checkAccess('view', 'Statement', 'due_date') && (
        <DateTimeField label="Due Date" source="due_date" format="MM/dd/yyyy" />
      )}
      {checkAccess('view', 'Statement', 'status') && <EnumField label="Status" source="status" />}
      <TimestampFields />
    </SimpleShowLayout>
  );
};

export default React.memo(SummaryTab);
