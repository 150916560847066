import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Edit, SimpleForm, AutocompleteInput, NumberInput, TextInput } from 'react-admin';
import InputUtils from 'utils/input';

const Credit_notesEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ReferenceInput reference="Statement" source="statement_id">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <TextInput source="stripe_invoice_item_id" />
      <NumberInput source="amount_cents" />
      <TextInput source="memo" />
    </SimpleForm>
  </Edit>
);

export default Credit_notesEdit;
