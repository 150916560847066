import React from 'react';
import { gql } from '@apollo/client';
import { BooleanField, Datagrid, TextField, NumberField, DateField } from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';
import JobInput from 'components/JobInput';
import DateFilter from 'components/Filter/DateFilter';

const CheckInFilters = [
  <IdFilter />,
  <ProfessionalInput alwaysOn />,
  <LocationInput alwaysOn />,
  <JobInput alwaysOn />,
  <DateFilter label="Date From" source="search.date_from" alwaysOn />,
  <DateFilter label="Date To" source="search.date_to" alwaysOn />,
];

const Check_insList = () => (
  <List filters={CheckInFilters} exporter={false} data-testid="check-ins-list">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Job ID" source="job_id" />
      <LinkField label="Professional ID" source="professional_id" />
      <LinkField label="Location ID" source="location_id" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="latitude" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="longitude" />
      <BooleanField source="manual_override" />
      <BooleanField source="accepted" />
      <DateField showTime options={{ hour12: false }} source="time" />
      <TextField source="distance" />
    </Datagrid>
  </List>
);

Check_insList.query = gql`
  query GET_LIST($input: CheckInQueryInput) {
    _checkInsMeta(input: $input) {
      count
    }
    checkIns(input: $input) {
      id
      job_id
      location_id
      professional_id
      latitude
      longitude
      accepted
      time
      distance
      manual_override
    }
  }
`;

export default Check_insList;
