import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';

export default {
  name: 'AssignmentLocations',
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Assignment Locations',
  },
};
