import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const DisabledReasonList = () => (
  <List exporter={false} filters={[<IdInput />]} data-testid="disabled-reason-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField label="Display Name" source="display_name" />
    </Datagrid>
  </List>
);

DisabledReasonList.query = gql`
  query disabledReasons($input: DisabledReasonQueryInput) {
    _disabledReasonsMeta(input: $input) {
      count
    }
    disabledReasons(input: $input) {
      id
      name
      display_name
    }
  }
`;

export default DisabledReasonList;
