import { gql } from '@apollo/client';
import list from './List';
import edit from './Edit';
import show from './Show';

export default {
  name: 'VmsUsers',
  inputShow: gql`
    query vmsUsers($input: VmsUserQueryInput) {
      vmsUsers(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  options: {
    group: 'VMS',
    label: 'VMS Users',
  },
};
