export const calcPayoutRate = (
  chargeRate: number = 0,
  margin_percent_1099: number | string = 0,
  charge_max_daily_regular_hours: number = 0,
  charge_max_daily_ot_hours: number = 0,
  charge_overtime_multiplier: number = 0,
  payout_max_daily_regular_hours: number = 0,
  payout_max_daily_ot_hours: number = 0,
  payout_overtime_multiplier: number = 0,
) => {
  margin_percent_1099 = Number(margin_percent_1099);
  const chargeHours =
    charge_max_daily_regular_hours + charge_max_daily_ot_hours * charge_overtime_multiplier;
  const payHours =
    payout_max_daily_regular_hours + payout_max_daily_ot_hours * payout_overtime_multiplier;

  if (!payHours) {
    // prevent NaN result since payHours is later used as a denominator.
    return 0;
  }

  const totalPay = chargeRate * chargeHours * (1 - margin_percent_1099);
  return Math.round(totalPay / payHours);
};

export const calc1099Margin = (
  chargeRate: number = 0,
  payoutRate: number = 0,
  charge_max_daily_regular_hours: number = 0,
  charge_max_daily_ot_hours: number = 0,
  charge_overtime_multiplier: number = 0,
  payout_max_daily_regular_hours: number = 0,
  payout_max_daily_ot_hours: number = 0,
  payout_overtime_multiplier: number = 0,
) => {
  const chargeHours =
    charge_max_daily_regular_hours + charge_max_daily_ot_hours * charge_overtime_multiplier;
  const totalCharge = chargeRate * chargeHours;
  const payHours =
    payout_max_daily_regular_hours + payout_max_daily_ot_hours * payout_overtime_multiplier;
  const totalPay = payoutRate * payHours;
  if (!totalCharge || !totalPay) {
    // prevent NaN result since totalCharge is later used as a denominator.
    return 0;
  }
  return Math.round((1 - totalPay / totalCharge) * 100000) / 100000.0;
};

export const calcTotalPay = (
  chargeRate: number = 0,
  margin_percent_1099: number | string = 0,
  hours: number = 0,
  charge_max_daily_regular_hours: number = 0,
  charge_max_daily_ot_hours: number = 0,
  charge_overtime_multiplier: number = 0,
  payout_max_daily_regular_hours: number = 0,
  payout_max_daily_ot_hours: number = 0,
  payout_overtime_multiplier: number = 0,
) => {
  const payoutRate = calcPayoutRate(
    chargeRate,
    margin_percent_1099,
    charge_max_daily_regular_hours,
    charge_max_daily_ot_hours,
    charge_overtime_multiplier,
    payout_max_daily_regular_hours,
    payout_max_daily_ot_hours,
    payout_overtime_multiplier,
  );
  const regularPay = payoutRate * Math.min(payout_max_daily_regular_hours, hours);
  const otPay =
    Math.max(hours - payout_max_daily_regular_hours, 0) *
    payoutRate *
    Number(payout_overtime_multiplier);
  return regularPay + otPay;
};

export const calcTotalCharge = (
  chargeRate: number = 0,
  hours: number = 0,
  charge_max_daily_regular_hours: number = 0,
  charge_overtime_multiplier: number = 0,
) => {
  const regularCharge = chargeRate * Math.min(charge_max_daily_regular_hours, hours);
  const otCharge =
    Math.max(hours - charge_max_daily_regular_hours, 0) *
    chargeRate *
    Number(charge_overtime_multiplier);
  return regularCharge + otCharge;
};
