import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const ProfessionalPositionsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Position" source="position.display_name" />
    </Datagrid>
  </List>
);

ProfessionalPositionsList.query = gql`
  query GET_LIST($input: ProfessionalPositionQueryInput) {
    _professionalPositionsMeta(input: $input) {
      count
    }
    professionalPositions(input: $input) {
      id
      professional_id
      position_id
      years_of_experience
      completed_onboarding_at
      position {
        id
        display_name
      }
    }
  }
`;

export default ProfessionalPositionsList;
