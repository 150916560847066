import React, { useState } from 'react';
import { ResumeReviewStatus } from '../ApplicationStatusControl';
import { Confirm } from 'react-admin';
import { MenuItem } from '@mui/material';

interface ResumeReviewItemProps {
  updateApplicationStatus: (val: any) => void;
}

const ResumeReviewItem = ({ updateApplicationStatus }: ResumeReviewItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Return to Resume Review</MenuItem>
      <Confirm
        isOpen={open}
        title="Return to Resume Review"
        content="Return this professional's application to Resume Review?"
        onConfirm={() => updateApplicationStatus({ application_status: ResumeReviewStatus })}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ResumeReviewItem;
