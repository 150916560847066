import React, { memo } from 'react';
import { Loading, useInput } from 'react-admin';
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';
import { useTransactions } from './hooks';
import { useFormContext } from 'react-hook-form';

const RefundFormList = ({ billingGroupId }) => {
  const { watch } = useFormContext();
  const type = watch('type');
  const { data, isLoading } = useTransactions({ billingGroupId, type });
  const { field } = useInput({ source: 'transaction' });
  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    return null;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Select</TableCell>
          <TableCell>Transaction Id</TableCell>
          <TableCell>Billing Group ID</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!data.length && (
          <TableRow>
            <TableCell colSpan={4}>No items found for given user</TableCell>
          </TableRow>
        )}
        {data.map((row) => (
          <TableRow key={row.id}>
            <TableCell>
              <Checkbox
                color="primary"
                disabled={!!field.value && field.value.id !== row.id}
                defaultChecked={!!field.value && field.value.id !== row.id}
                onChange={() => {
                  const value = field.value && field.value.id === row.id ? null : row;
                  field.onChange(value);
                }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </TableCell>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.billing_group_id}</TableCell>
            <TableCell>{DisplayHelpers.money(row.amount_left_cents)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(RefundFormList);
