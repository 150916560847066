import React from 'react';
import { Create, SimpleForm, AutocompleteInput } from 'react-admin';
import ReferenceInput from 'components/ReferenceInput';
import PositionInput from 'components/PositionInput';

const LocationTypePositionsCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="location_type_id" reference="LocationType">
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
      <PositionInput />
    </SimpleForm>
  </Create>
);

export default LocationTypePositionsCreate;
