import React, { useRef, useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  IconButton,
  Link,
  CardActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useRefresh, useNotify, useRecordContext } from 'react-admin';
import { DeleteOutline, CloudUploadOutlined } from '@mui/icons-material';
import getAxios from 'getAxios';
import config from 'config';
import _ from 'lodash';

const ResumeField = (props) => {
  const { source } = props;
  const axios = getAxios();
  const record = useRecordContext();
  const url = _.get<string>(record, source.split('.'));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const inputRef = useRef<null | HTMLInputElement>(null);
  const refresh = useRefresh();
  const parseResume = () => {
    setLoading(true);
    axios
      .post(`${config.rails_url}/v3/admin/professionals/${record.id}/resume/parse`)
      .then(() => refresh())
      .catch(setError)
      .finally(() => setLoading(false));
  };
  const deleteFile = () => {
    setLoading(true);
    axios
      .delete(`${config.rails_url}/v3/admin/professionals/${record.id}/resume`)
      .then(() => refresh())
      .catch(setError)
      .finally(() => setLoading(false));
  };
  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = e.target?.files?.[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('professional[resume]', file);
    axios
      .post(`${config.rails_url}/v3/admin/professionals/${record.id}/resume`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => refresh())
      .catch(setError)
      .finally(() => setLoading(false));
  };
  const notify = useNotify();
  useEffect(() => {
    if (error) {
      notify(error.message, { type: 'warning' });
    }
  }, [error, notify]);
  return (
    <Card
      sx={(theme) => ({
        width: 320,
        border: `1px solid ${theme.palette.grey[200]}`,
      })}
      elevation={0}
      data-testid="resume-field"
    >
      <CardHeader
        title="Resume"
        data-testid="resume-field-header"
        subheaderTypographyProps={{
          variant: 'caption',
        }}
        subheader={
          url ? (
            <Link href={url} target="_blank" data-testid="resume-field-download">
              Download
            </Link>
          ) : null
        }
        action={
          loading ? (
            <CircularProgress />
          ) : (
            <IconButton
              onClick={() => (url ? deleteFile() : inputRef.current?.click())}
              color="primary"
              size="large"
            >
              {url ? <DeleteOutline /> : <CloudUploadOutlined />}
            </IconButton>
          )
        }
      />
      <input ref={inputRef} type="file" hidden onChange={uploadFile} />
      <CardActions>
        {url && (
          <Button onClick={parseResume} data-testid="resume-field-parse">
            Parse resume
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default ResumeField;
