import * as R from 'ramda';

export const isEmptyObject = (obj: object | undefined | null): boolean => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

/**
 * Get the value at path of object. If the resolved value is undefined the defaultValue is used in its place.
 * WARING: This function does not support names with dots even though they are valid key names.
 * @param obj object like value
 * @param path string or number path in an array
 * @param defaultValue (optional) value to default to if path is not found or is undefined
 * @returns value at the end of path or defaultValue
 */
export const getObjectPath = <T>(
  obj: object | null | undefined,
  path: string[] | number[] | (string | number)[],
  defaultValue?: T,
): T | undefined => {
  if (isEmptyObject(obj)) {
    return defaultValue;
  }

  const value = R.pathOr(defaultValue, path as any, obj);
  if (value === undefined) {
    return defaultValue;
  }
  return value;
};

export const sanatizeNullishFromObject = <Obj = object>(object: unknown): Obj => {
  const newObject = <Obj>{};
  Object.entries(object).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      newObject[key] = value;
    }
  });
  return newObject;
};
