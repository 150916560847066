import List from './List';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'disputeShiftWitnesses',
  inputShow: gql`
    query disputeShiftWitnesses($input: DisputeShiftWitnessQueryInput) {
      disputeShiftWitnesses(input: $input) {
        id
        dispute_id
        name
        position
        phone
        email
        created_at
        updated_at
        __typename
      }
    }
  `,
  edit: Edit,
  list: List,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Dispute Shift Witnesses',
  },
};
