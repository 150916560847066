import React from 'react';
import { TextInput, NumberInput, Create, SimpleForm, required } from 'react-admin';

const VmsTypeCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="fee_percent" validate={[required()]} />
      <NumberInput source="non_billable_orientation_burden_hours" />
      <NumberInput source="billable_orientation_hours" />
      <TextInput label="Bullhorn ID" source="bullhorn_id" />
    </SimpleForm>
  </Create>
);

export default VmsTypeCreate;
