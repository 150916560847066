import toBase64 from './base64Helper';
export const transform = async (data) => {
  const file = data.file;
  if (file) {
    if (typeof file === 'string') {
      const { b: _, ...rest } = data;
      return rest;
    }
    const { rawFile, title } = file;
    const baseFile = await toBase64(rawFile);
    return {
      ...data,
      filename: title,
      file: baseFile,
    };
  }
  return data;
};
