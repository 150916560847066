import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import config from 'config';
import { useFileUpload } from 'hooks/useFileUpload';

export const ItemField = (props) => {
  const {
    record: { id, has_item_url },
  } = props;
  const { hiddenInput, uploading, openFilePrompt } = useFileUpload({
    url: `${config.item_url}/upload/${id}`,
    accept: undefined,
  });

  return (
    <>
      {hiddenInput}
      <Button
        data-testid="ssn-upload-button"
        disabled={uploading}
        color="primary"
        variant={has_item_url ? 'text' : 'outlined'}
        size="small"
        onClick={openFilePrompt}
        startIcon={
          uploading ? (
            <CircularProgress size={20} />
          ) : has_item_url ? (
            <CheckCircleOutlineIcon />
          ) : (
            <UploadIcon />
          )
        }
      >
        SSN Card
      </Button>
    </>
  );
};
