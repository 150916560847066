import React from 'react';
import { TextInput, NumberInput, Create, SimpleForm } from 'react-admin';

const TaxClassificationCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="sequence" />
    </SimpleForm>
  </Create>
);

export default TaxClassificationCreate;
