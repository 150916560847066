import React from 'react';
import {
  NumberField,
  TextField,
  BooleanField,
  SimpleShowLayout,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

const SummaryTab = () => (
  <SimpleShowLayout>
    <TextField label="ID" source="id" />
    <TextField source="name" />
    <TextField label="Display Name" source="display_name" />
    <TextField source="kind" />
    <NumberField source="sequence" />
    <ArrayField source="licenses">
      <SingleFieldList linkType={false}>
        <ChipField source="label_short" />
      </SingleFieldList>
    </ArrayField>
    <BooleanField source="active" />
    <BooleanField label="Requires Specialty" source="requires_specialty" />
    <TextField label="Qualifications Group Name" source="qualifications_group.name" />
    <ArrayField source="specialties">
      <SingleFieldList linkType={false}>
        <ChipField source="label" />
      </SingleFieldList>
    </ArrayField>
    <NumberField options={{ style: 'percent' }} source="hospital_mark_up" />
    <TextField source="responsibilities" />
    <TextField component="pre" source="requirements_description" />
  </SimpleShowLayout>
);

export default React.memo(SummaryTab);
