import React from 'react';
import { TextInput, Edit, SimpleForm, SelectInput } from 'react-admin';

const DisabledReasonEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="disabled-reason-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="disabled-reason-edit-name" />
      <TextInput source="display_name" data-testid="disabled-reason-edit-display-name" />
      <SelectInput
        choices={['Staff', 'User', 'Both'].map((v) => ({ id: v, name: v }))}
        source="person_type"
        data-testid="disabled-reason-edit-person-type"
      />
    </SimpleForm>
  </Edit>
);

export default DisabledReasonEdit;
