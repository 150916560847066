import React from 'react';
import { Typography } from '@mui/material';
import { useUpdate, useRefresh, useNotify, useRecordContext } from 'react-admin';
import ButtonWithConfirmation from 'components/ButtonWithConfirmation';

const CloseMonthButton = () => {
  const record = useRecordContext();
  const [update, { isLoading }] = useUpdate();

  const refresh = useRefresh();
  const notify = useNotify();
  const closeMonth = async () => {
    try {
      await update('BalanceSheet', {
        id: record.id,
        data: { closed: true },
      });
      notify('Month Closed', { type: 'success' });
      refresh();
    } catch (error) {
      console.error(error);
      notify('Error: Month not closed', { type: 'warning' });
    }
  };

  if (!record || record.closed) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      size="small"
      variant="outlined"
      color="primary"
      onClick={closeMonth}
      loading={isLoading}
      confirmationTitle="Close Month"
      confirmationContent={
        <Typography>Are you sure you want to close this month {record.id}?</Typography>
      }
    >
      Close Month
    </ButtonWithConfirmation>
  );
};

export default CloseMonthButton;
