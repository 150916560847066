import React from 'react';
import { Collapse } from '@mui/material';
import { getDisplayableAttributes } from './payloadUtils';
import { useGetManyReference } from 'ra-core';

const DisplayQualificationAttributes = ({ andCriteria, isExpanded, selectedQualification }) => {
  const { data: qualificationAttributes = [] } = useGetManyReference(
    'QualificationAttribute',
    {
      target: 'qualification_id',
      id: selectedQualification?.id,
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'ASC' },
    },
    {
      enabled: selectedQualification?.id !== undefined,
    },
  );

  return (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      {getDisplayableAttributes(qualificationAttributes, andCriteria).map(
        ([attribute, value], index) => (
          <div key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>{attribute.name}</span>
              <span>{value}</span>
            </div>
          </div>
        ),
      )}
    </Collapse>
  );
};

export default DisplayQualificationAttributes;
