import getAxios from 'getAxios';
import { useNotify } from 'react-admin';

export function useFileDownload({ fileName }: { fileName: string }) {
  const notify = useNotify();
  const axios = getAxios();
  const downloadFile = async (url: string) => {
    try {
      const { data } = await axios.get(url);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      const message = error.response?.data?.message || error.request?.data?.error || error.message;
      notify(message, { type: 'warning' });
      console.error(error);
    }
  };
  return downloadFile;
}
