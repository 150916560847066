import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';

const JobProfileShow = () => (
  <Show>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
      <Tab label="Info">
        <IdField />
        <LinkField label="Job ID" source="job_id" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

JobProfileShow.query = gql`
  query GET_ONE($id: Int!) {
    jobProfile(id: $id) {
      id
      job_id
    }
  }
`;

export default JobProfileShow;
