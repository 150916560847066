import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
import PolymorphicIdInput from 'components/PolymorphicIdInput';
import { ENUMS } from 'components/Enum/enums';

const recipientOptions = Object.entries(ENUMS.Note.recipient).map(([_, value]) => ({
  id: value,
  name: value,
}));

const NotesEdit = () => (
  <Edit mutationMode="pessimistic" data-testid="notes-edit">
    <SimpleForm>
      <NumberInput source="author_id" data-testid="notes-edit-author" />
      <PolymorphicIdInput field="recipient" choices={recipientOptions} />
      <TextInput source="text" data-testid="notes-edit-text" />
    </SimpleForm>
  </Edit>
);

export default NotesEdit;
