import React from 'react';
import {
  SimpleShowLayout,
  NumberField,
  FunctionField,
  useRecordContext,
  Identifier,
} from 'react-admin';
import { Typography, Card, CardContent, Box } from '@mui/material';
import Heading from 'components/Heading';
import MoneyField from 'components/MoneyField';
import { DisplayHelpers } from 'utils/helpers';

const JobAside = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  } else if (!record.job_billing_sums) {
    record.job_billing_sums = {};
  }

  return (
    <Card sx={{ minWidth: 330, marginLeft: '1em' }}>
      <CardContent>
        <SimpleShowLayout record={record}>
          <Heading>Job ID: {record.id}</Heading>
          <FunctionField
            label={
              record.invoice_ids && record.invoice_ids.length > 1 ? 'Invoice IDs' : 'Invoice ID'
            }
            data-testid="job_aside-invoice_id"
            render={(v) => {
              return (
                <span data-testid="link-field">
                  {v.invoice_ids &&
                    v.invoice_ids.map((invoiceId: Identifier, i: number) => [
                      i > 0 && ', ',
                      <a href={`/Invoice/${invoiceId}/show`} key={invoiceId}>
                        {invoiceId}
                      </a>,
                    ])}
                </span>
              );
            }}
          />
          <FunctionField
            label={record.payout_ids && record.payout_ids.length > 1 ? 'Payout IDs' : 'Payout ID'}
            data-testid="job_aside-payout_id"
            render={(v) => {
              return (
                <span data-testid="link-field">
                  {v.payout_ids &&
                    v.payout_ids.map((payoutId: Identifier, i: number) => [
                      i > 0 && ', ',
                      <a href={`/Payout/${payoutId}/show`} key={payoutId}>
                        {payoutId}
                      </a>,
                    ])}
                </span>
              );
            }}
          />
          <Box my={3} />
          <Typography variant="h6" data-testid="job_aside-pay_heading">
            Pay
          </Typography>
          <NumberField
            label="Payout Regular Hours"
            source="job_billing_sums.payout_regular_hours"
            data-testid="job_aside-payout_regular_hours"
          />
          <NumberField
            label="Payout Overtime Hours"
            source="job_billing_sums.payout_overtime_hours"
            data-testid="job_aside-payout_overtime_hours"
          />
          <NumberField
            label="Payout Double Hours"
            source="job_billing_sums.payout_double_hours"
            data-testid="job_aside-payout_double_hours"
          />
          <NumberField
            label="Payout On Call Hours"
            source="job_billing_sums.total_on_call_hours"
            data-testid="job_aside-total_on_call_hours"
          />
          <MoneyField label="Pay Adjustments Total" source="expense_adjustment_total_cents" />
          <MoneyField
            label="Total Hourly pay"
            source="job_billing_sums.payout_total_amount_cents"
          />
          <FunctionField
            label="Total Pay"
            render={(v) => {
              return DisplayHelpers.money(
                v.expense_adjustment_total_cents + v.job_billing_sums.payout_total_amount_cents,
              );
            }}
            data-testid="job_aside-total_pay"
          />
          <Box my={3} />
          <Typography variant="h6" data-testid="job_aside-charge_heading">
            Charge
          </Typography>
          <NumberField
            label="Charge Regular Hours"
            source="job_billing_sums.charge_regular_hours"
            data-testid="job_aside-charge_regular_hours"
          />
          <NumberField
            label="Charge Overtime Hours"
            source="job_billing_sums.charge_overtime_hours"
            data-testid="job_aside-charge_overtime_hours"
          />
          <NumberField
            label="Charge Double Hours"
            source="job_billing_sums.charge_double_hours"
            data-testid="job_aside-charge_double_hours"
          />
          <NumberField
            label="Charge On Call Hours"
            source="job_billing_sums.total_on_call_hours"
            data-testid="job_aside-charge_total_on_call_hours"
          />
          <MoneyField label="Charge Adjustments Total" source="revenue_adjustment_total_cents" />
          <MoneyField
            label="Total Hourly Charge"
            source="job_billing_sums.charge_total_amount_cents"
          />
          <FunctionField
            label="Total Charge"
            render={(v) => {
              return DisplayHelpers.money(
                v.revenue_adjustment_total_cents + v.job_billing_sums.charge_total_amount_cents,
              );
            }}
            data-testid="job_aside-total_charge"
          />
        </SimpleShowLayout>
      </CardContent>
    </Card>
  );
};

export default JobAside;
