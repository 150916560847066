import React from 'react';
import { FunctionField } from 'react-admin';
import _ from 'lodash';

const PercentField = ({ maximumFractionDigits = 2, ...rest }) => (
  <FunctionField
    {...rest}
    render={(v) => {
      const value = _.get<any>(v, rest.source.split('.'));
      return new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits }).format(
        value,
      );
    }}
  />
);

export default PercentField;
