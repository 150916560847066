import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import EnumField from 'components/Enum/EnumField';
import IdField from 'components/IdField';

const LocationBlockReasonList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <EnumField source="kind" />
      <TextField source="label" />
      <TextField source="slug" />
      <TextField source="active" />
    </Datagrid>
  </List>
);

LocationBlockReasonList.query = gql`
  query LocationBlockReasons($input: LocationBlockReasonQueryInput) {
    _locationBlockReasonsMeta(input: $input) {
      count
    }
    locationBlockReasons(input: $input) {
      id
      kind
      label
      slug
      active
      created_at
      updated_at
    }
  }
`;

export default LocationBlockReasonList;
