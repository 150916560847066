import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import List from 'components/List';

const ProfessionalTravelAssignmentStatesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional.id" />
      <TextField label="State" source="state.name" />
    </Datagrid>
  </List>
);

ProfessionalTravelAssignmentStatesList.query = gql`
  query GET_LIST($input: ProfessionalTravelAssignmentStateQueryInput) {
    _professionalTravelAssignmentStatesMeta(input: $input) {
      count
    }
    professionalTravelAssignmentStates(input: $input) {
      id
      state_id
      professional_id
      professional {
        id
      }
      state {
        id
        name
      }
    }
  }
`;

export default ProfessionalTravelAssignmentStatesList;
