import { gql } from '@apollo/client';
import List from './List';
import Show from './Show';

export default {
  name: 'itemized_transactions',
  inputShow: gql`
    query itemizedTransactions($input: ItemizedTransactionQueryInput) {
      itemizedTransactions(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Itemized Transactions',
  },
};
