import React from 'react';
import useDialog from 'hooks/useDialog';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { useNotify, useRefresh, SimpleForm, TextInput, SaveButton, Toolbar } from 'react-admin';
import getAxios from 'getAxios';

const axios = getAxios();

const EditVoucherIdButton = ({ invoice }) => {
  const { open, openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSubmit = async ({ voucher_id }) => {
    try {
      const response = await axios.put(`v3/admin/invoices/${invoice.id}`, { voucher_id });
      notify(response?.data?.message ?? 'Success', { type: 'success' });
    } catch (error: any) {
      notify(error?.message ?? 'Error', { type: 'error' });
    } finally {
      refresh();
      closeDialog();
    }
  };

  return (
    <>
      <Button data-testid="edit-voucher-id-button" size="small" onClick={openDialog}>
        Edit Voucher ID
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Edit voucher ID</DialogTitle>
        <SimpleForm
          onSubmit={handleSubmit}
          toolbar={
            <Toolbar sx={{ 'flex-direction': 'row-reverse' }}>
              <SaveButton />
            </Toolbar>
          }
        >
          <TextInput source="voucher_id" label="Voucher ID" />
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default EditVoucherIdButton;
