import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import LinkField from 'components/LinkField';

const BillingGroupsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="LocationBillingGroup"
      target="location_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="billing_group_id" typename="BillingGroup" />
        <TextField label="Name" source="billing_group.name" />
        <TextField label="Status" source="billing_group.status" />
        <BooleanField label="Auto Pay" source="billing_group.auto_pay" />
        <TextField label="Plan" source="billing_group.plan" />
        <TextField label="Billing" source="billing_group.billing" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(BillingGroupsTab);
