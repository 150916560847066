import { gql } from '@apollo/client';
import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';

export default {
  name: 'jobProfiles',
  inputShow: gql`
    query jobProfiles($input: JobProfileQueryInput) {
      jobProfiles(input: $input) {
        id
      }
    }
  `,
  create: Create,
  edit: Edit,
  list: List,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Job Profile',
  },
};
