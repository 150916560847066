import React from 'react';
import { TextField, Datagrid, ReferenceManyField, Pagination, SimpleShowLayout } from 'react-admin';
import LinkField from 'components/LinkField';
import Heading from 'components/Heading';
import IdField from 'components/IdField';

const ReviewsTab = () => (
  <SimpleShowLayout>
    <Heading>Reviews by Professional</Heading>
    <ReferenceManyField
      label=""
      reference="Review"
      target="professional_reviewer_id"
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <LinkField label="Job" resource="Job" source="job.id" />
        <LinkField label="Reviewee" resource="Location" source="job.location.id" />
        <TextField label="Rating" source="rating" />
        <TextField label="Comment" source="comments" />
      </Datagrid>
    </ReferenceManyField>
    <Heading>Professional Reviews</Heading>
    <ReferenceManyField
      label=""
      reference="Review"
      target="professional_reviewee_id"
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <LinkField label="Job" resource="Job" source="job.id" />
        <LinkField label="Reviewee" resource="Location" source="job.location.id" />
        <TextField label="Rating" source="rating" />
        <TextField label="Comment" source="comments" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default ReviewsTab;
