import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import DateTimeField from 'components/DateTimeField';
import { Link as LinkField } from 'react-router-dom';

const ContractsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Contract"
      target="assignment_id"
    >
      <Datagrid bulkActionButtons={false}>
        <DateTimeField label="Signed At" source="signed_at" />
        <TextField label="Contract Type" source="contract_type" />
        <FunctionField
          label="Professional ID"
          render={(v) => {
            return (
              <LinkField to={`/Professional/${v.professional_id}/show`}>
                {v.professional_id}
              </LinkField>
            );
          }}
        />
        <FunctionField
          label="Administrator ID"
          render={(v) => (
            <LinkField to={`/Administrator/${v.administrator_id}/show`}>
              {v.administrator_id}
            </LinkField>
          )}
        />
        <FunctionField
          label="PDF (if available)"
          render={(v) =>
            v.upload && (
              <Button
                component="a"
                href={v.upload}
                download={`${v.assignment_id}_w2_contract_${v.contract_type}_professional_${v.professional_id}.pdf`}
                startIcon={<DownloadIcon />}
              >
                Download Contract
              </Button>
            )
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(ContractsTab);
