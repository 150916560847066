import React from 'react';
import { TextInput, Create, SimpleForm, required } from 'react-admin';

const MspCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput label="MSP Name" source="name" validate={[required()]} />
      <TextInput label="MSP Label" source="label" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default MspCreate;
