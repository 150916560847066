import React from 'react';
import { Edit, SimpleForm, BooleanField } from 'react-admin';

const JobProfileEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <BooleanField source="marketplace" />
    </SimpleForm>
  </Edit>
);

export default JobProfileEdit;
