import useCheckAccess from 'hooks/useCheckAccess';
import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const BillingGroupManagementCompaniesEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        {checkAccess('update', 'BillingGroupManagementCompany', 'name') && (
          <TextInput source="name" />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default BillingGroupManagementCompaniesEdit;
