import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const messages = {
  ...englishMessages,
  // resources: {
  //   BackgroundCheck: {
  //     name: 'Background check |||| Background checks',
  //   },
  // },
};

export const i18nProvider = polyglotI18nProvider((_locale) => messages as any, 'en', {
  allowMissing: true,
});
