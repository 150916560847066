import React from 'react';
import { gql } from '@apollo/client';
import { TextField, DateField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const PhoneScreeningRequestShow = () => (
  <Show data-testid="phone-screening-request-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Assignment Request" source="assignment_request.id" />
      <TextField label="Professional ID" source="professional.id" />
      <TextField source="status" />
      <TextField
        label="Masked Professional Phone Number"
        source="masked_professional_phone_number"
      />
      <TextField label="Masked Interviewer Phone Number" source="masked_interviewer_phone_number" />
      <TextField label="Interviewer" source="interviewer_name" />
      <DateField
        label="Confirmed Phone Screening Time"
        showTime
        options={{ hour12: false }}
        source="confirmed_phone_screening_time"
      />
      <TextField
        label="Masked Professional Phone Number Sid"
        source="masked_professional_phone_number_sid"
      />
      <TextField label="Conference Sid" source="conference_sid" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

PhoneScreeningRequestShow.query = gql`
  query phoneScreeningRequest($id: Int!) {
    phoneScreeningRequest(id: $id) {
      id
      assignment_request_id
      assignment_request {
        id
      }
      professional_id
      professional {
        id
      }
      status
      masked_interviewer_phone_number
      interviewer_name
      masked_professional_phone_number
      confirmed_phone_screening_time
      phone_screening_times
      masked_professional_phone_number_sid
      conference_sid
      created_at
      updated_at
    }
  }
`;

export default PhoneScreeningRequestShow;
