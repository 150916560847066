import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const AccountLocationsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

AccountLocationsShow.query = gql`
  query GET_ONE($id: Int!) {
    accountLocation(id: $id) {
      id
      created_at
      updated_at
    }
  }
`;

export default AccountLocationsShow;
