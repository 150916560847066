import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, BooleanField, BooleanInput, TextInput } from 'react-admin';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import VmsTypeInput from 'components/VmsTypeInput';
import List from 'components/List';

const VmsUsersFilters = [
  <IdFilter />,
  <VmsTypeInput />,
  <TextInput label="VMS User ID" alwaysOn source="vms_external_id" />,
  <BooleanInput label="Needs Review" alwaysOn source="search.updated" />,
];

const VmsUsersList = () => (
  <List filters={VmsUsersFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField label="Bullhorn ID" source="bullhorn_id" />
      <TextField label="Company" source="new_data.name" />
      <TextField label="VMS Type" source="new_data.parentClientCorporation.name" />
      <BooleanField label="Needs Review" source="updated" />
    </Datagrid>
  </List>
);

VmsUsersList.query = gql`
  query GET_LIST($input: VmsUserQueryInput) {
    _vmsUsersMeta(input: $input) {
      count
    }
    vmsUsers(input: $input) {
      id
      vms_external_id
      bullhorn_id
      updated
      new_data
      created_at
      updated_at
    }
  }
`;

export default VmsUsersList;
