import React from 'react';
import { Create, SimpleForm, NumberInput } from 'react-admin';

const Net_termsCreate = () => (
  <Create>
    <SimpleForm>
      <NumberInput source="number_of_days" />
    </SimpleForm>
  </Create>
);

export default Net_termsCreate;
