import React from 'react';
import { TextInput, NumberInput, Edit, SimpleForm } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import InvoiceInput from 'components/InvoiceInput';

const RefundEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="refund-edit">
    <SimpleForm>
      <InvoiceInput />
      <EnumSelectInput source="reason" />
      <TextInput source="status" data-testid="refund-edit-status" />
      <EnumSelectInput source="kind" />
      <NumberInput source="amount_cents" data-testid="refund-edit-amount-cents" />
    </SimpleForm>
  </Edit>
);

export default RefundEdit;
