import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';

const CustomProfessionalProfileEhrSystemShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <LinkField source="professional_profile.id" />
      <TextField source="label" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

CustomProfessionalProfileEhrSystemShow.query = gql`
  query GET_ONE($id: Int!) {
    customProfessionalProfileEhrSystem(id: $id) {
      id
      label
      professional_profile {
        id
      }
      created_at
      updated_at
    }
  }
`;

export default CustomProfessionalProfileEhrSystemShow;
