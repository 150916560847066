import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  Datagrid,
  BooleanField,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';
import LinkField from 'components/LinkField';
import IdInput from 'components/Filter/IdInput';
import ReferenceInput from 'components/ReferenceInput';
import StripeUrlField from 'components/StripeUrlField';
import List from 'components/List';
import IdField from 'components/IdField';

const StripeAccountListFilters = [
  <IdInput />,
  <NumberInput label="Professional ID" alwaysOn source="professional_id" />,
  <BooleanInput label="Active" alwaysOn source="active" />,
  <BooleanInput label="Verified" alwaysOn source="verified" />,
  <ReferenceInput
    source="tax_classification_id"
    reference="TaxClassification"
    sort={{ field: 'sequence', order: 'ASC' }}
    emptyText="All"
    alwaysOn
    label="Tax Classification"
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

const StripeAccountList = () => (
  <List filters={StripeAccountListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional.id" />
      <TextField label="Tax Classification" source="tax_classification.label" />
      <BooleanField label="Verified" source="verified" />
      <BooleanField label="Active" source="active" />
      <StripeUrlField />
    </Datagrid>
  </List>
);

StripeAccountList.query = gql`
  query stripeAccounts($input: StripeAccountQueryInput) {
    _stripeAccountsMeta(input: $input) {
      count
    }
    stripeAccounts(input: $input) {
      id
      tax_classification {
        id
        label
      }
      professional {
        id
      }
      address_city
      address_first
      address_second
      address_zip
      state {
        abbreviation
      }
      coordinates {
        latitude
        longitude
      }
      stripe_id
      verified
      active
    }
  }
`;

export default StripeAccountList;
