import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import { useGetRecordId } from 'react-admin';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import config from 'config';
import { useFileUpload } from 'hooks/useFileUpload';
import { useTimesheetDownload } from 'hooks/useTimesheetDownload';

const UploadTimesheet = () => {
  const timecardId = useGetRecordId();
  const { hiddenInput, uploading, openFilePrompt } = useFileUpload({
    url: `${config.rails_url}/v3/admin/timecards/${timecardId}/upload`,
    accept: 'application/pdf',
    key: 'timesheet_file',
  });
  const { downloadFile, downloading } = useTimesheetDownload();
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {hiddenInput}
      <Button
        disabled={uploading}
        color="primary"
        variant="outlined"
        size="small"
        onClick={openFilePrompt}
        startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
      >
        Upload Timesheet
      </Button>
      <Box ml={2}>
        <Button
          disabled={downloading}
          color="primary"
          variant="outlined"
          size="small"
          startIcon={downloading ? <CircularProgress size={20} /> : <DownloadIcon />}
          onClick={() => downloadFile(timecardId)}
        >
          Download Timesheet
        </Button>
      </Box>
    </Box>
  );
};
export default UploadTimesheet;
