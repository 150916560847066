import React, { useMemo } from 'react';
import { Grow } from '@mui/material';
import Alert, { AlertProps } from '@mui/material/Alert';
import { isValid, parse, parseISO, formatDuration, intervalToDuration } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

interface Props {
  start: string;
  end: string;
  timezone?: string;
}

export default function Duration(props: Props) {
  const { open, ...alertProps } = useMemo(() => getShiftDurationState(props), [props]);
  return (
    <Grow in={open}>
      <Alert {...alertProps} data-testid="duration" />
    </Grow>
  );
}

type ShiftDurationAlert = AlertProps & { open: boolean };

function getShiftDurationState({ start, end, timezone }: Props): ShiftDurationAlert {
  const defaultState: ShiftDurationAlert = {
    open: false,
    severity: 'info',
    children: 'Select time',
  };
  if (!start || !end) {
    return defaultState;
  }
  const startDate = parseTime(start, timezone);
  const endDate = parseTime(end, timezone);
  const duration = intervalToDuration({ start: startDate, end: endDate });
  return {
    open: true,
    severity: duration.days && duration.days > 0 ? 'warning' : 'info',
    children: `${formatDuration(duration, {
      format: ['days', 'hours', 'minutes'],
    })} shift`,
  };
}

function parseTime(str: string, timezone?: string) {
  const d = parse(str, 'yyyy-MM-dd HH:mm:ss', new Date());
  if (isValid(d)) {
    if (timezone) {
      return zonedTimeToUtc(d, timezone);
    }
    return d;
  }
  return parseISO(str);
}
