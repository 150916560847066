import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import inflection from 'inflection';
import React, { useEffect } from 'react';
import { isValid, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { startOfDay, add } from 'date-fns/fp';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { useInput, useTranslate } from 'react-admin';
import _ from 'lodash';

interface DateTimeInputProps {
  label?: string;
  source: string;
  tzSource?: string;
  time_zone?: string;
  record?: string;
  isJobCreate?: boolean;
  showTime?: boolean;
  options?: Record<string, any>;
  validate?: any[];
  minDate?: Date;
  maxDate?: Date;
  helperText?: string;
}

const DateTimeInput = (props: DateTimeInputProps) => {
  const {
    source,
    tzSource,
    record,
    label: _label,
    time_zone,
    isJobCreate,
    minDate,
    maxDate,
    helperText = ' ',
  } = props;
  const {
    field: { value, ...inputProps },
    fieldState,
    isRequired,
  } = useInput(props);
  const translate = useTranslate();

  const timezone: string =
    time_zone || (tzSource && _.get(record, tzSource.split('.'))) || 'America/Los_Angeles';

  const label = _label || getLabel(source, timezone);

  const onChange = (date: any) => {
    if (isValid(date)) {
      const formatted = format(
        date,
        "yyyy-MM-dd'T'HH:mm:ssXX",
        timezone ? { timeZone: timezone } : undefined,
      );
      inputProps.onChange(formatted);
    } else {
      inputProps.onChange(null);
    }
  };

  useEffect(() => {
    const dateValue: Date | null = value ? utcToZonedTime(new Date(value), timezone) : null;
    onChange(dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const initialFocusedDate = _.flowRight(
    add({
      days: 1,
      hours: /end/.test(source) ? 17 : 8,
    }),
    startOfDay,
  )(new Date());

  const setInitialFocusedDate = () => {
    if (isJobCreate) {
      onChange(initialFocusedDate);
    }
  };

  /**
   *
   * @returns Date object with the input date and time preserved for the entered timezone
   */
  const parseOutputFormat = (v) =>
    new Date(new Date(v).toLocaleString('en-US', { timeZone: timezone }));

  const isOutputFormatted = isValid(parseOutputFormat(value));

  const formattedValue = () => {
    if (!value) {
      return null;
    }
    if (isOutputFormatted) {
      return parseOutputFormat(value);
    }
    if (timezone) {
      return _.flowRight<any, any, any>(
        (v) => format(v, "yyyy-MM-dd'T'HH:mm:ssXX", timezone ? { timeZone: timezone } : undefined),
        parseISO,
      )(value);
    }
    return new Date(value);
  };

  return (
    <FormControl margin="dense" data-testid="date-time-input">
      <DateTimePicker
        minDate={minDate}
        maxDate={maxDate}
        {...inputProps} //location has an impact on this...
        label={label}
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        value={formattedValue()}
        onChange={onChange} //Keyboard onChange callback
        onOpen={setInitialFocusedDate}
        ampm={false}
        renderInput={(props) => (
          <FormControl required={isRequired} error={fieldState.isTouched && !!fieldState.error}>
            <TextField variant="filled" {...props} />
            <FormHelperText>
              {fieldState.isTouched && fieldState.error?.message
                ? translate(fieldState.error.message)
                : helperText}
            </FormHelperText>
          </FormControl>
        )}
        inputFormat="MM/dd/yyyy HH:mm"
        mask="__/__/____ __:__"
      />
    </FormControl>
  );
};

export default DateTimeInput;

function getLabel(source: string, tz?: string) {
  const humanized = inflection.humanize(source);
  if (!tz) {
    return humanized;
  }
  return `${humanized} (${format(new Date(), 'zzz', { timeZone: tz })})`;
}
