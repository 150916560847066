import EnumSelectInput from 'components/Enum/EnumSelectInput';
import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const NursysEnrollmentsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput label="Professional Error" source="professional_error" />
      <EnumSelectInput source="status" />
    </SimpleForm>
  </Edit>
);

export default NursysEnrollmentsEdit;
