import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Create, SimpleForm, NumberInput } from 'react-admin';

const ProfessionalAssignmentFavoritesCreate = () => (
  <Create redirect={(_, __, data) => `Professional/${data.professional_id}/show/assignments`}>
    <SimpleForm>
      <ProfessionalInput />
      <NumberInput source="assignment_id" />
    </SimpleForm>
  </Create>
);

export default ProfessionalAssignmentFavoritesCreate;
