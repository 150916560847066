import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Edit, SimpleForm, AutocompleteInput, DateTimeInput, BooleanInput } from 'react-admin';
import PolymorphicInput from 'components/PolymorphicInput';
import InputUtils from 'utils/input';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import JobInput from 'components/JobInput';

const CancellationsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="cancellations-edit">
    <SimpleForm>
      <JobInput />
      <PolymorphicInput
        field="personable"
        choices={[
          { id: 'Location', name: 'Location' },
          { id: 'Professional', name: 'Professional' },
        ]}
      />
      <DateTimeInput source="booked_at" />
      <BooleanInput source="cleared" />
      <EnumSelectInput source="window" />
      <ReferenceInput reference="CancellationReason" source="cancellation_reason_id">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default CancellationsEdit;
