import React from 'react';
import { JsonInput as RAJsonInput } from 'react-admin-json-view';

const JsonInput = ({ source, label = '', rootKey = 'root', ...rest }): JSX.Element => (
  <RAJsonInput
    source={source}
    label={label}
    reactJsonOptions={{
      name: rootKey,
      collapsed: true,
      displayDataTypes: false,
      enableClipboard: false,
    }}
    {...rest}
  />
);

export default JsonInput;
