import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
} from '@mui/material';
import { PhoneScreeningRequest } from 'typings/graphql';
import * as R from 'ramda';
import { ENUMS } from 'components/Enum/enums';
import LinkField from 'components/LinkField';
import { displayInTimezone } from 'utils/time';
import { useRecordContext } from 'ra-core';

type ScreenRequestsFieldProps = {
  record?: Record<string, any> & { assignment_requests?: PhoneScreeningRequest[] };
};

const {
  PhoneScreeningRequest: { status },
} = ENUMS;

const makeStyles = (color) => (theme) => {
  const colors = {
    green: {
      color: theme.palette.getContrastText(theme.palette.success.dark),
      backgroundColor: theme.palette.success.dark,
    },
    red: {
      color: theme.palette.getContrastText(theme.palette.error.dark),
      backgroundColor: theme.palette.error.dark,
    },
    blue: {
      color: theme.palette.getContrastText(theme.palette.info.dark),
      backgroundColor: theme.palette.info.dark,
    },
    gray: {
      color: theme.palette.getContrastText(theme.palette.grey[600]),
      backgroundColor: theme.palette.grey[600],
    },
  };

  return {
    position: 'static',
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(0.5),
    ...theme.typography.caption,
    ...colors[color],
  };
};

const filter = (...statuses) =>
  R.filter((ps: PhoneScreeningRequest) => statuses.includes(ps.status));

const ScreenRequestsField = ({ record: initialRecord }: ScreenRequestsFieldProps) => {
  const contextRecord = useRecordContext();
  const record = initialRecord || contextRecord;
  const screenings = useMemo<PhoneScreeningRequest[]>(
    () => record?.assignment_requests?.map((r) => r.phone_screening_request).filter(Boolean) ?? [],
    [record],
  );
  const [open, setOpened] = useState(false);

  const lengths = useMemo(
    () => ({
      gray: filter(status.pending, status.reschedule_requested)(screenings).length,
      blue: filter(status.completed, status.started)(screenings).length,
      red: filter(status.canceled, status.no_show)(screenings).length,
      green: filter(status.scheduled)(screenings).length,
    }),
    [screenings],
  );

  if (!screenings || screenings.length === 0) {
    return null;
  }
  return (
    <>
      <ButtonBase onClick={() => setOpened(true)}>
        {Object.keys(lengths)
          .filter((color) => lengths[color] > 0)
          .map((color) => (
            <Avatar key={color} sx={makeStyles(color)}>
              {lengths[color]}
            </Avatar>
          ))}
      </ButtonBase>
      <Dialog maxWidth="md" onClose={() => setOpened(false)} open={open}>
        <DialogTitle>Assignment ID: {record?.id} Phone Screenings</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Professional ID</TableCell>
                <TableCell>Primary Location ID</TableCell>
                <TableCell>Phone Screen Date</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {screenings.map((row: PhoneScreeningRequest) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <LinkField
                      to={`/Professional/${row?.assignment_request?.professional_id}/show`}
                    >
                      {row?.assignment_request?.professional_id}
                    </LinkField>
                  </TableCell>
                  <TableCell>
                    <LinkField to={`/Location/${record.primary_location.id}/show`}>
                      {record.primary_location.id}
                    </LinkField>
                  </TableCell>
                  <TableCell>
                    {displayInTimezone(
                      record.primary_location.timezone_lookup,
                      row.confirmed_phone_screening_time,
                    )}
                  </TableCell>
                  <TableCell>{row?.masked_professional_phone_number}</TableCell>
                  <TableCell>{ENUMS.PhoneScreeningRequest.status[row.status]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScreenRequestsField;
