import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberField, Datagrid, NumberInput, SelectInput, FunctionField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import ReviewLink from './ReviewLink';
import IdField from 'components/IdField';
import ProfessionalInput from 'components/ProfessionalInput';
import CompanyInput from 'components/CompanyInput';
import JobInput from 'components/JobInput';

const ReviewListFilters = [
  <IdInput />,
  <ProfessionalInput alwaysOn source="search.professional_id" />,
  <CompanyInput alwaysOn source="search.company_id" />,
  <JobInput alwaysOn />,
  <NumberInput alwaysOn label="Rating" source="rating" />,
  <SelectInput
    alwaysOn
    choices={[
      { id: 'reviews_for_professional', name: 'Reviews for Professional' },
      { id: 'reviews_for_company', name: 'Reviews for Company' },
    ]}
    source="search.reviews_for_type"
    label="Review Type"
  />,
];

const ReviewList = () => (
  <List filters={ReviewListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <FunctionField label="Reviewer" render={({ reviewer }) => ReviewLink(reviewer)} />
      <LinkField label="Location ID" source="job.location.id" />
      <FunctionField label="Reviewee" render={({ reviewee }) => ReviewLink(reviewee)} />
      <LinkField label="Job ID" source="job.id" />
      <NumberField label="Rating" source="rating" />
    </Datagrid>
  </List>
);

ReviewList.query = gql`
  query reviews($input: ReviewQueryInput) {
    _reviewsMeta(input: $input) {
      count
    }
    reviews(input: $input) {
      id
      reviewer {
        id
        account_type
        company {
          id
        }
        professional {
          id
        }
      }
      reviewee {
        id
        account_type
        company {
          id
        }
        professional {
          id
        }
      }
      job {
        id
        location {
          id
        }
      }
      rating
      comments
    }
  }
`;

export default ReviewList;
