import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { red, green } from '@mui/material/colors';
import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { NursysEnrollment } from 'typings/graphql';
import { ENUMS } from 'components/Enum/enums';
import { useRecordContext } from 'ra-core';

const NursysField = ({ source }) => {
  const record = useRecordContext();
  const data = (record?.[source] ?? []) as NursysEnrollment[];
  if (!data.length) {
    return null;
  }

  if (data.length > 1) {
    return <Typography data-testid="nursys_field">Multiple results</Typography>;
  }
  const enrollment = data[0];
  if (enrollment.status === ENUMS.NursysEnrollment.status.clear) {
    return <CheckCircle style={{ color: green[500] }} data-testid="nursys_field" />;
  }

  return (
    <Tooltip title={<Typography>{enrollment.professional_error ?? ''}</Typography>}>
      <RemoveCircle style={{ color: red[500] }} data-testid="nursys_field" />
    </Tooltip>
  );
};

NursysField.defaultProps = {
  label: 'Nursys',
  sortable: false,
  source: 'nursysEnrollments',
};

export default NursysField;
