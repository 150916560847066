import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Edit, SimpleForm, AutocompleteInput, TextInput } from 'react-admin';

const Friendly_id_slugsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="slug" />
      <ReferenceInput reference="sluggables" source="sluggable_id">
        <AutocompleteInput optionText="id" />
      </ReferenceInput>
      <TextInput source="sluggable_type" />
      <TextInput source="scope" />
    </SimpleForm>
  </Edit>
);

export default Friendly_id_slugsEdit;
