import React, { useEffect } from 'react';
import { AutocompleteArrayInput, required } from 'react-admin';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import { useFormContext } from 'react-hook-form';

const AdministratorPermissionGroupsInput = () => {
  const { setValue, watch } = useFormContext();
  const formGroupIds = watch('account.permission_group_ids');
  const accountPermissionGroups = watch('account.permission_groups');

  useEffect(() => {
    const accountPermissionGroupIds = accountPermissionGroups?.map((pg) => pg.id) ?? [];
    if (!formGroupIds?.length && accountPermissionGroupIds.length) {
      setValue('account.permission_group_ids', accountPermissionGroupIds);
    }
  }, [accountPermissionGroups]);

  return (
    <ReferenceArrayInput
      reference="PermissionGroup"
      label="Role(s)"
      perPage={50}
      source="account.permission_group_ids"
      filter={{ account_type: 'administrator' }}
    >
      <AutocompleteArrayInput
        optionText="display_name"
        validate={[required()]}
        data-testid="account-permission_group"
      />
    </ReferenceArrayInput>
  );
};

export default AdministratorPermissionGroupsInput;
