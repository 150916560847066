import React from 'react';
import { gql } from '@apollo/client';
import {
  BooleanField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { ENUMS } from 'components/Enum/enums';

const LocalRateRuleShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <FunctionField
          label="VMS Type ID"
          render={(v) =>
            v.vms_type_id ? (
              <LinkField to={`/VmsType/${v.vms_type_id}/show`}>{v.vms_type_id}</LinkField>
            ) : null
          }
        />
        <ReferenceField label="VMS Type" source="vms_type_id" reference="vms_types">
          <TextField source="label" />
        </ReferenceField>
        <FunctionField
          label="MSP ID"
          render={(v) =>
            v.msp_id ? <LinkField to={`/Msp/${v.msp_id}/show`}>{v.msp_id}</LinkField> : null
          }
        />
        <ReferenceField label="MSP" source="msp_id" reference="msps">
          <TextField source="label" />
        </ReferenceField>
        <FunctionField
          label="Health System ID"
          render={(v) =>
            v.health_system_id ? (
              <LinkField to={`/HealthSystem/${v.health_system_id}/show`}>
                {v.health_system_id}
              </LinkField>
            ) : null
          }
        />
        <ReferenceField label="Health System" source="health_system_id" reference="health_systems">
          <TextField source="label" />
        </ReferenceField>
        <FunctionField
          label="Location ID"
          render={(v) =>
            v.location_id ? (
              <LinkField to={`/Location/${v.location_id}/show`}>{v.location_id}</LinkField>
            ) : null
          }
        />
        <ReferenceField label="Location" source="location_id" reference="locations">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          label="Applies To"
          render={(v) => (v.applies_to ? ENUMS.LocalRateRule.applies_to[v.applies_to] : null)}
        />
        <BooleanField source="local_okay" />
        <NumberField source="mileage_cutoff" />
        <TextField source="rate_origin" />
        <FunctionField
          label="Rate Origin Rule"
          render={(v) => ENUMS.LocalRateRule.rule_name[v.rate_origin]}
        />
        <NumberField source="offset_amount_cents" />
        <NumberField source="offset_percentage" />
        <BooleanField source="force_draft" />
      </SimpleShowLayout>
    </Show>
  );
};

LocalRateRuleShow.query = gql`
  query GET_ONE($id: Int!) {
    localRateRule(id: $id) {
      id
      vms_type_id
      msp_id
      health_system_id
      location_id
      applies_to
      local_okay
      mileage_cutoff
      rate_origin
      offset_amount_cents
      offset_percentage
      force_draft
    }
  }
`;

export default LocalRateRuleShow;
