import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import DateInput from 'components/DateInput';

const AuditsCreate = () => (
  <Create>
    <SimpleForm>
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export default AuditsCreate;
