import React, { useEffect } from 'react';
import DateInput from 'components/DateInput';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { BooleanInput } from 'react-admin';

interface DisableableDateInputProps {
  dateSource: string;
  disableSource: string;
  disableLabel?: string;
  label?: string;
}

const DisableableDateInput = ({
  disableLabel,
  dateSource,
  disableSource,
  label,
}: DisableableDateInputProps) => {
  const { setValue, watch } = useFormContext();
  const isDateDisabled = watch(disableSource);

  useEffect(() => {
    if (isDateDisabled) {
      setValue(dateSource, null, { shouldDirty: true });
    }
  }, [isDateDisabled, setValue, dateSource]);

  return (
    <Box display="flex" alignItems="center" columnGap={2}>
      <DateInput label={label} disabled={isDateDisabled} source={dateSource} />
      <BooleanInput
        label={disableLabel}
        source={disableSource}
        data-testid="date-input-disable-source"
      />
    </Box>
  );
};

export default DisableableDateInput;
