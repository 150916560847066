import React from 'react';
import { gql } from '@apollo/client';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import { IdFilter } from 'components/Filter';
import useCheckAccess from 'hooks/useCheckAccess';
import DisputeInput from 'components/DisputeInput';

const RevenueAdjustmentsFilters = [<IdFilter />, <DisputeInput alwaysOn />];

const RevenueAdjustmentsList = () => {
  const checkAccess = useCheckAccess();

  return (
    <List filters={RevenueAdjustmentsFilters}>
      <Datagrid sx={{ marginTop: 5 }}>
        {checkAccess('view', 'RevenueAdjustment', 'id') && <LinkField label="ID" source="id" />}
        {checkAccess('view', 'RevenueAdjustment', 'amount_cents') && (
          <MoneyField label="Amount" source="amount_cents" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'job_id') && (
          <LinkField label="Job ID" source="job.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'dispute_id') && (
          <LinkField label="Dispute ID" source="dispute_id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'adjustment_type') && (
          <TextField label="Adjustment Type" source="adjustment_type" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'invoice_id') && (
          <LinkField label="Invoice ID" source="invoice.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'balance_sheet_id') && (
          <LinkField label="Balance Sheet ID" source="balance_sheet.id" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'primary_adjustment') && (
          <BooleanField label="Primary Adjustment" source="primary_adjustment" />
        )}
        {checkAccess('view', 'RevenueAdjustment', 'notes') && (
          <TextField label="Notes" source="notes" />
        )}
      </Datagrid>
    </List>
  );
};
RevenueAdjustmentsList.query = gql`
  query GET_LIST($input: RevenueAdjustmentQueryInput) {
    _revenueAdjustmentsMeta(input: $input) {
      count
    }
    revenueAdjustments(input: $input) {
      id
      amount_cents
      adjustment_type
      job {
        id
      }
      balance_sheet {
        id
      }
      notes
      primary_adjustment
      timecard {
        id
      }
      past_month_adjustment
      dispute_id
      invoice {
        id
      }
    }
  }
`;

export default RevenueAdjustmentsList;
