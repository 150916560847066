import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import {
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TextInput,
  Create,
  SimpleForm,
} from 'react-admin';

const QuestionnaireResponseCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <NumberInput source="credential_id" />
      <ProfessionalInput />
      <NumberInput source="questionnaire_id" />
      <ArrayInput source="questions">
        <SimpleFormIterator>
          <TextInput label="question" source="question" />
          <TextInput label="answer" source="answer" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default QuestionnaireResponseCreate;
