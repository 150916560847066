import getAxios from 'getAxios';
import { saveAs } from 'file-saver';
import { useNotify } from 'react-admin';

type DownloadFileParams = {
  url: string;
  filename: string;
};

const useRequestCsv = () => {
  const notify = useNotify();
  const axios = getAxios();

  return ({ url, filename }: DownloadFileParams) =>
    axios
      .get(url, {
        headers: { Accept: 'text/csv' },
        responseType: 'blob',
      })
      .then(({ data }) => {
        saveAs(data, filename);
      })
      .catch((e) => {
        const blob = e.response?.data as Blob;
        if (blob) {
          blob
            .text()
            .then((text) => JSON.parse(text))
            .then((data) => {
              notify(data?.error || e.message, { type: 'warning' });
            });
        } else {
          notify(e.message, { type: 'warning' });
        }
      });
};

export default useRequestCsv;
