import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  BooleanField,
  ArrayField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';

const JobTemplateShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <LinkField label="Location ID" source="location.id" />
      <EnumField source="kind" />
      <ArrayField source="positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField source="specialties">
        <SingleFieldList linkType={false}>
          <ChipField source="label" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Surgery Types" source="surgery_types">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <EnumField label="Patient Population" source="patient_population" />
      <BooleanField label="Covid Care" source="covid_care" />
      <TextField source="detail" />
    </SimpleShowLayout>
  </Show>
);

JobTemplateShow.query = gql`
  query GET_ONE($id: Int!) {
    jobTemplate(id: $id) {
      id
      location {
        id
      }
      positions {
        id
        display_name
      }
      specialties {
        id
        label
      }
      surgery_types {
        id
        display_name
      }
      kind
      patient_population
      covid_care
      detail
    }
  }
`;

export default JobTemplateShow;
