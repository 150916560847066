import List from './List';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'stripe_accounts',
  inputShow: gql`
    query stripeAccounts($input: StripeAccountQueryInput) {
      stripeAccounts(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Stripe Accounts',
  },
};
