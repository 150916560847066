import React from 'react';
import { Create, TabbedForm } from 'react-admin';
import AssignmentFormToolbar from './FormToolbar';
import JobDetailsTab from './formTabs/JobDetailsTab';

const AssignmentsCreate = () => (
  <Create data-testid="assignments-create" redirect="show">
    <TabbedForm
      toolbar={<AssignmentFormToolbar />}
      defaultValues={{
        flexible_starts_date: true,
      }}
    >
      <TabbedForm.Tab label="Job Details">
        <JobDetailsTab />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
);

export default AssignmentsCreate;
