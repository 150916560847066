import React from 'react';
import { TextInput, BooleanInput, Edit, SimpleForm } from 'react-admin';

const ReviewEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="comments" />
      <TextInput source="rating" />
      <BooleanInput source="blocked" />
    </SimpleForm>
  </Edit>
);

export default ReviewEdit;
