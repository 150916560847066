import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import EnumField from 'components/Enum/EnumField';

const CancellationReasonsShow = () => (
  <Show data-testid="cancellation-reasons-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="label" />
      <EnumField source="role" />
      <TextField source="slug" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

CancellationReasonsShow.query = gql`
  query GET_ONE($id: Int!) {
    cancellationReason(id: $id) {
      id
      label
      role
      slug
      created_at
      updated_at
    }
  }
`;

export default CancellationReasonsShow;
