import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { AutocompleteInput, TextInput, DateTimeInput, Create, SimpleForm } from 'react-admin';
import { defaultValuesFromProps } from 'utils/helpers';
import InputUtils from 'utils/input';
import ProfessionalInput from 'components/ProfessionalInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const PhoneScreeningRequestCreate = (props) => {
  const defaultValue = defaultValuesFromProps(props);

  return (
    <Create data-testid="phone-screening-request-create" redirect="show">
      <SimpleForm defaultValue={defaultValue}>
        <ReferenceInput
          label="Assignment request"
          source="assignment_request_id"
          reference="AssignmentRequest"
        >
          <AutocompleteInput optionText={InputUtils.optionText('id')} />
        </ReferenceInput>
        <ProfessionalInput />
        <EnumSelectInput source="status" />
        <TextInput source="phone_number" />
        <TextInput source="facility_contact_phone_number" />
        <TextInput source="phone_number_sid" />
        <TextInput source="conference_sid" />

        <DateTimeInput source="confirmed_phone_screening_time" />
      </SimpleForm>
    </Create>
  );
};

export default PhoneScreeningRequestCreate;
