import React from 'react';
import { getRates, calculateHours } from '../calculations';
import { DisplayHelpers } from 'utils/helpers';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';

const { money, rate } = DisplayHelpers;

const HoursRates = ({
  hours,
  rates,
}: {
  hours: ReturnType<typeof calculateHours>;
  rates: ReturnType<typeof getRates>;
}) => (
  <Table sx={{ width: 'auto ' }} size="small" data-testid="assignments_edit-rates_table">
    <TableBody>
      <TableRow data-testid="assignments_edit-rates_table_regular_row">
        <TableCell data-testid="assignments_edit-rates_table_regular_title">Regular</TableCell>
        <TableCell data-testid="assignments_edit-rates_table_regular_hours">
          {hours.total_regular_hours} hr
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_regular_rate">
          {rate(rates.base_rate)}
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_regular_total">
          {money(rates.base_amount)}
        </TableCell>
      </TableRow>
      <TableRow data-testid="assignments_edit-rates_table_overtime_row">
        <TableCell data-testid="assignments_edit-rates_table_overtime_title">Overtime</TableCell>
        <TableCell data-testid="assignments_edit-rates_table_overtime_hours">
          {hours.total_overtime_hours} hr
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_overtime_rate">
          {rate(rates.overtime_rate)}
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_overtime_total">
          {money(rates.overtime_amount)}
        </TableCell>
      </TableRow>
      <TableRow data-testid="assignments_edit-rates_table_double_row">
        <TableCell data-testid="assignments_edit-rates_table_double_title">Double</TableCell>
        <TableCell data-testid="assignments_edit-rates_table_double_hours">
          {hours.total_double_hours} hr
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_double_rate">
          {rate(rates.double_rate)}
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_double_total">
          {money(rates.double_amount)}
        </TableCell>
      </TableRow>
      <TableRow data-testid="assignments_edit-rates_table_blended_row">
        <TableCell data-testid="assignments_edit-rates_table_blended_title">Blended</TableCell>
        <TableCell data-testid="assignments_edit-rates_table_blended_hours">
          {hours.total_time} hr
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_blended_rate">
          {rate(rates.blended_rate)}
        </TableCell>
        <TableCell data-testid="assignments_edit-rates_table_blended_total">
          {money(rates.total_amount)}
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default HoursRates;
