import React from 'react';
import {
  BooleanInput,
  ArrayInput,
  TextInput,
  SelectInput,
  Create,
  SimpleForm,
  SimpleFormIterator,
} from 'react-admin';

const QuestionnaireCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="label" />
      <ArrayInput source="questions">
        <SimpleFormIterator>
          <TextInput label="question" source="question" />
          <TextInput label="answer" source="answer" />
          <SelectInput
            choices={[
              { id: 'text', name: 'text' },
              { id: 'date', name: 'date' },
              { id: 'datetime', name: 'datetime' },
              { id: 'number', name: 'number' },
              { id: 'select', name: 'select' },
              { id: 'multi-select', name: 'multi-select' },
              { id: 'boolean', name: 'boolean' },
              { id: 'radio', name: 'radio' },
              { id: 'large-text', name: 'large-text' },
            ]}
            label="type"
            source="type"
          />
          <TextInput label="expected_answer" source="expected_answer" />
          <BooleanInput label="required" source="required" />
          <TextInput label="options" source="options" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export default QuestionnaireCreate;
