import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PhoneInput from 'components/PhoneInput';
import AdministratorPermissionGroupsInput from 'resources/administrators/AdministratorPermissionGroupsInput';

const AdministratorsCreate = () => (
  <Create data-testid="administrators-create" redirect="show">
    <SimpleForm>
      <TextInput
        label="Email"
        source="account.email"
        type="email"
        data-testid="administrators-create-email"
      />
      <PhoneInput label="Phone Number" source="account.phone_number" />
      <TextInput
        label="First Name"
        source="account.first_name"
        data-testid="administrators-create-first-name"
      />
      <TextInput
        label="Last Name"
        source="account.last_name"
        data-testid="administrators-create-last-name"
      />
      <AdministratorPermissionGroupsInput />
      <EnumSelectInput source="department" required />
      <BooleanInput source="paused" />
    </SimpleForm>
  </Create>
);

export default AdministratorsCreate;
