interface Params {
  filter: {
    q?: string;
    search?: Record<string, unknown>;
    _meta?: any;
    [k: string]: any;
  };
  pagination?: {
    page?: number;
    perPage?: number;
  };
  sort: {
    order: string;
    field: string;
  };
}
export type VariablesArgs =
  | {
      type: 'GET_MANY';
      resource: string;
      params: Params & { ids: any[] };
    }
  | {
      type: 'GET_MANY_REFERENCE';
      resource: string;
      params: Params & {
        source: 'id';
        target: string;
        id: number;
      };
    };

const getSortField = (sort) => {
  if (sort.field) {
    const fields = sort.field.split('.');
    const field = {};
    if (fields[1]) {
      field[fields[0]] = {
        [fields[1]]: sort.order === 'ASC',
      };
    } else {
      field[fields[0]] = sort.order === 'ASC';
    }
    return field;
  }
  return undefined;
};

export const getListVariables = (args: VariablesArgs) => {
  const {
    params: { sort = {}, filter: { q = '', search = {}, ...restFilter } = {}, pagination = {} },
  } = args;
  delete restFilter._meta;
  const { page, perPage } = pagination;
  const variables = {
    input: {
      orderBy: getSortField(sort),
      filter: restFilter,
      page: perPage ? page : undefined,
      perPage: perPage || undefined,
      search: { ...search, q: q || undefined },
    },
  };
  if (args.type === 'GET_MANY') {
    const { ids } = args.params;
    variables.input.filter = {
      ...variables.input.filter,
      id: ids.map((v) => (typeof v === 'string' ? parseInt(v) : v)),
    };
  }
  if (args.type === 'GET_MANY_REFERENCE') {
    const { target, id } = args.params;
    variables.input.filter = {
      ...variables.input.filter,
      [target]: id,
    };
  }
  return variables;
};
