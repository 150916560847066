import React from 'react';
import {
  ReferenceArrayInput as ReactAdminReferenceArrayInput,
  useResourceContext,
} from 'react-admin';
import { useTheme } from '@mui/material';

const ReferenceArrayInput = (props) => {
  const theme = useTheme();
  const resource = useResourceContext(props);

  return (
    <ReactAdminReferenceArrayInput
      options={{
        suggestionsContainerProps: {
          style: { zIndex: theme.zIndex.tooltip },
        },
      }}
      filter={{
        ...props.filter,
        _input: {
          optionText: props.children.props.optionText,
          gql: props.children.props.gql,
        },
      }}
      sort={{ field: 'id', order: 'ASC' }}
      resource={props.resource ?? resource}
      {...props}
    />
  );
};

export default ReferenceArrayInput;
