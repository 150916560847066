import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';
import SpecialtyInput from 'components/SpecialtyInput';

const ProfessionalSpecialtiesCreate = () => (
  <Create
    redirect={(_resource, _id, data) => `Professional/${data.professional_id}/show/specialties`}
  >
    <SimpleForm>
      <ProfessionalInput />
      <SpecialtyInput />
    </SimpleForm>
  </Create>
);

export default ProfessionalSpecialtiesCreate;
