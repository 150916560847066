import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid, BooleanField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const LocationTypeList = () => (
  <List filters={[<IdInput />]} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="label" />
      <BooleanField source="active" />
      <BooleanField label="Admin Only" source="admin_only" />
    </Datagrid>
  </List>
);

LocationTypeList.query = gql`
  query locationTypes($input: LocationTypeQueryInput) {
    _locationTypesMeta(input: $input) {
      count
    }
    locationTypes(input: $input) {
      id
      label
      active
      admin_only
    }
  }
`;

export default LocationTypeList;
