import React from 'react';
import {
  BooleanInput,
  Edit,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import { validateSlug } from 'utils/custom-validations';
import SelectLabelInputs from './SelectLabelInputs';
import { transformPayload } from './utils';

const PostingFieldEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" transform={transformPayload}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="label" validate={[required()]} />
      <TextInput source="description" validate={[required()]} />
      <TextInput source="slug" validate={[required(), validateSlug]} />
      <SelectInput
        source="field_type"
        choices={[
          { id: 'string', name: 'String' },
          { id: 'boolean', name: 'Boolean' },
          { id: 'number', name: 'Number' },
          { id: 'single_select', name: 'Single Select' },
          { id: 'multi_select', name: 'Multi Select' },
        ]}
        validate={[required()]}
      />
      <BooleanInput source="show_on_per_diem" validate={[required()]} />
      <BooleanInput source="show_on_assignment" validate={[required()]} />
      <BooleanInput source="required" validate={[required()]} />
      <SelectLabelInputs />
    </SimpleForm>
  </Edit>
);

export default PostingFieldEdit;
