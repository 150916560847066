import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  BooleanField,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { Box } from '@mui/material';
import QuickCreateButton from 'components/QuickCreateButton';
import ConditionInput from 'components/ConditionInput';
import IdField from 'components/IdField';

const RequirementsTab = () => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <Box my={3}>
        <QuickCreateButton initialValues={{ position_id: record.id }} resource="Requirement">
          <ConditionInput />
        </QuickCreateButton>
      </Box>

      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="Requirement"
        target="position_id"
      >
        <Datagrid bulkActionButtons={false}>
          <IdField />
          <BooleanField label="Medely" source="medely" />
          <TextField label="Condition" source="condition.label" />
          <TextField label="State" source="state.abbreviation" />
          <TextField label="Location" source="location.name" />
          <TextField label="VMS" source="lvms_type.name" />
          <FunctionField
            render={(record) => (
              <DeleteWithConfirmButton redirect={`/Position/${record.id}/show/certifications`} />
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default RequirementsTab;
