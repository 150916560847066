import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, EditButton, TopToolbar } from 'react-admin';
import useCheckAccess from 'hooks/useCheckAccess';

const ShowActions = () => {
  const checkAccess = useCheckAccess();
  const canUpdate = checkAccess('update', 'Condition');
  if (!canUpdate) {
    return null;
  }

  return (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  );
};

const ConditionShow = () => (
  <Show actions={<ShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="slug" />
      <TextField source="condition_type" />
      <TextField source="payload" />
      <TextField source="display_category" />
    </SimpleShowLayout>
  </Show>
);

ConditionShow.query = gql`
  query GET_ONE($id: Int!) {
    condition(id: $id) {
      id
      label
      name
      slug
      condition_type
      payload
      display_category
    }
  }
`;

export default ConditionShow;
