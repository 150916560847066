import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material';
import { ENUMS } from 'components/Enum/enums';
import { capitalize } from 'inflection';

const ReviewLink = (props) => {
  const account_type = props?.account_type;
  let id;
  if (account_type === ENUMS.Account.account_type.PROFESSIONAL) {
    id = props?.professional?.id;
  } else if (account_type === ENUMS.Account.account_type.FACILITY) {
    id = props?.company?.id;
  } else {
    id = props?.administrator?.id;
  }

  if (!id) {
    return null;
  }

  return (
    <Link component={RRLink} to={`/${account_type}/${id}/show`}>
      <Typography>
        {capitalize(account_type)}: {id}
      </Typography>
    </Link>
  );
};

export default ReviewLink;
