import React, { memo, useState } from 'react';
import { SimpleForm, useNotify, useRefresh } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import RefundFormType from './RefundFormType';
import RefundFormList from './RefundFormList';
import RefundFormSummary from './RefundFormSummary';
import { useCreateRefund } from './hooks';
import RefundFormToolbar from './RefundFormToolbar';
import RefundFormTitle from './RefundFormTitle';

type RefundFormStep = 'type' | 'list' | 'summary';

const formFlow: RefundFormStep[] = ['type', 'list', 'summary'];
const defaultValues = {
  type: undefined,
  amount: undefined,
  transaction: undefined,
};

const RefundForm = ({ billingGroup, closeDialog }) => {
  const createRefund = useCreateRefund();
  const notify = useNotify();
  const refresh = useRefresh();
  const [step, setStep] = useState<RefundFormStep>('type');

  const validate = (values) => {
    if (step === 'type' && !values.type) {
      return { type: 'Required' };
    }
    if (step === 'list' && !values.transaction) {
      return { transaction: 'Required' };
    }
    if (step === 'summary') {
      if (values.amount === null) {
        return { amount: 'Required' };
      }
      const maxBalance = Math.min(values.transaction.amount_left_cents, billingGroup.balance_cents);
      if (values.amount > maxBalance) {
        return {
          amount: `Amount can't be more than ${DisplayHelpers.money(maxBalance)}`,
        };
      }
    }
    return {};
  };

  const onCancel = () => {
    setStep('type');
    closeDialog();
  };

  const handleSubmit = async (values) => {
    const idx = formFlow.indexOf(step);
    if (idx === formFlow.length - 1) {
      try {
        await createRefund({
          amount: values.amount,
          transactionId: values.transaction.id,
          billingGroupId: billingGroup.id,
        });
        notify('Refund has been successfully created', { type: 'success' });
        refresh();
        onCancel();
      } catch (e) {
        // @ts-ignore
        notify(e.message, { type: 'error' });
      }
    } else {
      setStep(formFlow[idx + 1]);
    }
  };

  return (
    <SimpleForm
      toolbar={<RefundFormToolbar onCancel={onCancel} isLastStep={step === 'summary'} />}
      validate={validate}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      sx={{ margin: '0 1rem' }}
    >
      <RefundFormTitle step={step} />
      {step === 'type' && <RefundFormType />}
      {step === 'list' && <RefundFormList billingGroupId={billingGroup.id} />}
      {step === 'summary' && <RefundFormSummary billingGroup={billingGroup} />}
    </SimpleForm>
  );
};

export default memo(RefundForm);
