import React from 'react';
import { gql } from '@apollo/client';
import { DeleteWithConfirmButton, Show, SimpleShowLayout, TextField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';
import Box from '@mui/material/Box';
import { currentTimeZone } from 'utils/time';

const PositionPostingFieldsShow = () => (
  <Show>
    <SimpleShowLayout>
      <IdField />
      <TextField source="posting_field.label" label="Posting Field" />
      <TextField source="position.display_name" label="Position" />
      <DateTimeField source="created_at" timeZone={currentTimeZone()} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DeleteWithConfirmButton confirmContent="Are you sure you want to delete?" />
      </Box>
    </SimpleShowLayout>
  </Show>
);

PositionPostingFieldsShow.query = gql`
  query GET_ONE($id: Int!) {
    positionPostingField(id: $id) {
      id
      position_id
      position {
        id
        display_name
      }
      posting_field_id
      posting_field {
        id
        label
      }
      created_at
    }
  }
`;

export default PositionPostingFieldsShow;
