import React from 'react';
import { TextInput, required, FormDataConsumer } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { ENUMS } from 'components/Enum/enums';
import { createConditionalInputWrapper } from 'hoc/createConditionalInputWrapper';
import { isCustomOtherReason, isOtherReason, rejectionReasons } from './utils';

const HideIfNonRejected = createConditionalInputWrapper(
  (formData) => formData.status === ENUMS.AssignmentRequest.status.rejected,
);

const HideIfNonOtherRejectedReason = createConditionalInputWrapper(
  (formData) =>
    isOtherReason(formData.rejection_reason) || isCustomOtherReason(formData.rejection_reason),
);

const RejectionReasonField = () => (
  <HideIfNonRejected>
    <EnumSelectInput
      format={(currentValue) => {
        const value =
          isOtherReason(currentValue) || isCustomOtherReason(currentValue)
            ? rejectionReasons.Other
            : currentValue;
        return value;
      }}
      validate={[required()]}
      label="Rejection Reason"
      source="rejection_reason"
      sort={false}
    />
    <HideIfNonOtherRejectedReason>
      <FormDataConsumer>
        {({ formData }) => {
          const value = isCustomOtherReason(formData.rejection_reason)
            ? formData.rejection_reason
            : '';
          return (
            <TextInput
              defaultValue={value}
              validate={[required()]}
              source="other_rejection_reason"
            />
          );
        }}
      </FormDataConsumer>
    </HideIfNonOtherRejectedReason>
  </HideIfNonRejected>
);

export default RejectionReasonField;
