import list from './List';
import { gql } from '@apollo/client';
import show from './Show';

export default {
  name: 'Requirements',
  inputShow: gql`
    query Requirements($input: RequirementQueryInput) {
      requirements(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  options: {
    group: 'Qualifications',
    label: 'Requirements',
  },
};
