import { Box } from '@mui/material';
import React from 'react';
import { BooleanInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';

type Props = {
  requester?: 'Location' | 'Professional';
};

const MassBlockInputs = ({ requester }: Props) => {
  const { watch } = useFormContext();
  const requesterType = watch('requester_type');
  const relationshipKind = watch('kind');

  if ((requester === 'Location' || requesterType === 'Location') && relationshipKind === 'block') {
    return (
      <Box>
        <BooleanInput
          label="Apply to entire VMS facility list"
          source="apply_to_vms_type"
          name="mass_block[apply_to_vms_type]"
          defaultValue={false}
        />
        <BooleanInput
          label="Apply to entire Health System facility list"
          source="apply_to_health_system"
          name="mass_block[apply_to_health_system]"
          defaultValue={false}
        />
        <BooleanInput
          label="Apply to entire MSP facility list"
          source="apply_to_msp"
          name="mass_block[apply_to_msp]"
          defaultValue={false}
        />
      </Box>
    );
  }

  return null;
};

export default MassBlockInputs;
