import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import PositionInput from 'components/PositionInput';

const PositionPostingFieldsListFilters = [<PositionInput label="Position Name" alwaysOn />];

const PositionPostingFieldsList = () => (
  <List exporter={false} filters={PositionPostingFieldsListFilters} hasCreate>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="position.display_name" label="Position" />
      <TextField source="posting_field.label" label="Posting Field" />
    </Datagrid>
  </List>
);

PositionPostingFieldsList.query = gql`
  query GET_LIST($input: PositionPostingFieldQueryInput) {
    positionPostingFields(input: $input) {
      id
      position_id
      position {
        id
        display_name
      }
      posting_field_id
      posting_field {
        id
        label
      }
    }
    _positionPostingFieldsMeta(input: $input) {
      count
    }
  }
`;

export default PositionPostingFieldsList;
