import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import { SearchFilter } from 'components/Filter';
import IdField from 'components/IdField';

const MspListFilters = [<IdInput />, <SearchFilter />];

const MspList = () => (
  <List filters={MspListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
    </Datagrid>
  </List>
);

MspList.query = gql`
  query msps($input: MspQueryInput) {
    _mspsMeta(input: $input) {
      count
    }
    msps(input: $input) {
      id
      name
      label
    }
  }
`;

export default MspList;
