import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'ProfessionalQualificationAttributes',
  inputShow: gql`
    query ProfessionalQualificationsAttributes(
      $input: ProfessionalQualificationAttributeQueryInput
    ) {
      professionalQualificationAttributes(input: $input) {
        id
        type
        value
        qualification_attribute_id
        professional_qualification_id
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Qualifications',
    label: 'Professional Qualification Attributes',
  },
};
