import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';

const CustomProfessionalProfileEhrSystemList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
    </Datagrid>
  </List>
);

CustomProfessionalProfileEhrSystemList.query = gql`
  query GET_LIST($input: CustomProfessionalProfileEhrSystemQueryInput) {
    _customProfessionalProfileEhrSystemsMeta(input: $input) {
      count
    }
    customProfessionalProfileEhrSystems(input: $input) {
      id
      label
    }
  }
`;

export default CustomProfessionalProfileEhrSystemList;
