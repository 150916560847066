import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, AutocompleteInput } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import PositionInput from 'components/PositionInput';
import ReferenceInput from 'components/ReferenceInput';
import IdField from 'components/IdField';
import List from 'components/List';

const LocationTypePositionsListFilters = [
  <IdInput />,
  <PositionInput alwaysOn />,
  <ReferenceInput
    alwaysOn
    label="Location Type"
    source="location_type_id"
    reference="LocationType"
    sort={{ field: 'label', order: 'ASC' }}
    perPage={50}
    resettable
  >
    <AutocompleteInput optionText="label" />
  </ReferenceInput>,
];

const LocationTypePositionsList = () => (
  <List exporter={false} filters={LocationTypePositionsListFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Location Type" source="location_type.label" />
      <TextField label="Position" source="position.display_name" />
    </Datagrid>
  </List>
);

LocationTypePositionsList.query = gql`
  query GET_LIST($input: LocationTypePositionQueryInput) {
    _locationTypePositionsMeta(input: $input) {
      count
    }
    locationTypePositions(input: $input) {
      id
      location_type {
        id
        label
      }
      position {
        id
        display_name
      }
    }
  }
`;

export default LocationTypePositionsList;
