import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';

const VmsPositionList = () => (
  <List filters={[<IdInput />]} exporter={false} bulkActionButtons={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField label="VMS Type" source="vms_type.label" />
      <LinkField label="Position ID" source="position.id" />
      <TextField label="Position" source="position.display_name" />
      <TextField label="VMS Position ID" source="vms_source_id" />
      <TextField label="VMS Position Label" source="label" />
      <TextField label="VMS Category ID" source="vms_category_id" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

VmsPositionList.query = gql`
  query vmsPositions($input: VmsPositionQueryInput) {
    _vmsPositionsMeta(input: $input) {
      count
    }
    vmsPositions(input: $input) {
      id
      vms_type {
        id
        label
      }
      position {
        id
        display_name
      }
      vms_source_id
      vms_category_id
      label
      active
    }
  }
`;

export default VmsPositionList;
