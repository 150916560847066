import React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';

const QualificationsGroupEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="qualifications-group-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="qualifications-group-edit-name" />
    </SimpleForm>
  </Edit>
);

export default QualificationsGroupEdit;
