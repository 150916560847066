import React, { memo, useState, useCallback } from 'react';
import { Confirm, useNotify, useRefresh } from 'react-admin';
import { Button } from '@mui/material';
import useDialog from 'hooks/useDialog';
import useApi from 'hooks/useApi';

const CapsReportVerify = ({ professionalId }) => {
  const api = useApi();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const { open, openDialog, closeDialog } = useDialog();

  const handleRequest = useCallback(() => {
    const perform = async (id: number) => {
      try {
        setLoading(true);

        await api({
          url: `/admin/staffs/${id}/request_caps_check`,
          method: 'POST',
        });

        closeDialog();
        notify('CAPs check has been successfully requested');
        refresh();
      } catch (error: any) {
        notify(error.message, { type: 'warning' });
      } finally {
        setLoading(false);
      }
    };

    perform(professionalId);
  }, [api, closeDialog, notify, refresh, professionalId]);

  return (
    <>
      <Button size="small" color="primary" onClick={openDialog}>
        Request CAPs Check
      </Button>
      <Confirm
        loading={loading}
        onClose={closeDialog}
        isOpen={open}
        onConfirm={handleRequest}
        content=""
        title="Are you sure you want to request CAPs check?"
      />
    </>
  );
};

export default memo(CapsReportVerify);
