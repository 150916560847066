import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const BillingGroupInput = (props) => (
  <ReferenceInput gql={['id', 'name']} {...props}>
    <AutocompleteInput optionText={(o) => `${o?.id}`} {...getChildrenProps(props)} />
  </ReferenceInput>
);

BillingGroupInput.defaultProps = {
  label: 'Billing Group',
  source: 'billing_group_id',
  reference: 'BillingGroup',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
};

export default BillingGroupInput;
