import React, { useEffect, useCallback } from 'react';
import { useGetManyReference } from 'react-admin';
import * as R from 'ramda';
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  ListSubheader,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ENUMS } from 'components/Enum/enums';
import _ from 'lodash';

type Props = {
  professionalId: number;
  relevantQualificationIds: number[];
};

const ProfileExportSelects = ({ professionalId, relevantQualificationIds }: Props) => {
  const {
    workHistories,
    workGaps,
    educations,
    references,
    professional_qualifications,
    onToggle,
    state,
  } = useProfileExportSelectsController(professionalId, relevantQualificationIds);
  const toggleWorkHistory = onToggle('work_history_ids');
  const toggleWorkGap = onToggle('work_gap_ids');
  const toggleEducation = onToggle('education_ids');
  const toggleReference = onToggle('professional_reference_ids');
  const toggleQualification = onToggle('professional_qualification_ids');

  const questionnaires = professional_qualifications.filter(
    (pq) => pq.qualification?.qualification_type === ENUMS.Qualification.qualification_type.q_and_a,
  );
  const skills = professional_qualifications.filter(
    (pq) =>
      pq.qualification?.qualification_type === ENUMS.Qualification?.qualification_type?.typeform,
  );
  const certifications = professional_qualifications.filter(
    (pq) =>
      pq.qualification?.qualification_type ===
      ENUMS.Qualification?.qualification_type?.certification,
  );
  const documents = professional_qualifications.filter(
    (pq) =>
      pq.qualification?.qualification_type === ENUMS.Qualification?.qualification_type?.document,
  );
  const licenses = professional_qualifications.filter(
    (pq) =>
      pq.qualification?.qualification_type === ENUMS.Qualification?.qualification_type?.license &&
      pq.qualification?.slug !== 'drivers_license',
  );

  if (!state) {
    return null;
  }
  return (
    <List disablePadding>
      {workHistories?.length > 0 && (
        <>
          <ListSubheader>Work Histories</ListSubheader>
          {workHistories
            ?.sort((a, b) => +new Date(b.start_date || '') - +new Date(a.start_date || ''))
            .map((wh) => (
              <ListItem key={wh.id} disablePadding sx={{ paddingLeft: 4 }}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={state.work_history_ids?.includes(wh.id) || false}
                    onChange={(_, checked) => toggleWorkHistory(wh.id, checked)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={wh.organization_name}
                  secondaryTypographyProps={{ component: 'div' } as any}
                  secondary={
                    <>
                      <Typography>Position: {wh.position_title}</Typography>
                      <Typography>Location: {wh.location}</Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
        </>
      )}
      {workGaps?.length > 0 && (
        <>
          <ListSubheader>Work gaps</ListSubheader>
          {workGaps.map((wg) => (
            <ListItem key={wg.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={state.work_gap_ids?.includes(wg.id) || false}
                  onChange={(_, checked) => toggleWorkGap(wg.id, checked)}
                />
              </ListItemIcon>
              <ListItemText primary={wg.explanation} />
            </ListItem>
          ))}
        </>
      )}
      {educations?.length > 0 && (
        <>
          <ListSubheader>Education</ListSubheader>
          {educations.map((edu) => (
            <ListItem key={edu.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={state.education_ids?.includes(edu.id) || false}
                  onChange={(_, checked) => toggleEducation(edu.id, checked)}
                />
              </ListItemIcon>
              <ListItemText
                primary={edu.school_name}
                secondaryTypographyProps={{ component: 'div' } as any}
                secondary={
                  <>
                    <Typography>Degree type: {edu.degree_type}</Typography>
                    <Typography>Field of study: {edu.field_of_study}</Typography>
                    <Typography>State: {edu.location}</Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </>
      )}
      {references?.length > 0 && (
        <>
          <ListSubheader>Professional references</ListSubheader>
          {references.map((r) => (
            <ListItem key={r.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={state.professional_reference_ids?.includes(r.id) || false}
                  onChange={(_, checked) => toggleReference(r.id, checked)}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${r.first_name} ${r.last_name}`}
                secondaryTypographyProps={{ component: 'div' } as any}
                secondary={
                  <>
                    <Typography>Title: {r.title}</Typography>
                    <Typography>Email: {r.email}</Typography>
                    <Typography>Phone: {r.phone_number}</Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </>
      )}
      {skills?.length > 0 && (
        <>
          <ListSubheader>Skills assessment responses</ListSubheader>
          {skills.map((s) => (
            <ListItem key={s.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={(s?.id && state.professional_qualification_ids?.includes(s.id)) || false}
                  onChange={(_, checked) => s?.id && toggleQualification(s.id, checked)}
                />
              </ListItemIcon>
              <ListItemText primary={s.qualification?.long_name} />
            </ListItem>
          ))}
        </>
      )}
      {licenses?.length > 0 && (
        <>
          <ListSubheader>Licenses</ListSubheader>
          {licenses.map((license) => (
            <ListItem key={license.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    (license?.id && state.professional_qualification_ids?.includes(license.id)) ||
                    false
                  }
                  onChange={(_, checked) => license?.id && toggleQualification(license.id, checked)}
                />
              </ListItemIcon>
              <ListItemText primary={license.qualification?.long_name} />
            </ListItem>
          ))}
        </>
      )}
      {certifications?.length > 0 && (
        <>
          <ListSubheader>Certifications</ListSubheader>
          {certifications.map((certification) => (
            <ListItem key={certification.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    (certification?.id &&
                      state.professional_qualification_ids?.includes(certification.id)) ||
                    false
                  }
                  onChange={(_, checked) =>
                    certification?.id && toggleQualification(certification.id, checked)
                  }
                />
              </ListItemIcon>
              <ListItemText primary={certification.qualification?.long_name} />
            </ListItem>
          ))}
        </>
      )}
      {documents?.length > 0 && (
        <>
          <ListSubheader>Documents</ListSubheader>
          {documents.map((document) => (
            <ListItem key={document.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    (document?.id && state.professional_qualification_ids?.includes(document.id)) ||
                    false
                  }
                  onChange={(_, checked) =>
                    document?.id && toggleQualification(document.id, checked)
                  }
                />
              </ListItemIcon>
              <ListItemText primary={document?.qualification?.long_name} />
            </ListItem>
          ))}
        </>
      )}
      {questionnaires?.length > 0 && (
        <>
          <ListSubheader>Questionnaires</ListSubheader>
          {questionnaires.map((q) => (
            <ListItem key={q.id} disablePadding sx={{ paddingLeft: 4 }}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={(q?.id && state.professional_qualification_ids?.includes(q.id)) || false}
                  onChange={(_, checked) => q?.id && toggleQualification(q.id, checked)}
                />
              </ListItemIcon>
              <ListItemText primary={`${q.qualification?.long_name} Questionnaire`} />
            </ListItem>
          ))}
        </>
      )}
    </List>
  );
};

export default ProfileExportSelects;

interface SelectedIds {
  work_history_ids: number[];
  education_ids: number[];
  professional_reference_ids: number[];
  work_gap_ids: number[];
  professional_qualification_ids: number[];
}

const getIds = R.map(_.property('id'));

function useProfileExportSelectsController(
  professionalId: number,
  relevantQualificationIds: number[],
) {
  const { setValue, getValues, watch } = useFormContext();
  const getPqIds = useCallback(() => getValues('professional_qualification_ids'), [getValues]);
  const state = watch();

  const { data: workHistories = [] } = useGetManyReference('WorkHistory', {
    target: 'professional_id',
    id: professionalId,
    pagination: { page: 1, perPage: 60 },
    sort: { field: 'id', order: 'DESC' },
  });
  const { data: workGaps = [] } = useGetManyReference('WorkGap', {
    target: 'professional_id',
    id: professionalId,
    pagination: { page: 1, perPage: 60 },
    sort: { field: 'id', order: 'DESC' },
  });
  const { data: educations = [] } = useGetManyReference('Education', {
    target: 'professional_id',
    id: professionalId,
    pagination: { page: 1, perPage: 60 },
    sort: { field: 'id', order: 'DESC' },
  });
  const { data: references = [] } = useGetManyReference('ProfessionalReference', {
    target: 'professional_id',
    id: professionalId,
    pagination: { page: 1, perPage: 60 },
    sort: { field: 'id', order: 'DESC' },
    filter: { status: ENUMS.ProfessionalReference.status.accepted },
  });
  const { data: professional_qualifications = [] } = useGetManyReference(
    'ProfessionalQualification',
    {
      target: 'professional_id',
      id: professionalId,
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'DESC' },
      filter: { status: ENUMS.ProfessionalQualification.status.approved },
    },
  );

  useEffect(() => {
    if (workHistories?.length === 0) {
      return;
    }
    if (getValues('work_history_ids') === undefined) {
      setValue('work_history_ids', getIds(workHistories));
    }
  }, [workHistories, setValue, getValues]);

  useEffect(() => {
    if (workGaps?.length === 0) {
      return;
    }
    if (getValues('work_gap_ids') === undefined) {
      setValue('work_gap_ids', getIds(workGaps));
    }
  }, [workGaps, setValue, getValues]);

  useEffect(() => {
    if (educations?.length === 0) {
      return;
    }
    if (getValues('education_ids') === undefined) {
      setValue('education_ids', getIds(educations));
    }
  }, [educations, setValue, getValues]);

  useEffect(() => {
    if (references?.length === 0) {
      return;
    }
    if (getValues('professional_reference_ids') === undefined) {
      setValue('professional_reference_ids', getIds(references));
    }
  }, [references, setValue, getValues]);

  useEffect(() => {
    if (professional_qualifications?.length === 0) {
      return;
    }
    let pqIds = getPqIds();

    if (pqIds === undefined) {
      pqIds = getIds(professional_qualifications);
      setValue('professional_qualification_ids', pqIds);
    }

    setValue(
      'professional_qualification_ids',
      pqIds.filter((pq) => {
        const matchedPq = professional_qualifications.find((tbPq) => tbPq.id === pq);

        // If skills assessment, return relevant qualifications for an assignment
        if (matchedPq?.qualification?.qualification_type === 'typeform') {
          return relevantQualificationIds.includes(matchedPq?.qualification?.id);
        }

        return !(
          matchedPq?.qualification?.slug === 'tb_screening' ||
          matchedPq?.qualification?.slug === 'tb_screening_q_and_a'
        );
      }),
    );
  }, [professional_qualifications, setValue, getPqIds, relevantQualificationIds, getValues]);

  const onToggle = useCallback(
    (field: keyof SelectedIds) => (id: number, checked: boolean) => {
      const ids = getValues(field);
      if (!ids) {
        return;
      }
      setValue(field, checked ? [...ids, id] : ids.filter((_id) => _id !== id));
    },
    [setValue, getValues],
  );

  return {
    workHistories,
    workGaps,
    educations,
    references,
    professional_qualifications,
    onToggle,
    state,
  };
}
