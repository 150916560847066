import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const MarketInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput optionText="display_name" {...getChildrenProps(props)} />
  </ReferenceInput>
);

MarketInput.defaultProps = {
  label: 'Market',
  source: 'market_id',
  reference: 'Market',
  emptyValue: null,
  emptyText: 'Clear',
  sort: { field: 'display_name', order: 'ASC' },
  perPage: 20,
};

export default MarketInput;
