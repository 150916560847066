import React from 'react';
import { TextInput, Edit, SimpleForm, required } from 'react-admin';
import StateInput from 'components/StateInput';
import TimezoneInput from 'components/TimezoneInput';

const MarketEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="market-edit">
    <SimpleForm>
      <TextInput
        source="display_name"
        validate={[required()]}
        data-testid="market-edit-display-name"
      />
      <TextInput source="name" validate={[required()]} data-testid="market-edit-name" />
      <TimezoneInput source="timezone" validate={[required()]} />
      <StateInput validate={[required()]} />
    </SimpleForm>
  </Edit>
);

export default MarketEdit;
