import { BooleanInput, Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import LinkField from 'components/LinkField';
import IdInput from 'components/Filter/IdInput';
import AdministratorInput from 'components/Filter/AdministratorInput';

const Filters = [
  <IdInput />,
  <AdministratorInput label="Last Updated By" source="last_updated_by_admin_id" alwaysOn />,
  <BooleanInput
    alwaysOn
    source="search.uploaded_by_saas_professional"
    label="Uploaded by BYOP Professional"
  />,
];

const ProfessionalQualificationsList = () => (
  <List exporter={false} filters={Filters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional_id" />
      <TextField label="Professional Name" source="professional.account.name" />
      <LinkField label="Qualification ID" source="qualification_id" />
      <TextField label="Qualification Name" source="qualification.long_name" />
      <TextField label="Status" source="status" />
    </Datagrid>
  </List>
);

ProfessionalQualificationsList.query = gql`
  query GET_LIST($input: ProfessionalQualificationQueryInput) {
    professionalQualifications(input: $input) {
      id
      status
      qualification_id
      qualification {
        id
        long_name
        qualification_type
        slug
      }
      professional_qualification_attributes {
        id
        type
        value
        state
        qualification_attribute {
          id
          slug
          name
        }
      }
      professional_id
      professional {
        id
        account {
          id
          name
        }
      }
      created_at
      updated_at
    }
    _professionalQualificationsMeta(input: $input) {
      count
    }
  }
`;

export default ProfessionalQualificationsList;
