import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.alert = () => {
  console.log('alert blocked');
};

document.addEventListener(
  'wheel',
  function disableScrollInNumberInputs() {
    if (
      document.activeElement?.nodeName === 'INPUT' &&
      document.activeElement?.getAttribute('type') === 'number'
    ) {
      const activeElement = document.activeElement as any;
      activeElement.blur();
    }
  },
  { passive: false },
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
