import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DeleteWithConfirmButton,
} from 'react-admin';
import RequirementTypeField from './RequirementTypeField';
import TimestampFields from 'components/TimestampFields';

const RequirementShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <RequirementTypeField />
      <TextField source="condition.label" />
      <TextField source="condition.payload" />
      <BooleanField label="Add to booked jobs?" source="add_to_booked_jobs" />
      <BooleanField source="required" />
      <TimestampFields />
      <DeleteWithConfirmButton />
    </SimpleShowLayout>
  </Show>
);

RequirementShow.query = gql`
  query GET_ONE($id: Int!) {
    requirement(id: $id) {
      id
      kind
      medely
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      company_id
      company {
        id
        name
      }
      vms_type {
        id
        name
      }
      state {
        id
        abbreviation
      }
      location_id
      location {
        id
        name
      }
      condition {
        id
        label
        payload
      }
      required
      add_to_booked_jobs
      created_at
      updated_at
    }
  }
`;

export default RequirementShow;
