import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Card, IconButton, Menu, MenuItem, TextField, Autocomplete } from '@mui/material';
import { getQualificationSlug } from './payloadUtils';
import QualificationAttributeDialog from './QualificationAttributeDialog';
import useIsOpen from 'hooks/useIsOpen';
import { isLastIndex } from 'utils/array';
import { Button, useGetList, usePrevious } from 'react-admin';
import DisplayQualificationAttributes from './DisplayQualificationAttributes';

const QualificationInput = ({
  orIndex,
  orCriteria,
  addAndCriteria,
  updateAndCriteria,
  removeAndCriteria,
  updateAndCriteriaAttributes,
  allQualifications,
  setAllQualifications,
}) => {
  const selectedValue = Object.keys(orCriteria?.[0] ?? {})[0];

  const prevSelectedValue = usePrevious(selectedValue);
  const { data: selectedQualifications = [] } = useGetList(
    'Qualification',
    {
      filter: {
        slug: selectedValue,
      },
    },
    {
      enabled: selectedValue !== undefined && prevSelectedValue !== selectedValue,
    },
  );

  const [qualificationSearch, setQualificationSearch] = useState('');
  const { data: qualifications = [] } = useGetList('Qualification', {
    filter: { q: qualificationSearch },
  });

  useEffect(() => {
    setAllQualifications((prevQualifications) => {
      const qualificationsMap = new Map([
        ...prevQualifications.map((qualification) => [qualification.slug, qualification]),
        ...qualifications.map((qualification) => [qualification.slug, qualification]),
        ...selectedQualifications.map((qualification) => [qualification.slug, qualification]),
      ]);

      if (qualificationsMap.size !== prevQualifications.length) {
        return [...qualificationsMap.values()];
      }

      return prevQualifications;
    });
  }, [qualifications, setAllQualifications, selectedQualifications]);

  const { isOpen, handleClose, handleOpen } = useIsOpen();

  const [expandedMap, setExpandedMap] = useState({});
  const [anchorEls, setAnchorEls] = useState<any[]>(
    Array.from({ length: orCriteria.length }, () => null),
  );
  const isMenuOpen = (andIndex) => Boolean(anchorEls[andIndex]);
  const handleOpenMenu = (andIndex, event) => {
    const copyAnchorEls = [...anchorEls];
    copyAnchorEls.splice(andIndex, 1, event.currentTarget);
    setAnchorEls(copyAnchorEls);
  };
  const handleCloseMenu = (andIndex) => {
    const copyAnchorEls = [...anchorEls];
    copyAnchorEls.splice(andIndex, 1, null);
    setAnchorEls(copyAnchorEls);
  };

  const updateQualification = useCallback(
    (andIndex, slug) => {
      if (slug) {
        updateAndCriteria(andIndex, { [slug]: {} });
        handleOpen();
      } else {
        updateAndCriteria(andIndex, {});
      }
    },
    [updateAndCriteria, handleOpen],
  );
  const [selectedIndex, setSelectedIndex] = React.useState<string | undefined>(undefined);
  const getQualification = (andIndex) => {
    const qualificationSlug = getQualificationSlug(orCriteria[andIndex]);
    return allQualifications.find(({ slug }) => slug === qualificationSlug);
  };
  return (
    <>
      {orCriteria.map((andCriteria, andIndex) => {
        const itemKey = `${orIndex}:${andIndex}`;
        return (
          <Fragment key={andIndex}>
            <Card
              style={{
                boxShadow: 'unset',
                marginRight: '20px',
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
              }}
            >
              <Autocomplete
                options={allQualifications}
                onChange={(_e, item: any) => {
                  setSelectedIndex(itemKey);
                  updateQualification(andIndex, item.slug);
                }}
                disableClearable
                value={getQualification(andIndex) ?? null}
                fullWidth
                style={{ width: '240px' }}
                getOptionLabel={(option: any) => option?.long_name ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={qualificationSearch}
                    onChange={({ target }) => setQualificationSearch(target.value)}
                    label="Qualification"
                  />
                )}
              />
              <IconButton
                sx={{ marginBottom: '12px' }}
                onClick={(e) => handleOpenMenu(andIndex, e)}
                size="large"
              >
                ...
              </IconButton>
              <Menu
                anchorEl={anchorEls[andIndex]}
                open={isMenuOpen(andIndex)}
                onClose={() => handleCloseMenu(andIndex)}
              >
                <MenuItem
                  onClick={() => {
                    setExpandedMap((expanded) => ({
                      ...expanded,
                      [itemKey]: !expanded[itemKey],
                    }));
                    handleCloseMenu(andIndex);
                  }}
                >
                  {expandedMap[itemKey] ? 'Hide' : 'Show'} Attributes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelectedIndex(itemKey);
                    handleOpen();
                    handleCloseMenu(andIndex);
                  }}
                >
                  Edit Attributes
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    removeAndCriteria(andIndex);
                    handleCloseMenu(andIndex);
                  }}
                >
                  Remove
                </MenuItem>
              </Menu>
              <DisplayQualificationAttributes
                isExpanded={expandedMap[itemKey]}
                selectedQualification={getQualification(andIndex)}
                andCriteria={orCriteria[andIndex]}
              />
            </Card>
            <QualificationAttributeDialog
              selectedQualification={getQualification(andIndex)}
              andCriteria={andCriteria}
              updateAndCriteriaAttributes={updateAndCriteriaAttributes(andIndex)}
              isOpen={isOpen && selectedIndex === itemKey}
              handleClose={handleClose}
            />
            {!isLastIndex(orCriteria, andIndex) && (
              <div
                style={{
                  padding: '0px 12px',
                  marginTop: '12px',
                  fontSize: '20px',
                  fontWeight: '700',
                }}
              >
                AND
              </div>
            )}
          </Fragment>
        );
      })}
      <Button variant="outlined" label="+ AND" type="button" onClick={() => addAndCriteria([{}])} />
    </>
  );
};

export default QualificationInput;
