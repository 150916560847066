import { gql } from '@apollo/client';
import list from './List';
import create from './Create';
import show from './Show';

export default {
  name: 'location_type_positions',
  inputShow: gql`
    query locationTypePositions($input: LocationTypePositionQueryInput) {
      locationTypePositions(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  create,
  options: {
    group: 'Misc',
    label: 'Location Type Positions',
  },
};
