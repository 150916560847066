import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { RecommendedAssignments } from 'components/RecommendedAssignments';
import useDialog from 'hooks/useDialog';
import React from 'react';

const getIds = (record) => {
  if (record?.__typename === 'Professional') {
    return {
      professional_id: record?.id,
      assignment_id: record?.person?.lead?.lead_assignment_id,
    };
  }
  const professional_id = record?.professional?.id;
  const assignment_id = record?.assignment?.id ?? record?.id;
  return { professional_id, assignment_id };
};

export const RecommendedAssignmentsButton = (props) => {
  const { record, ...buttonProps } = props;
  const { assignment_id, professional_id } = getIds(record);
  const { open, openDialog, closeDialog } = useDialog();
  return (
    <>
      <Button
        size="small"
        color="primary"
        {...buttonProps}
        onClick={openDialog}
        data-testid="professional_show-assignments_tab_recommended_assignments_button"
      >
        Recommended assignments
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        fullWidth
        maxWidth="lg"
        data-testid="professional_show-assignments_tab_recommended_assignments_dialog"
      >
        <DialogTitle>Recommended assignments</DialogTitle>
        <DialogContent>
          <RecommendedAssignments assignment_id={assignment_id} professional_id={professional_id} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            data-testid="professional_show-assignments_tab_recommended_assignments_close_button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
