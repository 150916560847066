import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { SelectInput } from 'react-admin';

const MspInput = (props) => (
  <ReferenceInput {...props}>
    <SelectInput optionText="label" {...getChildrenProps(props)} data-testid="msp-input" />
  </ReferenceInput>
);

MspInput.defaultProps = {
  label: 'MSP',
  alwaysOn: true,
  reference: 'Msp',
  emptyValue: null,
  source: 'msp_id',
  perPage: 60,
  sort: { field: 'label', order: 'ASC' },
};

export default MspInput;
