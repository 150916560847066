import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import List from 'components/List';
import IdField from 'components/IdField';

const CancellationReasonsList = () => (
  <List exporter={false} data-testid="cancellation-reasons-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="label" />
      <EnumField source="role" />
    </Datagrid>
  </List>
);

CancellationReasonsList.query = gql`
  query GET_LIST($input: CancellationReasonQueryInput) {
    _cancellationReasonsMeta(input: $input) {
      count
    }
    cancellationReasons(input: $input) {
      id
      label
      role
    }
  }
`;

export default CancellationReasonsList;
