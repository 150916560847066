import React from 'react';
import {
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
  TextInput,
  useRecordContext,
  useResourceContext,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import { format, parseISO } from 'date-fns/fp';
import QuickCreateButton from 'components/QuickCreateButton';
import IdField from 'components/IdField';
import _ from 'lodash';

const displayText = _.flowRight<any, any, any, any>(
  format('MM/dd/yyyy HH:mm'),
  parseISO,
  _.property('created_at'),
);

const NotesTab = () => {
  const record = useRecordContext();
  const recipient_type = useResourceContext();
  const recipient_id = record.id;

  return (
    <SimpleShowLayout>
      <QuickCreateButton initialValues={{ recipient_type, recipient_id }} resource="Note">
        <TextInput
          fullWidth
          multiline
          source="text"
          data-testid="professional_show-notes_add_note_field"
        />
      </QuickCreateButton>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="Note"
        target="recipient_id"
        filter={{ recipient_type }}
      >
        <Datagrid
          bulkActionButtons={false}
          rowClick="edit"
          data-testid="professional_show-notes_tab_table"
        >
          <IdField />
          <TextField
            label="Author"
            source="author.account.name"
            data-testid="professional_show-notes_author_field"
          />
          <TextField label="Note" source="text" data-testid="professional_show-notes_note_field" />
          <FunctionField
            label="Created At"
            render={displayText}
            data-testid="professional_show-notes_created_at_field"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default NotesTab;
