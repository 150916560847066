import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'ProfessionalQualifications',
  inputShow: gql`
    query ProfessionalQualifications($input: ProfessionalQualificationQueryInput) {
      professionalQualifications(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Qualifications',
    label: 'Professional Qualifications',
  },
};
