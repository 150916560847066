import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'Qualifications',
  inputShow: gql`
    query Qualifications($input: QualificationQueryInput) {
      qualifications(input: $input) {
        id
        long_name
        slug
        qualification_type
        instructions
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Qualifications',
    label: 'Qualifications',
  },
};
