import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { Box } from '@mui/material';
import QuickCreateButton from 'components/QuickCreateButton';
import SpecialtyInput from 'components/SpecialtyInput';

const SpecialtiesTab = () => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <Box my={3}>
        <QuickCreateButton initialValues={{ position_id: record.id }} resource="PositionSpecialty">
          <SpecialtyInput />
        </QuickCreateButton>
      </Box>

      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="PositionSpecialty"
        target="position_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Specialty" source="specialty.id" />
          <TextField label="Display Name" source="specialty.label" />
          <FunctionField
            render={(record) => (
              <DeleteWithConfirmButton redirect={`/Position/${record.id}/show/specialties`} />
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(SpecialtiesTab);
