import React, { useCallback, useEffect, useState } from 'react';
import { Login } from 'react-admin';
import { Box, Card, Button, TextField, FormControl, Collapse } from '@mui/material';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import Alert, { AlertColor } from '@mui/material/Alert';

const LoginPage = () => {
  const [position, setPosition] = useState({});
  const [showForgot, setShowForgot] = useState(false);
  const [resetResponse, setResetResponse] = useState<{ severity: AlertColor; content: string }>();
  const [email, setEmail] = useState('');
  const auth = getAuth();

  const sendResetEmail = useCallback(() => {
    sendPasswordResetEmail(auth, email)
      .then((_) =>
        setResetResponse({ severity: 'success', content: 'Reset was successfully submitted' }),
      )
      .catch((_) =>
        setResetResponse({
          severity: 'error',
          content: 'Reset did not complete successfully, please try again.',
        }),
      );
  }, [auth, email]);
  useEffect(() => {
    const { bottom = 0 } = document.querySelector('form')?.getBoundingClientRect() ?? {};
    setPosition({ top: bottom + 80 });
  }, []);

  const toggleForgotPassword = useCallback(() => {
    setShowForgot(!showForgot);
  }, [showForgot]);
  return (
    <>
      <Login backgroundImage="/background.jpg" />
      <Box
        color="white"
        position="absolute"
        width="100%"
        {...position}
        display="flex"
        justifyContent="center"
      >
        {showForgot ? (
          <Card style={{ width: '20rem' }}>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter email"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></TextField>
              <Button onClick={sendResetEmail}>Send Reset Email</Button>
            </FormControl>
            {
              <Collapse in={resetResponse !== undefined}>
                <Alert severity={resetResponse?.severity}>{resetResponse?.content}</Alert>
              </Collapse>
            }
          </Card>
        ) : (
          <Button size="small" variant="contained" component="a" onClick={toggleForgotPassword}>
            Reset password
          </Button>
        )}
      </Box>
    </>
  );
};

export default LoginPage;
