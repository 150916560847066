import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import MoneyField from 'components/MoneyField';

const Credit_notesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <MoneyField label="amount" source="amount_cents" />
      <TextField source="memo" />
      <TextField source="statement.id" />
      <TextField source="stripe_invoice_item_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

Credit_notesShow.query = gql`
  query GET_ONE($id: Int!) {
    creditNote(id: $id) {
      id
      amount_cents
      memo
      statement {
        id
      }
      stripe_invoice_item_id
      created_at
      updated_at
    }
  }
`;

export default Credit_notesShow;
