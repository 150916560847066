import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';
import InputUtils from 'utils/input';

const LocationInput = (props) => (
  <ReferenceInput gql={['name']} {...props}>
    <AutocompleteInput
      optionText={InputUtils.optionText('id', 'name')}
      {...getChildrenProps(props)}
      data-testid="location-input"
    />
  </ReferenceInput>
);

LocationInput.defaultProps = {
  label: 'Location',
  source: 'location_id',
  reference: 'Location',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
  fullWidth: false,
};

export default LocationInput;
