import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, FileField, FunctionField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const ConditionDocumentsShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <IdField />
        <LinkField label="Condition ID" source="condition_id" />
        <TextField label="name" source="name" />
        <TextField label="category" source="category" />
        <FunctionField
          label="file"
          render={(record) => {
            const url = record.file;
            const startAt = url.lastIndexOf('/') + 1;
            const endAt = url.indexOf('?');
            const title = url.substring(startAt, endAt);
            return <FileField download={true} source="file" title={title} />;
          }}
        />
        <DateTimeField label="Created At" source="created_at" />
        <DateTimeField label="Updated At" source="updated_at" />
      </SimpleShowLayout>
    </Show>
  );
};

ConditionDocumentsShow.query = gql`
  query GET_ONE($id: Int!) {
    conditionDocument(id: $id) {
      id
      condition_id
      category
      name
      file
      created_at
      updated_at
    }
  }
`;

export default ConditionDocumentsShow;
