import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Edit, SimpleForm, AutocompleteInput, TextInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const AttachmentsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <ReferenceInput source="license_field_id" reference="license_fields">
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
      <ProfessionalInput />
      <TextInput source="value" />
    </SimpleForm>
  </Edit>
);

export default AttachmentsEdit;
