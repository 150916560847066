import React from 'react';
import { BooleanField, TextField, Labeled, useRecordContext } from 'react-admin';
import LinkField from 'components/LinkField';

const RequirementTypeField = () => {
  const record = useRecordContext();

  if (record.medely) {
    return (
      <Labeled label="Medely Requirement">
        <BooleanField label="Medely Requirement" source="medely" />
      </Labeled>
    );
  }

  if (record.company_id) {
    return (
      <Labeled label="Company">
        <LinkField label="Company" source="company_id" />
      </Labeled>
    );
  }

  if (record.location_id) {
    return (
      <Labeled label="Location">
        <LinkField label="Location" source="location_id" />
      </Labeled>
    );
  }

  if (record.state) {
    return (
      <Labeled label="State">
        <TextField label="State" source="state.abbreviation" />
      </Labeled>
    );
  }

  if (record.vms_type) {
    return (
      <Labeled label="VMS Type">
        <TextField label="VMS Type" source="vms_type.name" />
      </Labeled>
    );
  }

  return null;
};

export default RequirementTypeField;
