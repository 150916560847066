import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';

const ItemizedTransactionBalance = ({ amount }) => (
  <Typography color={amount < 0 ? 'error' : 'textSecondary'}>
    Transaction balance: {DisplayHelpers.money(amount)}
  </Typography>
);

export default memo(ItemizedTransactionBalance);
