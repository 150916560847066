import React from 'react';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { Edit, SimpleForm } from 'react-admin';
import DateInput from 'components/DateInput';
import useCheckAccess from 'hooks/useCheckAccess';

const StatementsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit mutationMode="pessimistic" redirect="show" data-testid="statements-edit">
      <SimpleForm>
        {checkAccess('update', 'Statement', 'due_date') && (
          <DateInput label="Due Date" source="due_date" />
        )}
        {checkAccess('update', 'Statement', 'status') && (
          <EnumSelectInput label="Status" source="status" />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default StatementsEdit;
