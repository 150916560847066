import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import InputUtils from 'utils/input';
import { Create, SimpleForm, BooleanInput, AutocompleteInput, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import Heading from 'components/Heading';
import RejectionReasonField from './RejectionReasonField';
import ProfessionalInput from 'components/ProfessionalInput';
import { transformPayload } from './utils';

const AssignmentRequestsCreate = () => (
  <Create transform={transformPayload} redirect="show" data-testid="assignment-requests-create">
    <SimpleForm>
      <ReferenceInput label="Assignment ID" source="assignment_id" reference="Assignment">
        <AutocompleteInput optionText={InputUtils.optionText('id')} />
      </ReferenceInput>
      <ProfessionalInput />
      <EnumSelectInput label="Status" source="status" />
      <RejectionReasonField />
      <BooleanInput label="Submitted to VMS" source="submitted_to_vms" />
      <Heading>Export Profile</Heading>
      <TextInput label="Interview Availability" source="interview_availability" />
      <TextInput label="Pro Prev. Worked Here" source="professional_prev_worked_here" />
      <TextInput label="Time Off Request" source="time_off_request" />
      <TextInput label="Note" source="note" fullWidth multiline />
      <EnumSelectInput label="Offer Source" source="offer_source" sort={false} />
      <EnumSelectInput label="Contract Type" source="contract_type" resettable />
    </SimpleForm>
  </Create>
);

export default AssignmentRequestsCreate;
