import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import DateInput from 'components/DateInput';

const AuditsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);

export default AuditsEdit;
