// https://stackoverflow.com/a/60036350/14635495
import React from 'react';
import Chip from '@mui/material/Chip';
import { useRecordContext } from 'ra-core';

const LocaleDateTimeChipField = ({ source }) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const date = new Date(record[source] ?? record);
  const label = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  return (
    <span
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: -8,
        marginBottom: -8,
        paddingBottom: '3%',
        paddingTop: '3%',
      }}
    >
      <Chip key={source} sx={{ margin: 2 }} label={label} onClick={(e) => e.preventDefault()} />
    </span>
  );
};

export default LocaleDateTimeChipField;
