import React from 'react';
import { EditButton, useRecordContext, TopToolbar } from 'react-admin';
import config from 'config';
import CopyLink from 'components/CopyLink';

const JobBoardShowActions = () => {
  const record = useRecordContext();

  return (
    <TopToolbar>
      {record?.active_job && <CopyLink textToCopy={`${config.rails_url}/jb/${record.id}`} />}
      <EditButton />
    </TopToolbar>
  );
};

export default JobBoardShowActions;
