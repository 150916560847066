import * as React from 'react';
import {
  TextField,
  Datagrid,
  DateField,
  FunctionField,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Link } from '@mui/material';
import { Link as RRLink } from 'react-router-dom';
import IdField from 'components/IdField';
import { RecommendedAssignmentsButton } from '../RecommendedAssignmentsButton';

const AssignmentRequestsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      label={false}
      reference="AssignmentRequest"
      pagination={<Pagination />}
      target="professional_id"
    >
      <Datagrid
        bulkActionButtons={false}
        data-testid="professional_show-assignment_requests_tab_table"
      >
        <IdField />
        <TextField
          label="Name"
          source="professional.account.name"
          data-testid="professional_show-assignment_requests_tab_name"
        />
        <FunctionField
          label="Type"
          render={(v) => (v.assignment.is_w2 ? 'W2' : '1099')}
          data-testid="professional_show-assignment_requests_tab_type"
        />
        <TextField
          label="Profile Strength"
          source="professional.profile_strength"
          data-testid="professional_show-assignment_requests_tab_profile_strength"
        />
        <DateField
          label="Applied On"
          source="created_at"
          data-testid="professional_show-assignment_requests_tab_applied_on"
        />
        <FunctionField
          label="Recruiter"
          render={(r) =>
            r.professional &&
            r.professional.recruiter &&
            r.professional.recruiter.account && (
              <Link component={RRLink} to={`/Administrator/${r.professional.recruiter.id}/show`}>
                {r.professional.recruiter.account.name}
              </Link>
            )
          }
          data-testid="professional_show-assignment_requests_tab_recruiter"
        />
        <TextField
          label="Status"
          source="status"
          data-testid="professional_show-assignment_requests_tab_status"
        />
        <LinkField label="Assignment ID" source="assignment_id" />
        <TextField
          label="Health System"
          source="assignment.company.health_system.label"
          data-testid="professional_show-assignment_requests_tab_health_system"
        />
        <DateField
          label="Start Date"
          source="assignment.starts_date"
          data-testid="professional_show-assignment_requests_tab_start_date"
        />
        <TextField
          label="Assignment Status"
          source="assignment.status"
          data-testid="professional_show-assignment_requests_tab_assignment_status"
        />
        <TextField
          label="VMS Profile"
          source="vms_profile_status"
          data-testid="professional_show-assignment_requests_tab_vms_profile"
        />
        <TextField
          label="VMS Application"
          source="vms_application_status"
          data-testid="professional_show-assignment_requests_tab_vms_application"
        />

        {/* TODO: Verify works */}
        <RecommendedAssignmentsButton />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default AssignmentRequestsTab;
