import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, NumberField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const CredentialRejectedReasonsList = () => (
  <List exporter={false} data-testid="Credential-rejected-reasons-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField source="rank" />
    </Datagrid>
  </List>
);

CredentialRejectedReasonsList.query = gql`
  query GET_LIST($input: CredentialRejectedReasonQueryInput) {
    credentialRejectedReasons(input: $input) {
      id
      name
      label
      rank
    }
  }
`;

export default CredentialRejectedReasonsList;
