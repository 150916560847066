import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import MoneyField from 'components/MoneyField';
import StateInput from 'components/StateInput';
import IdField from 'components/IdField';

const StateListFilters = [
  <IdInput />,
  <StateInput source="id" alwaysOn resettable />,
  <BooleanInput source="nlc" label="NLC" alwaysOn />,
];

const StateList = () => (
  <List filters={StateListFilters} exporter={false} data-testid="state-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="abbreviation" />
      <BooleanField source="active" />
      <BooleanField label="NLC" source="nlc" />
      <BooleanField label="Display by Hourly" source="display_by_hourly" />
      <MoneyField label="Minimum Rate Cents" source="minimum_rate_cents" />
    </Datagrid>
  </List>
);

StateList.query = gql`
  query states($input: StateQueryInput) {
    _statesMeta(input: $input) {
      count
    }
    states(input: $input) {
      id
      name
      abbreviation
      rectangle_image
      square_image
      display_by_hourly
      active
      nlc
      minimum_rate_cents
    }
  }
`;

export default StateList;
