import React, { FC, memo } from 'react';

interface ITransactionLinkProps {
  transaction: string;
  kind: string;
}

const TransactionLink: FC<ITransactionLinkProps> = ({ kind, transaction }) => {
  if (!['bank_debit', 'credit', 'stripe_ach'].includes(kind)) {
    return <>{transaction}</>;
  }
  return <a href={`https://dashboard.stripe.com/payments/${transaction}`}>{transaction}</a>;
};

export default memo(TransactionLink);
