import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, Toolbar, Tooltip, Typography } from '@mui/material';
import PdfButton from 'components/PdfButton';
import GetApp from '@mui/icons-material/GetApp';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { RemoveCircleOutline } from '@mui/icons-material';
import UploadItem from './UploadItem';
import { SimpleForm, useNotify } from 'react-admin';
import ProfileExportSelects from 'resources/assignment_requests/profile_export/Selects';
import config from 'config';
import create from 'utils/api/create';

export const ExportProfile = (props) => {
  const { record } = props;

  const notify = useNotify();
  const [allValues, setAllValues] = useState({ open: false, deleting: false });

  const onSubmit = async (values) => {
    try {
      await create(`${config.pdf_url}/professionals/${record.id}/profile`, values);
      notify('Profile saved');
      setAllValues({ ...allValues, open: false });
    } catch (error) {
      notify((error as any).message, { type: 'warning' });
    }
  };

  return (
    <>
      <Tooltip disableHoverListener={record.has_upload_pdf} title="You need to save the profile first">
        <span>
          <PdfButton
            disabled={!record.has_upload_pdf}
            color="primary"
            href={`/professionals/${record?.id}/profile`}
            download={`${String(
              record?.account?.last_name,
            ).toLowerCase()}_professional_${record?.id}.pdf`}
            size="small"
            variant="outlined"
            startIcon={<GetApp />}
          >
            Profile
          </PdfButton>
        </span>
      </Tooltip>
      <Button
        aria-label="export profile"
        variant="outlined"
        onClick={() => setAllValues({ ...allValues, open: true })}
        color="primary"
        size="small"
      >
        Save profile
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={allValues.open}
        onClose={() => setAllValues({ ...allValues, open: false })}
      >
        <DialogTitle>Save travel submission package</DialogTitle>
        <Box mx={3}>
          <Typography>
            {record.has_item ? (
              <CheckCircleOutline fontSize="inherit" color="primary" />
            ) : (
              <RemoveCircleOutline fontSize="inherit" color="error" />
            )}{' '}
            SSN is {!record.has_item ? 'not' : ''} set
          </Typography>
        </Box>
        <SimpleForm
          onSubmit={onSubmit}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button aria-label="export submit" type="submit" variant="contained" color="primary">
                Save
              </Button>
              <Button onClick={() => setAllValues({ ...allValues, open: false })} color="primary">
                Cancel
              </Button>
            </Toolbar>
          }
        >
          <ProfileExportSelects
            professionalId={record.id}
            relevantQualificationIds={[]}
          />
        </SimpleForm>
      </Dialog>
    </>
  );
};
