import * as React from 'react';
import {
  Datagrid,
  ReferenceManyField,
  Pagination,
  NumberField,
  SimpleShowLayout,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';

const JobBillingsTab = ({ target }) => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="JobBilling"
        target={target}
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'JobBilling', 'id') && <LinkField label="Job Billing" source="id" />}
          {checkAccess('view', 'Job', 'job_id') && <LinkField label="Job" source="job_id" />}
          {checkAccess('view', 'JobBilling', 'total_hours') && (
            <NumberField
              label="Total Hours"
              source="total_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'total_on_call_hours') && (
            <NumberField
              label="Total On Call Hours"
              source="total_on_call_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_total_amount_cents') && (
            <MoneyField label="Payout Total Amount Cents" source="payout_total_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'charge_total_amount_cents') && (
            <MoneyField label="Charge Total Amount Cents" source="charge_total_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'payout_regular_hours') && (
            <NumberField
              label="Payout Regular Hours"
              source="payout_regular_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_overtime_hours') && (
            <NumberField
              label="Payout Overtime Hours"
              source="payout_overtime_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_double_hours') && (
            <NumberField
              label="Payout Double Hours"
              source="payout_double_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_regular_hours') && (
            <NumberField
              label="Charge Regular Hours"
              source="charge_regular_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_overtime_hours') && (
            <NumberField
              label="Charge Overtime Hours"
              source="charge_overtime_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_double_hours') && (
            <NumberField
              label="Charge Double Hours"
              source="charge_double_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_overtime_amount_cents') && (
            <MoneyField
              label="Payout Overtime Amounnt Cents"
              source="payout_overtime_amount_cents"
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_overtime_amount_cents') && (
            <MoneyField
              label="Charge Overtime Amount Cents"
              source="charge_overtime_amount_cents"
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_double_amount_cents') && (
            <MoneyField label="Payout Double Amount Cents" source="payout_double_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'charge_double_amount_cents') && (
            <MoneyField label="Charge Double Amount Cents" source="charge_double_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'charge_callback_hours') && (
            <NumberField
              label="Charge Callback Hours"
              source="charge_callback_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_callback_amount_cents') && (
            <MoneyField
              label="Charge Callback Amount Cents"
              source="charge_callback_amount_cents"
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_callback_hours') && (
            <NumberField
              label="Payout Callback Hours"
              source="payout_callback_hours"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_callback_amount_cents') && (
            <MoneyField
              label="Payout Callback Amount Cents"
              source="payout_callback_amount_cents"
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_regular_amount_cents') && (
            <MoneyField label="Payout Regular Amount Cents" source="payout_regular_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'charge_regular_amount_cents') && (
            <MoneyField label="Charge Regular Amount Cents" source="charge_regular_amount_cents" />
          )}
          {checkAccess('view', 'JobBilling', 'total_breaks_minutes') && (
            <NumberField
              label="Total Breaks Minutes"
              source="total_breaks_minutes"
              options={{ maximumFractionDigits: 2 }}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(JobBillingsTab);
