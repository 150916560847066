import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, NumberInput } from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import DateTimeField from 'components/DateTimeField';

const ConditionDocumentsFilters = [
  <IdFilter alwaysOn />,
  <NumberInput label="Condition ID" source="condition_id" alwaysOn />,
];

const ConditionDocumentsList = () => (
  <List exporter={false} filters={ConditionDocumentsFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Condition ID" source="condition_id" />
      <TextField label="category" source="category" />
      <TextField label="name" source="name" />
      <DateTimeField label="Created At" source="created_at" />
      <DateTimeField label="Updated At" source="updated_at" />
    </Datagrid>
  </List>
);

ConditionDocumentsList.query = gql`
  query GET_LIST($input: ConditionDocumentQueryInput) {
    _conditionDocumentsMeta(input: $input) {
      count
    }
    conditionDocuments(input: $input) {
      id
      condition_id
      name
      category
      created_at
      updated_at
    }
  }
`;

export default ConditionDocumentsList;
