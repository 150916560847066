import React from 'react';
import {
  DeleteWithConfirmButton,
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  useGetRecordId,
} from 'react-admin';
import QuickCreateButton from 'components/QuickCreateButton';
import PositionInput from 'components/PositionInput';

const PositionsTab = () => {
  const id = useGetRecordId();

  return (
    <SimpleShowLayout>
      <QuickCreateButton initialValues={{ location_id: id }} resource="LocationPosition">
        <PositionInput />
      </QuickCreateButton>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="LocationPosition"
        target="location_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Position ID" source="position_id" />
          <TextField label="Display Name" source="position.display_name" />
          <DeleteWithConfirmButton redirect={`/Location/${id}/show/location_positions`} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(PositionsTab);
