import React from 'react';
import { useRecordContext } from 'react-admin';

export const ProfessionalTitle = () => {
  const record = useRecordContext();

  return (
    <span>
      Professional #{record?.id} {record?.account?.first_name} {record?.account?.last_name}
    </span>
  );
};
