import React from 'react';
import { Layout as ReactAdminLayout, AppBar } from 'react-admin';
import { Box, Typography } from '@mui/material';
import Menu from './Menu';
import { Error } from './Error';
import { AppLocationContext } from '@react-admin/ra-navigation';
import { ToggleThemeButton } from './ToggleThemeButton';

const MyAppBar = (props) => (
  <AppBar color="primary" {...props} data-testid="my-app-bar">
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title" />
    </Box>
    <ToggleThemeButton />
  </AppBar>
);

const Layout = (props) => (
  <AppLocationContext>
    <Box
      sx={{
        '.RaLayout-content': {
          width: 'calc(100vw - 300px)',
          '.RaList-main': {
            overflow: 'scroll',
          },
          '.RaDatagrid-root': {
            overflow: 'scroll',
          },
        },
      }}
    >
      <ReactAdminLayout {...props} appBar={MyAppBar} menu={Menu} error={Error} />
    </Box>
  </AppLocationContext>
);

export default Layout;
