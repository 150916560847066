import React from 'react';
import { Edit, SimpleForm, NumberInput } from 'react-admin';

const ContractsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <NumberInput source="assignment_id" />
    </SimpleForm>
  </Edit>
);

export default ContractsEdit;
