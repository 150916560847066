import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';

const ProfessionalQualificationAttributesList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="professional_qualification_id" />
      <TextField label="Qualification" source="qualification_attribute.qualification.long_name" />
      <TextField label="Qualification Attribute" source="qualification_attribute.name" />
    </Datagrid>
  </List>
);

ProfessionalQualificationAttributesList.query = gql`
  query GET_LIST($input: ProfessionalQualificationAttributeQueryInput) {
    professionalQualificationAttributes(input: $input) {
      id
      professional_qualification_id
      qualification_attribute_id
      type
      value
      metadata
      qualification_attribute {
        id
        name
        qualification {
          id
          long_name
        }
      }
    }
    _professionalQualificationAttributesMeta(input: $input) {
      count
    }
  }
`;

export default ProfessionalQualificationAttributesList;
