import React from 'react';
import { TopToolbar, CreateButton, useListContext, FilterButton } from 'react-admin';
import { Box } from '@mui/material';
import { ImportCsv, ExportCsv } from '.';
import _ from 'lodash';

const PayoutsListActions = (props) => {
  const { filterValues } = useListContext(props);

  return (
    <TopToolbar>
      <Box display="flex" flexDirection="column" alignItems="flex-end" mb={2} ml={1}>
        <Box display="flex" flexDirection="row" mt={1}>
          <Box mr={1}>
            <ImportCsv />
          </Box>
          <ExportCsv
            filters={_.omit(filterValues?.search, [
              'has_balance',
              'failing_payouts',
              'failed_payouts',
            ])}
          />
        </Box>
        <Box display="flex" flexDirection="row" mt={1}>
          <Box mr={1}>
            <FilterButton />
          </Box>
          <CreateButton />
        </Box>
      </Box>
    </TopToolbar>
  );
};

export default PayoutsListActions;
