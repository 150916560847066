import React from 'react';
import {
  TextField,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ShowButton,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { Card, FormHelperText } from '@mui/material';
import MoneyField from 'components/MoneyField';
import { RecommendedAssignmentsButton } from './RecommendedAssignmentsButton';

export const ProfessionalAside = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  if (!record) {
    return null;
  }
  const isReference = resource !== 'Professional';
  let professional = record;
  if (isReference) {
    professional = record.professional;
  }
  if (!professional) {
    return null;
  }

  return (
    <Card style={{ marginLeft: '1em', flex: '1 0 16em' }}>
      <SimpleShowLayout record={professional}>
        <FunctionField
          label="Professional"
          render={(r) => `${r.account?.first_name ?? ''} ${r.account?.last_name ?? ''}`}
          data-testid="professional_show-aside_professional_field"
        />
        <TextField label="ID" source="id" data-testid="professional_show-aside_id_field" />
        <ArrayField source="markets">
          <SingleFieldList linkType={false}>
            <ChipField source="display_name" data-testid="professional_show-aside_markets_field" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="positions">
          <SingleFieldList linkType={false}>
            <ChipField
              source="display_name"
              data-testid="professional_show-aside_positions_field"
            />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="specialties">
          <SingleFieldList linkType={false}>
            <ChipField source="label" data-testid="professional_show-aside_specialties_field" />
          </SingleFieldList>
        </ArrayField>
        <MoneyField source="balance_cents" />
        {isReference && <ShowButton resource="Professional" />}

        {/* TODO: Leads going away. Do we still need this? */}
        <FunctionField
          data-testid="professional_show-aside_recommended_assignments_field"
          label="Lead recommended assignments"
          render={(record) => (
            <>
              <RecommendedAssignmentsButton
                record={record}
                disabled={!record.person?.lead?.lead_assignment_id}
                variant="outlined"
              />
              {!record.person?.lead?.lead_assignment_id && (
                <FormHelperText>the lead has no assignment</FormHelperText>
              )}
            </>
          )}
        />
      </SimpleShowLayout>
    </Card>
  );
};
