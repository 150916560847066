import React from 'react';
import ReferenceArrayInput from '../ReferenceArrayInput';
import { AutocompleteArrayInput } from 'react-admin';
import { getChildrenProps } from 'components/ReferenceInput';

const MarketFilter = (props) => (
  <ReferenceArrayInput {...props}>
    <AutocompleteArrayInput
      optionText="display_name"
      {...getChildrenProps(props)}
      data-testid="market-filter"
    />
  </ReferenceArrayInput>
);

MarketFilter.defaultProps = {
  label: 'Market',
  emptyText: 'All',
  reference: 'Market',
  source: 'search.market_ids',
};

export default MarketFilter;
