import React, { memo } from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';

const ReferencesTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="ProfessionalReference"
      source="professional_id"
      target="professional_id"
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label="ID"
          render={(v) => <LinkField to={`/ProfessionalReference/${v.id}/show`}>{v.id}</LinkField>}
        />
        <TextField label="Reference Name" source="name" />
        <TextField label="Title" source="title" />
        <TextField label="Location" source="location" />
        <TextField label="Phone" source="phone_number" />
        <TextField label="Email" source="email" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default memo(ReferencesTab);
