import React from 'react';
import { required, AutocompleteArrayInput } from 'react-admin';
import { Box } from '@mui/material';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import InputUtils from 'utils/input';

const ItemizedTransactionBillingGroups = () => (
  <Box>
    <ReferenceArrayInput
      resource="FinancialTransaction"
      label="Select Billing Groups"
      reference="BillingGroup"
      source="billing_group_ids"
      filterToQuery={(searchText) => {
        return { id: [parseInt(searchText)] };
      }}
      validate={[required()]}
      fullWidth
      sort={{ field: 'id', order: 'ASC' }}
    >
      <AutocompleteArrayInput optionText={InputUtils.optionText('id')} />
    </ReferenceArrayInput>
  </Box>
);

export default ItemizedTransactionBillingGroups;
