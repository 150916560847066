import React from 'react';
import ReferenceInput from './ReferenceInput';
import { SelectInput, SelectArrayInput, useResourceContext } from 'react-admin';
import ReferenceArrayInput from './ReferenceArrayInput';

const PositionInput = ({ multiple = false, source = 'position_ids', ...props }) => {
  const resource = useResourceContext(props);

  if (multiple) {
    return (
      <ReferenceArrayInput resource={resource} sx={{ minWidth: 256 }} source={source} {...props}>
        <SelectArrayInput optionText="display_name" {...props} data-testid="position-input" />
      </ReferenceArrayInput>
    );
  }
  return (
    <ReferenceInput resource={resource} sx={{ minWidth: 256 }} source="position_id" {...props}>
      <SelectInput resettable optionText="display_name" {...props} data-testid="position-input" />
    </ReferenceInput>
  );
};

PositionInput.defaultProps = {
  label: 'Position',
  reference: 'Position',
  sort: { field: 'display_name', order: 'ASC' },
  perPage: 100,
  fullWidth: false,
};

export default PositionInput;
