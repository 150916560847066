import { RaRecord } from 'react-admin';
import config from 'config';

type UploadInput = {
  label: string;
  url: string;
  file: any | null;
  eventType: any | null;
};

type UploadTypes =
  | '530'
  | 'timesheet-clock-in'
  | 'geolocation-override-clock-in'
  | 'timesheet-clock-out'
  | 'geolocation-override-clock-out'
  | 'supervisor-signature'
  | 'clock-out-approval';

export const ClockEventAttachmentUploadMap = {
  'timesheet-clock-in': {
    label: 'Timesheet Clock In Photo',
    reason: 'interaction_with_facility_timecard_system',
    eventType: 'CheckIn',
  },
  'timesheet-clock-out': {
    label: 'Timesheet Clock Out Photo',
    reason: 'interaction_with_facility_timecard_system',
    eventType: 'CheckOut',
  },
  'geolocation-override-clock-in': {
    label: 'Clock In Geolocation Override',
    reason: 'photo_evidence_for_manual_override',
    eventType: 'CheckIn',
  },
  'geolocation-override-clock-out': {
    label: 'Clock Out Geolocation Override',
    reason: 'photo_evidence_for_manual_override',
    eventType: 'CheckOut',
  },
  'supervisor-signature': {
    label: (attachment) => {
      return attachment
        ? `Supervisor Signature (Name: ${attachment.metadata.supervisor_name})`
        : 'Supervisor Signature';
    },
    reason: 'supervisor_signature_for_waiving_meal_break',
    eventType: 'CheckOut',
  },
};
export const JobUploadTypeInfo = (
  type: UploadTypes,
  record: RaRecord,
  clockEventAttachments: any[],
): [boolean, UploadInput] => {
  if (type === '530') {
    return [
      !!record.attachment,
      {
        label: '530 Upload',
        url: `${config.rails_url}/v3/admin/jobs/${record.id}/attachment`,
        file: record.attachment,
        eventType: null,
      },
    ];
  }

  if (ClockEventAttachmentUploadMap[type]) {
    return ClockEventAttachmentUpload(type, clockEventAttachments);
  }

  return [
    false,
    {
      label: undefined,
      url: undefined,
      file: undefined,
      eventType: undefined,
    },
  ];
};
const ClockEventAttachmentUpload = (
  type: UploadTypes,
  clockEventAttachments: any[],
): [boolean, UploadInput] => {
  const map = ClockEventAttachmentUploadMap[type];

  const upload =
    clockEventAttachments?.find(
      (a) => a.reasons.includes(map.reason) && a.clock_event_type === map.eventType,
    ) || null;

  return [
    !!upload,
    {
      label: map.label instanceof Function ? map.label(upload) : map.label,
      url: `${config.rails_url}/v3/admin/clock_event_attachments`,
      file: upload ? upload.file : null,
      eventType: map.eventType,
    },
  ];
};
