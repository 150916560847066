import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const LocationTypePositionsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Location Type Label" source="location_type.label" />
      <TextField label="Position" source="position.display_name" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

LocationTypePositionsShow.query = gql`
  query GET_ONE($id: Int!) {
    locationTypePosition(id: $id) {
      id
      location_type {
        id
        label
      }
      position {
        id
        display_name
      }
      created_at
      updated_at
    }
  }
`;

export default LocationTypePositionsShow;
