import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const HealthSystemInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput
      optionText="label"
      {...getChildrenProps(props)}
      data-testid="health-system-input"
    />
  </ReferenceInput>
);

HealthSystemInput.defaultProps = {
  label: 'Health System',
  alwaysOn: true,
  reference: 'HealthSystem',
  emptyValue: null,
  source: 'health_system_id',
  perPage: 60,
  sort: { field: 'label', order: 'ASC' },
};

export default HealthSystemInput;
