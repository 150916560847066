import { gql } from '@apollo/client';
import list from './List';
// import create from './Create';
import edit from './Edit';
import show from './Show';

export default {
  name: 'VmsAssignments',
  inputShow: gql`
    query vmsAssignments($input: VmsAssignmentQueryInput) {
      vmsAssignments(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  // create,
  edit,
  options: {
    group: 'VMS',
    label: 'VMS Assignments',
  },
};
