export const reasonsOptions = [
  { id: 'LocationBlockReason', name: 'Location block reason' },
  { id: 'ProfessionalBlockReason', name: 'Professional block reason' },
];

export const referencesOptions = [
  { id: 'Location', name: 'Location' },
  { id: 'Professional', name: 'Professional' },
];

export const kindOptions = [
  { id: 'block', name: 'Block' },
  { id: 'favorite', name: 'Favorite' },
];

export const format = () => (r) => r?.label ?? '';
