import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, Datagrid, ReferenceField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import MarketInput from 'components/MarketInput';
import { SearchFilter } from 'components/Filter';

const MarketListFilters = [
  <IdInput />,
  <MarketInput label="Market" source="id" alwaysOn resettable />,
  <SearchFilter />,
];

const MarketList = () => (
  <List filters={MarketListFilters} exporter={false} data-testid="market-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Display Name" source="display_name" />
      <TextField source="name" />
      <ReferenceField label="State" source="state.id" reference="State" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="timezone" />
    </Datagrid>
  </List>
);

MarketList.query = gql`
  query markets($input: MarketQueryInput) {
    _marketsMeta(input: $input) {
      count
    }
    markets(input: $input) {
      id
      display_name
      state {
        id
      }
      name
      timezone
    }
  }
`;

export default MarketList;
