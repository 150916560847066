import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import useCheckAccess from 'hooks/useCheckAccess';

const ItemizedTransactionsTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="ItemizedTransaction"
        target="invoice_id"
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'ItemizedTransaction', 'id') && <IdField label="ID" />}
          {checkAccess('view', 'ItemizedTransaction', 'id') && (
            <MoneyField label="Amount" source="amount_cents" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'from_source') && (
            <TextField label="From Source" source="from_source" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'to_source') && (
            <TextField label="To Source" source="to_source" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'billing_group_id') && (
            <FunctionField
              label="Billing Group ID"
              render={(v) => (
                <LinkField to={`/BillingGroup/${v.billing_group_id}/show`}>
                  {v.billing_group_id}
                </LinkField>
              )}
            />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'financial_transaction_id') && (
            <FunctionField
              label="Financial Transaction ID"
              render={(v) => {
                if (v.financial_transaction_id) {
                  return (
                    <LinkField to={`/FinancialTransaction/${v.financial_transaction_id}/show`}>
                      {v.financial_transaction_id}
                    </LinkField>
                  );
                }
              }}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(ItemizedTransactionsTab);
