import React from 'react';
import config from 'config';
import { useFileUpload } from 'hooks/useFileUpload';
import { Button, CircularProgress } from '@mui/material';

const ImportVoucherIds = () => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin//invoices/import_voucher_ids`,
    accept: 'text/csv',
  });
  return (
    <div>
      {hiddenInput}
      <Button
        disabled={uploading}
        onClick={openFilePrompt}
        variant="outlined"
        style={{ marginTop: '5px' }}
        size="small"
        endIcon={uploading ? <CircularProgress size={20} /> : undefined}
      >
        Import&nbsp;Voucher Ids
      </Button>
    </div>
  );
};
export default ImportVoucherIds;
