import { usePermissions } from 'react-admin';
import { getObjectPath } from 'utils/object';

export type Action = 'actions' | 'create' | 'delete' | 'update' | 'view';

const useCheckAccess = () => {
  const { permissions } = usePermissions();
  const { calculated_permissions = {} } = permissions;

  return (action: Action, resource: string, sources?: string | string[]) => {
    // The all permission on the resource supersedes other permissions, so if it exists, return true
    const hasResourceAllPermission = getObjectPath(
      calculated_permissions,
      [action, resource, 'all'],
      false,
    );
    if (sources === undefined || sources === 'all') {
      return hasResourceAllPermission;
    }
    if (hasResourceAllPermission) {
      return true;
    }

    if (Array.isArray(sources)) {
      return sources.every((attribute) =>
        getObjectPath(calculated_permissions, [action, resource, attribute], false),
      );
    }

    return getObjectPath(calculated_permissions, [action, resource, sources], false);
  };
};

export default useCheckAccess;
