import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';
import Heading from 'components/Heading';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { createConditionalInputWrapper } from 'hoc/createConditionalInputWrapper';
import RejectionReasonField from './RejectionReasonField';
import { transformPayload } from './utils';
import DisableableDateInput from 'components/DisableableDateInput';

const HideIfApplicationNonRejected = createConditionalInputWrapper(
  (formData) => formData.vms_application_status === 'rejected',
);

const HideIfProfileNonRejected = createConditionalInputWrapper(
  (formData) => formData.vms_profile_status === 'rejected',
);

const AssignmentRequestsEdit = () => (
  <Edit
    transform={transformPayload}
    mutationMode="pessimistic"
    redirect="show"
    data-testid="assignment-requests-edit"
  >
    <SimpleForm>
      <NumberInput label="Referrer ID" source="referrer_professional_id" />
      <Heading>Professional Application</Heading>
      <TextInput label="Interview Availability" source="interview_availability" />
      <TextInput label="Professional Prev. Worked Here" source="professional_prev_worked_here" />
      <DisableableDateInput
        disableLabel="ASAP"
        dateSource="preferred_start_date"
        disableSource="asap"
      />
      <TextInput label="Time Off Request" source="time_off_request" />
      <TextInput label="Exceptions" source="exceptions" />
      <EnumSelectInput label="Bonus Type" source="bonus_type" />
      <EnumSelectInput label="Application Status" source="status" />
      <RejectionReasonField />
      <TextInput label="Note" source="note" fullWidth multiline />
      <EnumSelectInput label="Offer Source" source="offer_source" sort={false} />
      <Heading>Professional Profile</Heading>
      <EnumSelectInput label="Professional Profile Status" source="vms_profile_status" />
      <HideIfProfileNonRejected>
        <EnumSelectInput
          label="Profile Rejected by CAM reason"
          source="cam_reject_reason"
          sort={false}
        />
      </HideIfProfileNonRejected>
      <EnumSelectInput label="Contract Type" source="contract_type" resettable />
      <TextInput label="Recruiter Notes" source="recruiter_notes" fullWidth multiline />
      <Heading>VMS Application Status</Heading>
      <EnumSelectInput label="VMS Application Status" source="vms_application_status" />
      <HideIfApplicationNonRejected>
        <EnumSelectInput label="Rejected by VMS reason" source="vms_reject_reason" />
      </HideIfApplicationNonRejected>
      <TextInput label="CAM Notes" source="cam_notes" fullWidth multiline />
    </SimpleForm>
  </Edit>
);

export default AssignmentRequestsEdit;
