import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  FunctionField,
  SimpleShowLayout,
  useRecordContext,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import TransactionLink from '../TransactionLink';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import ItemizedTransactionForm from './ItemizedTransactionForm';

const ItemizedTransactionsTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <SimpleShowLayout>
      {checkAccess('actions', 'FinancialTransaction', 'apply') && <ItemizedTransactionForm />}
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="ItemizedTransaction"
        target="financial_transaction_id"
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'ItemizedTransaction', 'id') && <IdField />}
          {checkAccess('view', 'FinancialTransaction', ['kind', 'transaction_id']) && (
            <FunctionField
              label="Payment ID"
              render={(v) => (
                <TransactionLink
                  kind={v.financial_transaction.kind}
                  transaction={v.financial_transaction.transaction_id}
                />
              )}
            />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'amount_cents') && (
            <MoneyField label="Amount" source="amount_cents" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'from_source') && (
            <TextField label="From" source="from_source" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'to_source') && (
            <TextField label="To" source="to_source" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'billing_group_id') && (
            <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'invoice_id') && (
            <LinkField label="Invoice ID" source="invoice.id" />
          )}
          {checkAccess('view', 'ItemizedTransaction', 'notes') && (
            <TextField label="Notes" source="notes" />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(ItemizedTransactionsTab);
