import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TextField, TabbedShowLayoutTabs } from 'react-admin';
import BillingGroupsTab from 'components/tabs/BillingGroupsTab';

const BillingGroupManagementCompaniesShow = () => {
  return (
    <Show>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <TextField label="ID" source="id" />
          <TextField label="Name" source="name" />
        </Tab>
        <Tab label="Billing Groups" path="billing_groups">
          <BillingGroupsTab target="billing_group_management_company_id" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

BillingGroupManagementCompaniesShow.query = gql`
  query GET_ONE($id: Int!) {
    billingGroupManagementCompany(id: $id) {
      id
      name
    }
  }
`;

export default BillingGroupManagementCompaniesShow;
