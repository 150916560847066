import React from 'react';
import { TextInput, NumberInput, Edit, SimpleForm } from 'react-admin';

const TaxClassificationEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <NumberInput source="sequence" />
    </SimpleForm>
  </Edit>
);

export default TaxClassificationEdit;
