import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { SelectInput } from 'react-admin';

const VmsTypeInput = (props) => (
  <ReferenceInput {...props}>
    <SelectInput optionText="label" {...getChildrenProps(props)} data-testid="vms-type-input" />
  </ReferenceInput>
);

VmsTypeInput.defaultProps = {
  label: 'VMS Type',
  alwaysOn: true,
  reference: 'VmsType',
  emptyText: 'All',
  emptyValue: null,
  source: 'vms_type_id',
  perPage: 60,
  sort: { field: 'label', order: 'ASC' },
};

export default VmsTypeInput;
