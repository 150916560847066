import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberField, TextField, BooleanField, Datagrid } from 'react-admin';
import List from 'components/List';
import SearchInput from 'components/Filter/SearchInput';
import IdField from 'components/IdField';

const PositionListFilters = [<IdInput />, <SearchInput alwaysOn resettable />];

const PositionList = () => (
  <List filters={PositionListFilters} exporter={false} data-testid="position-list">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField label="Display Name" source="display_name" />
      <TextField source="kind" />
      <NumberField source="sequence" />
      <BooleanField source="active" />
      <BooleanField label="Requires Specialty" source="requires_specialty" />
      <NumberField options={{ style: 'percent' }} source="hospital_mark_up" />
      <TextField label="Qualifications Group Name" source="qualifications_group.name" />
    </Datagrid>
  </List>
);

PositionList.query = gql`
  query positions($input: PositionQueryInput) {
    _positionsMeta(input: $input) {
      count
    }
    positions(input: $input) {
      id
      name
      display_name
      sequence
      active
      kind
      requires_specialty
      hospital_mark_up
      qualifications_group {
        id
        name
      }
    }
  }
`;

export default PositionList;
