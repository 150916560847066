import React from 'react';
import { TextInput } from 'react-admin';

const SearchInput = (props) => <TextInput {...props} data-testid="search-input" />;

SearchInput.defaultProps = {
  alwaysOn: true,
  source: 'q',
  label: 'Search',
};

export default SearchInput;
