import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Datagrid } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';

const EmergencyContactList = () => (
  <List exporter={false} filters={[<IdInput />]} bulkActionButtons={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="First Name" source="first_name" />
      <TextField label="Last Name" source="last_name" />
      <TextField label="Phone Number" source="phone_number" />
    </Datagrid>
  </List>
);

EmergencyContactList.query = gql`
  query emergencyContacts($input: EmergencyContactQueryInput) {
    _emergencyContactsMeta(input: $input) {
      count
    }
    emergencyContacts(input: $input) {
      id
      first_name
      last_name
      phone_number
    }
  }
`;

export default EmergencyContactList;
