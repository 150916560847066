import React from 'react';
import { TextField, Datagrid, useRecordContext, Pagination, List } from 'react-admin';
import IdField from 'components/IdField';
// import ActionButton from 'components/ActionButton';
import { Box } from '@mui/material';

const MedelyRequirementsTable = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h3>Medely Requirements</h3>
        {/* <ActionButton
          text="EDIT"
          method="PUT"
          data={{
            saas_plan: 'custom',
          }}
          url={`/v3/admin/companies/${record.id}`}
          withConfirmation
          confirmTitle="Edit Medely requirements"
          confirmDescription="Are you sure you want to edit Medely requirements? This cannot be undone."
        /> */}
      </Box>
      <List
        pagination={<Pagination />}
        resource="Requirements"
        exporter={false}
        filter={{
          search: {
            company_medely_requirements: {
              vms_type_id: record.vms_type_id ?? null,
            },
          },
        }}
      >
        <Datagrid bulkActionButtons={false}>
          <IdField />
          <TextField source="condition.label" />
          <TextField source="kind" />
          <TextField label="Position" source="position.display_name" />
          <TextField label="Specialty" source="specialty.label" />
        </Datagrid>
      </List>
    </>
  );
};

export default React.memo(MedelyRequirementsTable);
