import React from 'react';
import { NumberInput } from 'react-admin';
import { round } from 'utils/number';

const PercentInput = ({ restricted = false, source, ...rest }) => (
  <NumberInput
    source={source}
    validate={(value) =>
      restricted && ![0, 0.04].includes(value) && value !== rest.record?.statement_fee_percent
        ? 'Should be 0% or 4%'
        : value > 1
        ? 'Should not be more than 100%'
        : undefined
    }
    format={(v) => round(v * 100)}
    parse={(v) => round(parseFloat(v) / 100)}
    {...rest}
  />
);

export default PercentInput;
