import { gql } from '@apollo/client';
import list from './List';
import create from './Create';
import show from './Show';

export default {
  name: 'LocationBillingGroups',
  inputShow: gql`
    query locationBillingGroups($input: LocationBillingGroupQueryInput) {
      locationBillingGroups(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  create,
  options: {
    group: false,
  },
};
