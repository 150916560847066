import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  FunctionField,
  BooleanField,
} from 'react-admin';
import { Link } from 'react-router-dom';
import LinkField from 'components/LinkField';

const LocationsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="AssignmentLocation"
      target="assignment_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="location_id" />
        <TextField label="Name" source="location.name" />
        <TextField label="Status" source="location.status" />
        <TextField label="Type" source="location.location_type.label" />
        <TextField label="Phone Number" source="location.phone_number" />
        <FunctionField
          label="Account Manager"
          render={(r) =>
            r.location.account_manager &&
            r.location.account_manager.account && (
              <Link to={`/Administrator/${r.location.account_manager_id}/show`}>
                {r.location.account_manager.account.name}
              </Link>
            )
          }
        />
        <FunctionField
          label="Sales Associate"
          render={(r) =>
            r.location.sales_associate &&
            r.location.sales_associate.account && (
              <Link to={`/Administrator/${r.location.sales_associate_id}/show`}>
                {r.location.sales_associate.account.name}
              </Link>
            )
          }
        />
        <BooleanField label="Primary Location" source="primary_location" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(LocationsTab);
