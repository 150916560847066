import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';
import InputUtils from 'utils/input';

const DisputeInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput
      optionText={InputUtils.optionText('id')}
      {...getChildrenProps(props)}
      data-testid="dispute-input"
    />
  </ReferenceInput>
);

DisputeInput.defaultProps = {
  label: 'Dispute ID',
  source: 'dispute_id',
  reference: 'Dispute',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
  fullWidth: false,
};

export default DisputeInput;
