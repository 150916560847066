import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'funding_sources',
  inputShow: gql`
    query fundingSources($input: FundingSourceQueryInput) {
      fundingSources(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Funding Sources',
  },
};
