import React from 'react';
import { TextInput, minLength, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const CommentsField = () => {
  const { getValues } = useFormContext();
  const values = getValues();
  const isLocationBlockingPro =
    values.kind === 'block' && values.reason_type === 'LocationBlockReason';

  return (
    <TextInput
      source="comments"
      validate={isLocationBlockingPro ? [required(), minLength(20)] : null}
      multiline
      rows={3}
    />
  );
};

export default CommentsField;
