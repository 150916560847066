export const amountLeft = (transaction, invoices, userBalances) => {
  const selectedAmount = invoices.reduce((acct, i) => acct + (i.amount_cents || 0), 0);
  const balancedAmount = userBalances.reduce((acct, b) => acct + (b.balance_cents || 0), 0);
  const amountLeftAfterIT =
    transaction.itemized_transactions?.reduce((acc, curr) => {
      if (curr.from_source === 'financial_transaction') {
        return acc - curr.amount_cents;
      } else if (curr.to_source === 'financial_transaction') {
        return acc + curr.amount_cents;
      }
      return acc;
    }, transaction.amount_cents) ?? transaction.amount_cents;
  return amountLeftAfterIT - selectedAmount - balancedAmount;
};
