import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const CancellationReasonsCreate = () => (
  <Create data-testid="cancellation-reasons-create" redirect="show">
    <SimpleForm>
      <TextInput source="label" data-testid="cancellation-reasons-create-label" />
      <EnumSelectInput source="role" data-testid="cancellation-reasons-create-role" />
      <TextInput source="slug" data-testid="cancellation-reasons-create-slug" />
    </SimpleForm>
  </Create>
);

export default CancellationReasonsCreate;
