import JobInput from 'components/JobInput';
import LocationInput from 'components/LocationInput';
import ProfessionalInput from 'components/ProfessionalInput';
import React from 'react';
import { Edit, SimpleForm, NumberInput, BooleanInput, DateTimeInput, TextInput } from 'react-admin';

const CheckOutsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="check-outs-edit">
    <SimpleForm>
      <JobInput data-testid="check-outs-edit-job" />
      <LocationInput data-testid="check-outs-edit-location" />
      <ProfessionalInput data-testid="check-outs-edit-professional" />
      <NumberInput
        options={{ maximumFractionDigits: 10 }}
        source="latitude"
        data-testid="check-outs-edit-latitude"
      />
      <NumberInput
        options={{ maximumFractionDigits: 10 }}
        source="longitude"
        data-testid="check-outs-edit-longitude"
      />
      <TextInput source="distance" label="Distance (ft)" data-testid="check-outs-edit-distance" />
      <BooleanInput source="accepted" data-testid="check-outs-edit-accepted" />
      <DateTimeInput
        source="created_at"
        label="Check Out Time"
        data-testid="check-outs-edit-created-at"
      />
      <DateTimeInput
        source="time"
        label="Professional Submitted Time"
        data-testid="check-outs-edit-time"
      />
    </SimpleForm>
  </Edit>
);

export default CheckOutsEdit;
