import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import JsonField from 'components/JsonField';

const PermissionGroupsEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="display_name" />
      <TextInput source="account_type" />
      <JsonField source="permissions" rootKey="Permissions" />
    </SimpleForm>
  </Edit>
);

export default PermissionGroupsEdit;
