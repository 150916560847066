import { ENUMS } from 'components/Enum/enums';
import React from 'react';
import { Create, SimpleForm, NumberInput, BooleanInput, SelectInput } from 'react-admin';
import HealthSystemInput from 'components/HealthSystemInput';
import LocationInput from 'components/LocationInput';
import MspInput from 'components/MspInput';
import VmsTypeInput from 'components/VmsTypeInput';
import { choicesFromEnum } from 'utils/helpers';

const LocalRateRulesCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <VmsTypeInput />
      <MspInput />
      <HealthSystemInput />
      <LocationInput />
      <BooleanInput source="local_okay" defaultValue={true} />
      <SelectInput source="applies_to" choices={choicesFromEnum(ENUMS.LocalRateRule.applies_to)} />
      <NumberInput source="mileage_cutoff" />
      <SelectInput
        source="rate_origin"
        choices={choicesFromEnum(ENUMS.LocalRateRule.rate_origin)}
      />
      <NumberInput source="offset_amount_cents" />
      <NumberInput source="offset_percentage" />
      <BooleanInput source="force_draft" defaultValue={null} />
    </SimpleForm>
  </Create>
);

export default LocalRateRulesCreate;
