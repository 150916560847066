import React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const ProfessionalBlockReasonEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <EnumSelectInput source="kind" />
      <TextInput source="label" />
    </SimpleForm>
  </Edit>
);

export default ProfessionalBlockReasonEdit;
