import List from './List';
import Edit from './Edit';
import Show from './Show';

export default {
  name: 'ExpenseAdjustments',
  list: List,
  edit: Edit,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Expense Adjustments',
  },
};
