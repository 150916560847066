import React from 'react';
import { Button } from '@mui/material';
import { useFileDownload } from 'hooks/useFileDownload';

const ExportCsv = ({ filters }) => {
  const downloadCsv = useFileDownload({ fileName: 'medely_approved_w2_payouts.csv' });

  const handleClick = () => {
    downloadCsv(`/v3/admin/payouts/export?${new URLSearchParams(filters).toString()}`);
  };

  return (
    <Button size="small" variant="outlined" onClick={handleClick}>
      Export W2
    </Button>
  );
};

export default ExportCsv;
