import React from 'react';
import { TextInput, BooleanInput, Create, SimpleForm } from 'react-admin';

const LocationTypeCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <TextInput source="label" />
      <BooleanInput source="active" />
      <BooleanInput source="admin_only" />
    </SimpleForm>
  </Create>
);

export default LocationTypeCreate;
