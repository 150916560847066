import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import {
  AutocompleteArrayInput,
  TextInput,
  BooleanInput,
  Create,
  SimpleForm,
  required,
} from 'react-admin';
import MarketInput from '../../components/MarketInput';

const JobsBoardCreate = () => (
  <Create redirect="show" data-testid="jobs-board-create">
    <SimpleForm>
      <ReferenceArrayInput label="Positions" source="position_ids" reference="Position">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
      <MarketInput validate={[required()]} />
      <TextInput source="board" />
      <TextInput source="role" />
      <TextInput source="title" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zip" />
      <TextInput fullWidth multiline source="description" />
      <TextInput source="salary" />
      <TextInput source="job_type" />
      <TextInput source="category" />
      <BooleanInput source="active_job" />
    </SimpleForm>
  </Create>
);

export default JobsBoardCreate;
