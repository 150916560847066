import { compose } from 'ramda';

import { format } from 'date-fns';
import { startOfDay } from 'utils/time';

const DATE_PATTERN = /\d{4}(\W\d{2}){2}/;

export const isDateOnly = (date: string) =>
  date.includes('T00:00:00.000Z') || DATE_PATTERN.test(date);

export const offsetTimeZone = (date: string) => (isDateOnly(date) ? startOfDay(date) : date);

export const formatDate = (val: string | undefined, dateFormat = 'MM/dd/yyyy') =>
  val ? compose((value) => format(new Date(value), dateFormat), offsetTimeZone)(val) : '';
