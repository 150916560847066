import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const MarketLocationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="State" source="market.state.abbreviation" />
      <TextField source="city" />
      <TextField source="county" />
      <TextField label="Zip Code" source="zip_code" />
      <TextField label="Market ID" source="market.id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

MarketLocationShow.query = gql`
  query marketLocation($id: Int!) {
    marketLocation(id: $id) {
      id
      city
      county
      zip_code
      market {
        id
        state {
          abbreviation
        }
      }
      created_at
      updated_at
    }
  }
`;

export default MarketLocationShow;
