import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

const QualificationAttributeEdit = () => (
  <Edit mutationMode="pessimistic" data-testid="staff-edit" redirect="show">
    <SimpleForm>
      <TextInput source="name" fullWidth multiline />
    </SimpleForm>
  </Edit>
);

export default QualificationAttributeEdit;
