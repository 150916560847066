import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import {
  TextInput,
  BooleanInput,
  NumberInput,
  AutocompleteArrayInput,
  Edit,
  SimpleForm,
  required,
} from 'react-admin';

const SpecialtyEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="specialty-edit">
    <SimpleForm>
      <TextInput source="name" data-testid="specialty-edit-name" />
      <TextInput source="label" data-testid="specialty-edit-label" />
      <BooleanInput source="active" />
      <NumberInput source="rate_differential" validate={required()} />
      <ReferenceArrayInput label="Positions" source="position_ids" reference="Position">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default SpecialtyEdit;
