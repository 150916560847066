import React, { useState, useMemo } from 'react';
import {
  TextField,
  Datagrid,
  ChipField,
  ArrayField,
  SingleFieldList,
  ReferenceManyField,
  Pagination,
  FunctionField,
  BooleanField,
  SimpleShowLayout,
  useNotify,
  useRefresh,
  useGetRecordId,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useApi from 'hooks/useApi';
import useDialog from 'hooks/useDialog';
import ProfessionalInput from 'components/ProfessionalInput';
import IdField from 'components/IdField';
import ScreenRequestsField from 'resources/assignments/ScreenRequestsField';

const CreateChildAssignment = ({ assignmentRequest }) => {
  const api = useApi();
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState<boolean>(false);
  const [newAssignmentId, setNewAssignmentId] = useState<number | undefined>(undefined);
  const { open, openDialog, closeDialog } = useDialog();

  const handleClose = () => {
    closeDialog();
    refresh();
  };

  const handleCreate = async () => {
    try {
      setLoading(true);

      const response = await api({
        url: `/v3/admin/assignments/${assignmentRequest.assignment.id}/duplicate`,
        method: 'POST',
        data: {
          assignment: {
            assignment_request_id: assignmentRequest.id,
          },
        },
      });

      setNewAssignmentId(response.data.payload.id);
      openDialog();
    } catch (error: any) {
      notify(error.response?.data?.error || error.message, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleCreate} disabled={loading}>
        Create&nbsp;Child&nbsp;Assignment
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent>Child assignment has been successfully created!</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button to={`/Assignment/${newAssignmentId}/show`} component={Link}>
            Open Assignment
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const AssignmentRequestsTab = ({ canManage }) => {
  const [submittedToVMS, setSubmitted] = useState(false);
  const id = useGetRecordId();
  const filter = useMemo(
    () => ({ submitted_to_vms: submittedToVMS || undefined }),
    [submittedToVMS],
  );
  return (
    <SimpleShowLayout>
      {canManage && (
        <Box display="flex" justifyContent="space-between" my={3}>
          <QuickCreateButton initialValues={{ assignment_id: id }} resource="AssignmentRequest">
            <ProfessionalInput />
            <EnumSelectInput resource="AssignmentRequest" source="status" />
          </QuickCreateButton>
          <FormControlLabel
            control={
              <Switch
                checked={submittedToVMS}
                onChange={(e) => setSubmitted(e.target.checked)}
                name="antoine"
              />
            }
            label="Submitted to VMS"
          />
        </Box>
      )}

      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="AssignmentRequest"
        target="assignment_id"
        filter={filter}
      >
        <Datagrid bulkActionButtons={false}>
          <IdField />
          <LinkField label="Professional ID" source="professional_id" typename="Professional" />
          <TextField label="Professional Name" source="professional.account.name" />
          <TextField label="Professional Phone Number" source="professional.account.phone_number" />
          <ArrayField label="Professional Markets" source="professional.markets">
            <SingleFieldList linkType={false}>
              <ChipField source="display_name" />
            </SingleFieldList>
          </ArrayField>
          <FunctionField
            label="Recruiter"
            render={(r) =>
              r.professional.recruiter &&
              r.professional.recruiter.account && (
                <Link to={`/Administrator/${r.professional.recruiter_id}/show`}>
                  {r.professional.recruiter.account.name}
                </Link>
              )
            }
          />
          <TextField label="Status" source="status" />
          <FunctionField
            label="Interview"
            render={(r) => {
              const screenRequestsRecord = { ...r.assignment, assignment_requests: [r] };
              return <ScreenRequestsField record={screenRequestsRecord} />;
            }}
          />
          <FunctionField
            label="Submitted To VMS"
            render={(v) => (
              <BooleanField record={{ submitted: !!v.vms_application_status }} source="submitted" />
            )}
          />
          <FunctionField
            render={(v) =>
              v.assignment?.number_of_openings > 1 &&
              ['available', 'matched'].includes(v.assignment?.status) ? (
                <CreateChildAssignment assignmentRequest={v} />
              ) : null
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(AssignmentRequestsTab);
