import React from 'react';
import { TextInput } from 'react-admin';
import InputUtils from 'utils/input';

const IdInput = (props) => <TextInput {...props} data-testid="id-input" />;

IdInput.defaultProps = {
  alwaysOn: true,
  parse: InputUtils.parse.int,
  source: 'id',
  label: 'ID',
  resettable: true,
};

export default IdInput;
