import DateTimeInput from 'components/DateTimeInput';
import React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';

const PaymentEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="status" />
      <DateTimeInput showTime options={{ hour12: false }} source="last_payment_attempt_at" />
      <DateTimeInput showTime options={{ hour12: false }} source="processed_on" />
      <DateTimeInput showTime options={{ hour12: false }} source="arrival_date" />
      <TextInput source="account_type" />
      <TextInput source="provider" />
      <TextInput source="provider_transaction_id" />
      <TextInput source="provider_status" />
      <TextInput source="provider_payment_delivery_method" />
    </SimpleForm>
  </Edit>
);

export default PaymentEdit;
