import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, ReferenceField } from 'react-admin';
import List from 'components/List';

const Friendly_id_slugsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="slug" />
      <ReferenceField reference="sluggables" source="sluggable_id">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="sluggable_type" />
      <TextField source="scope" />
    </Datagrid>
  </List>
);

Friendly_id_slugsList.query = gql`
  query GET_LIST($input: FriendlyIDSlugQueryInput) {
    _friendlyIDSlugsMeta(input: $input) {
      count
    }
    friendlyIDSlugs(input: $input) {
      id
    }
  }
`;

export default Friendly_id_slugsList;
