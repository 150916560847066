import React from 'react';
import ReferenceInput, { getChildrenProps } from '../ReferenceInput';
import { AutocompleteInput } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';

const AdministratorInput = (props) => (
  <ReferenceInput
    {...props}
    gql={[
      ` account {
          id
          first_name
          last_name
        }`,
    ]}
  >
    <AutocompleteInput
      optionText={DisplayHelpers.professional.fullName}
      {...getChildrenProps(props)}
      data-testid="administrator-input"
    />
  </ReferenceInput>
);

AdministratorInput.defaultProps = {
  emptyText: 'All',
  reference: 'Administrator',
};

export default AdministratorInput;
