import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  FunctionField,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import JsonField from 'components/JsonField';
import VmsUserShowActions from './ShowActions';
import LocationsTab from './tabs/LocationsTab';
import useCheckAccess from 'hooks/useCheckAccess';

const diff = function (obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = { from: obj1[key], to: obj2[key] };
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = diff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

const VmsUsersShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<VmsUserShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="VMS Details">
          <TextField label="ID" source="id" />
          <LinkField label="Vms Type ID" source="vms_type.id" />
          <TextField label="Vms Type" source="vms_type.label" />
          <TextField label="Vms External ID" source="vms_external_id" />
          <FunctionField
            label="Differences"
            render={(v) => {
              if (v && v.updated && v.current_data && v.new_data) {
                const r = { difference: diff(v.current_data, v.new_data) };
                return <JsonField record={r} source="difference" rootKey="Differences" />;
              }
            }}
          />
          <JsonField source="new_data" rootKey="New Data" />
          <JsonField source="valid_data" rootKey="Current Data" label="Current Data" />
          <BooleanField label="Needs Review" source="updated" />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'Location') && (
          <Tab label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

VmsUsersShow.query = gql`
  query GET_ONE($id: Int!) {
    vmsUser(id: $id) {
      id
      vms_external_id
      bullhorn_id
      updated
      current_data
      valid_data
      new_data
      created_at
      updated_at
      vms_type {
        id
        label
      }
    }
  }
`;

export default VmsUsersShow;
