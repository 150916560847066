import React from 'react';
import { Create, SimpleForm } from 'react-admin';
import LocationInput from 'components/LocationInput';
import PositionInput from 'components/PositionInput';

const LocationPositionsCreate = () => (
  <Create>
    <SimpleForm>
      <LocationInput />
      <PositionInput />
    </SimpleForm>
  </Create>
);

export default LocationPositionsCreate;
