import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import MoneyInput from 'components/MoneyInput';
import { DisplayHelpers } from 'utils/helpers';
import { useFormContext } from 'react-hook-form';

const RefundFormSummary = ({ billingGroup }) => {
  const { watch } = useFormContext();
  const transaction = watch('transaction');

  return (
    <Box my={1}>
      <Typography color="textSecondary" mb={1}>
        Billing Group balance: {DisplayHelpers.money(billingGroup.balance_cents)}
      </Typography>
      <Typography color="textSecondary" mb={1}>
        Charge amount: {DisplayHelpers.money(transaction.amount_left_cents)}
      </Typography>
      <MoneyInput label="Amount" source="amount" />
    </Box>
  );
};

export default memo(RefundFormSummary);
