import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { Box } from '@mui/material';
import QuickCreateButton from 'components/QuickCreateButton';
import PositionInput from 'components/PositionInput';

const LocationTypePositionsTab = ({ canCreate, canDelete }) => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      {canCreate && (
        <Box my={3}>
          <QuickCreateButton
            initialValues={{ location_type_id: record.id }}
            resource="LocationTypePosition"
          >
            <PositionInput />
          </QuickCreateButton>
        </Box>
      )}

      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="LocationTypePosition"
        target="location_type_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <TextField label="Display Name" source="position.display_name" />
          <FunctionField
            render={(location_type) =>
              canDelete && (
                <DeleteWithConfirmButton
                  redirect={`/LocationType/${location_type.id}/show/positions`}
                />
              )
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(LocationTypePositionsTab);
