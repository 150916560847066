import React from 'react';
import { NumberInput, FunctionField, Labeled, required, minValue } from 'react-admin';
import HoursRates from './HoursRates';
import useAssignmentCalculator from '../useAssignmentCalculator';
import { useWatch } from 'react-hook-form';

const ChargeRatesTab = () => {
  const values = useWatch();
  const { chargeValues, hours_per_shift, number_of_shifts_per_week, status } =
    useAssignmentCalculator(values);

  // TODO: Check here
  // const chargePerWeekCentsField = useField('charge_per_week_cents', {
  //   initialValue: chargeValues.rates.total_amount,
  // });
  // useEffect(() => {
  //   chargePerWeekCentsField.input.onChange(chargeValues.rates.total_amount);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chargeValues.rates.total_amount]);

  if (typeof status === 'undefined') {
    return null;
  }

  return (
    <>
      <Labeled label="Hours per shift">
        <FunctionField
          render={() => hours_per_shift}
          data-testid="assignments_edit-hours_per_shift"
        />
      </Labeled>
      <Labeled label="Shifts per week">
        <FunctionField
          render={() => number_of_shifts_per_week}
          data-testid="assignments_edit-number_of_shifts_per_week"
        />
      </Labeled>
      <Labeled label="Hours per week">
        <FunctionField
          render={() => chargeValues.hours.total_time}
          data-testid="assignments_edit-hours_per_week"
        />
      </Labeled>
      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="charge_max_daily_regular_hours"
        data-testid="assignments_edit-charge_max_daily_regular_hours"
      />
      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="charge_max_daily_ot_hours"
        data-testid="assignments_edit-charge_max_daily_ot_hours"
      />
      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="charge_max_weekly_regular_hours"
        data-testid="assignments_edit-charge_max_weekly_regular_hours"
      />
      <NumberInput
        step={0.01}
        validate={minValue(0, 'Cannot be less than zero')}
        source="charge_overtime_multiplier"
        data-testid="assignments_edit-charge_overtime_multiplier"
      />
      <NumberInput
        step={0.01}
        validate={minValue(0, 'Cannot be less than zero')}
        source="charge_double_overtime_multiplier"
        data-testid="assignments_edit-charge_double_overtime_multiplier"
      />
      <NumberInput
        step={0.01}
        validate={[required(), minValue(1.0, 'Cannot be less than 1.0')]}
        source="charge_callback_multiplier"
        data-testid="assignments_edit-charge_callback_multiplier"
      />
      <HoursRates hours={chargeValues.hours} rates={chargeValues.rates} />
    </>
  );
};
export default ChargeRatesTab;
