import React from 'react';
import {
  Create,
  SimpleForm,
  NumberInput,
  BooleanInput,
  DateTimeInput,
  TextInput,
} from 'react-admin';
import { defaultValuesFromProps } from 'utils/helpers';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';
import JobInput from 'components/JobInput';

const CheckInsCreate = (props) => {
  const defaultValue = defaultValuesFromProps(props);

  return (
    <Create redirect="show" data-testid="check-ins-create">
      <SimpleForm defaultValue={defaultValue}>
        <JobInput />
        <LocationInput />
        <ProfessionalInput />
        <NumberInput options={{ maximumFractionDigits: 10 }} source="latitude" />
        <NumberInput options={{ maximumFractionDigits: 10 }} source="longitude" />
        <TextInput source="distance" label="Distance (ft)" />
        <BooleanInput source="accepted" />
        <DateTimeInput source="time" label="Professional Submitted Time" />
      </SimpleForm>
    </Create>
  );
};

export default CheckInsCreate;
