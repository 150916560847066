import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, DateField, Datagrid } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const PhoneScreeningRequestList = () => (
  <List filters={[<IdInput />]} exporter={false} data-testid="phone-screening-request-list">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Assignment Request ID" source="assignment_request.id" />
      <LinkField label="Professional ID" source="professional_id" />
      <LinkField label="Location ID" source="assignment_request.assignment.primary_location.id" />
      <TextField source="status" />
      <DateField
        label="Confirmed Phone Screening Time"
        showTime
        options={{ hour12: false }}
        source="confirmed_phone_screening_time"
      />
    </Datagrid>
  </List>
);

PhoneScreeningRequestList.query = gql`
  query phoneScreeningRequests($input: PhoneScreeningRequestQueryInput) {
    _phoneScreeningRequestsMeta(input: $input) {
      count
    }
    phoneScreeningRequests(input: $input) {
      id
      assignment_request {
        id
        assignment {
          id
          primary_location {
            id
          }
        }
      }
      professional_id
      status
      phone_screening_times
      confirmed_phone_screening_time
    }
  }
`;

export default PhoneScreeningRequestList;
