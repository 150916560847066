import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, TextInput } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import IdField from 'components/IdField';

const BillingGroupParentsFilters = [
  <IdInput />,
  <TextInput alwaysOn label="Name" source="search.name" />,
];

const BillingGroupParentsList = () => (
  <List hasCreate exporter={false} filters={BillingGroupParentsFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
    </Datagrid>
  </List>
);

BillingGroupParentsList.query = gql`
  query GET_LIST($input: BillingGroupParentQueryInput) {
    _billingGroupParentsMeta(input: $input) {
      count
    }
    billingGroupParents(input: $input) {
      id
      name
    }
  }
`;

export default BillingGroupParentsList;
