import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'condition_documents',
  inputShow: gql`
    query conditionDocuments($input: ConditionDocumentQueryInput) {
      conditionDocuments(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Qualifications',
    label: 'Condition Document',
  },
};
