import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  FunctionField,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import IdField from 'components/IdField';
import JsonField from 'components/JsonField';
import VmsAssignmentShowActions from './ShowActions';
import AssignmentsTab from './tabs/AssignmentsTab';
import useCheckAccess from 'hooks/useCheckAccess';

const diff = function (obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = { from: obj1[key], to: obj2[key] };
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = diff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

const VmsAssignmentsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<VmsAssignmentShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="VMS Details">
          <IdField />
          <TextField label="Vms External ID" source="vms_external_id" />
          <TextField label="bullhorn ID" source="bullhorn_id" />
          <TextField label="Bullhorn Client ID" source="bullhorn_client_id" />
          <TextField label="Reason Assignment not Created" source="reason_assignment_not_created" />
          <FunctionField
            label="Differences"
            render={(v) => {
              if (v && v.updated && v.current_data && v.new_data) {
                const r = { difference: diff(v.current_data, v.new_data) };
                return <JsonField record={r} source="difference" rootKey="Differences" />;
              }
            }}
          />
          <JsonField source="new_data" rootKey="New Data" />
          <JsonField source="valid_data" label="Current Data" rootKey="Current Data" />
          <BooleanField label="Needs Review" source="updated" />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'Assignment') && (
          <Tab label="Assignments" path="assignments">
            <AssignmentsTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

VmsAssignmentsShow.query = gql`
  query GET_ONE($id: Int!) {
    vmsAssignment(id: $id) {
      id
      vms_external_id
      bullhorn_id
      bullhorn_client_id
      reason_assignment_not_created
      updated
      current_data
      valid_data
      new_data
      created_at
      updated_at
    }
  }
`;

export default VmsAssignmentsShow;
