import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import LinkField from 'components/LinkField';

const AccountsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="AccountBillingGroup"
      target="billing_group_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="account_id" typename="Account" />
        <TextField label="Name" source="account.name" />
        <TextField label="Email" source="account.email" />
        <FunctionField
          label="Permission Groups"
          render={(record) =>
            record.account.permission_groups.map(({ display_name }) => display_name).join(', ')
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default AccountsTab;
