import React from 'react';
import { Typography } from '@mui/material';

const EnumError = () => (
  <Typography variant="body2" color="error">
    Not found
  </Typography>
);

export default EnumError;
