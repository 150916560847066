import React, { useState } from 'react';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import useCredentials from './useCredentials';

export enum FilterEnum {
  ALL = 'All',
  MEDELY_REQUIRED = 'Medely Required',
  OPTIONAL = 'Optional',
  ARCHIVED = 'Archived',
}

const CredentialsFilter = ({ children }: any) => {
  const [currentFilter, setCurrentFilter] = useState<FilterEnum>(FilterEnum.ALL);
  const record = useRecordContext();
  const credentials = useCredentials(record.id);

  return (
    <>
      <ButtonGroup sx={{ margin: '16px 0' }}>
        {Object.values(FilterEnum).map((filter) => (
          <Button
            data-testid="credentials-filter"
            variant={filter === currentFilter ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setCurrentFilter(filter)}
            key={filter}
            sx={{ fontWeight: 700 }}
          >
            {filter}
            <Chip
              size="small"
              label={credentials[filter].length}
              sx={{ marginLeft: '0.5rem', backgroundColor: '#EEEEEE' }}
            />
          </Button>
        ))}
      </ButtonGroup>
      <div className="RaDatagrid-root">{children({ data: credentials[currentFilter] ?? [] })}</div>
    </>
  );
};

export default CredentialsFilter;
