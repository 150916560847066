import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const Heading = ({ children }) => (
  <Box mt={3} mb={2} width="100%">
    <Typography variant="h6" data-testid="heading">
      {children}
    </Typography>
    <Divider />
  </Box>
);

export default Heading;
