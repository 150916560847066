import List from './List';
import Create from './Create';
import Edit from './Edit';
import Show from './Show';
import { gql } from '@apollo/client';

export default {
  name: 'timecards',
  inputShow: gql`
    query timecards($input: TimecardQueryInput) {
      timecards(input: $input) {
        id
        status
        starts_time
        ends_time
        professional {
          id
        }
        assignment {
          id
        }
        __typename
      }
    }
  `,
  list: List,
  create: Create,
  edit: Edit,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Timecards',
  },
};
