import React from 'react';
import { Button } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import useImpersonate from '../hooks/useImpersonate';

const ImpersonateButton = ({ account }) => {
  const { impersonate, loading } = useImpersonate();

  if (account?.account_type === 'administrator') {
    return null;
  }

  return (
    <Button
      data-testid="impersonate_button"
      disabled={loading}
      onClick={() => impersonate(account)}
      size="small"
      color="primary"
      startIcon={<ExitToApp />}
    >
      Log in
    </Button>
  );
};

export default ImpersonateButton;
