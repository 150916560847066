import React, { memo } from 'react';
import { Button } from 'react-admin';
import { Box, Dialog } from '@mui/material';
import RefundForm from './RefundForm';
import useDialog from 'hooks/useDialog';
import useBillingGroup from 'hooks/useBillingGroup';

// TODO: Check on this implementation
const RefundFormButton = ({ billingGroupId, ...props }) => {
  const { open, closeDialog, openDialog } = useDialog();
  const { data: billingGroup, isLoading } = useBillingGroup(billingGroupId);

  const couldBeRefunded = billingGroup?.balance_cents > 0;
  if (!couldBeRefunded || isLoading) {
    return null;
  }

  return (
    <Box {...props}>
      <Button onClick={openDialog} label="Refund" variant="outlined" color="primary" />
      <Dialog fullWidth open={open} onClose={closeDialog} aria-label="Refund" maxWidth="sm">
        <RefundForm billingGroup={billingGroup} closeDialog={closeDialog} />
      </Dialog>
    </Box>
  );
};

export default memo(RefundFormButton);
