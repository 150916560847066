import { ENUMS } from 'components/Enum/enums';

export const rejectionReasons = ENUMS.AssignmentRequest.rejection_reason;

export const isOtherReason = (rejection_reason) => rejection_reason === rejectionReasons.Other;

type RejectionReason = (typeof rejectionReasons)[keyof typeof rejectionReasons];
export const isCustomOtherReason = (
  rejection_reason: string | RejectionReason | undefined | null,
) => {
  if (rejection_reason === undefined || rejection_reason === null) {
    return false;
  }

  const rejectionReasonList: string[] = Object.values(rejectionReasons);
  return !rejectionReasonList.includes(rejection_reason);
};

export const transformPayload = (data) => {
  if (isOtherReason(data.rejection_reason)) {
    data.rejection_reason = data.other_rejection_reason;
  }
  delete data.other_rejection_reason;
  return data;
};
