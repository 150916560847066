import React, { useRef } from 'react';
import { Labeled, useRecordContext } from 'react-admin';
import { Button, Grid, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import useClockEventAttachments from 'hooks/useClockEventAttachments';
import useCheckIns from 'hooks/useCheckIns';
import useCheckOuts from 'hooks/useCheckOuts';
import getAxios from 'getAxios';
import { JobUploadTypeInfo, ClockEventAttachmentUploadMap } from './JobUploadTypeInfo';

const axios = getAxios();

const JobFileUpload = ({
  type,
}: {
  type:
    | '530'
    | 'timesheet-clock-in'
    | 'geolocation-override-clock-in'
    | 'timesheet-clock-out'
    | 'geolocation-override-clock-out'
    | 'supervisor-signature'
    | 'clock-out-approval';
}) => {
  const eventId = useRef(null);
  const record = useRecordContext();
  const jobId = record.id as number;

  const { data: checkIns } = useCheckIns(jobId);
  const { data: checkOuts } = useCheckOuts(jobId);
  const checkInId = checkIns ? checkIns[0]?.id : null;
  const checkOutId = checkOuts ? checkOuts[0]?.id : null;

  if (
    ClockEventAttachmentUploadMap[type]?.eventType === 'CheckIn' &&
    eventId.current !== checkInId
  ) {
    eventId.current = checkInId;
  } else if (
    ClockEventAttachmentUploadMap[type]?.eventType === 'CheckOut' &&
    eventId.current !== checkOutId
  ) {
    eventId.current = checkOutId;
  }

  const { data: clockEventAttachments } = useClockEventAttachments(jobId);

  const [uploadExists, { label, url, file, eventType }] = JobUploadTypeInfo(
    type,
    record,
    clockEventAttachments,
  );

  const handleChange = async (e, handleSuccess, showError, setUploading, headers, ref, config) => {
    const fileToBase64String = async (file: File) =>
      new Promise<string>((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
            resolve(base64String);
          };
          reader.readAsDataURL(file);
        } catch {
          reject('unable to encode file');
        }
      });

    setUploading(true);
    const [file] = e.target.files;
    const encodedFile = await fileToBase64String(file);

    const attrs = {
      clock_event_attachment: {
        clock_event_id: eventId.current,
        clock_event_type: eventType,
        file: {
          contents: encodedFile,
          filename: file.name,
        },
        reason: JSON.stringify([`${ClockEventAttachmentUploadMap[type].reason}`]),
      },
    };

    axios
      .post(url, attrs, { headers } ?? config)
      .then(handleSuccess)
      .catch(showError)
      .finally(() => {
        setUploading(false);
        if (ref.current) {
          ref.current.value = '';
        }
      });
  };

  const showUploadOrReplace = !uploadExists || type === '530';

  const { hiddenInput, uploading, openFilePrompt } = useFileUpload({
    url,
    accept: '*',
    passedHandleChange: type === '530' ? undefined : handleChange,
  });
  return (
    <>
      {hiddenInput}
      <Labeled label={label} data-testid="job-file-upload">
        <Grid container spacing={2}>
          {uploadExists && (
            <Grid item>
              <Button size="small" variant="outlined" color="primary" href={file} target="_blank">
                Download
              </Button>
            </Grid>
          )}
          {showUploadOrReplace && (
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={uploading}
                startIcon={uploading ? <CircularProgress size={20} /> : null}
                onClick={openFilePrompt}
              >
                {uploadExists ? 'Replace Attachment' : 'Upload Attachment'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Labeled>
    </>
  );
};

export default JobFileUpload;
