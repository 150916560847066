import React from 'react';
import { Create, SelectInput, SimpleForm } from 'react-admin';
import PolymorphicIdInput from 'components/PolymorphicIdInput';
import { referencesOptions, kindOptions } from './utils';
import CommentsField from './CommentsField';
import MassBlockInputs from './MassBlockInputs';
import ReasonInput from './ReasonInput';

const RelationshipsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <SelectInput label="Relationship Kind" choices={kindOptions} source="kind" />
      <CommentsField />
      <ReasonInput />
      <PolymorphicIdInput field="requester" choices={referencesOptions} />
      <MassBlockInputs />
      <PolymorphicIdInput field="requestee" choices={referencesOptions} />
    </SimpleForm>
  </Create>
);

export default RelationshipsCreate;
