import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, Show, SimpleShowLayout } from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import MoneyField from 'components/MoneyField';
import TimestampFields from 'components/TimestampFields';

const RefundShow = () => (
  <Show data-testid="refund-show">
    <SimpleShowLayout>
      <NumberField label="ID" source="id" />
      <TextField label="Invoice ID" source="invoice.id" />
      <TextField label="Billing Group ID" source="invoice.billing_group_id" />
      <EnumField source="reason" />
      <TextField source="status" />
      <EnumField source="kind" />
      <MoneyField label="Amount of Cents" source="amount_cents" />
      <TextField label="Stripe ID" source="stripe_id" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

RefundShow.query = gql`
  query refund($id: Int!) {
    refund(id: $id) {
      id
      invoice {
        id
        billing_group_id
      }
      reason
      status
      kind
      amount_cents
      stripe_id
      created_at
      updated_at
    }
  }
`;

export default RefundShow;
