import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const AuditsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
    </SimpleShowLayout>
  </Show>
);

AuditsShow.query = gql`
  query GET_ONE($id: Int!) {
    audit(id: $id) {
      id
    }
  }
`;

export default AuditsShow;
