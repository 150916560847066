import React from 'react';
import { TextInput, Create, SimpleForm, required } from 'react-admin';
import StateInput from 'components/StateInput';
import TimezoneInput from 'components/TimezoneInput';

const MarketCreate = () => (
  <Create redirect="show" data-testid="market-create">
    <SimpleForm>
      <TextInput source="display_name" validate={[required()]} />
      <TextInput source="name" validate={[required()]} />
      <TimezoneInput source="timezone" validate={[required()]} />
      <StateInput validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default MarketCreate;
