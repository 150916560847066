import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const DisabledReasonShow = () => (
  <Show data-testid="disabled-reason-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField label="Display Name" source="display_name" />
      <TextField label="Person Type" source="person_type" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

DisabledReasonShow.query = gql`
  query disabledReason($id: Int!) {
    disabledReason(id: $id) {
      id
      name
      display_name
      person_type
      created_at
      updated_at
    }
  }
`;

export default DisabledReasonShow;
