import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { NumberField, TextField, Datagrid } from 'react-admin';
import List from 'components/List';
import { SearchFilter } from 'components/Filter';
import IdField from 'components/IdField';

const VmsTypeListFilters = [<IdInput />, <SearchFilter />];

const VmsTypeList = () => (
  <List filters={VmsTypeListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField source="label" />
      <NumberField label="Fee Percent" source="fee_percent" />
    </Datagrid>
  </List>
);

VmsTypeList.query = gql`
  query vmsTypes($input: VmsTypeQueryInput) {
    _vmsTypesMeta(input: $input) {
      count
    }
    vmsTypes(input: $input) {
      id
      fee_percent
      name
      label
    }
  }
`;

export default VmsTypeList;
