import React from 'react';
import { ENUMS } from 'components/Enum/enums';
import { Typography } from '@mui/material';
import { useUpdate, useRefresh, useNotify, useRecordContext } from 'react-admin';
import ButtonWithConfirmation from 'components/ButtonWithConfirmation';

const ApproveDraft = () => {
  const record = useRecordContext();
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const approveDraft = async () => {
    try {
      await update('Assignment', {
        id: record.id,
        data: { _action: 'APPROVE' },
      });
      notify('Assignment approved', { type: 'success' });
      refresh();
    } catch (error) {
      console.error(error);
      notify('Error: Assignment not approved', { type: 'warning' });
    }
  };
  if (record.status !== ENUMS.Assignment.status.draft) {
    return null;
  }
  return (
    <ButtonWithConfirmation
      size="small"
      variant="outlined"
      color="primary"
      onClick={approveDraft}
      loading={isLoading}
      confirmationTitle="Approve draft"
      confirmationContent={
        <Typography>
          Are you sure you would like to approve and post assignment ID {record.id}?
        </Typography>
      }
    >
      Approve draft
    </ButtonWithConfirmation>
  );
};

export default ApproveDraft;
