import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { Datagrid, DateField, TextField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const QuestionnaireList = () => (
  <List exporter={false} filters={[<IdInput />]}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="label" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

QuestionnaireList.query = gql`
  query questionnaires($input: QuestionnaireQueryInput) {
    _questionnairesMeta(input: $input) {
      count
    }
    questionnaires(input: $input) {
      id
      label
      created_at
      updated_at
      questions
    }
  }
`;

export default QuestionnaireList;
