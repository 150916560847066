import React from 'react';
import { TextInput, Create, SimpleForm, BooleanInput } from 'react-admin';

const ReferralSourceCreate = () => (
  <Create data-testid="referral-source-create" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="label" />
      <BooleanInput source="admin_only" />
    </SimpleForm>
  </Create>
);

export default ReferralSourceCreate;
