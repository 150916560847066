import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import config from 'config';

interface ButtonProps {
  endpoint: string;
  accept: string;
  text: string;
}

const UploadFileButton = ({ accept, endpoint, text }: ButtonProps) => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/${endpoint}`,
    accept: accept,
  });

  return (
    <div>
      {hiddenInput}
      <Button
        disabled={uploading}
        onClick={openFilePrompt}
        variant="outlined"
        size="small"
        startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
      >
        {text || 'Upload'}
      </Button>
    </div>
  );
};

export default UploadFileButton;
