import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import JsonInput from 'components/JsonInput';

const VmsUsersEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <JsonInput source="valid_data" rootKey="Current Data" />
    </SimpleForm>
  </Edit>
);

export default VmsUsersEdit;
