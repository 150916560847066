import React from 'react';
import { gql } from '@apollo/client';
import {
  TextField,
  Show,
  BooleanField,
  NumberField,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ReferenceManyField,
  Pagination,
  TabbedShowLayoutTabs,
} from 'react-admin';
import StripeUrlField from 'components/StripeUrlField';
import useCheckAccess from 'hooks/useCheckAccess';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const StripeAccountShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="details">
          <TextField label="ID" source="id" />
          <LinkField label="Professional ID" source="professional.id" />
          <TextField label="Tax Classification" source="tax_classification.label" />
          <BooleanField source="verified" />
          <BooleanField source="active" />
          <TextField label="Stripe ID" source="stripe_id" />
          <TextField label="First Name" source="first_name" />
          <TextField label="Last Name" source="last_name" />
          <TextField label="Company Name" source="company_name" />
          <NumberField
            label="Latitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.latitude"
          />
          <NumberField
            label="Longitude"
            options={{ maximumFractionDigits: 10 }}
            source="coordinates.longitude"
          />
          <TextField label="Address City" source="address_city" />
          <TextField label="Address First" source="address_first" />
          <TextField label="Address Second" source="address_second" />
          <TextField label="Address Zip" source="address_zip" />
          <TextField label="State" source="state.name" />
          <TextField label="Phone Number" source="phone_number" />
          <StripeUrlField />
        </Tab>
        {checkAccess('view', 'ExternalAccount') && (
          <Tab label="External accounts">
            <ReferenceManyField
              pagination={<Pagination />}
              reference="ExternalAccount"
              target="stripe_account_id"
              label=""
            >
              <Datagrid bulkActionButtons={false}>
                <IdField />
                <TextField source="account_type" />
                <TextField source="bank_name" />
                <TextField source="last4" />
                <TextField source="exp_month" />
                <TextField source="exp_year" />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

StripeAccountShow.query = gql`
  query stripeAccount($id: Int!) {
    stripeAccount(id: $id) {
      id
      tax_classification {
        id
        label
      }
      professional {
        id
      }
      stripe_id
      first_name
      last_name
      company_name
      address_city
      address_first
      address_second
      address_zip
      state {
        id
        name
      }
      coordinates {
        latitude
        longitude
      }
      phone_number
      verified
      active
    }
  }
`;

export default StripeAccountShow;
