import React from 'react';
import { TextInput, Edit, SimpleForm } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const EmergencyContactEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="phone_number" />
    </SimpleForm>
  </Edit>
);

export default EmergencyContactEdit;
