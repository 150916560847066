import React from 'react';
import { Button } from '@mui/material';
import useClipboard from 'hooks/useClipboard';
import useNotifyWrap from 'hooks/useNotifyWrap';

const CopyLink = ({ textToCopy }) => {
  const copy = useNotifyWrap(useClipboard(), {
    onSuccess: 'Copied',
  });

  return (
    <>
      <Button size="small" onClick={() => copy(textToCopy)} variant="outlined" color="primary">
        Copy link
      </Button>
    </>
  );
};

export default CopyLink;
