import { NumberField, FunctionField, useRecordContext, SimpleShowLayout } from 'react-admin';
import { DisplayHelpers } from 'utils/helpers';
import Heading from 'components/Heading';
import MoneyField from 'components/MoneyField';
import PercentField from 'components/PercentField';
import Profit from '../Profit';
import React from 'react';
import { TotalPayPackage } from '../TotalPayPackage';
import useAssignmentCalculator from '../useAssignmentCalculator';

const { money, rate } = DisplayHelpers;

const PayoutRateDetailsTab = () => {
  const record = useRecordContext();
  const { is_w2 } = record;

  const {
    payoutValues,
    totalNonTaxablePayCents,
    totalPayCents,
    totalTaxablePayCents,
    numberOfWeeks,
    weeklyTaxableIncomeCents,
    weeklyNonTaxableIncomeCents,
  } = useAssignmentCalculator(record);

  return (
    <SimpleShowLayout>
      <FunctionField
        label="Total Weeks"
        render={() => numberOfWeeks.toLocaleString('en-US', { maximumFractionDigits: 2 })}
      />
      <NumberField label="Hours per shift" source="hours_per_shift" />
      <NumberField label="Shifts per week" source="number_of_shifts_per_week" />
      <FunctionField
        label="Hours per week"
        render={() => record.hours_per_shift * record.number_of_shifts_per_week}
      />

      <Heading>Professional Pay</Heading>
      <PercentField label="On call Fee percent" source="on_call_fee_percent" />
      <NumberField source="payout_overtime_multiplier" />
      <NumberField source="payout_double_overtime_multiplier" />
      <NumberField label="Payout callback multiplier" source="payout_callback_multiplier" />
      <MoneyField label="Payout on call rate" source="payout_on_call_hourly_rate_cents" />
      <MoneyField label="Payout base rate" source="payout_base_hourly_rate_cents" />
      <FunctionField
        label="Payout blended rate"
        render={() => rate(payoutValues.rates.blended_rate)}
      />
      {is_w2 && <MoneyField label="Taxable Base Rate" source="payout_taxable_hourly_rate_cents" />}
      {is_w2 && (
        <FunctionField
          label="Taxable Blended Rate"
          render={() => rate(payoutValues.taxableRates.taxable_blended_rate)}
        />
      )}
      {is_w2 && (
        <FunctionField
          label="Weekly Taxable Income"
          render={() => money(weeklyTaxableIncomeCents)}
        />
      )}
      <FunctionField label="Total weekly pay" render={() => money(record.pay_per_week_cents)} />
      <TotalPayPackage
        pay={totalPayCents}
        taxablePay={totalTaxablePayCents}
        nonTaxable={totalNonTaxablePayCents}
      />
      <Heading>Non taxable income</Heading>
      {is_w2 && !record?.local_only && (
        <MoneyField
          style={{ width: 320 }}
          label={`Lodging Per Diem (Max rate: ${rate(record.max_lodging_per_diem_cents, 'day')})`}
          source="lodging_per_diem_cents"
        />
      )}
      {is_w2 && !record?.local_only && (
        <MoneyField
          style={{ width: 320 }}
          label={`M&IE Per Diem (Max rate: ${rate(record.max_mie_per_diem_cents, 'day')})`}
          source="mie_per_diem_cents"
        />
      )}
      {is_w2 && (
        <FunctionField
          label="Weekly Non-Taxable Income"
          render={() => money(weeklyNonTaxableIncomeCents)}
        />
      )}

      <Heading>Burdens</Heading>

      <PercentField label="Social security multiplier" source="social_security_burden_multiplier" />
      <PercentField label="Medicare multiplier" source="medicare_burden_multiplier" />
      <MoneyField label="Federal unemployment max" source="federal_unemployment_max_burden_cents" />
      <PercentField
        label="Federal unemployment percent multiplier"
        source="federal_unemployment_percent_burden_multiplier"
      />
      <MoneyField label="State unemployment max" source="state_unemployment_max_burden_cents" />
      <PercentField
        label="State unemployment percent multiplier"
        source="state_unemployment_percent_burden_multiplier"
      />
      <MoneyField label="Compliance" source="compliance_burden_cents" />
      <PercentField label="Other multiplier" source="other_burden_multiplier" />

      <NumberField
        label="Non billable orientation hours"
        source="non_billable_orientation_burden_hours"
      />
      <NumberField label="Billable orientation hours" source="billable_orientation_hours" />
      <PercentField label="VMS fee multiplier" source="vms_fee_burden_multiplier" />
      <MoneyField label="ADP fee (for assignment)" source="adp_fee_burden_cents" />
      <MoneyField
        label="Travel reimbursement (weekly)"
        source="travel_reimbursement_burden_cents"
      />
      <MoneyField label="Medical (Per Week)" source="medical_burden_cents" />
      <MoneyField label="One time bonus" source="one_time_bonus_burden_cents" />
      <Profit grossMargin={record.gross_margin} grossProfitCents={record.gross_profit_cents} />
    </SimpleShowLayout>
  );
};

export default PayoutRateDetailsTab;
