import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  EditButton,
  TabbedShowLayoutTabs,
} from 'react-admin';
import SummaryTab from './tabs/SummaryTab';
import InvoicesTab from 'components/tabs/InvoicesTab';
import useCheckAccess from 'hooks/useCheckAccess';

const StatementsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show
      actions={
        <TopToolbar>
          <EditButton />
        </TopToolbar>
      }
      data-testid="statements-show"
    >
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Summary">
          <SummaryTab />
        </Tab>
        {checkAccess('view', 'Invoice', 'statement_id') && (
          <Tab label="Invoices">
            <InvoicesTab
              target="statement_id"
              showFilters={true}
              sort={{ field: 'due_date', order: 'DESC' }}
            />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

StatementsShow.query = gql`
  query GET_ONE($id: Int!) {
    statement(id: $id) {
      id
      paid_date
      pay_period_start
      billing_group_id
      amount_due_cents
      total_cents
      pay_period_end
      due_date
      close_time
      status
      invoices {
        id
        stripe_invoice_id
        stripe_invoice_url
        paid_date
        starts_time
        ends_time
        processing_fee_stripe_invoice_item_id
        stripe_invoice_number
        fee_percent
        total_cents
        sub_total_cents
        processing_fee_cents
        balance_cents
        created_at
        updated_at
        status
      }
      created_at
      updated_at
    }
  }
`;

export default StatementsShow;
