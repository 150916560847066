import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';

const NursysEnrollmentsList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional.id" />
      <TextField label="Professional Error" source="professional_error" />
      <EnumField source="status" />
    </Datagrid>
  </List>
);

NursysEnrollmentsList.query = gql`
  query GET_LIST($input: NursysEnrollmentQueryInput) {
    _nursysEnrollmentsMeta(input: $input) {
      count
    }
    nursysEnrollments(input: $input) {
      id
      professional {
        id
      }
      professional_error
      status
    }
  }
`;

export default NursysEnrollmentsList;
