import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { TextField, BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import MarketInput from 'components/MarketInput';
import LinkField from 'components/LinkField';

const MarketRateSettingListFilters = [
  <IdInput />,
  <MarketInput alwaysOn />,
  <BooleanInput alwaysOn source="active" />,
];

const MarketRateSettingList = () => (
  <List filters={MarketRateSettingListFilters} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <LinkField label="Market" source="market.display_name" />
      <BooleanField source="active" />
      <BooleanField
        label="Track Unpaid Breaks"
        source="settings.track_unpaid_breaks"
        sortable={false}
      />
    </Datagrid>
  </List>
);

MarketRateSettingList.query = gql`
  query marketRateSettings($input: MarketRateSettingQueryInput) {
    _marketRateSettingsMeta(input: $input) {
      count
    }
    marketRateSettings(input: $input) {
      id
      market_id
      market {
        id
        display_name
      }
      active
      settings
    }
  }
`;

export default MarketRateSettingList;
