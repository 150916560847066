import { useGetList } from 'react-admin';

const useCheckOuts = (job_id: number) =>
  useGetList('CheckOut', {
    filter: {
      search: {
        job_id,
      },
    },
  });

export default useCheckOuts;
