import React from 'react';
import {
  TextInput,
  SimpleForm,
  SaveButton,
  useNotify,
  useRefresh,
  useRecordContext,
  TopToolbar,
  Toolbar,
} from 'react-admin';
import { Button, Box, Typography, Dialog } from '@mui/material';
import useDialog from 'hooks/useDialog';
import useApi from 'hooks/useApi';

const ReverseTransactionDialog = () => {
  const api = useApi();
  const { open, closeDialog, openDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();

  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const handleSubmit = async (values) => {
    try {
      await api({
        url: `/v3/admin/itemized_transactions/${record.id}/reverse`,
        method: 'POST',
        data: values,
      });
      closeDialog();
      notify('Transaction has been successfully reversed');
      refresh();
    } catch (e: any) {
      notify(e.response?.data?.message ?? 'Something went wrong', { type: 'warning' });
    }
  };

  return (
    <>
      {!record?.reversed_itemized_transaction_id && (
        <TopToolbar>
          <Button onClick={openDialog} color="primary" size="small">
            Reverse Transaction
          </Button>
        </TopToolbar>
      )}
      <Dialog open={open} onClose={closeDialog}>
        <Typography variant="h6" px={2} pt={3}>
          Reverse Transaction #{record?.id}
        </Typography>
        <SimpleForm
          onSubmit={handleSubmit}
          record={{}}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" alwaysEnable />
              <Button onClick={closeDialog}>Cancel</Button>
            </Toolbar>
          }
        >
          <Box mb={2}>
            <Typography>
              Are you sure you would like to reverse this Itemized Transaction? This action cannot
              be undone.
            </Typography>
          </Box>
          <TextInput source="notes" fullWidth multiline />
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default ReverseTransactionDialog;
