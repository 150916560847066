import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import {
  AutocompleteArrayInput,
  TextInput,
  BooleanInput,
  Edit,
  SimpleForm,
  required,
} from 'react-admin';
import MarketInput from 'components/MarketInput';

const JobsBoardEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="jobs-board-edit">
    <SimpleForm>
      <ReferenceArrayInput label="Positions" source="position_ids" reference="Position">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
      <MarketInput validate={[required()]} />
      <TextInput source="board" data-testid="jobs-board-edit-board" />
      <TextInput source="role" data-testid="jobs-board-edit-role" />
      <TextInput source="title" data-testid="jobs-board-edit-title" />
      <TextInput source="city" data-testid="jobs-board-edit-city" />
      <TextInput source="state" data-testid="jobs-board-edit-state" />
      <TextInput source="zip" />
      <TextInput
        fullWidth
        multiline
        source="description"
        data-testid="jobs-board-edit-description"
      />
      <TextInput source="salary" data-testid="jobs-board-edit-salary" />
      <TextInput source="job_type" data-testid="jobs-board-edit-job-type" />
      <TextInput source="category" data-testid="jobs-board-edit-category" />
      <BooleanInput source="active_job" />
    </SimpleForm>
  </Edit>
);

export default JobsBoardEdit;
