import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, BooleanField, DateField, TextField, NumberField } from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import ProfessionalInput from 'components/ProfessionalInput';
import LocationInput from 'components/LocationInput';
import JobInput from 'components/JobInput';
import DateFilter from 'components/Filter/DateFilter';

const CheckOutFilters = [
  <IdFilter />,
  <ProfessionalInput alwaysOn />,
  <LocationInput alwaysOn />,
  <JobInput alwaysOn />,
  <DateFilter source="search.date_from" alwaysOn />,
  <DateFilter source="search.date_to" alwaysOn />,
];

const CheckOutsList = () => (
  <List filters={CheckOutFilters} exporter={false} data-testid="check-outs-list">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Job ID" source="job_id" />
      <LinkField label="Professional ID" source="professional_id" />
      <LinkField label="Location ID" source="location_id" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="latitude" />
      <NumberField options={{ maximumFractionDigits: 10 }} source="longitude" />
      <BooleanField source="manual_override" />
      <BooleanField source="accepted" />
      <DateField showTime options={{ hour12: false }} source="time" />
      <TextField source="distance" />
    </Datagrid>
  </List>
);

CheckOutsList.query = gql`
  query GET_LIST($input: CheckOutQueryInput) {
    _checkOutsMeta(input: $input) {
      count
    }
    checkOuts(input: $input) {
      id
      job_id
      location_id
      professional_id
      latitude
      longitude
      accepted
      time
      distance
      manual_override
    }
  }
`;

export default CheckOutsList;
