import React from 'react';
import ReferenceArrayInput from 'components/ReferenceArrayInput';
import { Create, AutocompleteArrayInput, SimpleForm, TextInput, NumberInput } from 'react-admin';
import DateTimeInput from 'components/DateTimeInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import Heading from 'components/Heading';
import MoneyInput from 'components/MoneyInput';
import ProfessionalInput from 'components/ProfessionalInput';

const JobsCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <Heading>Job Billing Description</Heading>
      <NumberInput label="User" source="user_id" />
      <DateTimeInput source="starts_time" />
      <DateTimeInput source="ends_time" />
      <PositionInput multiple />
      <SpecialtyInput multiple positionSource="position_ids" />
      <ReferenceArrayInput label="Surgery types" reference="SurgeryType" source="surgery_type_ids">
        <AutocompleteArrayInput optionText="display_name" />
      </ReferenceArrayInput>
      {/* One time bonus charge user */}
      <TextInput multiline fullWidth source="detail" />
      {/* Parking detail */}
      <NumberInput source="parking_reimbursement_cents" />

      <Heading>Job Billing Status</Heading>
      <ProfessionalInput />
      <DateTimeInput source="booked_at" />
      <DateTimeInput source="approved_clock_in" />
      <DateTimeInput source="approved_clock_out" />
      <MoneyInput label="One Time Bonus" source="bonus_one_time_cents" />

      <Heading>Assign job_billing to assignment</Heading>
      <NumberInput label="Assignment ID" source="assignment_id" />

      <Heading>For Admin</Heading>
      <TextInput multiline fullWidth label="Notes" source="admin_notes" />
    </SimpleForm>
  </Create>
);

export default JobsCreate;
