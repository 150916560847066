import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Show, SimpleShowLayout, BooleanField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const ReferralSourceShow = () => (
  <Show data-testid="referral-source-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="label" />
      <BooleanField label="Admin Only" source="admin_only" />
      <TimestampFields />
      <BooleanField label="Applies to Professional" source="applies_to_professionals" />
      <BooleanField label="Applies to Facilities" source="applies_to_facilities" />
    </SimpleShowLayout>
  </Show>
);

ReferralSourceShow.query = gql`
  query referralSource($id: Int!) {
    referralSource(id: $id) {
      id
      name
      label
      admin_only
      created_at
      updated_at
      applies_to_professionals
      applies_to_facilities
    }
  }
`;

export default ReferralSourceShow;
