import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';

const ConditionInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput optionText="label" {...getChildrenProps(props)} />
  </ReferenceInput>
);

ConditionInput.defaultProps = {
  label: 'Condition',
  source: 'condition_id',
  reference: 'Condition',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
};

export default ConditionInput;
