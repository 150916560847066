import React from 'react';
import { Edit, SimpleForm, NumberInput, FileInput, FileField, TextInput } from 'react-admin';
import { transform } from '../../utils/formFileHelper';

const transformFile = (file) => {
  if (typeof file === 'string') {
    const startAt = file.lastIndexOf('/') + 1;
    const endAt = file.indexOf('?');
    const fileName = file.substring(startAt, endAt);
    return {
      src: file,
      title: fileName,
    };
  }
  if (!(file instanceof File)) {
    return file;
  }
  const preview = URL.createObjectURL(file);
  return {
    rawFile: file,
    src: preview,
    title: file.name,
  };
};

const ConditionDocumentsEdit = () => (
  <Edit mutationMode="pessimistic" transform={transform} redirect="show">
    <SimpleForm>
      <NumberInput label="Condition ID" source="condition_id" />
      <TextInput label="Category" source="category" />
      <TextInput source="name" />
      <FileInput
        source="file"
        format={transformFile}
        parse={transformFile}
        accept="application/pdf"
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export default ConditionDocumentsEdit;
