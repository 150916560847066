import PhoneInput from 'components/PhoneInput';
import React from 'react';
import { Edit, SimpleForm, TextInput, FileInput, NumberInput } from 'react-admin';

const DisputeShiftWitnessesEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="position" />
      <PhoneInput source="phone" />
      <TextInput source="email" />
    </SimpleForm>
  </Edit>
);

export default DisputeShiftWitnessesEdit;
