import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput, useResourceContext } from 'react-admin';

const StateInput = (props) => {
  const resource = useResourceContext(props);

  return (
    <ReferenceInput resource={resource} {...props}>
      <AutocompleteInput optionText="name" {...getChildrenProps(props)} />
    </ReferenceInput>
  );
};

StateInput.defaultProps = {
  label: 'State',
  source: 'state_id',
  reference: 'State',
  sort: { field: 'name', order: 'ASC' },
  perPage: 60,
};

export default StateInput;
