import React from 'react';
import ReferenceInput from 'components/ReferenceInput';
import { Create, SimpleForm, AutocompleteInput, TextInput } from 'react-admin';
import ProfessionalInput from 'components/ProfessionalInput';

const AttachmentsCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="license_field_id" reference="license_fields">
        <AutocompleteInput optionText="label" />
      </ReferenceInput>
      <ProfessionalInput />
      <TextInput source="value" />
    </SimpleForm>
  </Create>
);

export default AttachmentsCreate;
