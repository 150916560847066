import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import { Typography, Box } from '@mui/material';
import { AssignmentStatus } from 'components/Enum/enums';
import { useFormState, useWatch } from 'react-hook-form';

const AssignmentFormToolbar = () => {
  const { isSubmitSuccessful, errors } = useFormState();
  useWatch();
  const status = useWatch({ name: 'status' });

  return (
    <Toolbar>
      <SaveButton label={status === AssignmentStatus.draft ? 'Post' : 'Save'} alwaysEnable />
      {!isSubmitSuccessful && errors?.custom_error?.message && (
        <Box ml={3}>
          <Typography color="error">{errors.custom_error.message as string}</Typography>
        </Box>
      )}
    </Toolbar>
  );
};

export default AssignmentFormToolbar;
