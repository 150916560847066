import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, DateField, FunctionField, NumberInput } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import { IdFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import DateTimeField from 'components/DateTimeField';
import ProfessionalInput from 'components/ProfessionalInput';
import BillingGroupInput from '../../components/BillingGroupInput';

const DisputesFilters = [
  <IdFilter alwaysOn />,
  <NumberInput label="Job ID" source="job_id" alwaysOn />,
  <NumberInput label="Invoice ID" source="invoice_id" alwaysOn />,
  <NumberInput alwaysOn label="Location ID" source="search.location_id" />,
  <BillingGroupInput alwaysOn source="search.billing_group_id" />,
  <ProfessionalInput alwaysOn source="search.professional_id" />,
  <EnumSelectInput alwaysOn source="reason" />,
  <EnumSelectInput alwaysOn source="status" />,
  <EnumSelectInput alwaysOn source="category" />,
  <EnumSelectInput alwaysOn source="resolution" />,
  <AdministratorInput
    label="Assigned To"
    source="assigned_to_id"
    alwaysOn
    resettable
    filter={{ department: 'Dispute' }}
    sort={{ field: 'first_name', order: 'ASC' }}
  />,
];

const DisputesList = () => (
  <List exporter={false} filters={DisputesFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Job" source="job_id" />
      <LinkField label="Invoice" source="invoice_id" />
      <LinkField label="Assigned To ID" source="assigned_to.account.id" />
      <TextField label="Assigned To" source="assigned_to.account.name" />
      <FunctionField
        label="Billing Group Id"
        render={(v) => {
          const billingGroupId = v.job?.billing_group_id || v.invoice?.billing_group_id;
          return (
            <LinkField to={`/BillingGroup/${billingGroupId}/show`}>{billingGroupId}</LinkField>
          );
        }}
      />
      <DateTimeField tzSource="job.location.timezone_lookup" source="job.starts_date" />
      <DateTimeField tzSource="job.location.timezone_lookup" source="original_clock_in" />
      <DateTimeField tzSource="job.location.timezone_lookup" source="original_clock_out" />
      <DateTimeField tzSource="job.location.timezone_lookup" source="adjusted_clock_in" />
      <DateTimeField tzSource="job.location.timezone_lookup" source="adjusted_clock_out" />
      <TextField source="reason" />
      <TextField source="category" />
      <TextField source="status" />
      <TextField source="resolution" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

DisputesList.query = gql`
  query GET_LIST($input: DisputeQueryInput) {
    _disputesMeta(input: $input) {
      count
    }
    disputes(input: $input) {
      id
      job_id
      invoice_id
      job {
        id
        billing_group_id
        location_id
        location {
          id
          timezone_lookup
        }
      }
      invoice {
        id
        billing_group_id
      }
      assigned_to {
        id
        account {
          id
          first_name
          last_name
          name
        }
      }
      assigned_to_id
      original_clock_in
      original_clock_out
      adjusted_clock_in
      adjusted_clock_out
      reason
      status
      category
      resolution
      created_at
      updated_at
    }
  }
`;

export default DisputesList;
