import React from 'react';
import { gql } from '@apollo/client';
import { BooleanField, Show, SimpleShowLayout, TextField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';

const PostingFieldsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="label" />
      <TextField source="description" />
      <TextField source="slug" />
      <TextField source="field_type" />
      <BooleanField source="show_on_per_diem" />
      <BooleanField source="show_on_assignment" />
      <BooleanField source="required" />
      <DateTimeField source="created_at" />
      <DateTimeField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

PostingFieldsShow.query = gql`
  query GET_ONE($id: Int!) {
    postingField(id: $id) {
      id
      label
      description
      slug
      field_type
      show_on_per_diem
      show_on_assignment
      required
      created_at
      updated_at
      constraints
    }
  }
`;

export default PostingFieldsShow;
