import React from 'react';
import useDialog from 'hooks/useDialog';
import { CardMedia, Dialog, DialogContent } from '@mui/material';
import { getObjectPath } from 'utils/object';

const isBase64 = (value: string) => {
  if (value === '' || value.trim() === '') {
    return false;
  }
  try {
    return Buffer.from(value, 'base64').toString('base64') === value;
  } catch (err) {
    return false;
  }
};

const buildSrc = (record, source, extensionSource = 'extension') => {
  const recordSrc = getObjectPath(record, source.split('.'), '');
  const extension = getObjectPath(record, extensionSource.split('.'), '');
  if (recordSrc && extension && isBase64(recordSrc)) {
    return `data:image/${record.extension};base64,${recordSrc}`;
  }
  return recordSrc;
};

const ImageField = (props) => {
  const { record, source, extensionSource } = props;
  const src = buildSrc(record, source, extensionSource);
  const { open, openDialog, closeDialog } = useDialog();
  return (
    <>
      <CardMedia
        component="img"
        onClick={openDialog}
        image={src}
        sx={{
          cursor: 'zoom-in',
          display: 'block',
          height: 250,
          minWidth: 500,
          width: 'auto',
        }}
      />
      <Dialog maxWidth="md" open={open} onClose={closeDialog}>
        <DialogContent>
          <CardMedia
            component="img"
            onClick={closeDialog}
            sx={{
              cursor: 'zoom-out',
              display: 'block',
              margin: 'auto',
              maxHeight: '80vh',
              maxWidth: '100%',
              width: 'auto',
            }}
            image={src}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageField;
