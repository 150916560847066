import { IPostingField } from 'components/PostingField';

// 'constraints' are parsed while working with them in the form
// and then stringified before sending to the server
export const transformPayload = (data: IPostingField) => {
  const constraintsString = JSON.stringify(data.constraints);
  return { ...data, constraints: constraintsString };
};

export const getSelectedSingleSelectValue = (value: string) => {
  return value ? JSON.parse(value)?.[0] : null;
};

export const getSelectedMultiSelectValue = (value: string) => {
  return value ? JSON.parse(value) : [];
};
