import React, { useCallback, useState } from 'react';
import { Button, CircularProgress, ButtonGroup } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import config from 'config';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
import { useFileUpload } from 'hooks/useFileUpload';
import useCheckAccess from 'hooks/useCheckAccess';
import getAxios from 'getAxios';

const axios = getAxios();

export const W2ContractField = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  const { w2_contract, id, is_w2 } = record;
  const checkAccess = useCheckAccess();
  const { hiddenInput, uploading, openFilePrompt } = useFileUpload({
    url: `${config.rails_url}/v3/admin/assignments/${id}/w2_contract`,
    accept: 'application/pdf',
  });

  const [deleting, setDeleting] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const handleDelete = useCallback(() => {
    if (window.confirm('Delete W2 contract?')) {
      setDeleting(true);
      axios
        .delete(`/v3/admin/assignments/${id}/w2_contract`)
        .then(() => {
          notify('Deleted');
          refresh();
        })
        .catch((e) => notify(e.message, { type: 'error' }))
        .finally(() => setDeleting(false));
    }
  }, [id, notify, refresh]);

  if (!is_w2) {
    return null;
  }
  if (w2_contract) {
    return (
      <ButtonGroup size="small">
        <Button
          component="a"
          href={w2_contract}
          download={`${id}_w2_contract.pdf`}
          startIcon={<DownloadIcon />}
        >
          W2 contract
        </Button>
        {checkAccess('update', 'Attachment') && (
          <Button disabled={deleting} onClick={handleDelete}>
            {deleting ? <CircularProgress size={20} /> : <DeleteOutline />}
          </Button>
        )}
      </ButtonGroup>
    );
  }
  return (
    <>
      {hiddenInput}
      <Button
        disabled={uploading}
        color="primary"
        variant="outlined"
        size="small"
        onClick={openFilePrompt}
        startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
      >
        W2 contract
      </Button>
    </>
  );
};
