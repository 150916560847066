import React from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import useDialog from 'hooks/useDialog';
import {
  TextInput,
  regex,
  required,
  useRefresh,
  useNotify,
  SimpleForm,
  Toolbar,
  SaveButton,
} from 'react-admin';
import config from 'config';
import getAxios from 'getAxios';

const validateSSN = [required(), regex(/^\d{3}-\d{2}-\d{4}$/, 'Must be a valid SSN')];

const UploadItem = ({ record }) => {
  const { has_item, id } = record;
  const title = (has_item ? 'Update' : 'Set') + ' SSN';
  const { open, openDialog, closeDialog } = useDialog();
  const refresh = useRefresh();
  const notify = useNotify();
  const axios = getAxios();
  const sendTrackingEvent = () => {
    axios.post(`${config.rails_url}/v3/admin/professionals/${id}/identity_verification`)
         .catch((e) => notify(e.message, { type: 'error' }));
  }
  const handleSubmit = ({ ssn }: any) =>
    axios
      .post(
        `${config.item_url}/item/${id}`,
        { item: ssn },
        {
          withCredentials: false,
        },
      )
      .then(() => {
        notify('SSN was set');
        refresh();
        closeDialog();
        sendTrackingEvent();
      })
      .catch((e) => notify(e.message, { type: 'error' }));

  return (
    <>
      <Button
        data-testid="ssn-dialog"
        onClick={openDialog}
        variant={has_item ? 'text' : 'outlined'}
        color="primary"
        size="small"
      >
        {title}
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <Typography variant="h6" px={2} pt={3}>
          {title}
        </Typography>
        <SimpleForm
          onSubmit={handleSubmit}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" data-testid="submit-ssn" />
              <Button onClick={() => closeDialog()}>Cancel</Button>
            </Toolbar>
          }
        >
          <TextInput label="SSN" placeholder="XXX-XX-XXXX" validate={validateSSN} source="ssn" />
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default UploadItem;
