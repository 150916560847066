import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import PolymorphicField from 'components/PolymorphicField';
import DateTimeField from 'components/DateTimeField';

const CancellationsShow = () => (
  <Show data-testid="cancellations-show">
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField label="Job ID" source="job.id" />
      <DateTimeField
        label="Start Date"
        tzSource="job.location.timezone_lookup"
        source="job.starts_date"
      />
      <DateTimeField
        label="Starts Time"
        tzSource="job.location.timezone_lookup"
        source="job.starts_time"
      />
      <PolymorphicField source="personable" />
      <DateTimeField label="Booked at" source="booked_at" />
      <BooleanField source="cleared" />
      <TextField label="Cancellation Window" source="window" />
      <TextField label="Cancellation Reason" source="cancellation_reason.label" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

CancellationsShow.query = gql`
  query GET_ONE($id: Int!) {
    cancellation(id: $id) {
      id
      cancellation_reason {
        id
        label
      }
      personable {
        ... on Location {
          id
          __typename
        }
        ... on Professional {
          id
          __typename
        }
      }
      job {
        id
        starts_date
        starts_time
        location {
          id
          timezone_lookup
        }
      }
      cleared
      window
      booked_at
      created_at
      updated_at
    }
  }
`;

export default CancellationsShow;
