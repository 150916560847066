import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const PositionSpecialtiesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Position ID" source="position.id" />
      <TextField label="Specialty" source="specialty.name" />
    </Datagrid>
  </List>
);

PositionSpecialtiesList.query = gql`
  query GET_LIST($input: PositionSpecialtyQueryInput) {
    _positionSpecialtiesMeta(input: $input) {
      count
    }
    positionSpecialties(input: $input) {
      id
      position {
        id
        name
      }
      specialty {
        id
        name
        label
      }
    }
  }
`;

export default PositionSpecialtiesList;
