import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, Show, SimpleShowLayout } from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import TimestampFields from 'components/TimestampFields';

const ProfessionalBlockReasonShow = () => (
  <Show>
    <SimpleShowLayout>
      <NumberField source="id" />
      <EnumField source="kind" />
      <TextField source="label" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

ProfessionalBlockReasonShow.query = gql`
  query professionalBlockReason($id: Int!) {
    professionalBlockReason(id: $id) {
      id
      kind
      label
      created_at
      updated_at
    }
  }
`;

export default ProfessionalBlockReasonShow;
