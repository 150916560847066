import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useInput, useRecordContext, useTranslate } from 'react-admin';
import inflection from 'inflection';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { format, utcToZonedTime } from 'date-fns-tz';
import { isValid, parseISO } from 'date-fns';
import _ from 'lodash';

const DateFilter = (props) => {
  const record = useRecordContext();
  const { source, label, disabled, time_zone, tzSource, ...rest } = props;
  const { field, fieldState, isRequired } = useInput(props);
  const initialized = useRef(false);

  const onChange = (date: any) => {
    if (isValid(date)) {
      const formatted = format(date, 'yyyy-MM-dd', timezone ? { timeZone: timezone } : undefined);
      field.onChange(formatted);
    } else {
      field.onChange(null);
    }
  };
  const translate = useTranslate();
  const fieldLabel = useMemo(() => label || inflection.humanize(source), [source, label]);
  const timezone: string =
    time_zone || (tzSource && _.get(record, tzSource.split('.'))) || 'America/Los_Angeles';

  useEffect(() => {
    let dateValue: Date | null = null;

    if (!initialized.current) {
      dateValue = field.value ? utcToZonedTime(new Date(field.value), timezone) : null;
      initialized.current = true;
    }

    onChange(dateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  const formattedValue = () => {
    if (!field.value) {
      return null;
    }
    const formatter = (value: Date) => {
      if (timezone) {
        return format(value, "yyyy-MM-dd'T'HH:mm:ssXX", { timeZone: timezone });
      }
      return format(value, 'yyyy-MM-dd');
    };
    return _.flowRight<any, any, any>(formatter, parseISO)(field.value);
  };

  return (
    <FormControl margin="dense">
      <MobileDatePicker
        {...rest}
        {...field}
        label={fieldLabel}
        disabled={disabled}
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        value={formattedValue()}
        renderInput={(props) => (
          <FormControl required={isRequired} error={fieldState.isTouched && !!fieldState.error}>
            <TextField variant="filled" {...props} data-testid="date-input" />
            <FormHelperText>
              {fieldState.isDirty && fieldState.error?.message
                ? translate(fieldState.error.message)
                : ' '}
            </FormHelperText>
          </FormControl>
        )}
        onChange={onChange}
        inputFormat="yyyy-MM-dd"
      />
    </FormControl>
  );
};

export default DateFilter;
