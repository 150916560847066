import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const RefundFormTitle = ({ step }) => {
  const { watch } = useFormContext();
  const type = watch('type');

  return (
    <Typography variant="h6" pt={2}>
      {step === 'type' && 'Refund type'}
      {step === 'list' && type === 'check' && 'Check payments'}
      {step === 'list' && type === 'credit' && 'Credit card charges'}
      {step === 'list' && type === 'bank_debit' && 'Debit charges'}
      {step === 'list' && type === 'ach' && 'ACH charges'}
      {step === 'list' && type === 'stripe_ach_credit_transfer' && 'Stripe ACH charges'}
      {step === 'summary' && type === 'check' && 'Check Refund summary'}
      {step === 'summary' && type === 'credit' && 'CC Refund summary'}
      {step === 'summary' && type === 'bank_debit' && 'Debit Refund summary'}
      {step === 'summary' && type === 'ach' && 'ACH Refund summary'}
      {step === 'summary' && type === 'stripe_ach_credit_transfer' && 'Stripe ACH Refund summary'}
    </Typography>
  );
};

export default memo(RefundFormTitle);
