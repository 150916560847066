import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';
import InputUtils from 'utils/input';

const TimecardInput = (props) => (
  <ReferenceInput {...props}>
    <AutocompleteInput
      optionText={InputUtils.optionText('id')}
      {...getChildrenProps(props)}
      data-testid="timecard-input"
    />
  </ReferenceInput>
);

TimecardInput.defaultProps = {
  label: 'Timecard ID',
  source: 'timecard_id',
  reference: 'Timecard',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
  fullWidth: false,
};

export default TimecardInput;
