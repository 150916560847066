import React, { Fragment, useEffect, useState } from 'react';
import { Button, Labeled, useInput, useRecordContext } from 'react-admin';
import QualificationInput from './QualificationInput';
import { isEqual } from 'lodash';
import { isLastIndex } from 'utils/array';

/**
  Payload data structure
  e.g.
  [
    [
      {"rn_license" : {
        // RN license from state_id 1
        "rn_license_state": 1,
        // Empty objects means true
        "rn_license_exp_date": {}
      }},
    ],
    // OR
    [
      {"rn_license" : {
        // Front RN license
        "rn_license_front": {},
        // Back RN license
        "rn_license_back": {}
      }},
      // AND
      // Advanced Cardiac Life Support
      {"acls" : {
        // Front ACLS license
        "acls_front": {},
        // Back ACLS license
        "acls_back": {}
      }}
    ],
  ]
 */

const PayloadInput = () => {
  const record = useRecordContext();
  const {
    field: { onChange, value },
  } = useInput({ source: 'payload' });

  const [allQualifications, setAllQualifications] = useState([]);

  const [payload, setPayload] = useState([[{}]]);

  useEffect(() => {
    if (record?.payload) {
      setPayload(record.payload);
    }
  }, [record?.payload]);

  const addOrCriteria = () =>
    setPayload((prevPayload) => {
      const newPayload = [...prevPayload, [{}]];
      return newPayload;
    });

  const removeOrCriteria = (orIndex) =>
    setPayload((prevPayload) => {
      const newPayload = [...prevPayload];
      newPayload.splice(orIndex, 1);
      return newPayload;
    });

  const addAndCriteria = (orIndex) => () =>
    setPayload((prevPayload) => {
      const newPayload = [...prevPayload];
      const orCriteria = [...newPayload[orIndex]];
      orCriteria.push({});
      newPayload.splice(orIndex, 1, orCriteria);
      return newPayload;
    });

  const updateAndCriteria = (orIndex) => (andIndex, andCriteria) =>
    setPayload((prevPayload) => {
      const newPayload = [...prevPayload];
      const orCriteria = [...newPayload[orIndex]];
      orCriteria.splice(andIndex, 1, andCriteria);
      newPayload.splice(orIndex, 1, orCriteria);
      return newPayload;
    });

  const removeAndCriteria = (orIndex) => (andIndex) =>
    setPayload((prevPayload) => {
      const newPayload = [...prevPayload];
      const orCriteria = [...newPayload[orIndex]];
      orCriteria.splice(andIndex, 1);
      newPayload.splice(orIndex, 1, orCriteria);
      return newPayload;
    });

  const updateAndCriteriaAttributes = (orIndex) => (andIndex) => (slug, attributes) =>
    setPayload((prevPayload) => {
      if (slug) {
        const newPayload = [...prevPayload];
        const orCriteria = [...newPayload[orIndex]];
        const andCriteria = { ...orCriteria[andIndex] };
        andCriteria[slug] = { ...attributes };
        orCriteria.splice(andIndex, 1, andCriteria);
        newPayload.splice(orIndex, 1, orCriteria);
        if (!isEqual(newPayload, prevPayload)) {
          return newPayload;
        }
      }
      return prevPayload;
    });

  // Watches and updates the payload on any changes
  useEffect(() => {
    if (!isEqual(payload, value)) {
      onChange(payload);
    }
  }, [onChange, value, payload]);

  return (
    <Labeled label="Acceptance Criteria">
      <div>
        {payload.map((orCriteria, orIndex) => (
          <Fragment key={orIndex}>
            <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
              <Button
                label="X"
                style={{ marginTop: '8px', color: 'red' }}
                type="button"
                variant="text"
                size="large"
                onClick={() => removeOrCriteria(orIndex)}
              />
              <QualificationInput
                orIndex={orIndex}
                orCriteria={orCriteria}
                addAndCriteria={addAndCriteria(orIndex)}
                updateAndCriteria={updateAndCriteria(orIndex)}
                removeAndCriteria={removeAndCriteria(orIndex)}
                updateAndCriteriaAttributes={updateAndCriteriaAttributes(orIndex)}
                allQualifications={allQualifications}
                setAllQualifications={setAllQualifications}
              />
            </div>
            <div>{!isLastIndex(payload, orIndex) && <h3 style={{ margin: '16px 0' }}>OR</h3>}</div>
          </Fragment>
        ))}
        <Button
          label="Add Criteria"
          sx={{ marginTop: '16px' }}
          variant="outlined"
          type="button"
          onClick={addOrCriteria}
        />
      </div>
    </Labeled>
  );
};

export default PayloadInput;
