import React from 'react';
import { gql } from '@apollo/client';
import { BooleanField, TextField, Show, SimpleShowLayout } from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import TimestampFields from 'components/TimestampFields';

const VmsPositionShow = () => (
  <Show>
    <SimpleShowLayout>
      <IdField />
      <TextField label="VMS Type" source="vms_type.label" />
      <LinkField label="Position ID" source="position.id" />
      <TextField label="Position" source="position.display_name" />
      <TextField label="VMS Position ID" source="vms_source_id" />
      <TextField label="VMS Position Label" source="label" />
      <TextField label="VMS Category" source="vms_category_id" />
      <BooleanField source="active" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

VmsPositionShow.query = gql`
  query vms($id: Int!) {
    vmsPosition(id: $id) {
      id
      vms_type {
        id
        label
      }
      vms_type_id
      position {
        id
        display_name
      }
      position_id
      vms_source_id
      vms_category_id
      label
      active
      created_at
      updated_at
    }
  }
`;

export default VmsPositionShow;
