import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  TextInput,
  required,
  useRefresh,
  useNotify,
  useGetRecordId,
  SimpleForm,
} from 'react-admin';
import useApi from 'hooks/useApi';
import useDialog from 'hooks/useDialog';

const PayW2Button = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const api = useApi();
  const dialog = useDialog();
  const [loading, setLoading] = useState(false);
  const payoutId = useGetRecordId();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      await api({
        url: `/v3/admin/payouts/${payoutId}/w2_pay`,
        method: 'PUT',
        data: values,
      });

      refresh();
      notify(`Paid. Great success!`);
    } catch (error: any) {
      notify(error.message, { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        onClick={dialog.openDialog}
        color="primary"
        size="small"
        variant="outlined"
      >
        Pay W2
      </Button>

      <SimpleForm defaultValues={{ adp_id: undefined }} onSubmit={handleSubmit}>
        <Dialog fullWidth open={dialog.open} onClose={dialog.closeDialog}>
          <DialogTitle>Reject Reason</DialogTitle>
          <DialogContent>
            <TextInput
              placeholder="ADP ID"
              fullWidth
              source="adp_id"
              validate={[required()]}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={dialog.closeDialog} color="primary">
              Close
            </Button>

            <Button
              disabled={loading}
              aria-label="Reject"
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSubmit}
            >
              {loading ? <CircularProgress size={24} /> : 'Pay W2'}
            </Button>
          </DialogActions>
        </Dialog>
      </SimpleForm>
    </>
  );
};

export default PayW2Button;
