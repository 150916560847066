import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'PostingFields',
  inputShow: gql`
    query postingFields($input: PostingFieldQueryInput) {
      postingFields(input: $input) {
        id
        label
        description
        slug
        field_type
        show_on_per_diem
        show_on_assignment
        required
        constraints
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Misc',
    label: 'Posting Fields',
  },
};
