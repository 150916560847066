import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import {
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  SelectInput,
  BooleanField,
  Datagrid,
} from 'react-admin';
import List from 'components/List';
import PositionInput from 'components/PositionInput';
import IdField from 'components/IdField';
import { JobsBoardsListActions } from './ListActions';

const choices = [
  { id: 'AK', name: 'AK' },
  { id: 'AL', name: 'AL' },
  { id: 'AR', name: 'AR' },
  { id: 'AZ', name: 'AZ' },
  { id: 'CA', name: 'CA' },
  { id: 'CO', name: 'CO' },
  { id: 'CT', name: 'CT' },
  { id: 'DC', name: 'DC' },
  { id: 'DE', name: 'DE' },
  { id: 'FL', name: 'FL' },
  { id: 'GA', name: 'GA' },
  { id: 'HI', name: 'HI' },
  { id: 'IA', name: 'IA' },
  { id: 'ID', name: 'ID' },
  { id: 'IL', name: 'IL' },
  { id: 'IN', name: 'IN' },
  { id: 'KS', name: 'KS' },
  { id: 'KY', name: 'KY' },
  { id: 'LA', name: 'LA' },
  { id: 'MA', name: 'MA' },
  { id: 'MD', name: 'MD' },
  { id: 'ME', name: 'ME' },
  { id: 'MI', name: 'MI' },
  { id: 'MN', name: 'MN' },
  { id: 'MO', name: 'MO' },
  { id: 'MS', name: 'MS' },
  { id: 'MT', name: 'MT' },
  { id: 'NC', name: 'NC' },
  { id: 'ND', name: 'ND' },
  { id: 'NE', name: 'NE' },
  { id: 'NH', name: 'NH' },
  { id: 'NJ', name: 'NJ' },
  { id: 'NM', name: 'NM' },
  { id: 'NV', name: 'NV' },
  { id: 'NY', name: 'NY' },
  { id: 'OH', name: 'OH' },
  { id: 'OK', name: 'OK' },
  { id: 'OR', name: 'OR' },
  { id: 'PA', name: 'PA' },
  { id: 'PR', name: 'PR' },
  { id: 'RI', name: 'RI' },
  { id: 'SC', name: 'SC' },
  { id: 'SD', name: 'SD' },
  { id: 'TN', name: 'TN' },
  { id: 'TX', name: 'TX' },
  { id: 'UT', name: 'UT' },
  { id: 'VA', name: 'VA' },
  { id: 'VT', name: 'VT' },
  { id: 'WA', name: 'WA' },
  { id: 'WI', name: 'WI' },
  { id: 'WV', name: 'WV' },
  { id: 'WY', name: 'WY' },
];

const JobsBoardListFilters = [
  <IdInput />,
  <PositionInput multiple alwaysOn source="search.position_ids" />,
  <SelectInput label="State" resettable choices={choices} alwaysOn source="state" />,
  <TextInput label="City" resettable alwaysOn source="search.city" />,
  <TextInput label="Market ID" resettable alwaysOn source="search.market_id" />,
];

const JobsBoardList = () => (
  <List
    actions={<JobsBoardsListActions />}
    filters={JobsBoardListFilters}
    exporter={false}
    data-testid="jobs-board-list"
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="board" />
      <TextField source="role" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField label="Market" source="market.display_name" />
      <TextField label="Market Id" source="market_id" />
      <ArrayField source="positions">
        <SingleFieldList>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Position Ids" source="positions">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="title" />
      <TextField label="Job Type" source="job_type" />
      <TextField source="salary" />
      <BooleanField label="Active Job" source="active_job" />
    </Datagrid>
  </List>
);

JobsBoardList.query = gql`
  query jobsBoards($input: JobsBoardQueryInput) {
    _jobsBoardsMeta(input: $input) {
      count
    }
    jobsBoards(input: $input) {
      id
      board
      role
      city
      state
      title
      market_id
      market {
        id
        display_name
      }
      positions {
        id
        display_name
      }
      job_type
      salary
      active_job
    }
  }
`;

export default JobsBoardList;
