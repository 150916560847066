import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';
import { FunctionField } from 'react-admin';

interface IdFieldProps {
  label?: string;
  sortBy?: string;
  source?: string;
  action?: string;
  resource?: string;
}

const IdField = ({ label, sortBy, action, resource }: IdFieldProps) => {
  const theme = useTheme();

  return (
    <FunctionField
      label={label}
      sortBy={sortBy}
      data-testid="id_field"
      render={(record) => (
        <Typography>
          <RRLink
            to={`/${resource ?? record.__typename}/${record.id}/${action}`}
            style={{ color: theme.palette.primary.main }}
          >
            {record.id}
          </RRLink>
        </Typography>
      )}
    />
  );
};

IdField.defaultProps = {
  label: 'ID',
  sortBy: 'id',
  action: 'show',
};

export default IdField;
