import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const ClockEventAttachmentsList = () => (
  <List hasCreate exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
    </Datagrid>
  </List>
);

ClockEventAttachmentsList.query = gql`
  query ClockEventAttachments($input: ClockEventAttachmentQueryInput) {
    clockEventAttachments(input: $input) {
      id
      clock_event_type
      clock_event_id
      file
      reasons
      created_at
      updated_at
      metadata
    }
  }
`;

export default ClockEventAttachmentsList;
