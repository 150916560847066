import React, { useEffect } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTranslate } from 'ra-core';
import { useTheme } from 'react-admin';
import { themeFromType } from 'theme';

const lightTheme = themeFromType('light');
const darkTheme = themeFromType('dark');

// Extended version of react admin's ToggleThemeButton

enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

const THEME_KEY = 'ra.theme';
const DEFAULT_THEME = Theme.LIGHT;

const getSavedTheme = (): Theme => {
  const currentSavedTheme = localStorage.getItem(THEME_KEY);
  if (currentSavedTheme) {
    return currentSavedTheme as Theme;
  }
  return DEFAULT_THEME;
};

export const ToggleThemeButton = () => {
  const translate = useTranslate();
  const [theme, setTheme] = useTheme();

  useEffect(() => {
    const currentSavedTheme = getSavedTheme();
    if (currentSavedTheme === Theme.LIGHT) {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  }, [theme, setTheme]);

  const handleTogglePaletteType = (): void => {
    const isCurrentlyDarkTheme = theme.palette?.mode === Theme.DARK;
    if (isCurrentlyDarkTheme) {
      setTheme(lightTheme);
      localStorage.setItem(THEME_KEY, Theme.LIGHT);
    } else {
      setTheme(darkTheme);
      localStorage.setItem(THEME_KEY, Theme.DARK);
    }
  };

  const toggleThemeTitle = translate('ra.action.toggle_theme', {
    _: 'Toggle Theme',
  });

  return (
    <Tooltip title={toggleThemeTitle} enterDelay={300} data-testid="toggle-theme-button">
      <IconButton color="inherit" onClick={handleTogglePaletteType} aria-label={toggleThemeTitle}>
        {theme?.palette?.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
};
